package com.erstedigital.socialbank.ui.dashboard

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.dashboard.store.DashboardStore
import com.erstedigital.socialbank.ui.dashboard.store.DashboardStoreFactory
import com.erstedigital.socialbank.ui.login.LoginComponent
import com.erstedigital.socialbank.ui.profile.store.ProfileStore
import com.erstedigital.socialbank.ui.profile.store.ProfileStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class DashboardComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
): ComponentContext by componentContext {
    private val dashboardStore = instanceKeeper.getStore {
        DashboardStoreFactory(storeFactory).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<DashboardStore.State> = dashboardStore.stateFlow

    val label: Flow<DashboardStore.Label> = dashboardStore.labels

    fun onEvent(event: DashboardStore.Intent) {
        dashboardStore.accept(event)
    }

    fun onOutput(output: Output){
        when (output) {
            else -> Unit
        }
    }


    sealed class Output {
        object OpenAuthorizationFlow  : Output()
        object NavigateToTransactions : DashboardComponent.Output()

    }

}
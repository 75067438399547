package com.erstedigital.socialbank.ui.documents

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.documents.details.DetailsComponent
import com.erstedigital.socialbank.ui.documents.store.DmsStore
import com.erstedigital.socialbank.ui.documents.store.DmsStoreFactory
import com.erstedigital.socialbank.ui.transactions.TransactionsComponent
import com.erstedigital.socialbank.ui.transactions.detail.DetailComponent
import com.erstedigital.socialbank.ui.transactions.products.ProductsComponent
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class DmsComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
): ComponentContext by componentContext {
    private val documentStore = instanceKeeper.getStore {
        DmsStoreFactory(storeFactory).create()
    }

    private val navigation = StackNavigation<Configuration>()


    private val stack = childStack(
        source = navigation,
        initialStack = { listOf(Configuration.Undefined) },
        childFactory = ::createChild,
    )

    val childStack: Value<ChildStack<*, Child>> = stack

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<DmsStore.State> = documentStore.stateFlow

    val label: Flow<DmsStore.Label> = documentStore.labels

    private fun createChild(configuration: Configuration, componentContext: ComponentContext): Child =
        when (configuration) {
            is Configuration.Undefined -> Child.Undefined
            is Configuration.Details -> Child.Details(
                DetailsComponent(
                    componentContext,
                    storeFactory,
                    configuration.documentId
                ),
                configuration.editable
            )
            is Configuration.NewDocumentDetails -> Child.NewDocumentDetails(
                DetailsComponent(
                    componentContext,
                    storeFactory,
                    null
                ),
            )
        }

    fun onEvent(event: DmsStore.Intent) {
        documentStore.accept(event)
    }

    fun onOutput(output: Output){
        when (output) {
            is Output.NavigateToDetails -> navigation.push(
                Configuration.Details(output.documentId, output.editable))
            is Output.NavigateBack -> navigation.pop()
            is Output.NavigateToNewDocument -> navigation.push(Configuration.NewDocumentDetails)
        }
    }


    sealed class Output {
        data object NavigateBack : Output()
        data class NavigateToDetails(val documentId: Long, val editable: Boolean): Output()

        data object NavigateToNewDocument : Output()
    }

    sealed class Child {
        data class Details(val detailsComponent: DetailsComponent, val editable: Boolean) : Child()
        data class NewDocumentDetails(val detailsComponent: DetailsComponent) : Child()

        data object Undefined : Child()

    }

    private sealed class Configuration: Parcelable {

        @Parcelize
        data class Details(val documentId: Long, val editable: Boolean) : Configuration()

        @Parcelize
        data object NewDocumentDetails : Configuration()

        @Parcelize
        data object Undefined : Configuration()


    }

}
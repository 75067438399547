package com.erstedigital.overdokladapi.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Item(
    @SerialName("name") val name : String,
    @SerialName("itemType") val itemType : String,
    @SerialName("quantity") val quantity : Double,
    @SerialName("vatRate") val vatRate : Double,
    @SerialName("price") val price : Double
)
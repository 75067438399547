package com.erstedigital.socialbank.data.datasources.dms

import com.erstedigital.socialbank.data.datasources.dashboard.DashboardDataSource
import com.erstedigital.socialbank.data.network.models.common.Attachment
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.request.UpdateAttachmentDTO
import com.erstedigital.socialbank.data.network.models.response.DashboardResponse
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeFormRequest
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.get
import io.ktor.client.request.request
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.client.statement.HttpResponse
import io.ktor.http.ContentType
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import io.ktor.http.path
import kotlinx.serialization.json.Json

class DmsRemoteDataSource constructor(private val httpClient: HttpClient): DmsDataSource.Remote {
    override suspend fun getFiles(): ApiResponse<Document, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("files")
            }

        }
    }

    override suspend fun getDocument(documentId: Long): ApiResponse<Document, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("files/document")
                parameters.append("documentId", documentId.toString())
            }

        }
    }

    override suspend fun downloadAttachment(attachmentId: Long): HttpResponse {
        return httpClient.request {
            url {
                method = HttpMethod.Get
                path("files/attachment")
                parameters.append("attachmentId", attachmentId.toString())
            }

        }
    }

    override suspend fun createDocument(document: Document, files: List<Triple<String, ByteArray, String>>?): ApiResponse<Document, ErrorResponse> {
        return httpClient.safeFormRequest(
            formData = formData {
                append("documentProperties", Json.encodeToString(Document.serializer(), document), Headers.build {
                    append(HttpHeaders.ContentType, ContentType.Application.Json.toString())
                    //append(HttpHeaders.ContentDisposition, "form-data; name=\"documentProperties\"")
                })
                files?.forEach {
                    append("files", it.second, Headers.build {
                        append(HttpHeaders.ContentType, it.third)
                        append(HttpHeaders.ContentDisposition, "filename=\"${it.first}\"")
                    })
                }
            },
        ){
            url {
                path("files")
                method = HttpMethod.Post
            }
            //contentType(ContentType.MultiPart.FormData)
        }
    }

    override suspend fun updateDocument(document: Document, files: List<Triple<String, ByteArray, String>>?): ApiResponse<Document, ErrorResponse> {
        return httpClient.safeFormRequest(
            formData = formData {
                append("documentProperties", Json.encodeToString(Document.serializer(), document), Headers.build {
                    append(HttpHeaders.ContentType, ContentType.Application.Json.toString())
                    //append(HttpHeaders.ContentDisposition, "form-data; name=\"documentProperties\"")
                })
                files?.forEach {
                    append("files", it.second, Headers.build {
                        append(HttpHeaders.ContentType, it.third)
                        append(HttpHeaders.ContentDisposition, "filename=\"${it.first}\"")
                    })
                }
            },
        ){
            url {
                path("files")
                method = HttpMethod.Put
            }
            //contentType(ContentType.MultiPart.FormData)
        }
    }

    override suspend fun updateAttachment(request: UpdateAttachmentDTO): ApiResponse<Attachment, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Put
                path("files")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }
    }

    override suspend fun deleteAttachment(attachmentId: Long): ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Delete
                path("files/attachment")
                parameters.append("attachmentId", attachmentId.toString())
            }
        }
    }

    override suspend fun deleteDocument(documentId: Long): ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Delete
                path("files/document")
                parameters.append("documentId", documentId.toString())
            }
        }
    }

}
package com.erstedigital.socialbank.ui.planning.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedExpenseRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedIncomeRequest
import com.erstedigital.socialbank.domain.models.ExpectedExpenseModel
import com.erstedigital.socialbank.domain.models.ExpectedIncomeModel

interface PlanningStore:
    Store<PlanningStore.Intent, PlanningStore.State, PlanningStore.Label> {

    sealed class Intent {
        data class UpdateExpectedIncome(val expectedIncome: UpdateExpectedIncomeRequest): Intent()
        data class UpdateExpectedExpense(val expectedExpense: UpdateExpectedExpenseRequest): Intent()

        data object SignOut: Intent()
    }

    data class State(
        val loading: Boolean = true,
        val error: String? = null,
        val expectedIncome: ExpectedIncomeModel? = null,
        val expectedExpense: ExpectedExpenseModel? = null
    )

    sealed interface Label {

        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }
}
package com.erstedigital.socialbank.ui.statistics.components

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.ModalBottomSheetState
import androidx.compose.material.ModalBottomSheetValue
import androidx.compose.material.rememberModalBottomSheetState
import androidx.compose.material3.DateRangePickerState

import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.rememberDateRangePickerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.erstedigital.socialbank.domain.models.StatisticsModel
import compose.icons.FeatherIcons
import compose.icons.feathericons.Calendar
import kotlinx.coroutines.launch


enum class Mode {
    WEEK,
    MONTH,
    CUSTOM
}

@OptIn(ExperimentalFoundationApi::class, ExperimentalMaterial3Api::class,
    ExperimentalMaterialApi::class
)
@Composable
fun StatisticsScreenContent(
    statistics: StatisticsModel,
    onPieChartClick: (List<Triple<String, Float, Float>>, String) -> Unit,
    handleShowDateRangePicker: () -> Unit,
) {
    val mode = remember { mutableStateOf(Mode.WEEK) }
    val pagerState = rememberPagerState(pageCount = { if (mode.value == Mode.WEEK) statistics.weekly.size else statistics.monthly.size })
    val scrollState = rememberScrollState()

    val currStart = remember { mutableStateOf((if (mode.value == Mode.WEEK) statistics.weekly[pagerState.currentPage] else statistics.monthly[pagerState.currentPage]).startDate) }
    val currEnd = remember { mutableStateOf((if (mode.value == Mode.WEEK) statistics.weekly[pagerState.currentPage] else statistics.monthly[pagerState.currentPage]).endDate) }
    val currSlice = remember { mutableStateOf(if (mode.value == Mode.WEEK) statistics.weekly[pagerState.currentPage] else statistics.monthly[pagerState.currentPage]) }

    LaunchedEffect(pagerState) {
        snapshotFlow { pagerState.currentPage }.collect { page ->
            currStart.value = (if (mode.value == Mode.WEEK) statistics.weekly[page] else statistics.monthly[page]).startDate
            currEnd.value = (if (mode.value == Mode.WEEK) statistics.weekly[page] else statistics.monthly[page]).endDate
            currSlice.value = if (mode.value == Mode.WEEK) statistics.weekly[page] else statistics.monthly[page]
        }
    }

    Scaffold(
        topBar =  {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding()
                    .background(MaterialTheme.colorScheme.surface)
                ,
            ) {
                Row(
                    modifier = Modifier.padding(start = 20.dp, top = 15.dp, bottom = 15.dp, end = 20.dp).fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    Text(
                        "${currStart.value.toString().split("-").reversed().joinToString(".")} - ${currEnd.value.toString().split("-").reversed().joinToString(".")}",
                        style = MaterialTheme.typography.headlineSmall,
                    )
                    IconButton(
                        onClick = {
                            handleShowDateRangePicker()
                        },
                    ) {
                        Icon(
                            FeatherIcons.Calendar,
                            "Change mode"
                        )
                    }
                }
            }
        }
    ) {
        Column(
            modifier = Modifier.verticalScroll(scrollState).padding(bottom = 120.dp, top = 70.dp)
        ) {

            Box(
                modifier = Modifier.fillMaxWidth(),
            ) {
                HorizontalPager(
                    state = pagerState,
                    pageSpacing = 8.dp,
                    contentPadding = PaddingValues(20.dp),
                    userScrollEnabled = true,
                    reverseLayout = false,
                    pageContent = { page ->
                        CarouselItem(if (mode.value == Mode.WEEK) statistics.weekly[page] else statistics.monthly[page])
                    }
                )
            }

            MonthlyBalanceCardComposable()
            BalanceCard(currSlice.value, onPieChartClick)
            BudgetCard(currSlice.value, onPieChartClick)
            ProductCategoryCard(currSlice.value.productCategories, onPieChartClick)
        }
    }
}


package com.erstedigital.socialbank.domain.models

import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class TransactionModel(
    val id: Long,
    val manualTransaction: ManualTransactionModel?,
    val fsReceiptModel: FsReceiptModel?,
    val transactions: List<TransactionListModel>,
    val splits: List<SplitModel>,
    val user: UserModel
) {

    fun getTotalAmount(): Double {
        if (fsReceiptModel != null) {
            return fsReceiptModel.totalPrice ?: 0.0
        }
        if (manualTransaction != null) {
            return manualTransaction.amount ?: 0.0
        }
        return transactions.sumByDouble { it.price ?: 0.0 }
    }

    fun getTotalProductAmount(): Double {
        if (fsReceiptModel != null) {
            return fsReceiptModel.items.sumByDouble { it.getTotalPrice() }
        }
        return 0.0
    }

    fun getOrganization(): OrganizationModel? {
        return fsReceiptModel?.organization ?: manualTransaction?.organization
    }

    fun getType(): TransactionType {
        if (fsReceiptModel != null) {
            return TransactionType.FS_RECEIPT
        }

        if (manualTransaction != null) {
            val amount = manualTransaction.amount ?: 0.0
            return when {
                amount > 0 -> TransactionType.INCOME
                amount < 0 -> TransactionType.EXPENSE
                else -> TransactionType.UNKNOWN
            }
        }
        if (transactions.isNotEmpty()) {
            return TransactionType.MULTI
        }
        return TransactionType.UNKNOWN
    }

    fun getDate(): LocalDate? {
        return fsReceiptModel?.getIssueLocalDate() ?: manualTransaction?.date
    }

    fun getCategory(): String? {
        return fsReceiptModel?.category ?: manualTransaction?.category
    }

    fun toUpdateManualTransactionRequest(): UpdateManualTransactionRequest {
        return UpdateManualTransactionRequest(
            id = manualTransaction?.id ?: 0,
            date = manualTransaction?.date,
            price = manualTransaction?.amount ?: 0.0,
            organizationId = manualTransaction?.organization?.id,
            category = manualTransaction?.category
        )
    }

}
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.mvikotlin.logging.store.LoggingStoreFactory
import com.arkivanov.mvikotlin.main.store.DefaultStoreFactory
import com.erstedigital.socialbank.di.appModule
import com.erstedigital.socialbank.di.external.Constants
import com.erstedigital.socialbank.di.initKoin
import com.erstedigital.socialbank.di.logging.KoinLogger
import com.erstedigital.socialbank.di.logging.MviLogger
import com.erstedigital.socialbank.ui.react.root.RootContent
import com.erstedigital.socialbank.ui.root.RootComponent
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import io.github.aakira.napier.DebugAntilog
import io.github.aakira.napier.Napier
import react.create
import react.dom.client.createRoot
import web.dom.document

@OptIn(ExperimentalDecomposeApi::class)
fun main() {
    val lifecycle = LifecycleRegistry()
    val componentContext = DefaultComponentContext(lifecycle = lifecycle)

    val options = FirebaseOptions(
        applicationId = Constants.appId,
        apiKey = Constants.apiKey,
        databaseUrl = Constants.databaseURL,
        projectId = Constants.projectId,
        gcmSenderId = Constants.messagingSenderId,
        authDomain = Constants.authDomain,
        storageBucket = Constants.storageBucket,
        gaTrackingId = Constants.measurementId,
    )

    Napier.base(DebugAntilog())

    initKoin() {
        logger(KoinLogger())
        modules(appModule)
    }

    Firebase.initialize(options = options)

    val rootComponent = RootComponent(
        componentContext = componentContext,
        storeFactory = LoggingStoreFactory(DefaultStoreFactory(), logger = MviLogger()),
    )

    lifecycle.resume()

    createRoot(document.getElementById("app")!!).render(
        RootContent.create {
            component = rootComponent
        }
    )

}
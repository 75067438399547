package com.erstedigital.socialbank.ui.react.statistics.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import io.data2viz.charts.chart.chart
import io.data2viz.charts.chart.discrete
import io.data2viz.charts.chart.mark.bar
import io.data2viz.charts.chart.mark.line
import io.data2viz.charts.chart.quantitative
import io.data2viz.charts.configuration.AxisConfiguration
import io.data2viz.charts.core.GridPosition
import io.data2viz.charts.core.HighlightMode
import io.data2viz.charts.core.MarkLabel
import io.data2viz.charts.viz.newVizContainer
import io.data2viz.color.Colors
import io.data2viz.geom.Size
import io.data2viz.math.Percent
import io.github.aakira.napier.Napier
import kotlinx.browser.document
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import react.useEffect
import react.useRef
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.pct
import web.cssom.px
import kotlin.math.roundToInt

external interface BalanceChartCardProps: Props {
    var slice: StatisticsModel.DateSlice
}

val BalanceChartCard: FC<BalanceChartCardProps> = FC { props ->

    val containerRef = useRef<HTMLDivElement>(null)
    val weekDays = listOf("Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun")

    useEffect(props.slice) {

        val d = document.getElementById("chart-balance") as HTMLDivElement
        d.innerHTML = ""

        val distinctVals = props.slice.chart.points.map { it.balance }.distinct()
        val isBalanceConstant = distinctVals.size == 1

        if (isBalanceConstant) {
            d.innerHTML = "<div style=\"display: flex; justify-content: center; align-items: center; height: 100%;\"><h3>Balance is ${distinctVals.get(0).times(100).roundToInt().div(100.0)} €</h3></div>"
            return@useEffect
        }
        val vc = d.newVizContainer().apply {
            size = Size(300.0, 200.0)
        }
        vc.chart(data = props.slice.chart.points,) {

            config {
                backgroundColor = Colors.rgb(0xE4EAF0)
                yAxis {
                    enableAxisLine = false
                    //enableAxis = false
                    enableTicksLabels = true
                }
                xAxis {
                    enableAxisLine = false
                    enableTicks = false
                }
                mark {
                    fills = listOf(Colors.rgb(0xFF6130))
                    fillsHighlight = listOf(Colors.rgb(0xFF6130))
                    fillsSelect = listOf(Colors.rgb(0xFF6130))
                    strokeColors = listOf(Colors.rgb(0xFF6130))
                    strokeColorsHighlight = listOf(Colors.rgb(0xFF6130))
                    strokeColorsSelect = listOf(Colors.rgb(0xFF6130))
                    label = MarkLabel.Y
                    labelPosition = GridPosition.TopRight
                    labelDistance = 1.0
                }
            }

            val dayDimension =  if (props.slice.type === StatisticsModel.DateSlice.Type.WEEK) {
                discrete({ weekDays[domain.date?.dayOfWeek?.ordinal!!]})
            } else {
                discrete({ domain.date?.dayOfMonth.toString() })
            }
            val expenseDimension = quantitative({ domain.balance })

            line(dayDimension, expenseDimension)

        }
    }

    //div {
    //    id = "chart"
    //}
    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 20.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
        }

        elevation = 2

        CardContent {
            Box {
                sx {
                    width = 300.px
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    variant = TypographyVariant.body1
                    +MainRes.string.balance_over_time
                }
                Box {
                    sx {
                        width = 100.pct
                        height = 300.px
                    }
                    id = "chart-balance"
                }
            }
        }
    }
}
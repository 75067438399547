package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.StatisticsModel
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
class StatisticsPoint(
    val date: LocalDate? = null,
    var income: Double = 0.0,
    var expense: Double = 0.0,
    val balance: Double = 0.0
) {
    fun toModel(): StatisticsModel.Point {
        return StatisticsModel.Point(income, expense, balance, date)
    }


}
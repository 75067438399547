package com.erstedigital.socialbank.domain.models

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.toArgb
import kotlinx.serialization.Serializable
import kotlin.random.Random

@Serializable
data class UserModel(
    val id: Long?,
    var fullName: String?,
    var _color: Int? = getRandomColor(),
    var email: String? = null,
    var phoneNumber: String? = null,
    var selected: Boolean = false,
    var iban: String? = null,
    val groups: List<GroupModel> = emptyList(),
    var isMe: Boolean = false,
    var isAnonymous: Boolean = false,
    val allowAnalysis: Boolean? = null
) {

    var color: Int?
        get() {
            if (_color == null) {
                _color = getRandomColor()
            }
            return _color
        }
        set(value) {
            _color = value
        }

    fun getInitials(name: String?): String {
        val split = name?.split(" ") ?: emptyList()
        return when {
                split.size == 1 -> split.first().getOrNull(0)?.toString() ?: ""
                split.size > 1 -> "${split.first().firstOrNull() ?: ""}${split.last().firstOrNull() ?: ""}"
                else -> ""
            }.uppercase()
    }

    fun getInitials(): String {
        val split = fullName?.split(" ") ?: emptyList()
        return when {
            split.size == 1 -> split.first().getOrNull(0)?.toString() ?: ""
            split.size > 1 -> "${split.first().firstOrNull() ?: ""}${split.last().firstOrNull() ?: ""}"
            else -> "AN"
        }.uppercase()
    }

    // Note: 'withMe' parameter is unused. If required, you can modify accordingly.
    fun getFormattedName(name: String?, meString: String, anonymousString: String): String {
        if (isMe) {
            return "${name ?: anonymousString} ($meString)"
        }
        if (name.isNullOrEmpty()) {
            return anonymousString
        }
        return name
    }

    companion object {
        fun getRandomColor(): Int {
            // Add your cross-platform color generation code here
            // For simplicity, I'm just returning a random color
            // TODO: Add color generation code here
            val random = Random(1)
            return Color(
                random.nextInt(0, 255),
                random.nextInt(0, 255),
                random.nextInt(0, 255)
            ).toArgb()
        }
    }
}
package com.erstedigital.socialbank.ui.react.home

import androidx.compose.ui.ExperimentalComposeUiApi
import com.erstedigital.socialbank.ui.home.HomeComponent
import com.erstedigital.socialbank.ui.home.store.HomeStore
import com.erstedigital.socialbank.ui.home.store.TransactionState
import com.erstedigital.socialbank.ui.react.dashboard.DashboardContent
import com.erstedigital.socialbank.ui.react.dms.Dms
import com.erstedigital.socialbank.ui.react.dms.screens.DetailsScreen
import com.erstedigital.socialbank.ui.react.home.components.BottomNavBar
import com.erstedigital.socialbank.ui.react.home.components.DuplicateDialog
import com.erstedigital.socialbank.ui.react.home.components.ScannerResultDrawer
import com.erstedigital.socialbank.ui.react.planning.PlanningContent
import com.erstedigital.socialbank.ui.react.profile.ProfileContent
import com.erstedigital.socialbank.ui.react.statistics.StatisticsContent
import com.erstedigital.socialbank.ui.react.transactions.ManualTransactionContent
import com.erstedigital.socialbank.ui.react.transactions.TransactionsContent
import com.erstedigital.socialbank.ui.react.utils.*
import com.erstedigital.socialbank.ui.utils.RProps
import com.erstedigital.socialbank.ui.utils.useAsState
import js.core.jso
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.Box
import mui.material.CircularProgress
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import web.cssom.*
import web.storage.localStorage

// Props - RProps and children
@OptIn(ExperimentalComposeUiApi::class)
val HomeContent: FC<RProps<HomeComponent>> = FC { props ->

    val (state, setState) = useState(props.component.state.value)
    val (childStack, _) = props.component.childStack.useAsState()
    val (showNavbar, setShowNavbar) = useState(true)
    val (showBottomSheet, setShowBottomSheet) = useState(false)

    useEffect(state.transactionState){
        val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
        val localStorageNavigation = localStorage.getItem("navigation")
        if(localStorageNavigation != null) {
            if(localStorageNavigation == "dashboard") {
                props.component.onOutput(HomeComponent.Output.NavigateToDashboard)
            } else if(localStorageNavigation == "transactions") {
                props.component.onOutput(HomeComponent.Output.NavigateToTransactions)
            } else if(localStorageNavigation == "statistics") {
                props.component.onOutput(HomeComponent.Output.NavigateToStatistics)
            } else if(localStorageNavigation == "profile") {
                props.component.onOutput(HomeComponent.Output.NavigateToProfile)
            } else if (localStorageNavigation == "planning") {
                props.component.onOutput(HomeComponent.Output.NavigateToPlanning)
            }
        }
    }

    useEffect(state.transactionState) {
        when (state.transactionState) {
            TransactionState.CREATED -> {
                setShowBottomSheet(true)
            }
            else -> {
                setShowBottomSheet(false)
            }
        }
    }

    useEffect(state.manualTransactionClicked) {
        if (state.manualTransactionClicked) {
            props.component.onOutput(HomeComponent.Output.NavigateToManualTransaction)
            setShowNavbar(false)
        }
    }

    /*Box {
        sx {
            display = headerDisplay
            position = headerPosition
            padding = headerPadding
            top = headerTop
            bottom = headerBottom
            left = headerLeft
            right = headerRight
            backgroundColor = headerBackgroundColor
            height = headerHeight
            justifyContent = headerJustifyContent
            alignItems = headerAlignItems
            //zIndex = 1000
        }
        ReactHTML.img {
            style = jso {
                height = headerImgHeight
                objectFit = headerImgObjectFit
                marginRight = headerImgMarginRight
            }
            src = headerImgSrc
        }
    }*/
    Box {
        sx {
            width = 100.pct
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            overflow = Overflow.hidden
            flexGrow = number(1.0)
            marginTop = firstBoxMargin
        }
        Box {
            sx {
                display = headerDisplay
                position = headerPosition
                padding = headerPadding
                top = headerTop
                bottom = headerBottom
                left = headerLeft
                right = headerRight
                backgroundColor = headerBackgroundColor
                height = headerHeight
                justifyContent = headerJustifyContent
                alignItems = headerAlignItems
            }
            ReactHTML.img {
                style = jso {
                    height = headerImgHeight
                    objectFit = headerImgObjectFit
                    marginRight = headerImgMarginRight
                }
                src = headerImgSrc
            }
        }


        DuplicateDialog {
            setShowDialog = { props.component.onEvent(HomeStore.Intent.DismissDuplicateDialog) }
            onConfirm = { props.component.onEvent(HomeStore.Intent.DuplicateTransaction) }
            onDismiss = {
                props.component.onEvent(HomeStore.Intent.DismissDuplicateDialog)
                props.component.onEvent(HomeStore.Intent.ScanQrCode)
            }
            open = state.transactionState === TransactionState.DUPLICATE
        }

        ScannerResultDrawer {
            transactionModel = state.transaction
            onCategoryClicked = {
                props.component.onEvent(
                    HomeStore.Intent.UpdateCategory(
                        state.transaction?.id!!,
                        it.let {  "${it.primary}/${it.secondary}" }
                    )
                )
            }
            onDetailClicked = {
                setShowNavbar(false)
                props.component.onEvent(HomeStore.Intent.DismissResultBottomSheet)
                props.component.onOutput(HomeComponent.Output.NavigateToTransactionDetail(
                    state.transaction?.id!!
                ))
            }
            onClose = {
                props.component.onEvent(HomeStore.Intent.DismissResultBottomSheet)
                props.component.onEvent(HomeStore.Intent.ScanQrCode)
            }
            open = showBottomSheet
        }

        if (state.loading) {
            CircularProgress {
                sx {
                    position = Position.absolute
                    top = 50.pct
                    left = 50.pct
                }
            }
        } else {

            Box {
                sx {
                    width = 100.pct
                    height = 100.pct - if (showNavbar) 65.px else 0.px
                    maxHeight = 100.pct - if (showNavbar) 65.px else 0.px
                    flexGrow = number(1.0)
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                }
                id = "home-wrapper"

                when (val child = childStack.active.instance) {
                    is HomeComponent.Child.Dashboard -> {
                        DashboardContent {
                            component = child.dashboardComponent
                            onDmsClicked = {
                                setShowNavbar(false)
                                props.component.onOutput(HomeComponent.Output.NavigateToDms)
                            }
                            onFileClicked = { id ->
                                setShowNavbar(false)
                                props.component.onOutput(HomeComponent.Output.NavigateToDoctumentDetail(id))
                            }
                            parent = props.component
                        }

                    }
                    is HomeComponent.Child.Transactions -> {
                        TransactionsContent {
                            component = child.transactionsComponent
                            onSubpageClicked = {
                                setShowNavbar(it)
                            }
                        }
                    }
                    is HomeComponent.Child.Profile -> {
                        ProfileContent {
                            component = child.profileComponent
                        }
                    }
                    is HomeComponent.Child.Statistics -> {
                        StatisticsContent {
                            component = child.statisticsComponent
                            onChildClicked = {
                                setShowNavbar(it)
                            }
                            handleShowDateRangePicker = {

                            }
                        }
                    }
                    is HomeComponent.Child.Dms -> {
                        Dms {
                            component = child.dmsComponent
                            onBack = {
                                setShowNavbar(it)
                                props.component.onOutput(HomeComponent.Output.NavigateBack)
                            }
                        }
                    }
                    is HomeComponent.Child.Document -> {
                        DetailsScreen {
                            component = child.documentComponent
                            onBack = {
                                setShowNavbar(true)
                                props.component.onOutput(HomeComponent.Output.NavigateBack)
                            }
                            editable = false
                        }
                    }
                    is HomeComponent.Child.ManualTransaction -> {
                        ManualTransactionContent {
                            component = child.manualTransactionComponent
                            onBack = {
                                setShowNavbar(true)
                                props.component.onOutput(HomeComponent.Output.NavigateBack)
                                props.component.onEvent(HomeStore.Intent.DismissManualTransaction)
                            }
                        }
                    }
                    is HomeComponent.Child.Planning -> {
                        PlanningContent {
                            component = child.planningComponent
                            onBack = {
                                setShowNavbar(true)
                                props.component.onOutput(HomeComponent.Output.NavigateBack)
                            }
                            parent = props.component
                        }
                    }
                    else -> {

                    }
                }
            }
        }


        BottomNavBar {
            component = props.component
            show = showNavbar
        }
    }

}

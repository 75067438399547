package com.erstedigital.socialbank.ui.react.transactions

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.domain.models.TransactionType
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.react.home.components.CategorySelector
import com.erstedigital.socialbank.ui.react.home.components.CategoryType
import com.erstedigital.socialbank.ui.react.transactions.components.*
import com.erstedigital.socialbank.utils.parseCategoryInput
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowDropDown
import mui.material.*
import mui.material.Size
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import web.cssom.*
import web.events.Event
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement

external interface TransactionDetailsContentProps: Props {
    var transaction: TransactionModel?
    var onBack: (Boolean) -> Unit
    var onProductsClick: (List<ProductItemModel>) -> Unit
    var onCategoryChanged: (CategoryInput) -> Unit
    var onTransactionDelete: () -> Unit
    var onTransactionEdit: (UpdateManualTransactionRequest) -> Unit
}

val menuOptions = listOf(MainRes.string.delete)

val TransactionDetailsContent: FC<TransactionDetailsContentProps> = FC { props ->

    val (showMore, setShowMore) = useState(false)

    val (currCategory, setCurrCategory) = useState(null as CategoryInput?)
    val (updatableTransaction, setUpdatableTransaction) = useState(false)
    val inputRef = useRef<HTMLInputElement>(null)

    val anchorRef = useRef<HTMLDivElement>(null)
    val (menuOpen, setMenuOpen) = useState(false)
    val (selectedMenuIndex, setSelectedMenuIndex) = useState(0)

    val (editMode, setEditMode) = useState(false)

    useEffectOnce {
        setCurrCategory(props.transaction?.getCategory().parseCategoryInput())
    }

    fun handleMenuItemClick(index: Int) {
        setSelectedMenuIndex(index)
        setMenuOpen(false)
    }

    fun handleToggleMenu() {
        setMenuOpen(!menuOpen)
    }

    fun handleCloseMenu(event: Event) {

        if (anchorRef.current != null && anchorRef.current?.contains(event.target.unsafeCast<HTMLElement>()) == true) {
            return
        }

        setMenuOpen(false)
    }

    fun getCategoryPickerType(): CategoryType {
        if(props.transaction != null && props.transaction?.manualTransaction != null && props.transaction?.manualTransaction?.amount != null) {
            if (props.transaction?.getType() == TransactionType.FS_RECEIPT) {
                return CategoryType.EXPENSE
            } else {
                if (props.transaction?.manualTransaction?.amount!! >= 0) {
                    return CategoryType.INCOME
                } else {
                    return CategoryType.EXPENSE
                }
            }
        } else {
            return CategoryType.EXPENSE
        }
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            paddingBottom = 90.px
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
                justifyContent = JustifyContent.spaceBetween
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                }
                IconButton {
                    onClick = { props.onBack(true) }
                    ArrowBack()
                }
                Typography {
                    sx {
                        marginBottom = 10.px
                        width = 100.pct
                    }
                    variant = TypographyVariant.h4
                    +MainRes.string.detail
                }
            }

            Fragment {
                ButtonGroup {
                    ref = anchorRef
                    IconButton {
                        size = Size.small
                        onClick = { handleToggleMenu() }

                        ArrowDropDown()
                    }
                }

                Popper {
                    open = menuOpen
                    anchorEl = anchorRef.current
                    //disablePortal = true

                    Paper {
                        MenuList {
                            autoFocusItem = true
                            MenuItem {
                                key = MainRes.string.delete
                                onClick = {
                                    GlobalScope.launch {
                                        props.onTransactionDelete()
                                        delay(1000)
                                        props.onBack(true)
                                    }
                                }
                                +MainRes.string.delete
                            }
                            if(props.transaction?.getType() != TransactionType.FS_RECEIPT) {
                                MenuItem {
                                    key = MainRes.string.edit
                                    onClick = {
                                        setEditMode(true)
                                        setMenuOpen(false)
                                    }
                                    +MainRes.string.edit
                                }
                            }
                        }

                    }
                }
            }
        }

        if (!editMode) {
            console
            BasicInfoCard {
                transaction = props.transaction
            }
        } else {
            BasicInfoEditCard {
                transaction = props.transaction
                edit = props.onTransactionEdit
                onBack = props.onBack
                type = getCategoryPickerType()
                category = currCategory
            }
        }



        /*
        if (props.transaction?.getType() == TransactionType.FS_RECEIPT) {
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = AlignItems.center
                    width = 100.pct
                    marginBottom = 20.px
                }
                Typography {
                    sx {
                        width = 60.pct
                    }
                    +MainRes.string.expensive_products_description
                }

                Button {
                    sx {
                        backgroundColor = primaryColor
                    }
                    variant = ButtonVariant.contained
                    +MainRes.string.upload_photo
                }

                ReactHTML.input {
                    type = InputType.file
                    css { this.visibility = Visibility.hidden }
                    ref = inputRef
                    onChange = { event ->
                        val files = (event.target as? HTMLInputElement)?.files
                        val file = files?.get(0)!!

                        file.let {
                            val reader = FileReader()
                            reader.onload = {
                                val r = Int8Array(reader.result as ArrayBuffer).unsafeCast<ByteArray>()
                            }
                            reader.readAsArrayBuffer(it)
                        }
                    }
                }
            }
        }*/


        if (props.transaction?.getType() == TransactionType.FS_RECEIPT) {
            ProductOverview {
                products = props.transaction?.fsReceiptModel?.items ?: emptyList()
                onProductsClick = { props.onProductsClick(props.transaction?.fsReceiptModel?.items ?: emptyList()) }
            }
        }
        if (updatableTransaction) {
            Fab {
                sx {
                    position = Position.absolute
                    bottom = 20.px
                    right = 20.px
                }
                variant = FabVariant.extended
                onClick = {
                    props.onCategoryChanged(currCategory!!)
                    setUpdatableTransaction(false)
                }
                +MainRes.string.update
            }
        }
        CategorySelector {
            expanded = false
            minimal = false
            category = currCategory
            type = getCategoryPickerType()
            //type = CategoryType.EXPENSE
            onCategoryClicked = {
                setCurrCategory(it)
                setUpdatableTransaction(true)
            }
        }
        if (props.transaction?.getType() == TransactionType.FS_RECEIPT) {
            if (showMore) {
                OrgUnitInfoCard {
                    transaction = props.transaction
                }
                OrgInfoCard {
                    transaction = props.transaction
                }
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.center
                }

                Button {
                    variant = ButtonVariant.text
                    onClick = { setShowMore(!showMore) }
                    +( if (showMore) MainRes.string.show_less_details else MainRes.string.show_more_details)
                }
            }

            if (updatableTransaction) {
                Fab {
                    sx {
                        position = Position.absolute
                        bottom = 20.px
                        right = 20.px
                    }
                    variant = FabVariant.extended
                    onClick = {
                        props.onCategoryChanged(currCategory!!)
                        setUpdatableTransaction(false)
                    }
                    +MainRes.string.update
                }
            }
        }
    }

}
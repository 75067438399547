package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionDataSource
import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionListResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import io.github.aakira.napier.Napier

class GetTransactionsUsecase(
    private val transactionsRemoteDataSource: TransactionRemoteDataSource
) {
    suspend fun getTransactions(): ApiResponse<List<TransactionListResponse>, ErrorResponse> {
        return transactionsRemoteDataSource.getTransactions()
    }
}
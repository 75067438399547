package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.domain.models.StatisticsCustomRangeModel
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
class StatisticsCustomRangeResponse(
    val totalIncome: Double = 0.0,
    val totalExpense: Double = 0.0,
    val startDate: LocalDate? = null,
    val endDate: LocalDate? = null,
    val chart: StatisticsResponse.Chart? = null,
    val organizations: List<StatisticsResponse.Organization> = emptyList(),
    val orgUnits: List<StatisticsResponse.OrgUnit> = emptyList(),
    val categories: List<StatisticsResponse.Category> = emptyList()
) {
    fun toModel(): StatisticsCustomRangeModel {
        return StatisticsCustomRangeModel(
                totalIncome,
                totalExpense,
                startDate,
                endDate,
                chart?.toModel(),
                organizations.map { it.toModel(totalExpense) },
                orgUnits.map { it.toModel(totalExpense) },
                categories.map { it.toModel(totalExpense) }
        )
    }




}
package com.erstedigital.socialbank.ui.home.utils

import io.github.skeptick.libres.images.Image

data class AppCategoryModel(
    val main: String,
    val subcategories: List<String>,
    val drawable: Image? = null,
    val color: Int
)

fun CategoryInput?.getResource(isExpense: Boolean = true): Image? {
    if (this == null) {
        return (if (isExpense) fallbackExpense else fallbackIncome).drawable
    }
    var drawableRes = (getIncomeCategories() union getExpenseCategories()).find { this.primary == it.main }?.drawable
    if (drawableRes == null) {
        drawableRes = (if (isExpense) fallbackExpense else fallbackIncome).drawable
    }
    return drawableRes
}


fun CategoryInput?.getColor(isExpense: Boolean = true): Int {
    if (this == null) {
        return (if (isExpense) fallbackExpense else fallbackIncome).color
    }
    var colorRes = (getIncomeCategories() union getExpenseCategories()).find { this.primary == it.main }?.color
    if (colorRes == null) {
        colorRes = (if (isExpense) fallbackExpense else fallbackIncome).color
    }
    return colorRes
}
package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.entity.toEntity
import com.erstedigital.socialbank.data.network.models.request.LoginIdpRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.GoogleSignIn
import com.erstedigital.socialbank.domain.SignInResultCallback
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import io.github.aakira.napier.Napier
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext

class LoginIdpUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val userRemoteDataSource: UserRemoteDataSource,
    private val googleSignIn: GoogleSignIn
) {

    suspend fun authorize(
        scope: CoroutineScope,
        successCallback: () -> Unit,
        errorCallback: (String) -> Unit) = googleSignIn.signIn(object : SignInResultCallback {
        override fun onSuccess() {
            scope.launch {
                val res = Firebase.auth.signInAnonymously()
                when (val result = googleSignIn.retrieveAuthorizedUser()) {
                    is ApiResponse.Error -> {
                        withContext(Dispatchers.Main) {
                            errorCallback(result.toString())
                        }
                    }
                    is ApiResponse.Success -> {
                        val user = result.body
                        val loginIdpRequest = LoginIdpRequest(
                            email = user.email,
                            fullName = user.name,
                            isSocialBank = true,
                            fcmToken = res.user?.getIdToken(false)
                        )
                        val loginIdpResponse = userRemoteDataSource.loginIdp(loginIdpRequest)
                        if (loginIdpResponse is ApiResponse.Success) {
                            val userEntity = loginIdpResponse.body.user
                            userLocalDataSource.setUser(userEntity?.toEntity())
                            withContext(Dispatchers.Main) {
                                successCallback()
                            }
                        } else {
                            withContext(Dispatchers.Main) {
                                errorCallback("Error :)")
                            }
                        }
                    }
                }
             }
        }
        override fun onFailure(message: String) {
            Napier.e("tuuuuu")
            errorCallback(message)
        }

    })
}
package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.CreateFsReceiptRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class CreateFsReceiptUsecase(
    private val transactionsRemoteDataSource: TransactionRemoteDataSource
) {

    suspend operator fun invoke(params: CreateFsReceiptRequest): ApiResponse<TransactionResponse, ErrorResponse> {
        return transactionsRemoteDataSource.createFsReceipt(params)
    }
}
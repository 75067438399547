package com.erstedigital.socialbank.ui.transactions.manual.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.domain.models.CompanyModel
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import kotlinx.datetime.LocalDate

interface ManualTransactionStore: Store<ManualTransactionStore.Intent, ManualTransactionStore.State, Nothing> {

    sealed class Intent {
        data class CreateManualTransaction(
            val amount: Double,
            val date: LocalDate,
            val category: CategoryInput,
            val companyId: Long?,
        ) : Intent()

        data class SearchCompany(val q: String) : Intent()
        data class CreateCompany(val name: String, val ico: String?) : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val companyList: List<CompanyModel> = emptyList(),
        val error: String? = null,
        val transactionCreated: Boolean = false,
        val newCompanyCreated: Boolean = false,
    )

    sealed interface Label {
        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }

}
package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class StatisticsModel(
    val weekly: List<DateSlice>,
    val monthly: List<DateSlice>
) {


    @Serializable
    data class DateSlice(
        val totalIncome: Double = 0.0,
        val totalExpense: Double = 0.0,
        val startBalance: Double = 0.0,
        val startDate: LocalDate,
        val endDate: LocalDate,
        val number: Int,
        val chart: Chart,
        val organizations: List<Organization> = emptyList(),
        val orgUnits: List<OrgUnit> = emptyList(),
        val categories: List<Category> = emptyList(),
        val productCategories: List<ProductCategory> = emptyList(),
        val productCategoriesFiltered: List<ProductCategory> = emptyList(),
        val type: Type
    ) {

        fun getTotal(): Double {
            return totalIncome - totalExpense
        }

        enum class Type {
            WEEK, MONTH
        }
    }

    @Serializable
    data class Chart(
        val points: List<Point> = emptyList()
    ) {

    }

    @Serializable
    data class Point(
        val income: Double = 0.0,
        val expense: Double = 0.0,
        val balance: Double = 0.0,
        val date: LocalDate? = null
    ) {

        fun getTotal(): Double {
            return income - expense
        }

    }

    @Serializable
    data class Organization(
        val id: Long? = null,
        val name: String? = null,
        val ico: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    )

    @Serializable
    data class Category(
        val name: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    )

    @Serializable
    data class OrgUnit(
        val id: Long? = null,
        val name: String? = null,
        val organizationName: String? = null,
        val address: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    )

    @Serializable
    data class ProductCategory(
        val name: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    )
}
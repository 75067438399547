package com.erstedigital.socialbank.domain.usecase.planning

import com.erstedigital.socialbank.data.datasources.planning.PlanningRemoteDataSource
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.ExpectedIncomeModel

class GetExpectedIncomeUsecase(
    private val planningRemoteDataSource: PlanningRemoteDataSource
) {
    suspend fun getExpectedIncome() : ExpectedIncomeModel {
        planningRemoteDataSource.getExpectedIncome()
        var expectedIncomeModel  = ExpectedIncomeModel(
            income = null,
            recurringIncome = null,
            businessIncome = null,
            recurringBusinessIncome = null,
            socialBenefits = null,
            recurringSocialBenefits = null,
            otherIncome = null,
            recurringOtherIncome = null,
            date = null
        )
        return when (val networkRes = planningRemoteDataSource.getExpectedIncome()) {
            is ApiResponse.Success -> {
                expectedIncomeModel = networkRes.body.toModel()
                expectedIncomeModel
            }

            is ApiResponse.Error -> {
                expectedIncomeModel
            }
        }
    }

}
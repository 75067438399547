package com.erstedigital.socialbank.domain.models

import com.erstedigital.socialbank.domain.models.StatisticsModel.Category
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
class StatisticsCustomRangeModel(
    val totalIncome: Double = 0.0,
    val totalExpense: Double = 0.0,
    val startDate: LocalDate? = null,
    val endDate: LocalDate? = null,
    val chart: StatisticsModel.Chart? = null,
    val organizations: List<StatisticsModel.Organization> = emptyList(),
    val orgUnits: List<StatisticsModel.OrgUnit> = emptyList(),
    val categories: List<StatisticsModel.Category> = emptyList(),
    //val categoriesFiltered: List<Category> = emptyList()
    )
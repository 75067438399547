package com.erstedigital.socialbank.ui.transactions.detail

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStore
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.StateFlow

class DetailComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val transactionId: Long
): ComponentContext by componentContext {
    private val detailStore = instanceKeeper.getStore {
        DetailStoreFactory(storeFactory, transactionId).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<DetailStore.State> = detailStore.stateFlow

    fun onEvent(event: DetailStore.Intent) {
        detailStore.accept(event)
    }
    sealed class Output {
        object NavigateBack : Output()
    }

}
package com.erstedigital.socialbank.data.network.models.request

import kotlinx.serialization.Serializable

@Serializable
data class VerifyCodeRequest(
    val code: String,
    val login: String,
    val fcmToken: String?,
    val isSocialBank: Boolean
)
package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.GroupedTransactionsModel
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import kotlin.math.roundToInt

external interface ProductOverviewProps: Props {
    var products: List<ProductItemModel>?
    var onProductsClick: () -> Unit
}

val ProductOverview: FC<ProductOverviewProps> = FC { props ->

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                width = 100.pct
                marginBottom = 20.px
            }
            Typography {
                sx {
                    width = 60.pct
                }
                +MainRes.string.products
            }

            Button {
                variant = ButtonVariant.text
                +MainRes.string.show
                onClick = {
                    props.onProductsClick()
                }
            }
        }

        Card {
            sx {
                marginLeft = 3.px
                marginRight = 3.px
                marginBottom = 20.px
                marginTop = 4.px
                backgroundColor = surfaceColor
                borderRadius = cardBorderRadius
            }

            elevation = 2

            CardContent {
                props.products?.sortedByDescending { it.getTotalPrice() }?.take(3)?.map {
                    InfoRow {
                        label = it.product?.name ?: MainRes.string.unknown
                        value = "${(it.getTotalPrice() * 100).roundToInt().div(100.0)} €"
                    }
                }
            }
        }
    }
}

package com.erstedigital.socialbank.ui.react.dms.screens

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.dashboard.components.parseHtmlToPlainText
import com.erstedigital.socialbank.ui.documents.details.DetailsComponent
import com.erstedigital.socialbank.ui.documents.details.store.DetailsStore
import com.erstedigital.socialbank.ui.react.dms.components.AttachmentCard
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import emotion.react.css
import io.github.aakira.napier.Napier
import js.buffer.ArrayBuffer
import js.typedarrays.Int8Array
import kotlinx.browser.document
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.icons.material.ArrowBack
import mui.icons.material.Delete
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Card
import mui.material.CardContent
import mui.material.Checkbox
import mui.material.CircularProgress
import mui.material.FormControlVariant
import mui.material.IconButton
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.BlobPropertyBag

import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.dom.onChange
import react.useEffect
import react.useRef
import react.useState
import web.buffer.Blob
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.Visibility
import web.cssom.pct
import web.cssom.px
import web.file.FileReader
import web.html.HTMLInputElement
import web.html.InputType

external interface DetailsScreenProps: Props {
    var component: DetailsComponent
    var onBack: (Document?) -> Unit
    var editable: Boolean
    var parentId: Long?
}


val DetailsScreen: FC<DetailsScreenProps> = FC { props ->

    val (state, setState) = useState(props.component.state.value)
    val inputRef = useRef<HTMLInputElement>(null)
    var (name, setName) = useState("")
    val (note, setNote) = useState("")
    val (isVisibleAdvisor, setIsVisibleAdvisor) = useState(false)

    var (newFileData, setNewFileData) = useState(mutableListOf<Triple<String, ByteArray, String>>())

    useEffect{
        val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }

    useEffect(state.document){
        if (state.document != null) {
            val plainNote = parseHtmlToPlainText(state.document.note ?: "")
            setName(state.document.name ?: "")
            setNote(plainNote)
        }
    }

    //val handleFileChange: (ReactEvent) -> Unit = { event ->
        /*val files = (event.target as? HTMLInputElement)?.files
        val file = files?.get(0)

        file?.let {
            val reader = FileReader()
            reader.onload = { {
                val r = Uint8Array(reader.result as ArrayBuffer, 0, reader.result.byteLength as Int).unsafeCast<ByteArray>()


                // You can also handle the file data here or pass it to a parent component via props
            }
            reader.readAsArrayBuffer(it)
        }*/
    //}

    fun triggerDownload(data: ByteArray, fileName: String?) {
        val blob = org.w3c.files.Blob(arrayOf(data), BlobPropertyBag("application/octet-stream"))
        val url = URL.createObjectURL(blob)
        val anchor = document.createElement("a") as HTMLAnchorElement
        anchor.href = url
        anchor.download = fileName?.replace("\"", "") ?: "defaultFilename.txt"
        document.body?.appendChild(anchor)
        anchor.click()
        document.body?.removeChild(anchor)
        URL.revokeObjectURL(url)
        props.component.onEvent(DetailsStore.Intent.ClearDownloadedAttachment)
    }

    useEffect(state.downloadedAttachment){
        if (state.downloadedAttachment != null) {
            triggerDownload(state.downloadedAttachment.first, state.downloadedAttachment.second)
        }
    }

    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 100.pct
                maxWidth = 600.px
                paddingTop = 10.px
            }
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                }

                IconButton {
                    sx {
                        marginRight = 10.px
                    }
                    onClick = { props.onBack(
                        state.document
                    ) }
                    ArrowBack()
                }
                Typography {
                    sx {
                        marginBottom = 10.px
                        width = 100.pct
                    }
                    variant = TypographyVariant.h4
                    +(if (state.document != null) "${MainRes.string.document} ${state.document.name}" else MainRes.string.new_document)
                }

            }


            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    width = 100.pct
                }

                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 10.px
                    }
                    label = ReactNode(MainRes.string.name)
                    variant = FormControlVariant.filled
                    value = name
                    onChange = { event ->
                        setName((event.target as HTMLInputElement).value)
                    }
                    disabled = !props.editable
                }
                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 10.px
                    }
                    label = ReactNode(MainRes.string.details)
                    variant = FormControlVariant.filled
                    value = note
                    onChange = { event ->
                        setNote((event.target as HTMLInputElement).value)
                    }
                    disabled = !props.editable
                }


                Typography {
                    sx {
                        marginTop = 10.px
                    }
                    variant = TypographyVariant.body1
                    +(if (state.document == null) {
                        MainRes.string.attachments
                    } else {
                        if (state.document.attachments.isNotEmpty()) {
                            MainRes.string.existing_attachments
                        } else {
                            MainRes.string.no_attachments
                        }
                    })
                }

                state.document?.attachments?.map { attachment ->
                    AttachmentCard {
                        attachmentName = attachment.name!!
                        onDelete = {
                            props.component.onEvent(DetailsStore.Intent.DeleteAttachment(attachment.id!!))
                        }
                        onDownload = {
                            props.component.onEvent(DetailsStore.Intent.DownloadAttachment(attachment.id!!))
                        }
                    }
                }

                // if editing and there are previous attachments, show the "new attachments" label
                if (state.document != null && state.document.attachments.isNotEmpty()) {
                    Typography {
                        sx {
                            marginTop = 10.px
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.new_attachments
                    }
                }

                newFileData.map { attachment ->
                    AttachmentCard {
                        attachmentName = attachment.first
                        onDelete = {
                            setNewFileData(newFileData.filter { it != attachment }.toMutableList())
                        }
                    }
                }

                ReactHTML.input {
                    type = InputType.file
                    css { this.visibility = Visibility.hidden }
                    ref = inputRef
                    onChange = { event ->
                        val files = (event.target as? HTMLInputElement)?.files
                        val file = files?.get(0)!!

                        file.let {
                            val reader = FileReader()
                            reader.onload = {
                                val r = Int8Array(reader.result as ArrayBuffer).unsafeCast<ByteArray>()
                                val tempNewData = newFileData.toMutableList()
                                tempNewData.add(Triple(file.name, r, (file as Blob).type))
                                setNewFileData(tempNewData)
                            }
                            reader.readAsArrayBuffer(it)
                        }
                    }
                }

                if (props.editable) {
                    Card {
                        sx {
                            marginRight = 10.px
                            marginLeft = 10.px
                            backgroundColor = surfaceColor
                            borderRadius = cardBorderRadius
                            width = 100.pct
                            height = 50.px
                            marginTop = 10.px
                            marginBottom = 10.px
                            cursor = Cursor.pointer
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            alignItems = AlignItems.center
                        }
                        elevation = 2
                        CardContent {
                            sx {
                                width = 100.pct
                                height = 100.pct
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                alignItems = AlignItems.center
                            }

                            onClick = {
                                inputRef.current?.click()
                            }

                            Typography {
                                sx {
                                    width = 100.pct
                                    marginLeft = 10.px
                                }
                                variant = TypographyVariant.body1
                                +MainRes.string.upload_attachment
                            }

                        }
                    }

                    Box {
                        sx {
                            width = 100.pct
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            justifyContent = JustifyContent.spaceBetween
                            marginTop = 10.px
                            marginBottom = 10.px
                        }

                        Typography {
                            sx {
                                marginLeft = 10.px
                            }
                            variant = TypographyVariant.body1
                            +MainRes.string.visible_to_advisor
                        }

                        Checkbox {
                            sx {
                                marginLeft = 10.px
                            }
                            checked = isVisibleAdvisor
                            onChange = { event, _ ->
                                setIsVisibleAdvisor((event.target).checked)
                            }
                        }
                    }

                    Button {
                        sx {
                            width = 100.pct
                            backgroundColor = primaryColor
                        }
                        +(if (state.document != null) MainRes.string.update else MainRes.string.create)
                        variant = ButtonVariant.contained
                        onClick = {
                            if (state.document != null) {
                                props.component.onEvent(DetailsStore.Intent.UpdateDocument(
                                    state.document.id!!,
                                    name,
                                    isVisibleAdvisor,
                                    newFileData,
                                    note
                                ))
                                setNewFileData(mutableListOf())
                            } else {
                                props.component.onEvent(DetailsStore.Intent.CreateDocument(
                                    name,
                                    props.parentId ?: 0,
                                    isVisibleAdvisor,
                                    newFileData,
                                    note
                                ))
                                setNewFileData(mutableListOf())
                            }
                        }
                    }
                }
            }
        }

    }
}
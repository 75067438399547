package com.erstedigital.socialbank.di

import org.koin.core.KoinApplication
import org.koin.core.context.startKoin
import org.koin.dsl.KoinAppDeclaration
import org.koin.dsl.module
import org.koin.core.context.loadKoinModules

fun initKoin(enableNetworkLogs: Boolean = false, appDeclaration: KoinAppDeclaration = {}) = startKoin {
        appDeclaration()
        modules (
                module {loadKoinModules(
                        listOf(
                                localDataSourceModule(enableNetworkLogs),
                                networkModule(enableNetworkLogs),
                                remoteDataSourceModule(enableNetworkLogs),
                                usecaseModule(enableNetworkLogs)
                        )
                )}
        )}


fun KoinApplication.Companion.start(): KoinApplication = initKoin { }


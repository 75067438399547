package com.erstedigital.socialbank.ui.documents.details.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.usecase.documents.CreateDocumentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.DeleteAttachmentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.DownloadAttachmentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.GetDocumentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.UpdateDocumentUsecase
import com.erstedigital.socialbank.ui.documents.store.DmsStore
import com.erstedigital.socialbank.ui.documents.store.DmsStoreFactory
import io.github.aakira.napier.Napier
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class DetailsStoreFactory(
    private val storeFactory: StoreFactory,
    private val documentId: Long?
): KoinComponent {

    private val getDocumentUsecase by inject<GetDocumentUsecase>()
    private val downloadAttachmentUsecase by inject<DownloadAttachmentUsecase>()
    private val createDocumentUseCase by inject<CreateDocumentUsecase>()
    private val deleteAttachmentUseCase by inject<DeleteAttachmentUsecase>()
    private val updateDocumentUseCase by inject<UpdateDocumentUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): DetailsStore = object : DetailsStore, Store<DetailsStore.Intent, DetailsStore.State, DetailsStore.Label> by storeFactory.create(
        name = "DetailsStore",
        initialState = DetailsStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                if (documentId != null) {
                    dispatch(Action.FetchDocumentDetail)
                }
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class DocumentnData(val document: Document): Msg()
        data class AttachmentDeleted(val attachmentId: Long): Msg()
        data class DownloadedAttachment(val data: Pair<ByteArray, String>?): Msg()
        data object CreatedDocument: Msg()
    }

    private sealed class Action {
        object FetchDocumentDetail : Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<DetailsStore.Intent, Action, DetailsStore.State, Msg, DetailsStore.Label>() {

        override fun executeAction(action: Action, getState: () -> DetailsStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchDocumentDetail -> {
                        dispatch(Msg.Loading)
                        when (val result = getDocumentUsecase(documentId!!)) {
                            is ApiResponse.Success -> {
                                dispatch(Msg.DocumentnData(document = result.body))
                            }
                            is ApiResponse.Error.HttpError -> {
                                dispatch(
                                    Msg.Error(
                                        result.errorMessage ?: result.errorBody ?: "Unknown error"
                                    )
                                )
                            }
                            is ApiResponse.Error.GenericError -> {
                                dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                            }
                            is ApiResponse.Error.SerializationError -> {
                                dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                            }

                            else -> {}
                        }
                    }
                }
            }
        }




        override fun executeIntent(intent: DetailsStore.Intent, getState: () -> DetailsStore.State) {

            when (intent) {
                is DetailsStore.Intent.DownloadAttachment -> {
                    scope.launch {
                        downloadAttachment(intent.attachmentId)
                    }
                }
                is DetailsStore.Intent.ClearDownloadedAttachment -> {
                    dispatch(Msg.DownloadedAttachment(null))
                }
                is DetailsStore.Intent.CreateDocument -> {
                    scope.launch {
                        createDocument(
                            intent.folderName,
                            intent.parentId,
                            intent.isVisibleAdvisor,
                            intent.files,
                            intent.note
                        )
                    }
                }
                is DetailsStore.Intent.DeleteAttachment -> {
                    scope.launch {
                        deleteAttachment(intent.id)
                    }
                }
                is DetailsStore.Intent.UpdateDocument -> {
                    scope.launch {
                        updateDocument(
                            intent.id,
                            intent.folderName,
                            intent.isVisibleAdvisor,
                            intent.files,
                            intent.note
                        )
                    }
                }
            }

        }

        private suspend fun updateDocument(
            id: Long,
            folderName: String,
            isVisibleAdvisor: Boolean,
            files: List<Triple<String, ByteArray, String>>,
            note: String,
        ) {
            dispatch(Msg.Loading)
            val result = updateDocumentUseCase(
                id,
                folderName,
                isVisibleAdvisor,
                files,
                note
            )
            when (result) {
                is ApiResponse.Success -> {
                    dispatch(Msg.DocumentnData(document = result.body))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(message = result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error("Unknown error"))
                }
            }
        }

        private suspend fun deleteAttachment(id: Long) {
            dispatch(Msg.Loading)
            val result = deleteAttachmentUseCase(id)
            when (result) {
                is ApiResponse.Success -> {
                    dispatch(Msg.AttachmentDeleted(id))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error("Unknown error"))
                }
            }
        }

        private suspend fun createDocument(
            folderName: String,
            parentId: Long,
            isVisibleAdvisor: Boolean,
            files: List<Triple<String, ByteArray, String>>,
            note: String,
        ) {
            dispatch(Msg.Loading)
            val result = createDocumentUseCase(folderName, parentId, isVisibleAdvisor, files, note)
            when (result) {
                is ApiResponse.Success -> {
                    dispatch(Msg.CreatedDocument)
                    dispatch(Msg.DocumentnData(document = result.body))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(message = result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error("Unknown error"))
                }
            }
        }

        private suspend fun downloadAttachment(attachmentId: Long) {
            dispatch(Msg.Loading)
            val result = downloadAttachmentUsecase(attachmentId)
            if (result != null) {
                dispatch(Msg.DownloadedAttachment(result))
            }
        }


    }

    private object ReducerImpl: Reducer<DetailsStore.State, Msg> {
        override fun DetailsStore.State.reduce(msg: Msg): DetailsStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.DocumentnData -> copy(document = msg.document, error = null, loading = false)
            is Msg.DownloadedAttachment -> copy(loading=false, downloadedAttachment = msg.data)
            is Msg.CreatedDocument -> copy(loading = false, documentCreated = true)
            is Msg.AttachmentDeleted -> {
                val updatedDocument = document?.copy(attachments = document.attachments.filter { it.id != msg.attachmentId })
                copy(loading = false, document = updatedDocument)
            }
        }
    }
}
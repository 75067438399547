package com.erstedigital.socialbank.domain.usecase.documents

import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class GetDocumentUsecase(
    private val dmsRemoteDataSource: DmsRemoteDataSource
) {

    suspend operator fun invoke(documentId: Long): ApiResponse<Document, ErrorResponse> {
        return dmsRemoteDataSource.getDocument(documentId)
    }
}

package com.erstedigital.socialbank.ui.profile.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.domain.models.TransactionListModel
import com.erstedigital.socialbank.domain.models.UserModel
import kotlinx.coroutines.flow.Flow

interface ProfileStore: Store<ProfileStore.Intent, ProfileStore.State, ProfileStore.Label> {

    sealed class Intent {

        data class SaveProfile(val fullName: String, val email: String, val phone: String, val iban: String): Intent()
        data class ChangePassword(val login: String, val password: String, val newPassword: String): Intent()
        data object SignOut: Intent()
    }

    data class State(
        val loading: Boolean = false,
        val error: String? = null,
        val profile: UserModel? = null,
        val signedOut: Boolean = false,
        val actionSuccess: Boolean = false
    )

    sealed interface Label {

        object OnSignOut : Label
        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }
}
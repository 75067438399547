package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.data.network.models.common.StatisticsPoint
import com.erstedigital.socialbank.domain.models.StatisticsModel
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class StatisticsResponse(
    val weekly: List<Weekly>,
    val monthly: List<Monthly>
) {


    @Serializable
    data class Weekly(
        val totalIncome: Double,
        val totalExpense: Double,
        val startBalance: Double = 0.0,
        val startDate: LocalDate,
        val endDate: LocalDate,
        val weekNo: Int,
        val chart: Chart,
        val organizations: List<Organization> = emptyList(),
        val orgUnits: List<OrgUnit> = emptyList(),
        val categories: List<Category> = emptyList(),
        val productCategories: List<ProductCategory> = emptyList(),
        val productCategoriesFiltered: List<ProductCategory> = emptyList()
    ) {
        fun toModel(): StatisticsModel.DateSlice {
            val totalProduct = productCategories.sumByDouble { it.amount }
            return StatisticsModel.DateSlice(
                totalIncome,
                totalExpense,
                startBalance,
                startDate,
                endDate,
                weekNo,
                chart.toModel(),
                organizations.map { it.toModel(totalExpense) },
                orgUnits.map { it.toModel(totalExpense) },
                categories.map { it.toModel(totalExpense) },
                productCategories.map { it.toModel(totalProduct) },
                productCategoriesFiltered.map { it.toModel(totalProduct) },
                StatisticsModel.DateSlice.Type.WEEK
            )
        }
    }

    @Serializable
    data class Monthly(
        val totalIncome: Double = 0.0,
        val totalExpense: Double = 0.0,
        val startBalance: Double = 0.0,
        val startDate: LocalDate,
        val endDate: LocalDate,
        val monthNo: Int,
        val chart: Chart,
        val organizations: List<Organization> = emptyList(),
        val orgUnits: List<OrgUnit> = emptyList(),
        val categories: List<Category> = emptyList(),
        val productCategories: List<ProductCategory> = emptyList(),
        val productCategoriesFiltered: List<ProductCategory> = emptyList()
    ) {
        fun toModel(): StatisticsModel.DateSlice {
            val totalProduct = productCategories.sumByDouble { it.amount }
            return StatisticsModel.DateSlice(
                totalIncome,
                totalExpense,
                startBalance,
                startDate,
                endDate,monthNo,
                chart.toModel(),
                organizations.map { it.toModel(totalExpense) },
                orgUnits.map { it.toModel(totalExpense) },
                categories.map { it.toModel(totalExpense) },
                productCategories.map { it.toModel(totalProduct) },
                productCategoriesFiltered.map { it.toModel(totalProduct) },
                StatisticsModel.DateSlice.Type.MONTH
            )
        }
    }

    @Serializable
    data class Chart(
        val points: List<StatisticsPoint> = emptyList()
    ) {
        fun toModel(): StatisticsModel.Chart {
            return StatisticsModel.Chart(
                points.map(StatisticsPoint::toModel)
            )
        }
    }


    @Serializable
    data class Organization(
        val id: Long? = null,
        val name: String? = null,
        val ico: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    ) {
        fun toModel(totalExpense: Double): StatisticsModel.Organization {
            return StatisticsModel.Organization(
                id,
                name,
                ico,
                amount,
                amount/totalExpense * 100
            )
        }
    }

    @Serializable
    data class Category(
        val name: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    ) {
        fun toModel(totalExpense: Double): StatisticsModel.Category {
            return StatisticsModel.Category(name, amount, amount/totalExpense * 100)
        }
    }

    @Serializable
    data class OrgUnit(
        val id: Long? = null,
        val name: String? = null,
        val organizationName: String? = null,
        val address: String? = null,
        val amount: Double = 0.0,
        val percentage: Double = 0.0
    ) {
        fun toModel(totalExpense: Double): StatisticsModel.OrgUnit {
            return StatisticsModel.OrgUnit(
                id,
                name,
                organizationName,
                address,
                amount,
                amount/totalExpense * 100
            )
        }
    }

    @Serializable
    data class ProductCategory(
        val name: String? = null,
        val amount: Double = 0.0
    ) {
        fun toModel(totalExpense: Double): StatisticsModel.ProductCategory {
            return StatisticsModel.ProductCategory(name, amount, amount/totalExpense * 100)
        }
    }

    fun toModel(): StatisticsModel {
        return StatisticsModel(
            weekly.map(Weekly::toModel),
            monthly.map(Monthly::toModel)
        )
    }


}

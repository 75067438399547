package com.erstedigital.socialbank.data.network.models.request

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class CreateManualTransactionRequest(
    val price: Double,
    val date: LocalDate?,
    val category: String?,
    val companyId: Long?
)
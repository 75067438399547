package com.erstedigital.socialbank.data.network.models.common

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Document(
    var id: Long? = 0,
    val name: String? = "",
    val note: String? = null,
    val folder: Boolean? = null,
    val parentId: Long? = null,
    @SerialName("visibleClient") var isVisibleClient: Boolean? = null,
    @SerialName("visibleAdvisor") var isVisibleAdvisor: Boolean? = null,
    val attributes: List<Attribute> = emptyList(),
    var children: List<Document> = emptyList(),
    val attachments: List<Attachment> = emptyList(),
    var createdAt: String? = null,
    var modifiedAt: String? = null
) {
    fun findPathDocuments(root: Document, currentNodeId: Long): List<Document>? {
        // Check if the current root is the target node
        if (root.id == currentNodeId) {
            return listOf(root)
        }

        // Search in children
        for (child in root.children) {
            val path = findPathDocuments(child, currentNodeId)
            // If a path is found in the subtree, add the current root to the path and return it
            if (path != null) {
                return listOf(root) + path
            }
        }

        // Return null if the node is not found in this subtree
        return null
    }

}
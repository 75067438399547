package com.erstedigital.socialbank.ui.transactions.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import com.erstedigital.socialbank.domain.models.FilterModel
import com.erstedigital.socialbank.domain.models.GroupedTransactionsModel
import com.erstedigital.socialbank.domain.models.TransactionListModel
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStore.Intent
import kotlinx.datetime.LocalDate

interface TransactionStore: Store<TransactionStore.Intent, TransactionStore.State, Nothing> {

    sealed class Intent {
        object LoadTransactions : Intent()
        data class LoadFilteredTransactions(val filter: FilterModel) : Intent()
        data class SelectTransaction(val id: Long) : Intent()
        data class UpdateProductCategory(val id: Long, val category: String) : Intent()
        data object ClearSelectTransaction : Intent()
        data class CheckTransaction(val id: Long) : Intent()
        object RefreshTransactions : Intent()
        object MergeTransactions : Intent()
        object SplitTransactions : Intent()
        object DeleteTransactions : Intent()
        data class DeleteTransaction(val id: Long) : Intent()
        data class UpdateTransaction(val id: Long) : Intent()
        data class SetGroupType(val groupType: GroupType) : Intent()

        data class UpdateCategory(val category: CategoryInput) :Intent()

        data class UpdateManualTransaction(val manualTransaction: UpdateManualTransactionRequest) : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val transactions: List<TransactionListModel> = emptyList(),
        val groupedTransactions: List<GroupedTransactionsModel> = emptyList(),
        val selectedTransactions: List<TransactionListModel> = emptyList(),
        val groupType: GroupType = GroupType.DAY,
        val multiSelectEnabled: Boolean = false,
        val showEmpty: Boolean = false,

        val selectedTransaction: TransactionModel? = null,
        val categoryUpdate: Boolean = false,

        val error: String? = null
    )

    sealed interface Label {
        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }

    enum class GroupType {
        DAY, WEEK, MONTH
    }
}
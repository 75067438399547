package com.erstedigital.socialbank.ui.react.onboarding

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.onboarding.OnboardingComponent
import com.erstedigital.socialbank.ui.onboarding.utils.OnBoardingItems
import com.erstedigital.socialbank.ui.react.utils.*
import com.erstedigital.socialbank.ui.utils.RProps
import js.core.jso
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowForward
import mui.material.*
import mui.material.MobileStepperVariant.Companion.dots
import mui.material.Size
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.create
import react.dom.html.ReactHTML
import react.useState
import web.cssom.*
import web.storage.localStorage

val OnboardingContent: FC<RProps<OnboardingComponent>> = FC { props ->

    val (currentActiveStep, setCurrentActiveStep) = useState(0)
    val items = OnBoardingItems.getData()

    fun getLocalStorageUsername(): String {
        console.log(localStorage.getItem("username"))
        val username = localStorage.getItem("username")
        return if(username === undefined) "" else username
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            position = Position.fixed
            padding = 0.px
            top = 0.px
            bottom = 0.px
            left = 0.px
            right = 0.px
            //marginTop = firstBoxMargin
        }
        Box {
            sx {
                display = headerDisplay
                position = headerPosition
                padding = headerPadding
                top = headerTop
                bottom = headerBottom
                left = headerLeft
                right = headerRight
                backgroundColor = headerBackgroundColor
                height = headerHeight
                justifyContent = headerJustifyContent
                alignItems = headerAlignItems
            }
            ReactHTML.img {
                style = jso {
                    height = headerImgHeight
                    objectFit = headerImgObjectFit
                    marginRight = headerImgMarginRight
                }
                src = headerImgSrc
            }
        }

        Box {
            sx {
                width = 100.pct
                boxSizing = BoxSizing.borderBox
                flex = Flex(grow = number(1.0), shrink = number(0.0), basis = 0.px)
                overflowY = Overflow.clip
            }
            Box {
                sx {
                    height = 100.pct
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.start
                    alignItems = AlignItems.center
                }
                Box {
                    sx {
                        height = 100.pct
                        width = 70.pct
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        alignItems = AlignItems.center
                    }

                    Box {
                        items[currentActiveStep].image.let {
                            ReactHTML.img {
                                style = jso {
                                    width = 400.px
                                    height = 250.px
                                    objectFit = ObjectFit.contain
                                }
                                src = it
                                width = 400.0
                            }
                        }
                    }


                    items[currentActiveStep].title.let {
                        sx {
                            marginBottom = 30.px
                        }
                        Typography {
                            variant = TypographyVariant.h4
                            align = TypographyAlign.center
                            +it
                        }
                    }

                    items[currentActiveStep].desc.let {
                        Typography {
                            sx {
                                maxWidth = 400.px
                            }
                            variant = TypographyVariant.body1
                            align = TypographyAlign.center
                            +it
                        }
                    }

                    MobileStepper {
                        sx {
                            flexGrow = number(0.0)
                            marginBottom = 20.px
                            marginTop = 25.px
                        }
                        steps = 3
                        variant = dots
                        position = MobileStepperPosition.static
                        activeStep = currentActiveStep

                        nextButton = IconButton.create {
                            size = Size.small
                            onClick = {
                                if (currentActiveStep < items.size - 1) {
                                    setCurrentActiveStep(currentActiveStep + 1)
                                } else {
                                    setCurrentActiveStep(0)
                                }
                            }
                            ArrowForward()
                        }
                        backButton = IconButton.create {
                            size = Size.small
                            onClick = {
                                if (currentActiveStep > 0) {
                                    setCurrentActiveStep(currentActiveStep - 1)
                                } else {
                                    setCurrentActiveStep(items.size - 1)
                                }
                            }
                            ArrowBack()
                        }
                    }
                    Box {
                        sx {
                            //flexGrow = number(0.0)
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.center
                            width = 100.pct
                        }
                        Button {
                            sx {
                                //flexGrow = number(0.0)
                                marginBottom = 25.px
                                width = 70.pct
                                backgroundColor = primaryColor
                            }

                            onClick =
                                { props.component.onOutput(OnboardingComponent.Output.NavigateToLogin) }

                            variant = ButtonVariant.contained
                            +MainRes.string.sign_in
                        }
                        /*Button {
                            sx {
                                //flexGrow = number(0.0)
                                width = 70.pct
                            }
                            variant = ButtonVariant.outlined
                            onClick =
                                { props.component.onOutput(OnboardingComponent.Output.NavigateToRegister) }
                            +MainRes.string.sign_up
                        }*/
                    }
                }
            }
        }
    }
}
package com.erstedigital.socialbank.ui.transactions.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.UpdateFsReceiptCategoryRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.FilterModel
import com.erstedigital.socialbank.domain.models.GroupedTransactionsModel
import com.erstedigital.socialbank.domain.models.TransactionListModel
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.domain.usecase.transactions.*
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStore
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStoreFactory
import com.erstedigital.socialbank.utils.generateMonthRange
import com.erstedigital.socialbank.utils.generateWeekRange
import com.erstedigital.socialbank.utils.weekOfYear
import io.github.aakira.napier.Napier
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class TransactionStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val getTransactionsUsecase by inject<GetTransactionsUsecase>()
    private val getTransactionUsecase by inject<GetTransactionUsecase>()
    val updateProductCategoryUsecase: UpdateProductCategoryUsecase by inject()
    private val updateFsReceiptCategoryUsecase by inject<UpdateFsReceiptCategoryUsecase>()
    private val deleteTransactionUsecase by inject<DeleteTransactionUsecase>()
    private val updateManualTransactionUsecase by inject<UpdateManualTransactionUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): TransactionStore = object : TransactionStore, Store<TransactionStore.Intent, TransactionStore.State, Nothing> by storeFactory.create(
        name = "TransactionStore",
        initialState = TransactionStore.State(),
        bootstrapper = coroutineBootstrapper {
                 launch {
                     dispatch(Action.FetchTransactions)
                 }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class TransactionData(val transactions: List<TransactionListModel>): Msg()
        data class TransactionDetailData(val transaction: TransactionModel?): Msg()

        data class GroupTransactions(val groupedTransactions: List<GroupedTransactionsModel>): Msg()

        data class ProductUpdated(val updatedProduct: UpdateProductResponse) : Msg()
        data object ClearSelectedTransaction : Msg()
    }

    private sealed class Action {
        object FetchTransactions : Action()
        data class FetchFilteredTransactions(val filter: FilterModel) : Action()
        data class UpdateTransactionSelection(val id: Long, val selected: Boolean) : Action()
        object MergeSelectedTransactions : Action()
        object SplitSelectedTransactions : Action()
        object DeleteSelectedTransactions : Action()
        data class GroupTransactionsBy(val groupType: TransactionStore.GroupType) : Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<TransactionStore.Intent, Action, TransactionStore.State, Msg, Nothing>() {

        override fun executeAction(action: Action, getState: () -> TransactionStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchTransactions -> {
                        fetchTransactions()
                    }
                    Action.DeleteSelectedTransactions -> TODO()
                    is Action.FetchFilteredTransactions -> TODO()
                    is Action.GroupTransactionsBy -> groupTransactions(getState().transactions, action.groupType)
                    Action.MergeSelectedTransactions -> TODO()
                    Action.SplitSelectedTransactions -> TODO()
                    is Action.UpdateTransactionSelection -> TODO()
                }
            }
        }

        suspend fun fetchTransactions() {
            dispatch(Msg.Loading)
            when (val result = getTransactionsUsecase.getTransactions()) {
                is ApiResponse.Success -> {
                    dispatch(Msg.TransactionData(transactions = result.body.map { it.toModel() }))
                    groupTransactions(result.body.map { it.toModel() }, TransactionStore.GroupType.DAY)
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }

                else -> {}
            }
        }

        private fun groupByDay(data: List<TransactionListModel>): List<GroupedTransactionsModel> {
            val dates = data.mapNotNull { it.getLocalDate() }.distinct().sortedDescending()

            return dates.map { date ->
                val transactions = data.filter { it.getLocalDate() == date }.sortedWith(nullsFirst(compareBy{ it.getLocalTime() }))
                GroupedTransactionsModel(date, transactions)
            }.sortedByDescending { it.date }
        }



        private fun groupByWeek(data: List<TransactionListModel>): List<GroupedTransactionsModel> {
            val dates = data.mapNotNull { it.getLocalDate() }.distinct()

            val first = dates.minOrNull()
            val last = dates.maxOrNull()
            if (first == null || last == null) {
                return emptyList()
            }

            val range = first.generateWeekRange(last)


            return range.map { date ->
                val transactions = data.filter {
                    date.weekOfYear() == it.getLocalDate()?.weekOfYear()
                            && date.year == it.getLocalDate()?.year
                }.sortedWith(
                    compareByDescending<TransactionListModel> {  it.getLocalDate() }.thenBy { it.getLocalTime() }
                )
                GroupedTransactionsModel(date, transactions)
            }.filter { it.transactions.isNotEmpty() }
                .sortedByDescending { it.date }
        }

        private fun groupByMonth(data: List<TransactionListModel>): List<GroupedTransactionsModel> {
            val dates = data.mapNotNull { it.getLocalDate() }.distinct().sortedDescending()

            val first = dates.minOrNull()
            val last = dates.maxOrNull()
            if (first == null || last == null) {
                return emptyList()
            }

            val range = first.generateMonthRange(last)


            return range.map { date ->
                val transactions = data.filter {
                    date.monthNumber == it.getLocalDate()?.monthNumber
                            && date.year == it.getLocalDate()?.year
                }.sortedWith(
                    compareByDescending<TransactionListModel> {  it.getLocalDate() }.thenBy { it.getLocalTime() }
                )
                GroupedTransactionsModel(date, transactions)
            }.filter { it.transactions.isNotEmpty() }
                .sortedByDescending { it.date }
        }

        private fun groupTransactions(transactions: List<TransactionListModel>, groupBy: TransactionStore.GroupType) {
            val groupedTransactions = when (groupBy) {
                TransactionStore.GroupType.DAY -> groupByDay(transactions)
                TransactionStore.GroupType.WEEK -> groupByWeek(transactions)
                TransactionStore.GroupType.MONTH -> groupByMonth(transactions)
            }
            Napier.e("Grouped transactions: ${groupedTransactions}")
            dispatch(Msg.GroupTransactions(groupedTransactions))
        }

        override fun executeIntent(intent: TransactionStore.Intent, getState: () -> TransactionStore.State) {
            val state = getState()

            scope.launch {
                when (intent) {
                    is TransactionStore.Intent.LoadTransactions -> TODO()
                    is TransactionStore.Intent.LoadFilteredTransactions -> TODO()
                    is TransactionStore.Intent.SelectTransaction -> fetchTransactionDetail(intent.id)
                    is TransactionStore.Intent.ClearSelectTransaction -> dispatch(Msg.ClearSelectedTransaction)
                    is TransactionStore.Intent.UpdateProductCategory -> updateProductCategory(intent.id, intent.category)
                    is TransactionStore.Intent.CheckTransaction -> TODO()
                    TransactionStore.Intent.RefreshTransactions -> fetchTransactions()
                    TransactionStore.Intent.MergeTransactions -> TODO()
                    TransactionStore.Intent.SplitTransactions -> TODO()
                    TransactionStore.Intent.DeleteTransactions -> TODO()
                    is TransactionStore.Intent.DeleteTransaction -> deleteTransaction(intent.id)
                    is TransactionStore.Intent.UpdateCategory -> {
                            updateTransactionCategory(state.selectedTransaction?.id!!, "${intent.category.primary}/${intent.category.secondary}")
                    }
                    is TransactionStore.Intent.UpdateTransaction -> updateTransaction(intent.id)
                    is TransactionStore.Intent.UpdateManualTransaction -> updateManualTransaction(intent.manualTransaction)
                    else -> {}
                }
            }
        }

        suspend fun deleteTransaction(id: Long) {
            dispatch(Msg.Loading)
            when (val result = deleteTransactionUsecase.deleteTransaction(id)) {
                is ApiResponse.Success -> {
                    dispatch(Msg.TransactionDetailData(null))
                }
                else -> {
                    dispatch(
                        Msg.Error(
                            "Unknown error"
                        )
                    )
                }
            }
        }

        suspend fun updateTransactionCategory(transactionId: Long, category: String) {
            dispatch(Msg.Loading)
            when (val result = updateFsReceiptCategoryUsecase(
                UpdateFsReceiptCategoryRequest(
                    id = transactionId,
                    category = category
                )
            )) {
                is ApiResponse.Success -> {
                    dispatch(Msg.TransactionDetailData(transaction = result.body.toModel()))
                }
                else -> {
                    Napier.e("${result}")
                    dispatch(
                        Msg.Error(
                            "Unknown error"
                        )
                    )
                }
            }
        }

        private suspend fun updateProductCategory(productId: Long, category: String) {
            dispatch(Msg.Loading)

            val response = updateProductCategoryUsecase(productId, category)
            when (response) {
                is ApiResponse.Success -> {
                    dispatch(Msg.ProductUpdated(response.body))
                }
                else -> {
                    Napier.e("${response}")
                    dispatch(Msg.Error("Error updating product category"))
                }
            }
        }

        private fun fetchTransactionDetail(transactionId: Long) {
            scope.launch {
                dispatch(Msg.Loading)
                when (val result = getTransactionUsecase.getTransaction(transactionId)) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.TransactionDetailData(transaction = result.body.toModel()))
                    }
                    is ApiResponse.Error.HttpError -> {
                        dispatch(
                            Msg.Error(
                                result.errorMessage ?: result.errorBody ?: "Unknown error"
                            )
                        )
                    }
                    is ApiResponse.Error.GenericError -> {
                        dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    }
                    is ApiResponse.Error.SerializationError -> {
                        dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    }

                    else -> {}
                }
            }
        }

        suspend fun updateTransaction(id: Long) {
            dispatch(Msg.Loading)
            when (val result = deleteTransactionUsecase.deleteTransaction(id)) {
                is ApiResponse.Success -> {
                    dispatch(Msg.TransactionDetailData(null))
                }
                else -> {
                    dispatch(
                        Msg.Error(
                            "Unknown error"
                        )
                    )
                }
            }
        }

        suspend fun updateManualTransaction(manualTransaction: UpdateManualTransactionRequest) {
            dispatch(Msg.Loading)
            when (val result = updateManualTransactionUsecase.updateManualTransaction(manualTransaction)) {
                is ApiResponse.Success -> {
                    dispatch(Msg.TransactionDetailData(null))
                }
                else -> {
                    dispatch(
                        Msg.Error(
                            "Unknown error"
                        )
                    )
                }
            }
        }


    }

    private object ReducerImpl: Reducer<TransactionStore.State, Msg> {
        override fun TransactionStore.State.reduce(msg: Msg): TransactionStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.TransactionData -> copy(transactions = msg.transactions, error = null)
            is Msg.GroupTransactions -> copy(loading = false, groupedTransactions = msg.groupedTransactions, error = null)
            is Msg.ClearSelectedTransaction -> copy(selectedTransaction = null)
            is Msg.TransactionDetailData -> copy(selectedTransaction = msg.transaction, error = null, loading = false, categoryUpdate = false)
            is Msg.ProductUpdated -> copy(loading = false, error = null, categoryUpdate = true)
        }
    }
}
package com.erstedigital.socialbank.ui.dashboard.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.DashboardModel
import com.erstedigital.socialbank.domain.models.StatisticsCustomRangeModel
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.domain.usecase.dashboard.GetDashboardUsecase
import com.erstedigital.socialbank.domain.usecase.statistics.GetStatisticsUsecase
import com.erstedigital.socialbank.ui.statistics.store.StatisticsStore
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class DashboardStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val getDashboardUsecase by inject<GetDashboardUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): DashboardStore = object : DashboardStore, Store<DashboardStore.Intent, DashboardStore.State, DashboardStore.Label> by storeFactory.create(
        name = "DashboardStore",
        initialState = DashboardStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchDashboard)
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class DashboardData(val dashboard: DashboardModel): Msg()

    }

    private sealed class Action {
        data object FetchDashboard: Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<DashboardStore.Intent, Action, DashboardStore.State, Msg, DashboardStore.Label>() {

        override fun executeAction(action: Action, getState: () -> DashboardStore.State) {


            scope.launch {
                when (action) {
                    is Action.FetchDashboard -> getDashboard()
                }
            }
        }

        private suspend fun getDashboard () {
            dispatch(Msg.Loading)
            val result = getDashboardUsecase.getDashboard()

            dispatch(Msg.DashboardData(dashboard = result))

        }


        override fun executeIntent(intent: DashboardStore.Intent, getState: () -> DashboardStore.State) {
            val state = getState()
            when (intent) {
                else -> {}
            }
        }


    }

    private object ReducerImpl: Reducer<DashboardStore.State, Msg> {
        override fun DashboardStore.State.reduce(msg: Msg): DashboardStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.DashboardData -> copy(loading = false, dashboard = msg.dashboard, error = null)
            else -> copy()
        }
    }
}
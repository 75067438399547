package com.erstedigital.socialbank.data.network.models.request

import com.erstedigital.overdokladapi.data.Item
import com.erstedigital.overdokladapi.data.Receipt
import kotlinx.serialization.Serializable

@Serializable
data class CreateFsReceiptRequest(
    val receiptId: String? = null,
    val ico: String? = null,
    val cashRegisterCode: String? = null,
    val issueDate: String? = null,
    val createDate: String? = null,
    val customerId: String? = null,
    val dic: String? = null,
    val icDph: String? = null,
    val invoiceNumber: String? = null,
    val okp: String? = null,
    val paragon: Boolean? = null,
    val paragonNumber: String? = null,
    val pkp: String? = null,
    val receiptNumber: Long? = null,
    val type: String? = null,
    val taxBaseBasic: Double? = null,
    val taxBaseReduced: Double? = null,
    val totalPrice: Double? = null,
    val freeTaxAmount: Double? = null,
    val vatAmountBasic: Double? = null,
    val vatAmountReduced: Double? = null,
    val vatRateBasic: Double? = null,
    val vatRateReduced: Double? = null,
    val exemption: Boolean? = null,
    val org_buildingNumber: String? = null,
    val org_country: String? = null,
    val org_dic: String? = null,
    val org_icDph: String? = null,
    val org_ico: String? = null,
    val org_municipality: String? = null,
    val org_name: String? = null,
    val org_postalCode: String? = null,
    val org_propertyRegistrationNumber: String? = null,
    val org_streetName: String? = null,
    val org_vatPayer: Boolean? = null,
    val unit_buildingNumber: String? = null,
    val unit_cashRegisterCode: String? = null,
    val unit_country: String? = null,
    val unit_municipality: String? = null,
    val unit_postalCode: String? = null,
    val unit_propertyRegistrationNumber: String? = null,
    val unit_streetName: String? = null,
    val unit_name: String? = null,
    val unit_type: String? = null,
    val items: List<Product>
) {

    @Serializable
    data class Product(
            val name: String,
            val quantity: Double,
            val price: Double,
            val vatRate: Double,
            val itemType: String
    )
}

fun Receipt.toFsRequest(): CreateFsReceiptRequest {
    return CreateFsReceiptRequest(
        receiptId,
        ico,
        cashRegisterCode,
        issueDate,
        createDate,
        customerId,
        dic,
        icDph,
        invoiceNumber,
        okp,
        paragon,
        paragonNumber,
        pkp,
        receiptNumber.toLong(),
        type,
        taxBaseBasic,
        taxBaseReduced,
        totalPrice,
        freeTaxAmount,
        vatAmountBasic,
        vatAmountReduced,
        vatRateBasic,
        vatRateReduced,
        exemption,
        organization?.buildingNumber,
        organization?.country,
        organization?.dic,
        organization?.icDph,
        organization?.ico,
        organization?.municipality,
        organization?.name,
        organization?.postalCode,
        organization?.propertyRegistrationNumber,
        organization?.streetName,
        organization?.vatPayer,
        unit?.buildingNumber,
        unit?.cashRegisterCode,
        unit?.country,
        unit?.municipality,
        unit?.postalCode,
        unit?.propertyRegistrationNumber,
        unit?.streetName,
        unit?.name,
        unit?.unitType,
        items.map(Item::toRequest)
    )
}

fun Item.toRequest(): CreateFsReceiptRequest.Product {
    return CreateFsReceiptRequest.Product(
            name,
            quantity,
            price,
            vatRate,
            itemType
    )
}
package com.erstedigital.socialbank.ui.home.utils

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Color.Companion.Green
import androidx.compose.ui.graphics.toArgb
import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.Gray

fun getExpenseCategories() = listOf(
    AppCategoryModel("Bývanie a energie", listOf(
        "Nájom",
        "Úver na bývanie",
        "Energie",
        "Služby pre domácnosť",
        "Poplatky za odpad",
        "Vodné a stočné",
        "Poistenie",
        "Vybavenie a doplnky",
        "Upratovanie",
        "Starostlivosť o zvieratá",
        "Ostatné - Bývanie a energie"
    ), MainRes.image.living_energies, Color(0x333598).toArgb()),
    AppCategoryModel("Stravovanie", listOf(
        "Potraviny",
        "Reštaurácie a kaviarne",
        "Ostatné - Strava a potraviny"
    ), MainRes.image.food, Color(0x666403).toArgb()),
    AppCategoryModel("Zdravie a osobná starostlivosť", listOf(
        "Drogéria",
        "Lieky a zdravotná starostlivosť",
        "Kozmetika a optika",
        "Lekári a nemocnice",
        "Životné a zdravotné poistenie",
        "Ostatné - Zdravie a osobná starostlivosť"
    ), MainRes.image.health, Color(0xd1d220).toArgb()),
    AppCategoryModel("Oblečenie a obuv", listOf(
        "Móda",
        "Topánky",
        "Doplnky"
    ), MainRes.image.clothes, Color(0x60cad0).toArgb()),
    AppCategoryModel("Elektronika, komunikácia a média", listOf(
        "Účty za telefón",
        "Televízia a rádio",
        "Internet",
        "Elektronika"
    ), MainRes.image.electronics, Color(0x5b020f).toArgb()),
    AppCategoryModel("Zábava a oddych", listOf(
        "Zábava",
        "Záľuby a šport",
        "Knihy a tlač",
        "Hry a hračky",
        "Trafika",
        "Stávkovanie",
        "Ostatné - Oddych a záľuby"
    ), MainRes.image.fun_recreation, Color(0x744574).toArgb()),
    AppCategoryModel("Vzdelanie", listOf(
        "Škola a škôlka",
        "Učebnice a školské potreby",
        "Kurzy a doučovanie",
        "Ostatné - Vzdelanie"
    ), MainRes.image.education, Color(0x475e19).toArgb()),
    AppCategoryModel("Auto-moto", listOf(
        "Kúpa a splátky",
        "Pohonné hmoty",
        "Servis",
        "Autopoistenie",
        "Parkovanie",
        "Diaľničné známky a mýto",
        "Ostatné - Auto"
    ), MainRes.image.auto_moto, Color(0xc80621).toArgb()),
    AppCategoryModel("Doprava", listOf(
        "Verejná doprava",
        "Taxi",
        "Ostatné - Doprava"
    ), MainRes.image.transit, Color(0xe9f83c).toArgb()),
    AppCategoryModel("Cestovanie", listOf(
        "Ubytovanie",
        "Stravovanie",
        "Cestovné výdavky",
        "Zmenáreň",
        "Ostatné - Dovolenka"
    ), MainRes.image.vacation, Color(0xe24586).toArgb()),
    AppCategoryModel("Výber hotovosti", listOf(
        "Výber z bankomatu",
        "Výbery v pobočke",
        "Výber kreditnou kartou",
        "Splátka kreditnej karty",
        "Dobitie kreditu",
        "Iné výbery"
    ), MainRes.image.withdrawal, Color(0xfec82e).toArgb()),
    AppCategoryModel("Vreckové a výživné", listOf(
        "Alimenty",
        "Vreckové",
        "Ostatné - Vreckové a výživné"
    ), MainRes.image.aliments, Color(0x2589b5).toArgb() ),
    AppCategoryModel("Nákupy online", listOf(
        "Nezaradené online nákupy"
    ), MainRes.image.online, Color(0x0e64c3).toArgb() ),
    AppCategoryModel("Sporenie a investovanie", listOf(
        "Sporenie",
        "Stavebné sporenie",
        "Investície",
        "Cenné papiere",
        "Ostatné - Sporenie a investície"
    ), MainRes.image.savings, Color(0x5d9500).toArgb()),
    AppCategoryModel("Dane", listOf(
        "Daň z príjmu",
        "DPH",
        "Iné dane"
    ), MainRes.image.tax, Color(0xe97007).toArgb()),
    AppCategoryModel("Poplatky a úroky", listOf(
        "Prevody medzi vlastnými účtami",
        "Poplatky",
        "Úroky",
        "Pôžičky",
        "Iné poplatky"
    ), MainRes.image.fees, Color(0x205372).toArgb()),
    AppCategoryModel("Ostatné výdavky", listOf(
        "Splátky a financovanie",
        "Platby poistného",
        "Sociálne poistenie",
        "Darčeky",
        "Finančné dary a príspevky",
        "Pokuty a správne poplatky",
        "Iné výdavky"
    ), MainRes.image.other_expenses, Color(0x205372).toArgb()),
    AppCategoryModel("Nezatriedené výdavky", listOf(
        "Nezaradené výdavky"
    ), MainRes.image.uncategorized_expenses, Color(0x9a9a9a).toArgb())
)

fun getIncomeCategories() = listOf(
    AppCategoryModel("Bežné príjmy", listOf(
        "Mzda",
        "Dôchodok",
        "Prijmy z podnikania",
        "Dávky v nezamestnanosti",
        "Materská a rodičovské príspevky",
        "Rodinné prídavky",
        "Alimenty",
        "Iné pravidelné príjmy"
    ), MainRes.image.incomes, Green.toArgb()),
    AppCategoryModel("Mimoriadne príjmy", listOf(
        "Nájomné",
        "Vklady hotovosti",
        "Nadčasy a diéty",
        "Prevody medzi vlastnými účtami",
        "Online predaje",
        "Náhrady výdavkov",
        "Príjmy z úspor a investícií",
        "Príjmy z postení",
        "Iné nepravidelné príjmy"
    ), MainRes.image.special_incomes, Green.toArgb()),
    AppCategoryModel("Nezatriedené príjmy", listOf(
        "Nezatriedené príjmy"
    ), MainRes.image.uncategorized_incomes, Gray.toArgb())
)

val fallbackIncome =  AppCategoryModel("Nezatriedené príjmy", listOf(
    "Nezatriedené príjmy"
), MainRes.image.uncategorized_incomes, Gray.toArgb()
)

val fallbackExpense = AppCategoryModel("Nezatriedené výdavky", listOf(
    "Nezaradené výdavky"
), MainRes.image.uncategorized_expenses, Color(0x9a9a9a).toArgb())

val fallbackCategoryIncome = CategoryInput("Nezatriedené príjmy")
val fallbackCategoryExpense = CategoryInput("Nezatriedené výdavky")
package com.erstedigital.socialbank.domain.utils

import com.erstedigital.socialbank.data.network.utils.NetworkResultState
import io.github.aakira.napier.Napier
import kotlinx.datetime.LocalDate

fun <T> Exception.toErrorResult(): NetworkResultState<T> {
    return NetworkResultState.Failure(Exception(this))
}

fun String.toIntColor(): Int {
    var cleanHex = if (this.startsWith("#")) this.substring(1) else this
    cleanHex = cleanHex.filter { it.isDigit() || it in 'a'..'f' || it in 'A'..'F' }
    if (cleanHex.length != 6 && cleanHex.length != 8) throw IllegalArgumentException("Invalid hex color string")
    val color = cleanHex.toLong(16).toInt()
    return if (cleanHex.length == 6) {
        // Add alpha if not present
        (0xFF shl 24) or color
    } else {
        color
    }
}

fun Int.toHexColor(): String {
    return "#${this.toString(16).padStart(6, '0').uppercase()}"
}

fun String.parseLocalDate(): LocalDate? {
    val components = this.split("-")
    if (components.size != 3) return null

    return try {
        val year = components[0].toInt()
        val month = components[1].toInt()
        val day = components[2].toInt()
        LocalDate(year, month, day)
    } catch (e: Exception) {
        null
    }
}
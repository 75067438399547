package com.erstedigital.overdokladapi.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Organization (
    @SerialName("buildingNumber") val buildingNumber : String?,
    @SerialName("country") val country : String?,
    @SerialName("dic") val dic : String?,
    @SerialName("icDph") val icDph : String?,
    @SerialName("ico") val ico : String,
    @SerialName("municipality") val municipality : String?,
    @SerialName("name") val name : String?,
    @SerialName("postalCode") val postalCode : String?,
    @SerialName("propertyRegistrationNumber") val propertyRegistrationNumber : String?,
    @SerialName("streetName") val streetName : String?,
    @SerialName("vatPayer") val vatPayer : Boolean?
)
package com.erstedigital.socialbank.domain.usecase.documents

import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse


class UpdateDocumentUsecase(
    private val dmsRemoteDataSource: DmsRemoteDataSource
) {

    suspend operator fun invoke(
        id: Long,
        folderName: String,
        isVisibleAdvisor: Boolean,
        files: List<Triple<String, ByteArray, String>>?,
        note: String,
    ): ApiResponse<Document, ErrorResponse> {

        val document = Document(
            id = id,
            name = folderName,
            folder = false,
            parentId = null,
            isVisibleAdvisor = isVisibleAdvisor,
            isVisibleClient = true,
            note = note
        )
        return dmsRemoteDataSource.updateDocument(document, files)
    }
}
package com.erstedigital.socialbank.domain.usecase.user

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.entity.toUser
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.GetProfileResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.UserModel

class GetProfileUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val userRemoteDataSource: UserRemoteDataSource
) {

    suspend fun getLocalProfile(): UserModel? {
        return userLocalDataSource.getUser()?.toUser()
    }
    suspend fun getProfile(): ApiResponse<GetProfileResponse, ErrorResponse> {
        return userRemoteDataSource.getProfile()

    }
}
package com.erstedigital.socialbank.ui.documents.details.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.documents.store.DmsStore

interface DetailsStore: Store<DetailsStore.Intent, DetailsStore.State, DetailsStore.Label> {

    sealed class Intent {
        data class DownloadAttachment(val attachmentId: Long): Intent()
        data class CreateDocument(
            val folderName: String,
            val parentId: Long,
            val isVisibleAdvisor: Boolean,
            val files: List<Triple<String, ByteArray, String>>,
            val note: String,
        ): Intent()

        data class UpdateDocument(
            val id: Long,
            val folderName: String,
            val isVisibleAdvisor: Boolean,
            val files: List<Triple<String, ByteArray, String>>,
            val note: String,
        ): Intent()
        data object ClearDownloadedAttachment: Intent()
        data class DeleteAttachment(val id: Long): Intent()
    }

    data class State(
        val loading: Boolean = false,
        val error: String? = null,
        val document: Document? = null,
        val downloadedAttachment: Pair<ByteArray, String>? = null,
        val documentCreated: Boolean = false,
    )

    sealed interface Label {
    }
}
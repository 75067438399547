package com.erstedigital.socialbank.ui.react.login

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.login.LoginComponent
import com.erstedigital.socialbank.ui.login.store.LoginStore
import com.erstedigital.socialbank.ui.react.utils.*
import com.erstedigital.socialbank.ui.utils.RProps
import js.core.jso
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.icons.material.ArrowBack
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType.Companion.password
import web.storage.localStorage

val LoginContent: FC<RProps<LoginComponent>> = FC { props ->
        //val (username, setUsername) = useState<String>("")
        val (state, setState) = useState(props.component.state.value)
        val (isLogin, setIsLogin) = useState(true)
        val (confirmPassword, setConfirmPassword) = useState("")
        val (isResetPassword, setIsResetPassword) = useState(false)

        fun getLocalStorageUsername(): String {
                console.log(localStorage.getItem("username"))
                val username = localStorage.getItem("username")
                return if(username === undefined) "" else username
        }

        useEffect{
                val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
        }


        useEffect(state) {

                if (!state.loading && state.loggedIn) {
                        props.component.onOutput(LoginComponent.Output.NavigateToHome)
                }

        }


        Box {
                sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        position = Position.fixed
                        padding = 0.px
                        top = 0.px
                        bottom = 0.px
                        left = 0.px
                        right = 0.px
                        //marginTop = firstBoxMargin
                }
                Box {
                        sx {
                                display = headerDisplay
                                position = headerPosition
                                padding = headerPadding
                                top = headerTop
                                bottom = headerBottom
                                left = headerLeft
                                right = headerRight
                                backgroundColor = headerBackgroundColor
                                height = headerHeight
                                justifyContent = headerJustifyContent
                                alignItems = headerAlignItems
                        }
                        ReactHTML.img {
                                style = jso {
                                        height = headerImgHeight
                                        objectFit = headerImgObjectFit
                                        marginRight = headerImgMarginRight
                                }
                                src = headerImgSrc
                        }
                }
                if (state.loading) {
                        CircularProgress {
                                sx {
                                        position = Position.absolute
                                        top = 50.pct
                                        left = 50.pct
                                }
                        }
                } else {
                        Box {
                                sx {
                                        width = 100.pct
                                        boxSizing = BoxSizing.borderBox
                                        flex = Flex(grow = number(1.0), shrink = number(0.0), basis = 0.px)
                                        overflowY = Overflow.clip
                                }
                                Box {
                                        sx {
                                                display = Display.flex
                                                flexDirection = FlexDirection.column
                                                height = 100.pct
                                                width = 100.pct
                                                overflow = Overflow.hidden
                                                justifyContent = JustifyContent.center
                                                alignItems = AlignItems.center
                                        }
                                        Box {
                                                sx {
                                                        width = 70.pct
                                                        maxWidth = 400.px
                                                        height = 100.pct
                                                        display = Display.flex
                                                        flexDirection = FlexDirection.column
                                                        justifyContent = JustifyContent.center
                                                        alignItems = AlignItems.center
                                                }
                                                Box {
                                                        sx {
                                                                width = 100.pct
                                                                display = Display.flex
                                                                flexDirection = FlexDirection.row
                                                                justifyContent = JustifyContent.center
                                                                alignItems = AlignItems.center
                                                        }

                                                        if(!isLogin || isResetPassword) {
                                                                IconButton {
                                                                        onClick = {
                                                                                setIsLogin(true)
                                                                                setIsResetPassword(false)
                                                                        }
                                                                        ArrowBack()
                                                                }
                                                        }

                                                        Typography {
                                                                sx {
                                                                        marginBottom = 25.px
                                                                }
                                                                variant = TypographyVariant.h3
                                                                align = TypographyAlign.center
                                                                if(isLogin) +MainRes.string.sign_in else if(!isLogin && !isResetPassword) +MainRes.string.sign_up else +MainRes.string.reset_password
                                                        }
                                                }


                                                TextField {
                                                        sx {
                                                                width = 100.pct
                                                                marginBottom = 25.px
                                                        }
                                                        value = state.login
                                                        placeholder = MainRes.string.enter_email
                                                        onChange = { e ->
                                                                props.component.onEvent(
                                                                        LoginStore.Intent.ChangeUsername((e.target as HTMLInputElement).value)
                                                                )
                                                                //setUsername((e.target as HTMLInputElement).value)
                                                                localStorage.setItem(
                                                                        "username",
                                                                        (e.target as HTMLInputElement).value
                                                                )
                                                        }
                                                }

                                                if(!isResetPassword) {
                                                        TextField {
                                                                sx {
                                                                        width = 100.pct
                                                                        marginBottom = 25.px
                                                                }
                                                                value = state.password
                                                                placeholder = MainRes.string.enter_password
                                                                type = password
                                                                onChange = { e ->
                                                                        props.component.onEvent(
                                                                                LoginStore.Intent.ChangePassword((e.target as HTMLInputElement).value)
                                                                        )
                                                                }
                                                        }
                                                }

                                                if(!isLogin) {
                                                        TextField {
                                                                sx {
                                                                        width = 100.pct
                                                                        marginBottom = 25.px
                                                                }
                                                                value = confirmPassword
                                                                placeholder = MainRes.string.enter_password_confirmation
                                                                type = password
                                                                onChange = { e ->
                                                                        setConfirmPassword((e.target as HTMLInputElement).value)
                                                                }
                                                        }
                                                        if (state.password != confirmPassword && confirmPassword.isNotEmpty()) {
                                                                Typography {
                                                                        sx {
                                                                                color = Color("#FF0000")
                                                                        }
                                                                        +MainRes.string.passwords_do_not_match
                                                                }
                                                        }
                                                }

                                                if(!isResetPassword) {
                                                        Button {
                                                                sx {
                                                                        width = 100.pct
                                                                        marginBottom = 25.px
                                                                        backgroundColor = primaryColor
                                                                }
                                                                if (isLogin) +MainRes.string.sign_in else +MainRes.string.sign_up
                                                                //+MainRes.string.sign_in
                                                                variant = ButtonVariant.contained
                                                                disabled = !isLogin && (state.password != confirmPassword || state.password.isEmpty())
                                                                onClick = {
                                                                        if (isLogin)
                                                                                props.component.onEvent(
                                                                                        LoginStore.Intent.Login(
                                                                                                state.login,
                                                                                                state.password
                                                                                        )
                                                                                )
                                                                        else {
                                                                                props.component.onEvent(
                                                                                        LoginStore.Intent.Register(
                                                                                                state.login,
                                                                                                state.password
                                                                                        )
                                                                                )
                                                                                setIsLogin(true)
                                                                        }
                                                                }
                                                        }
                                                }
                                                if (isLogin && !isResetPassword) {
                                                        Button {
                                                                sx {
                                                                        width = 100.pct
                                                                        marginBottom = 25.px
                                                                }
                                                                +MainRes.string.sign_up
                                                                variant = ButtonVariant.outlined
                                                                onClick = {
                                                                        setIsLogin(false)
                                                                }
                                                        }

                                                        Button {
                                                                sx {
                                                                        width = 100.pct
                                                                        marginBottom = 25.px
                                                                }
                                                                +MainRes.string.reset_password
                                                                variant = ButtonVariant.outlined
                                                                onClick = {
                                                                        setIsResetPassword(true)
                                                                }
                                                        }
                                                }
                                                if(isResetPassword) {
                                                        Button {
                                                                sx {
                                                                        width = 100.pct
                                                                        marginBottom = 25.px
                                                                        backgroundColor = primaryColor
                                                                }
                                                                +MainRes.string.reset_password
                                                                variant = ButtonVariant.contained
                                                                onClick = {
                                                                        props.component.onEvent(
                                                                                LoginStore.Intent.Reset(
                                                                                        state.login
                                                                                )
                                                                        )
                                                                        setIsResetPassword(false)
                                                                }
                                                        }
                                                }
                                                Snackbar {
                                                        open = state.error != null
                                                        autoHideDuration = 3000
                                                        /*onClose = { _, _ ->
                                                                props.component.onEvent(

                                                                )
                                                        }*/
                                                        Alert {
                                                                severity = AlertColor.error
                                                                if(isResetPassword) +MainRes.string.failed_password_reset
                                                                else if(!isLogin) +MainRes.string.failed_user_registration
                                                                else +MainRes.string.failed_login
                                                        }
                                                }
                                                Snackbar {
                                                        open = state.actionSuccess
                                                        autoHideDuration = 3000
                                                        /*onClose = { _, _ ->
                                                                props.component.onEvent(

                                                                )
                                                        }*/
                                                        Alert {
                                                                severity = AlertColor.success
                                                                if(isResetPassword) +MainRes.string.success_password_reset
                                                                else +MainRes.string.successful_user_registration
                                                        }
                                                }


                                                /*Button {
                                        sx {
                                                width = 100.pct
                                                backgroundColor = primaryColor
                                        }
                                        +MainRes.string.submit_pin
                                        variant = ButtonVariant.contained
                                        onClick = {
                                                console.log(localStorage.getItem("username"))
                                                props.component.onOutput(
                                                        LoginComponent.Output.NavigateToVerifyCode(getLocalStorageUsername()))
                                        }
                                }*/
                                        }
                                }
                        }
                }

        }
}
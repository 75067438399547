package com.erstedigital.socialbank.domain.usecase.statistics

import com.erstedigital.socialbank.data.datasources.statistics.StatisticsDataSource
import com.erstedigital.socialbank.data.datasources.statistics.StatisticsRemoteDataSource
import com.erstedigital.socialbank.data.datasources.transaction.TransactionDataSource
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.StatisticsResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionListResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.StatisticsModel

class GetStatisticsUsecase(
    private val statisticsRemoteDataSource: StatisticsRemoteDataSource
) {
    suspend fun getStatistics(): ApiResponse<StatisticsResponse, ErrorResponse> {
        return statisticsRemoteDataSource.getStatistics()
    }
}
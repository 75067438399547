package com.erstedigital.socialbank.ui.react.statistics.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.IconButton
import mui.material.Tab
import mui.material.Tabs
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.icons.material.PieChartRounded
import mui.system.sx
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import react.ReactNode
import react.useRef
import react.useState
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import kotlin.math.roundToInt

external interface CategoriesCardProps: Props {
    var slice: StatisticsModel.DateSlice
    var onPieChartClicked: () -> Unit
}

val CategoriesCard: FC<CategoriesCardProps> = FC { props ->


    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 20.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
        }

        elevation = 2

        CardContent {
            Box {
                sx {
                    width = 300.px
                    height = 300.px
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                className = ComponentStyles.scrollable

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.center
                    }
                    Typography {
                        variant = TypographyVariant.body1
                        +MainRes.string.label_product_categories
                    }

                    IconButton {
                        onClick = {
                            props.onPieChartClicked()
                        }
                        PieChartRounded()
                    }
                }


                props.slice.productCategories.sortedByDescending { it.percentage }.map {
                    Box {
                        sx {
                            width = 100.pct
                            paddingTop = 3.px
                            paddingBottom = 3.px
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            justifyContent = JustifyContent.spaceBetween
                            alignItems = AlignItems.center
                        }
                        Typography {
                            variant = TypographyVariant.caption
                            +(it.name ?: MainRes.string.unknown)
                        }
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.column
                                justifyContent = JustifyContent.spaceBetween
                            }

                            Typography {
                                variant = TypographyVariant.body1
                                +(it.amount.times(100).roundToInt().div(100f).toString() + " €")
                            }

                            Typography {
                                variant = TypographyVariant.caption
                                +((it.percentage.times(100).roundToInt().div(100f)).toString() + " %")
                            }
                        }
                    }
                }
            }
        }
    }
}

private object ComponentStyles {
    var scrollable = emotion.css.ClassName {
        overflowY = Auto.auto
    }
}

package com.erstedigital.socialbank.data.network.models.request

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class UpdateManualTransactionRequest(
    val id: Long,
    val date: LocalDate?,
    val price: Double,
    val organizationId: Long?,
    val category: String?
)
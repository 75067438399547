package com.erstedigital.socialbank.ui.profile

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.profile.store.ProfileStore
import com.erstedigital.socialbank.ui.profile.store.ProfileStoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class ProfileComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val userAuthenticationFlow: () -> Unit,
): ComponentContext by componentContext {
    private val profileStore = instanceKeeper.getStore {
        ProfileStoreFactory(storeFactory).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<ProfileStore.State> = profileStore.stateFlow

    val label: Flow<ProfileStore.Label> = profileStore.labels

    fun onEvent(event: ProfileStore.Intent) {
        profileStore.accept(event)
    }

    fun onOutput(output: Output){
        when (output) {
            is Output.OpenAuthorizationFlow -> userAuthenticationFlow()
        }
    }


    sealed class Output {
        object OpenAuthorizationFlow  : Output()
    }

}
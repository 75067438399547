package com.erstedigital.socialbank.ui.transactions.detail.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.home.utils.CategoryInput

interface DetailStore: Store<DetailStore.Intent, DetailStore.State, Nothing> {

    sealed class Intent {
        data object LoadTransaction : Intent()
        data class UpdateCategory(val category: CategoryInput) : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val transaction: TransactionModel? = null,
        val error: String? = null
    )

    sealed interface Label {
        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }

}
package com.erstedigital.overdokladapi.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SearchIdentification(
    @SerialName("createDate") val createDate : Long,
    @SerialName("bucket") val bucket : Int,
    @SerialName("internalReceiptId") val internalReceiptId : String,
    @SerialName("searchUuid") val searchUuid : String
)
package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.ManualTransactionModel
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ManualTransaction(
    val id: Long?,
    @SerialName("organization") val organization: Organization?,
    @SerialName("category") val category: String?,
    @SerialName("date") val date: LocalDate?,
    @SerialName("price") val totalPrice: Double
) {

    fun toModel(): ManualTransactionModel {
        val model = ManualTransactionModel(id, totalPrice, date, category, organization?.toModel())
        return model
    }
}
package com.erstedigital.socialbank.ui.root

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.router.stack.replaceAll
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.erstedigital.socialbank.ui.home.HomeComponent
import com.erstedigital.socialbank.ui.login.LoginComponent
import com.erstedigital.socialbank.ui.onboarding.OnboardingComponent
import com.erstedigital.socialbank.ui.root.store.RootStore
import com.erstedigital.socialbank.ui.root.store.RootStoreImplFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.verify_code.VerifyCodeComponent
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlin.coroutines.CoroutineContext

class RootComponent internal constructor(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val login: (ComponentContext, (LoginComponent.Output) -> Unit) -> LoginComponent,
    private val onboarding: (ComponentContext, (OnboardingComponent.Output) -> Unit) -> OnboardingComponent,
    private val home: (ComponentContext, () -> Unit) -> HomeComponent,
    private val verifyCode: (ComponentContext, (VerifyCodeComponent.Output) -> Unit) -> VerifyCodeComponent,
    private val mainContext: CoroutineContext = Dispatchers.Main,
): ComponentContext by componentContext {

    private val rootStore =
        instanceKeeper.getStore {
            RootStoreImplFactory(
                storeFactory = storeFactory,
                mainContext = mainContext
            ).create()
        }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<RootStore.State> = rootStore.stateFlow

    val label: Flow<RootStore.Label> = rootStore.labels

    constructor(
        componentContext: ComponentContext,
        storeFactory: StoreFactory
    ): this(
        componentContext = componentContext,
        storeFactory = storeFactory,
        login = { childContext, output ->
            LoginComponent(
                componentContext = childContext,
                storeFactory = storeFactory,
                output = output
            )
        },
        onboarding = { childContext, output ->
            OnboardingComponent(
                componentContext = childContext,
                storeFactory = storeFactory,
                output = output
            )
        },
        home = { childContext, onSignOut ->
            HomeComponent(
                componentContext = childContext,
                storeFactory = storeFactory,
                openAuthorizationFlow = onSignOut
            )
        },
        verifyCode = { childContext, output ->
            VerifyCodeComponent(
                componentContext = childContext,
                storeFactory = storeFactory,
                output = output
            )
        }
    )

    private val navigation = StackNavigation<Configuration>()

    private val stack = childStack(
        source = navigation,
        initialConfiguration = Configuration.Undefined,
        handleBackButton = false,
        childFactory = ::createChild
    )

    val childStack: Value<ChildStack<*, Child>> = stack

    private fun createChild(configuration: Configuration, componentContext: ComponentContext): Child =
        when (configuration) {
            is Configuration.Login -> Child.Login(login(componentContext, ::onLoginOutput))
            is Configuration.Onboarding -> Child.Onboarding(onboarding(componentContext, ::onOnboardingOutput))
            is Configuration.Home -> Child.Home(HomeComponent(storeFactory, componentContext) {
                navigation.replaceAll(Configuration.Onboarding)
            })
            is Configuration.VerifyCode -> Child.VerifyCode(verifyCode(componentContext, ::onVerifyCodeOutput), configuration.username)
            is Configuration.Undefined -> Child.Undefined
        }

    fun onOutput(output: Output) {
        when (output) {
            Output.OpenContent -> navigation.replaceAll(Configuration.Home, onComplete = { println("navigation compli") })
            Output.OpenAuthorizationFlow -> navigation.replaceAll(Configuration.Onboarding)
        }
    }

    private fun onVerifyCodeOutput(output: VerifyCodeComponent.Output) {
        when (output) {
            is VerifyCodeComponent.Output.NavigateBack -> navigation.pop()
            is VerifyCodeComponent.Output.NavigateToHome -> navigation.replaceAll(Configuration.Home)
        }
    }

    private fun onLoginOutput(output: LoginComponent.Output) {
        when (output) {
            is LoginComponent.Output.NavigateBack -> navigation.pop()
            is LoginComponent.Output.NavigateToHome -> navigation.replaceAll(Configuration.Home)
            is LoginComponent.Output.NavigateToVerifyCode -> {
                navigation.push(Configuration.VerifyCode(output.username))
            }
        }
    }

    private fun onOnboardingOutput(output: OnboardingComponent.Output) {
        when (output) {
            is OnboardingComponent.Output.NavigateToLogin -> navigation.push(Configuration.Login)
            is OnboardingComponent.Output.NavigateToHome -> navigation.replaceAll(Configuration.Home)
            is OnboardingComponent.Output.NavigateToRegister -> navigation.push(Configuration.Login)
        }
    }

    private sealed class Configuration: Parcelable {

        @Parcelize
        data object Login : Configuration()

        @Parcelize
        data object Onboarding : Configuration()

        @Parcelize
        data object Home : Configuration()

        @Parcelize
        data class VerifyCode(val username: String) : Configuration()

        @Parcelize
        data object Undefined : Configuration()
    }

    sealed class Child {
        data class Login(val component: LoginComponent) : Child()

        data class Onboarding(val component: OnboardingComponent) : Child()

        data class Home(val component: HomeComponent) : Child()

        data class VerifyCode(val component: VerifyCodeComponent, val username: String) : Child()

        data object Undefined : Child()
    }
    sealed interface Output {
        object OpenContent : Output
        object OpenAuthorizationFlow : Output
    }
}
package com.erstedigital.socialbank.domain.models

import com.erstedigital.socialbank.domain.utils.parseLocalDate
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class FsReceiptModel(
    var id: Long?,
    val receiptId: String? = null,
    val ico: String? = null,
    val cashRegisterCode: String? = null,
    val issueDate: String? = null,
    val createDate: String? = null,
    val customerId: String? = null,
    val dic: String? = null,
    val icDph: String? = null,
    val invoiceNumber: String? = null,
    val okp: String? = null,
    val paragon: Boolean? = null,
    val paragonNumber: String? = null,
    val pkp: String? = null,
    val receiptNumber: Long? = null,
    val type: String? = null,
    val taxBaseBasic: Double? = null,
    val taxBaseReduced: Double? = null,
    val totalPrice: Double? = null,
    val freeTaxAmount: Double? = null,
    val vatAmountBasic: Double? = null,
    val vatAmountReduced: Double? = null,
    val vatRateBasic: Double? = null,
    val vatRateReduced: Double? = null,
    val exemption: Boolean? = null,
    var category: String?,
    val organization: OrganizationModel? = null,
    val orgUnit: OrgUnitModel? = null,
    val items: List<ProductItemModel> = emptyList()
) {

    fun getIssueLocalDate(): LocalDate? {
        return issueDate?.parseLocalDate()
    }

}
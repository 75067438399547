package com.erstedigital.socialbank.data.network.models.request

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class GetTransactionsFilterRequest(
    val from: LocalDate? = null,
    val to: LocalDate? = null,
    val category: String? = null,
    val organizationId: Long? = null,
    val orgUnitId: Long? = null
)
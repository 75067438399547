package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.data.network.models.common.AppUser
import com.erstedigital.socialbank.data.network.models.common.FsReceipt
import com.erstedigital.socialbank.data.network.models.common.ManualTransaction
import com.erstedigital.socialbank.data.network.models.common.Split
import com.erstedigital.socialbank.domain.models.TransactionModel
import kotlinx.serialization.Serializable

@Serializable
data class TransactionResponse(
    val id: Long,
    val manualTransaction: ManualTransaction?,
    val fsReceipt: FsReceipt?,
    val transactions: List<TransactionListResponse>? = null,
    val splits: List<Split>,
    val appUser: AppUser
) {
    fun toModel(): TransactionModel {
        val model = TransactionModel(
                id,
                manualTransaction?.toModel(),
                fsReceipt?.toModel(),
                transactions?.map { it.toModel() } ?: emptyList(),
                splits.map { it.toModel() },
                appUser.toModel()
        )
        return model
    }
}
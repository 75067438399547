package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.GroupedTransactionsModel
import com.erstedigital.socialbank.ui.home.utils.getResource
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.graphExpenseColor
import com.erstedigital.socialbank.ui.react.utils.graphIncomeColor
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import com.erstedigital.socialbank.utils.parseCategoryInput
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.*

external interface TransactionGroupItemProps: Props {
    var groupedTransactionsModel: GroupedTransactionsModel
    var onTransactionClick: (Long) -> Unit
}

val TransactionGroupItem: FC<TransactionGroupItemProps> = FC { props ->

    Box{

        sx {
            width = 100.pct
        }

        Typography {
            sx {
                marginTop = 6.px
                marginBottom = 6.px
            }
            variant = TypographyVariant.body2
            +"${props.groupedTransactionsModel.date.dayOfMonth}.${props.groupedTransactionsModel.date.monthNumber}.${props.groupedTransactionsModel.date.year}"
        }

        props.groupedTransactionsModel.transactions.map { transaction ->

            val transactionType = transaction.type

            Card {
                sx {
                    marginLeft = 3.px
                    marginRight = 3.px
                    marginBottom = 10.px
                    marginTop = 4.px
                    backgroundColor = surfaceColor
                    borderRadius = cardBorderRadius
                    cursor = Cursor.pointer
                }

                elevation = 2


                CardContent {

                    onClick = { props.onTransactionClick(transaction.id!!) }

                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            justifyContent = JustifyContent.spaceBetween
                            width = 100.pct
                        }
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                width = 100.pct
                            }
                            Box {
                                sx {
                                    display = Display.flex
                                    justifyContent = JustifyContent.left
                                    alignItems = AlignItems.center
                                }
                                ReactHTML.img {
                                    src = transaction.category.parseCategoryInput().getResource(transaction.isExpense)
                                    width = 30.0
                                    height = 30.0
                                }
                            }

                            Box  {
                                sx {
                                    display = Display.flex
                                    flexDirection = FlexDirection.column
                                    justifyContent = JustifyContent.spaceBetween
                                    height = 100.pct
                                    marginLeft = 10.px
                                }
                                Typography {
                                    variant = TypographyVariant.body2
                                    sx {
                                        //fontWeight = FontWeight.bold
                                    }
                                    +(if (transaction.type === "fs_receipt") transaction.organizationName else transaction.category?.replace("/null", ""))
                                }
                                Typography {
                                    variant = TypographyVariant.caption
                                    +( if (transactionType === "fs_receipt") transaction.getLocalTime().toString().split(":").take(2).joinToString(":","") else MainRes.string.manual_transaction)
                                }
                            }
                        }

                        Box {
                            sx {
                                width = 70.px
                                display = Display.flex
                                alignItems = AlignItems.center
                            }
                            Typography {
                                sx {
                                    width = 100.pct
                                    color = if (transaction.isExpense) {
                                        graphExpenseColor} else {
                                        graphIncomeColor}
                                }
                                align = TypographyAlign.right
                                variant = TypographyVariant.body1
                                +"${transaction.price}€"
                            }
                        }
                    }
                }
            }
        }
    }
}
package com.erstedigital.socialbank.ui.react.dms.components

import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.documents.details.store.DetailsStore
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.icons.material.Delete
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.IconButton
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px

external interface AttachmentCardProps: Props {
    var attachmentName: String
    var onDelete: () -> Unit
    var onDownload: () -> Unit
}

val AttachmentCard: FC<AttachmentCardProps> = FC { props ->


    Box {
        sx {
            width = 100.pct
        }
        onClick = {
            props.onDownload()
        }
        Card {
            sx {
                marginRight = 10.px
                backgroundColor = surfaceColor
                borderRadius = cardBorderRadius
                width = 100.pct
                height = 50.px
                marginTop = 10.px
                marginBottom = 10.px
                cursor = Cursor.pointer
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
            }
            elevation = 2
            CardContent {
                sx {
                    width = 100.pct
                    height = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.spaceBetween
                }
                Typography {
                    sx {
                        width = 100.pct
                        marginLeft = 10.px
                    }
                    variant = TypographyVariant.body1
                    +props.attachmentName
                }
                IconButton {
                    sx {
                        marginRight = 10.px
                    }
                    onClick = {
                        it.stopPropagation()
                        props.onDelete()
                    }
                    Delete()
                }
            }
        }
    }

}
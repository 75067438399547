package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.CreateManualTransactionRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class CreateManualTransactionUsecase(
    private val transactionsRemoteDataSource: TransactionRemoteDataSource
) {

    suspend fun createManualTransaction(request: CreateManualTransactionRequest): ApiResponse<TransactionResponse, ErrorResponse> {
        return transactionsRemoteDataSource.createManualTransaction(request)
    }
}
package com.erstedigital.socialbank.ui.react.home.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Modal
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.Translate
import web.cssom.pct
import web.cssom.px
import web.cssom.translate

external interface DuplicateDialogProps: Props {
    var setShowDialog: (Boolean) -> Unit
    var onConfirm: () -> Unit
    var onDismiss: () -> Unit
    var open: Boolean
}

val DuplicateDialog: FC<DuplicateDialogProps> = FC { props ->

    Modal {
        open = props.open
        onClose = { _, _ ->
            props.setShowDialog(false)
            props.onDismiss()
        }

        Box {
            sx {
                width = 300.px
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                backgroundColor = Color("#fff")
                borderRadius = 10.px
                padding = 15.px
            }

            Box {
                sx {
                    display= Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    width = 100.pct
                }

                Typography {
                    variant = TypographyVariant.h6
                    +MainRes.string.title_receipt_added
                    sx {
                        marginBottom = 20.px
                    }
                }

                Typography {
                    variant = TypographyVariant.body1
                    +MainRes.string.message_receipt_added

                    sx {
                        marginBottom = 20.px
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }

                    Button {
                        sx {
                            color = primaryColor
                        }
                        variant = ButtonVariant.text
                        +MainRes.string.no
                        onClick = {
                            props.onDismiss()
                            props.setShowDialog(false)
                        }
                    }

                    Button {
                        sx {
                            backgroundColor = primaryColor
                        }
                        variant = ButtonVariant.contained
                        +MainRes.string.yes
                        onClick = {
                            props.onConfirm()
                            props.setShowDialog(false)
                        }
                    }
                }
            }
        }
    }
}

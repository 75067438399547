package com.erstedigital.socialbank.ui.profile.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.ChangePasswordRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateProfileRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.UserModel
import com.erstedigital.socialbank.domain.usecase.auth.SignoutUsecase
import com.erstedigital.socialbank.domain.usecase.user.GetProfileUsecase
import com.erstedigital.socialbank.domain.usecase.user.UpdateProfileUsecase
import com.erstedigital.socialbank.domain.utils.toHexColor
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ProfileStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val getProfileUsecase by inject<GetProfileUsecase>()
    private val updateProfileUsecase by inject<UpdateProfileUsecase>()
    private val signoutUsecase by inject<SignoutUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): ProfileStore = object : ProfileStore, Store<ProfileStore.Intent, ProfileStore.State, ProfileStore.Label> by storeFactory.create(
        name = "ProfileStore",
        initialState = ProfileStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchProfile)
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data object SignOut : Msg()
        data class Error(val message: String): Msg()
        data class ProfileData(val profile: UserModel): Msg()
        data object ActionSuccess: Msg()

    }

    private sealed class Action {
        data object FetchProfile: Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<ProfileStore.Intent, Action, ProfileStore.State, Msg, ProfileStore.Label>() {

        override fun executeAction(action: Action, getState: () -> ProfileStore.State) {


            scope.launch {
                when (action) {
                    is Action.FetchProfile -> getProfile()
                }
            }
        }

        private suspend fun getProfile () {
            dispatch(Msg.Loading)
            val localProfile = getProfileUsecase.getLocalProfile()!!
            when (val result = getProfileUsecase.getProfile()) {
                is ApiResponse.Success -> dispatch(Msg.ProfileData(profile = result.body.toModel()))
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                    dispatch(Msg.ProfileData(profile = localProfile))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    dispatch(Msg.ProfileData(profile = localProfile))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    dispatch(Msg.ProfileData(profile = localProfile))
                }

                else -> {}
            }
        }

        private suspend fun logout() {
            signoutUsecase.signOut()
            dispatch(Msg.SignOut)
            publish(ProfileStore.Label.OnSignOut)
        }

        private fun saveProfile(updateProfileRequest: UpdateProfileRequest) {
            scope.launch {
                dispatch(Msg.Loading)

                when (val result = updateProfileUsecase.updateProfile(updateProfileRequest)) {
                    is ApiResponse.Success -> dispatch(Msg.ProfileData(profile = result.body.toModel()))
                    is ApiResponse.Error.HttpError -> {
                        dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                    }
                    is ApiResponse.Error.GenericError -> {
                        dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    }
                    is ApiResponse.Error.SerializationError -> {
                        dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    }

                    else -> {}
                }
            }

        }

        override fun executeIntent(intent: ProfileStore.Intent, getState: () -> ProfileStore.State) {
            val state = getState()
            when (intent) {
                is ProfileStore.Intent.SignOut -> {
                    scope.launch {
                        logout()
                    }
                }
                is ProfileStore.Intent.SaveProfile -> saveProfile(
                    UpdateProfileRequest(
                        state.profile?.id!!,
                        intent.fullName,
                        intent.iban,
                        intent.phone,
                        state.profile.color?.toHexColor(),
                        state.profile.allowAnalysis,
                       )
                    )
                is ProfileStore.Intent.ChangePassword -> changePassword(intent.login, intent.password, intent.newPassword)
            }
        }

        private fun changePassword(login: String, password: String, newPassword: String) {
            scope.launch {
                dispatch(Msg.Loading)

                when (val result = updateProfileUsecase.changePassword(ChangePasswordRequest(
                    login =  login,
                    password = password,
                    newPassword = newPassword
                )
                )) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.ActionSuccess)
                    }
                    is ApiResponse.Error.HttpError -> {
                        dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                    }
                    is ApiResponse.Error.GenericError -> {
                        dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    }
                    is ApiResponse.Error.SerializationError -> {
                        dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                    }

                    else -> {}
                }
            }
        }


    }

    private object ReducerImpl: Reducer<ProfileStore.State, Msg> {
        override fun ProfileStore.State.reduce(msg: Msg): ProfileStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.ProfileData -> copy(loading = false, profile = msg.profile, error = null)
            is Msg.SignOut -> copy(signedOut = true, loading = false)
            is Msg.ActionSuccess -> copy(loading = false, actionSuccess = true)
        }
    }
}
package com.erstedigital.socialbank.di

import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import com.russhwolf.settings.coroutines.SuspendSettings
import com.russhwolf.settings.coroutines.toSuspendSettings
import org.koin.dsl.module

@OptIn(ExperimentalSettingsApi::class)
val appModule = module {
    single<SuspendSettings> {
        StorageSettings().toSuspendSettings()
    }
}
package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.product.ProductRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.UpdateProductRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class UpdateProductCategoryUsecase(
    private val productRemoteDataSource: ProductRemoteDataSource
) {

    suspend operator fun invoke(productId: Long, category: String): ApiResponse<UpdateProductResponse, ErrorResponse> {
        return productRemoteDataSource.updateProductCategory(
            UpdateProductRequest(
                id = productId,
                category = category
            )
        )
    }
}
package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.OrganizationModel
import kotlinx.serialization.Serializable

@Serializable
data class Organization(
    val id: Long?,
    val buildingNumber: String? = null,
    val country: String? = null,
    val dic: String? = null,
    val icDph: String? = null,
    val ico: String? = null,
    val municipality: String? = null,
    val name: String? = null,
    val postalCode: String? = null,
    val propertyRegistrationNumber: String? = null,
    val streetName: String? = null,
    val vatPayer: Boolean? = null,
    val category: String? = null
) {
    fun toModel(): OrganizationModel {
        val model = OrganizationModel(
            id,
            buildingNumber,
            country,
            dic,
            icDph,
            ico,
            municipality,
            name,
            postalCode,
            propertyRegistrationNumber,
            streetName,
            vatPayer,
            category
        )
        return model
    }
}
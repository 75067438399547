package com.erstedigital.socialbank.domain.models

import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.benasher44.uuid.uuid4
import kotlinx.serialization.Serializable

@Serializable
@Parcelize
data class ProductItemModel(
    val id: Long?,
    var quantity: Double?,
    val product: ProductModel?,
    var discount: ProductModel?,
    val color: Int? = getRandomColor()
): Parcelable {
    fun toBudgetProduct(): ProductBudgetModel {
        return ProductBudgetModel(
            uuid4().toString(),
            id ?: 0L,
            product?.name ?: "",
            product?.price ?: 0.0,
            null,
            quantity ?: 0.0,
            quantity ?: 0.0,
            null,
            false
        )
    }

    fun getTotalPrice(): Double {
        return quantity?.times(product?.price ?: 0.0) ?: 0.0
    }

    companion object {
        fun getRandomColor(): Int {
            // TODO: Replace this with your multiplatform color generation logic
            return 0 // Dummy value
        }
    }
}
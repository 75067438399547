package com.erstedigital.socialbank.domain.usecase.planning

import com.erstedigital.socialbank.data.datasources.planning.PlanningRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedIncomeRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.ExpectedIncomeModel

class UpdateExpectedIncomeUsecase (
    private val planningRemoteDataSource: PlanningRemoteDataSource
) {
    suspend fun updateExpectedIncome(updateExpectedIncomeRequest : UpdateExpectedIncomeRequest): ExpectedIncomeModel {
        var expectedIncomeModel = ExpectedIncomeModel(
            income = null,
            recurringIncome = null,
            businessIncome = null,
            recurringBusinessIncome = null,
            socialBenefits = null,
            recurringSocialBenefits = null,
            otherIncome = null,
            recurringOtherIncome = null,
            date = null
        )
        return when (val networkRes = planningRemoteDataSource.updateExpectedIncome(updateExpectedIncomeRequest)) {
            is ApiResponse.Success -> {
                expectedIncomeModel = networkRes.body.toModel()
                expectedIncomeModel
            }

            is ApiResponse.Error -> {
                expectedIncomeModel
            }
        }
    }
}
package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.serialization.Serializable


@Serializable
data class DashboardModel(
    val balance: Double? = null,
    val appUser: UserModel? = null,
    val leaderboardModel: LeaderboardModel? = null,
    val notes: List<NoteModel> = emptyList(),
    val userTransactions: List<UserTransactionModel> = emptyList(),
    val recentDocuments: List<DocumentModel> = emptyList(),
    val monthlyTransactions: List<MonthlyTransactionModel> = emptyList(),
    val monthlyIncome: Double? = null,
    val monthlyExpenses: Double? = null,
    val monthlyExpectedIncome: Double? = null,
    val monthlyExpectedExpenses: Double? = null
) {

    @Serializable
    data class LeaderboardModel(
        val position: Int,
        val productCount: Int,
        val users: List<LeaderModel>
    )

    @Serializable
    data class DocumentModel(
        val id: Long? = null,
        val name: String? = null,
        val modifiedAy: LocalDateTime? = null,
    )

    @Serializable
    data class LeaderModel(
        val name: String?,
        val amount: Int
    )

    @Serializable
    data class NoteModel(
        val text: String?,
        val appUserVisibility: Boolean,
        val createdAt: LocalDateTime? = null,
        val notePeriods: List<NotePeriod>? = null,
        val type: String? = null,
        val intervalFrom : LocalDate? = null,
        val intervalTo : LocalDate? = null
    )

    @Serializable
    data class NotePeriod(
        val start: LocalDate? = null,
        val end: LocalDate? = null,
        val state: String? = null,
    )

    @Serializable
    data class UserTransactionModel(
        val id: Long,
        val from: UserModel? = null,
        val to: UserModel? = null,
        val settledFrom: Boolean = false,
        val settledTo: Boolean = false,
        val amount: Double = 0.0,
        val organizationName: String? = null,
        val date: LocalDateTime? = null,
        val transactionId: Long? = null
    )

    @Serializable
    data class MonthlyTransactionModel(
        val id: Long? = null,
        val price: Double? = null,
        val organizationName: String? = null,
        val type: String? = null,
        val date: String? = null,
        val category: String? = null,
        val organizationId: Long? = null,
        val splitCreatorName: String? = null,
        val splitTotalPrice: Double? = null,
        val splitPeopleCount: Int? = null,
        var hasImages: Boolean = false
    ) {
        val transactionType: TransactionType
            get() = when (type) {
                "income" -> TransactionType.INCOME
                "expense" -> TransactionType.EXPENSE
                "fs_receipt" -> TransactionType.FS_RECEIPT
                "multi" -> TransactionType.MULTI
                "fs_receipt_split" -> TransactionType.FS_RECEIPT_SPLIT
                "fs_receipt_received" -> TransactionType.FS_RECEIPT_SPLIT_RECEIVED
                else -> TransactionType.UNKNOWN
            }

        val isExpense: Boolean
            get() = when(transactionType) {
                TransactionType.EXPENSE,
                TransactionType.FS_RECEIPT,
                TransactionType.UNKNOWN,
                TransactionType.MULTI -> true
                else -> false
            }
        fun getLocalTime(): LocalTime? {
            date ?: return null
            if (type?.startsWith("fs_receipt") == true) {
                return tryParseTime(date)?.time
            }
            return null
        }
        private fun tryParseDate(date: String): LocalDate? {
            val components = date.split(".")
            if (components.size != 3) return null

            return try {
                val year = components[2].toInt()
                val month = components[1].toInt()
                val day = components[0].toInt()
                LocalDate(year, month, day)
            } catch (e: Exception) {
                e.printStackTrace()
                null
            }
        }

        private fun tryParseTime(date: String): LocalDateTime {
            val datetimeComponents = date.split(" ")
            if (datetimeComponents.size != 2) throw Exception("Invalid datetime format")

            val datePart = tryParseDate(datetimeComponents[0]) ?: throw Exception("Invalid date format")
            val timeComponents = datetimeComponents[1].split(":")
            if (timeComponents.size != 3) throw Exception("Invalid time format")

            val hour = timeComponents[0].toInt()
            val minute = timeComponents[1].toInt()
            val second = timeComponents[2].toInt()

            return LocalDateTime(datePart.year, datePart.monthNumber, datePart.dayOfMonth, hour, minute, second)
        }
    }
}
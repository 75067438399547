package com.erstedigital.socialbank.di.logging

import org.koin.core.logger.Level
import org.koin.core.logger.Logger
import org.koin.core.logger.MESSAGE

class KoinLogger() : Logger() {

    private val prefix = "(KOIN) :: "
    override fun display(level: Level, msg: MESSAGE) {
        val message = prefix + msg
        when (level) {
            Level.DEBUG -> console.log(message)
            Level.INFO -> console.log(message)
            Level.WARNING -> console.log(message)
            Level.ERROR -> console.log(message)
            else -> {}
        }
    }

}
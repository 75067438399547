package com.erstedigital.socialbank.domain.models

import kotlinx.serialization.Serializable

@Serializable
data class ProductOwnershipModel(
    val productItemModel: ProductItemModel,
    val userModel: UserModel,
    val quantity: Double
) {

    fun getTotalPrice(): Double {
        return quantity.times(productItemModel.product?.price ?: 0.0)
    }
}
package com.erstedigital.socialbank.domain.models

import io.github.aakira.napier.Napier
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.datetime.toLocalDate
import kotlinx.serialization.Serializable

@Serializable
class TransactionListModel(
    val id: Long? = null,
    val price: Double? = null,
    val date: String? = null,
    val type: String? = null,
    val organizationName: String? = null,
    val organizationId: Long? = null,
    val category: String? = null,
    val splitCreatorName: String? = null,
    val splitTotalPrice: Double? = null,
    val splitPeopleCount: Int? = null,
    var selected: Boolean = false
) {

    fun toModel(): TransactionListModel {
        return TransactionListModel(
            id,
            price?:0.0,
            date?: "",
            type?: "",
            organizationName?: "",
            organizationId?: 0L,
            category?: "",
            splitCreatorName?: "",
            splitTotalPrice?: 0.0,
            splitPeopleCount?: 0,
        )
    }

    fun getLocalDate(): LocalDate? {
        date ?: return null
        if (type?.startsWith("fs_receipt") == true) {
            return tryParseTime(date).date
        }
        return LocalDate.parse(date)
    }

    val transactionType: TransactionType
        get() = when (type) {
            "income" -> TransactionType.INCOME
            "expense" -> TransactionType.EXPENSE
            "fs_receipt" -> TransactionType.FS_RECEIPT
            "multi" -> TransactionType.MULTI
            "fs_receipt_split" -> TransactionType.FS_RECEIPT_SPLIT
            "fs_receipt_received" -> TransactionType.FS_RECEIPT_SPLIT_RECEIVED
            else -> TransactionType.UNKNOWN
        }

    val isExpense: Boolean
        get() = when(transactionType) {
            TransactionType.EXPENSE,
            TransactionType.FS_RECEIPT,
            TransactionType.UNKNOWN,
            TransactionType.MULTI -> true
            else -> false
        }

    private fun tryParseDate(date: String): LocalDate? {
        val components = date.split(".")
        if (components.size != 3) return null

        return try {
            val year = components[2].toInt()
            val month = components[1].toInt()
            val day = components[0].toInt()
            LocalDate(year, month, day)
        } catch (e: Exception) {
            e.printStackTrace()
            null
        }
    }

    private fun tryParseTime(date: String): LocalDateTime {
        val datetimeComponents = date.split(" ")
        if (datetimeComponents.size != 2) throw Exception("Invalid datetime format")

        val datePart = tryParseDate(datetimeComponents[0]) ?: throw Exception("Invalid date format")
        val timeComponents = datetimeComponents[1].split(":")
        if (timeComponents.size != 3) throw Exception("Invalid time format")

        val hour = timeComponents[0].toInt()
        val minute = timeComponents[1].toInt()
        val second = timeComponents[2].toInt()

        return LocalDateTime(datePart.year, datePart.monthNumber, datePart.dayOfMonth, hour, minute, second)
    }

    fun getLocalTime(): LocalTime? {
        date ?: return null
        if (type?.startsWith("fs_receipt") == true) {
            return tryParseTime(date)?.time
        }
        return null
    }

    fun getLocalDateTime(): LocalDateTime? {
        date ?: return null
        if (type?.startsWith("fs_receipt") == true) {
            return tryParseTime(date)
        }
        return null
    }
}
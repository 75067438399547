package com.erstedigital.socialbank.ui.onboarding

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.login.store.LoginStore
import com.erstedigital.socialbank.ui.login.store.LoginStoreFactory
import com.erstedigital.socialbank.ui.onboarding.store.OnboardingStore
import com.erstedigital.socialbank.ui.onboarding.store.OnboardingStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import com.arkivanov.mvikotlin.extensions.coroutines.labels

class OnboardingComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    private val output: (Output) -> Unit
): ComponentContext by componentContext {


    private val onboardingStore = instanceKeeper.getStore {
        OnboardingStoreFactory(storeFactory).create()
    }

    val label: Flow<OnboardingStore.Label> = onboardingStore.labels

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<OnboardingStore.State> = onboardingStore.stateFlow

    fun onEvent(event: OnboardingStore.Intent) {
        onboardingStore.accept(event)
    }


    fun onOutput(output: Output) {
        output(output)
    }

    sealed class Output {
        data object NavigateToLogin: Output()

        data object NavigateToHome: Output()
        data object NavigateToRegister: Output()
    }
}
package com.erstedigital.socialbank.ui.react.transactions

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.CompanyModel
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.react.home.components.CategorySelector
import com.erstedigital.socialbank.ui.react.home.components.CategoryType
import com.erstedigital.socialbank.ui.react.transactions.components.NewCompanyModal
import com.erstedigital.socialbank.ui.transactions.manual.ManualTransactionComponent
import com.erstedigital.socialbank.ui.transactions.manual.store.ManualTransactionStore
import js.core.jso
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import mui.base.FilterOptionsState
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowForward
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.onChange
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType

external interface ManualTransactionContentProps: Props {
    var component: ManualTransactionComponent
    var onBack: () -> Unit
}

val filter = { options: Array<out CompanyModel?>, state: FilterOptionsState<CompanyModel?> ->
    options.filter {
        it?.name?.contains(state.inputValue ?: "", true) ?: false
    }.toMutableList()
}

val ManualTransactionContent: FC<ManualTransactionContentProps> = FC { props ->

    val (amount, setAmount) = useState("")
    val (selectedType, setSelectedType) = useState(CategoryType.EXPENSE)
    val (selectedCategory, setSelectedCategory) = useState<CategoryInput?>(null)
    val (selectedCompany, setSelectedCompany) = useState<CompanyModel?>(null)
    val (date, setDate) = useState(
        LocalDateTime.parse(
        js("new Date().toISOString().split(\"Z\")[0]") as String
    ).date)
    val (showCreateCompanyModal, setShowCreateCompanyModal) = useState(false)

    val (state, setState) = useState(props.component.state.value)


    useEffect{
        val job = props.component.state.onEach {
            setState(it)
        }.launchIn(GlobalScope)
    }

    useEffect(state.transactionCreated) {
        if (state.transactionCreated) {
            props.onBack()
        }
    }

    NewCompanyModal {
        open = showCreateCompanyModal
        onDismiss = {
            setShowCreateCompanyModal(false)
        }
        onConfirm = { name, ico ->
            props.component.onEvent(ManualTransactionStore.Intent.CreateCompany(
                name = name,
                ico = ico
            ))
            setShowCreateCompanyModal(false)
        }
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            paddingBottom = 90.px
            maxWidth = 600.px
            marginLeft = 5.pct
            marginRight = 5.pct
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
            }

            IconButton {
                onClick = {
                    props.onBack()
                }
                ArrowBack()
            }
            Typography {
                sx {
                    marginBottom = 10.px
                    width = 100.pct
                }
                variant = TypographyVariant.h4
                +MainRes.string.manual_transaction
            }
        }

        TextField {
            sx {
                width = 100.pct
                marginBottom = 10.px
                marginTop = 10.px
            }
            variant = FormControlVariant.outlined
            value = amount
            label = ReactNode("${MainRes.string.amount} (€)")
            onChange = { event ->
                val target = event.target as HTMLInputElement
                val input = target.value.replace(Regex("[^\\d.]"), "")
                if (input.matches(Regex("^\\d*(\\.\\d{0,2})?$"))) {
                    setAmount(input)
                }
            }
            SelectProps= jso {
                startAdornment = Fragment.create {
                    +"€"
                }
            }
        }

        TextField {
            sx {
                width = 100.pct
            }
            label = ReactNode("")
            value = date
            type = InputType.date
            variant = FormControlVariant.outlined
            onClick = {
                val target = it.target as HTMLInputElement
                //setDate(LocalDate.parse(target.value))
                setDate(LocalDate.parse(it.target.asDynamic().value))
            }
            onBlur = {
                val target = it.target as HTMLInputElement
                //target.type = InputType.text.toString()
            }
            onChange = {
                val target = it.target as HTMLInputElement
                setDate(LocalDate.parse(target.value))
            }
        }
        if (selectedType == CategoryType.EXPENSE) {
            Autocomplete<AutocompleteProps<CompanyModel?>> {
                sx {
                    width = 100.pct
                    marginBottom = 10.px
                    marginTop = 10.px
                }
                options = props.component.state.value.companyList.toTypedArray()
                disablePortal = true
                renderInput = { params ->
                    TextField.create {
                        sx {
                            width = 100.pct
                        }
                        +params
                        onChange = { value ->
                            props.component.onEvent(ManualTransactionStore.Intent.SearchCompany((value.target as HTMLInputElement).value))
                        }
                        label = ReactNode(MainRes.string.organization)
                    }
                }
                onChange = { _, value, _, _ ->
                    val v = value as CompanyModel?

                    if (v?.id == null && v?.name?.startsWith("Create ") == true) {
                        setShowCreateCompanyModal(true)
                    } else {
                        setSelectedCompany(v)
                    }
                }
                value = selectedCompany
                getOptionLabel = { it?.name ?: "" }
                isOptionEqualToValue = { option, value ->
                    option?.id === value?.id
                }
                filterOptions = { options, params ->
                    val filtered = filter(options, params)

                    if (params.inputValue.isNotEmpty()) {
                        filtered.add(
                            CompanyModel(
                                id = null,
                                name = "Create ${params.inputValue}",
                                ico = null,
                            )
                        )
                    }
                    filtered.toTypedArray()
                }
                renderOption = { d,comp,state,p ->
                    Box.create {

                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.start
                            paddingLeft = 5.px
                            marginBottom = 5.px
                            cursor = Cursor.pointer
                        }
                        +d
                        +p
                        Typography {
                            sx {
                                width = 100.pct
                                marginTop = 5.px
                                marginBottom = 2.px
                            }
                            variant = TypographyVariant.body1
                            +comp?.name
                        }
                        if (!comp?.ico.isNullOrEmpty()) {
                            Typography {
                                variant = TypographyVariant.caption
                                +"${MainRes.string.ico}: ${comp?.ico}"
                            }
                        }
                    }
                }
            }
        }
        ToggleButtonGroup {
            sx {
                width = 100.pct
                marginBottom = 10.px
                marginTop = 10.px
            }
            exclusive = true
            onChange = { _, value ->
                if (value != null) {
                    setSelectedType(value as CategoryType)
                    setSelectedCategory(null)
                }
            }
            value = selectedType

            ToggleButton {
                value = CategoryType.INCOME
                sx {
                    width = 50.pct
                    marginRight = 10.px
                }
                +MainRes.string.income
            }

            ToggleButton {
                value = CategoryType.EXPENSE
                sx {
                    width = 50.pct
                }
                +MainRes.string.expense
            }
        }

        CategorySelector {
            category = selectedCategory
            expanded = (true)
            minimal = (false)
            onCategoryClicked = { setSelectedCategory(it) }
            type = selectedType
        }

        Fab {
            sx {
                position = Position.absolute
                bottom = 20.px
                right = 20.px
            }
            disabled = amount.isEmpty() || selectedCategory == null
            onClick = {
                if (amount.isNotEmpty() && selectedType == CategoryType.EXPENSE && selectedCategory != null && selectedCompany != null) {
                    props.component.onEvent(ManualTransactionStore.Intent.CreateManualTransaction(
                        amount = amount.toDouble() * -1.0,
                        date = date,
                        category = selectedCategory,
                        companyId = selectedCompany.id!!
                    ))
                }
                if (amount.isNotEmpty() && selectedType == CategoryType.INCOME && selectedCategory != null) {
                    props.component.onEvent(ManualTransactionStore.Intent.CreateManualTransaction(
                        amount = amount.toDouble(),
                        date = date,
                        category = selectedCategory,
                        companyId = null
                    ))
                }
            }
            ArrowForward()
        }
    }
}
package com.erstedigital.socialbank.domain.usecase.statistics

import com.erstedigital.socialbank.data.datasources.statistics.StatisticsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.StatisticsCustomRangeResponse
import com.erstedigital.socialbank.data.network.models.response.StatisticsResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.DateRange
import kotlinx.datetime.LocalDate


class GetCustomRangeStatisticsUsecase(
    private val statisticsRemoteDataSource: StatisticsRemoteDataSource
) {
    suspend fun getStatistics(startDate: LocalDate, endDate: LocalDate): ApiResponse<StatisticsCustomRangeResponse, ErrorResponse> {
        return statisticsRemoteDataSource.getCustomRangeStatistics(
            DateRange(
                startDate,
                endDate)
        )
    }
}
package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.useMemo
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.pct
import web.cssom.px

external interface OrgInfoCardProps: Props {
    var transaction: TransactionModel?
}

val OrgInfoCard: FC<OrgInfoCardProps> = FC { props ->

    val details = useMemo(props.transaction) {
        val details = ArrayList<Pair<String, String>>()
        props.transaction?.fsReceiptModel?.organization?.name?.let { details.add(MainRes.string.name to it) }
        props.transaction?.fsReceiptModel?.organization?.streetName?.let { details.add(MainRes.string.street_name to it) }
        props.transaction?.fsReceiptModel?.organization?.buildingNumber?.let { details.add(MainRes.string.building_number to it) }
        props.transaction?.fsReceiptModel?.organization?.country?.let { details.add(MainRes.string.country to it) }
        props.transaction?.fsReceiptModel?.organization?.municipality?.let { details.add(MainRes.string.municipality to it) }
        props.transaction?.fsReceiptModel?.organization?.postalCode?.let { details.add(MainRes.string.postal_code to it) }
        props.transaction?.fsReceiptModel?.organization?.dic?.let { details.add(MainRes.string.dic to it) }
        props.transaction?.fsReceiptModel?.organization?.ico?.let { details.add(MainRes.string.ico to it) }
        props.transaction?.fsReceiptModel?.organization?.icDph?.let { details.add(MainRes.string.ic_dph to it) }
        props.transaction?.fsReceiptModel?.organization?.propertyRegistrationNumber?.let { details.add(MainRes.string.property_registration_number to it) }
        details
    }

    Box{
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
        }

        Typography {
            sx {
                width = 60.pct
            }
            +MainRes.string.organization_details
        }

        Card {
            sx {
                marginLeft = 3.px
                marginRight = 3.px
                marginBottom = 20.px
                marginTop = 4.px
                backgroundColor = surfaceColor
                borderRadius = cardBorderRadius
            }

            elevation = 2

            CardContent {

                details.map {
                    InfoRow {
                        label = it.first
                        value = it.second
                    }
                }

            }
        }

    }
}
package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.overdokladapi.OverDokladApi
import com.erstedigital.overdokladapi.data.response.ReceiptFindResponse
import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.CreateFsReceiptRequest
import com.erstedigital.socialbank.data.network.models.request.toFsRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.exception.ReceiptNotFoundException
import com.erstedigital.socialbank.domain.models.TransactionModel
import io.github.aakira.napier.Napier

class CreateFsReceiptV2Usecase(
    private val transactionsRemoteDataSource: TransactionRemoteDataSource
) {

    val overDokladApi = OverDokladApi()

    suspend fun createFsReceipt(params: String): ApiResponse<Result, ErrorResponse> {

        try {
            val overDokladResponse = overDokladApi.find(params)
            val mapped = mapOverdokladResult(overDokladResponse)

            if (mapped == null) {
                val request = overDokladResponse?.receipt!!.toFsRequest()
                val remoteResult = transactionsRemoteDataSource.createFsReceiptV2(request)

                if (remoteResult is ApiResponse.Success) {
                    val remoteResult = remoteResult.body

                    if (remoteResult.status == 0) {
                        return ApiResponse.Success(
                            body = Result(
                                result = Result.Type.CREATED,
                                model = remoteResult.transaction?.toModel(),
                                receipt = request
                            )
                        )
                    } else if (remoteResult.status == 1) {
                        return ApiResponse.Success(
                            body = Result(
                                result = Result.Type.EXISTS,
                                receipt = request
                            )
                        )
                    } else {
                        return ApiResponse.Success(
                            body = Result(
                                result = Result.Type.UNKNOWN,
                                receipt = request
                            )
                        )
                    }
                } else {
                    return ApiResponse.Error.GenericError(
                        message = remoteResult.toString(),
                        errorMessage = null
                    )
                }
            } else {
                return ApiResponse.Error.GenericError(
                    message = mapped,
                    errorMessage = null
                )
            }
        } catch (e: Exception) {
            return ApiResponse.Error.HttpError(
                code = 500,
                errorBody = null,
                errorMessage = e.message,
            )
        }
    }

    private fun mapOverdokladResult(response: ReceiptFindResponse?): String? {
        if (response?.receipt != null && response.returnValue == 0) {
            return null
        }
        return ReceiptNotFoundException().message
    }

    data class Result(
        val result: Type = Type.UNKNOWN,
        val model: TransactionModel? = null,
        val receipt: CreateFsReceiptRequest? = null
    ) {

        enum class Type {
            CREATED, EXISTS, UNKNOWN
        }
    }
}
package com.erstedigital.socialbank.domain.usecase.org

import com.erstedigital.socialbank.data.datasources.company.CompanyRemoteDataSource
import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.response.CompanyListResponse
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class GetCompaniesUsecase(
    private val companyRemoteDataSource: CompanyRemoteDataSource
) {

    suspend operator fun invoke(searchString: String): ApiResponse<List<CompanyListResponse>, ErrorResponse> {
        return companyRemoteDataSource.search(searchString)
    }
}
package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.domain.models.UserModel
import com.erstedigital.socialbank.domain.utils.toIntColor
import kotlinx.serialization.Serializable

@Serializable
data class UserListResponse(
        val id: Long,
        val fullName: String?,
        val phoneNumber: String?,
        val email: String?,
        val iban: String?,
        val color: String?
) {
    fun toModel(): UserModel {
        return UserModel(id,
                fullName,
                email = email,
                phoneNumber = phoneNumber,
                iban = iban,
                _color = color?.let {
                    return@let try {
                        it.toIntColor()
                    } catch (e: Exception) {
                        e.printStackTrace()
                        null
                    }
                })
    }
}
package com.erstedigital.socialbank.ui.root.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.domain.models.UserModel
import com.erstedigital.socialbank.domain.usecase.auth.UserSignedInUsecase
import com.erstedigital.socialbank.ui.profile.store.ProfileStore
import com.erstedigital.socialbank.ui.profile.store.ProfileStoreFactory
import org.koin.core.component.KoinComponent
import com.erstedigital.socialbank.ui.root.store.RootStore.Label
import com.erstedigital.socialbank.ui.root.store.RootStore.State
import io.github.aakira.napier.Napier
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.koin.core.component.inject
import org.koin.core.logger.Logger
import kotlin.coroutines.CoroutineContext

internal class RootStoreImplFactory(
    private val storeFactory: StoreFactory,
    private val mainContext: CoroutineContext = Dispatchers.Unconfined,
) : KoinComponent {

    private val userSignedInUsecase by inject<UserSignedInUsecase>()


    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): RootStore =
        object : RootStore, Store<Nothing, State, Label> by storeFactory.create(
            name = "RootStoreImplStore",
            initialState = State(),
            bootstrapper = coroutineBootstrapper {
                launch { dispatch(Action.CheckUserAlreadySigned) }
            },
            executorFactory = { ExecutorImpl(mainContext = mainContext) },
            reducer = ReducerImpl
        ) {}

    private sealed interface Action {
        object CheckUserAlreadySigned : Action
    }

    private sealed class Msg {
        data object Loading : Msg()

        data object UserSignedIn : Msg()
        data object UserNotSignedIn : Msg()

    }

    private inner class ExecutorImpl(
        mainContext: CoroutineContext = Dispatchers.Unconfined,
    ) : CoroutineExecutor<Nothing, Action, State, Msg, Label>(mainContext = mainContext) {


        override fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.CheckUserAlreadySigned -> checkUserAlreadySigned()
            }
        }

        private fun checkUserAlreadySigned() {
            scope.launch {
                if (userSignedInUsecase.isSignedIn()) {
                    publish(Label.UserAlreadySigned)
                    dispatch(Msg.UserSignedIn)
                } else {
                    publish(Label.UserNotSigned)
                    dispatch(Msg.UserNotSignedIn)
                }

            }
        }
    }

    private object ReducerImpl: Reducer<State, Msg> {
        override fun State.reduce(msg: Msg):State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.UserSignedIn -> copy(loading = false, userSignedIn = true)
            is Msg.UserNotSignedIn -> copy(loading = false, userSignedIn = false)
        }
    }
}
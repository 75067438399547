package com.erstedigital.socialbank.ui.react.dashboard

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.dashboard.DashboardComponent
import com.erstedigital.socialbank.ui.home.HomeComponent
import com.erstedigital.socialbank.ui.home.store.HomeStore
import com.erstedigital.socialbank.ui.react.dashboard.ComponentStyles.scrollable
import com.erstedigital.socialbank.ui.react.utils.*
import io.data2viz.charts.chart.chart
import io.data2viz.charts.chart.discrete
import io.data2viz.charts.chart.mark.bar
import io.data2viz.charts.chart.quantitative
import io.data2viz.charts.viz.newVizContainer
import io.data2viz.color.Colors
import io.data2viz.geom.Size
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.*
import web.storage.localStorage
import kotlin.math.absoluteValue

/**
 * Dashboard component props
 * @property component DashboardComponent
 * @property onDmsClicked Function0<Unit>
 *     Function to be called when the user clicks on the DMS button
 *     in the dashboard
 * @property onFileClicked Function1<Long, Unit>
 *     Function to be called when the user clicks on a file in the dashboard
 * @property parent HomeComponent
 */
external interface DashboardComponentProps : Props {
    var component: DashboardComponent
    var onDmsClicked: () -> Unit
    var onFileClicked: (Long) -> Unit
    var parent: HomeComponent
}

/**
 * Dashboard content component
 * @param props DashboardComponentProps
 * @return ReactNode
 */
val DashboardContent: FC<DashboardComponentProps> = FC { props ->
    val (state, setState) = useState(props.component.state.value)

    useEffect {
        props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }

    useEffect {
        window.onload = {
            val d = document.getElementById("chart") as HTMLDivElement
            d.innerHTML = ""
            val vc = d.newVizContainer().apply {
                size = Size(300.0, 200.0)
            }
            val data =
                listOf(Pair("Income", state.dashboard?.monthlyIncome), Pair("Expenses", state.dashboard?.monthlyExpenses))
            console.log("Data: $data")
            val expectedData = listOf(Pair("Expected Income", state.dashboard?.monthlyExpectedIncome), Pair("Expected Expenses", state.dashboard?.monthlyExpectedExpenses))
            console.log("Expected Data: $expectedData")
            vc.chart(data = data) {

                config {
                    backgroundColor = Colors.rgb(0xE4EAF0)
                    yAxis {
                        enableAxisLine = false
                        enableTicks = false
                        //enableAxis = false
                    }
                    xAxis {
                        enableAxisLine = false
                        enableTicks = false
                    }
                    mark {
                        fills = listOf(Colors.rgb(0xff0000))
                        fillsHighlight = listOf(Colors.rgb(0xff0000))
                        fillsSelect = listOf(Colors.rgb(0xff0000))
                        strokeColors = listOf(Colors.rgb(0xff0000))
                        strokeColorsHighlight = listOf(Colors.rgb(0xff0000))
                        strokeColorsSelect = listOf(Colors.rgb(0xff0000))
                    }
                }

                val categoryDimension =
                    discrete({ data[0].first }) // Use the first element of the pair (Income or Expenses) as the category

                val valueDimension =
                    quantitative({ data[0].second?.toDouble() }) // Use the second element of the pair as the value

                bar(categoryDimension, valueDimension)
            }
        }
    }

    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                maxWidth = 600.px
                width = 90.pct
                height = 100.pct
                paddingTop = 20.px
                paddingBottom = 120.px
            }

            // Monthly balance
            Box {
                sx {
                    display = Display.flex
                    // justify between
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.spaceBetween
                }
                Typography {
                    sx {
                        marginBottom = 10.px
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.h5
                    +MainRes.string.dashboard_balance_string
                }
                Typography {
                    sx {
                    }
                    variant = TypographyVariant.h5
                    +MainRes.string.dashboard_balance.format((state.dashboard?.monthlyIncome?.toInt()?.minus(state.dashboard.monthlyExpenses?.toInt()!!.absoluteValue)).toString())
                }
            }

            // Total balance
            Box {
                sx {
                    display = Display.flex
                    // justify between
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.spaceBetween
                }
                Typography {
                    sx {
                        marginBottom = 10.px
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.body1
                    +MainRes.string.dashboard_total_balance_string
                }
                Typography {
                    sx {
                    }
                    variant = TypographyVariant.body1
                    +MainRes.string.dashboard_balance.format(state.dashboard?.balance?.toInt().toString())
                }
            }

            // Monthly income and expenses
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.spaceBetween
                    cursor = Cursor.pointer
                }
                onClick = {
                    localStorage.setItem("navValue", 1.toString())
                    localStorage.setItem("navigation", "transactions")
                    props.parent.onOutput(HomeComponent.Output.NavigateToTransactions)
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        width = 40.pct
                        backgroundColor = graphIncomeColor
                        borderRadius = 10.px
                        padding = 10.px
                        color = NamedColor.white

                    }
                    Typography {
                        sx {
                            marginBottom = 10.px
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_income
                    }
                    Typography {
                        sx {
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_balance.format(state.dashboard?.monthlyIncome?.toInt().toString())
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        width = 40.pct
                        backgroundColor = graphExpenseColor
                        borderRadius = 10.px
                        padding = 10.px
                        color = NamedColor.white
                    }
                    Typography {
                        sx {
                            marginBottom = 10.px
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_expense
                    }
                    Typography {
                        sx {
                            marginBottom = 10.px
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_balance.format(
                            state.dashboard?.monthlyExpenses?.toInt().toString()
                        )
                    }
                }
            }

            // Previous months
            Button {
                sx {
                    color = primaryColor
                    marginTop = 10.px
                    marginBottom = 10.px
                    marginTop = 10.px
                }
                onClick = {
                    localStorage.setItem("navValue", 2.toString())
                    localStorage.setItem("navigation", "statistics")
                    props.parent.onOutput(HomeComponent.Output.NavigateToStatistics)
                }
                variant = ButtonVariant.outlined
                +MainRes.string.dashboard_previous_months
            }

            // Divider line
            Divider {
                sx {
                    width = 100.pct
                    height = 2.px
                    backgroundColor = surfaceColor
                    marginBottom = 20.px
                    marginTop = 10.px
                    borderRadius = 50.px
                }
            }

            // Expected income and expenses
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.spaceBetween
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        width = 40.pct
                        backgroundColor = graphIncomeColor
                        borderRadius = 10.px
                        padding = 10.px
                        color = NamedColor.white
                        cursor = Cursor.pointer

                    }
                    Typography {
                        sx {
                            marginBottom = 10.px
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_expected_income
                    }
                    Typography {
                        sx {
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_balance.format(state.dashboard?.monthlyExpectedIncome?.toInt().toString())
                    }
                    onClick = {
                        localStorage.setItem("navValue", 4.toString())
                        localStorage.setItem("navigation", "planning")
                        localStorage.setItem("navigation_subpage", "income")
                        props.parent.onOutput(HomeComponent.Output.NavigateToPlanning)
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        width = 40.pct
                        backgroundColor = graphExpenseColor
                        borderRadius = 10.px
                        padding = 10.px
                        color = NamedColor.white
                        cursor = Cursor.pointer
                    }
                    Typography {
                        sx {
                            marginBottom = 10.px
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_expected_expense
                    }
                    Typography {
                        sx {
                            marginBottom = 10.px
                            fontWeight = FontWeight.bold
                        }
                        variant = TypographyVariant.body1
                        +MainRes.string.dashboard_balance.format(state.dashboard?.monthlyExpectedExpenses?.toInt().toString())
                    }
                    onClick = {
                        localStorage.setItem("navValue", 4.toString())
                        localStorage.setItem("navigation", "planning")
                        localStorage.setItem("navigation_subpage", "expense")
                        props.parent.onOutput(HomeComponent.Output.NavigateToPlanning)
                    }
                }
            }

            // New transaction
            Button {
                sx {
                    backgroundColor = graphExpenseColor
                    marginTop = 10.px
                    marginBottom = 10.px
                    marginTop = 10.px
                }
                onClick = {
                    localStorage.setItem("navValue", 2.toString())
                    localStorage.setItem("navigation", "statistics")
                    props.parent.onEvent(HomeStore.Intent.ScanQrCode)
                }
                variant = ButtonVariant.contained
                +MainRes.string.dashboard_add_transaction
            }

            // Divider line
/*
            Divider {
                sx {
                    width = 100.pct
                    height = 2.px
                    backgroundColor = surfaceColor
                    marginBottom = 20.px
                    marginTop = 10.px
                    borderRadius = 50.px
                }
            }
*/

            /*// Transactions
            Box {
                sx {
                    cursor = Cursor.pointer
                    marginBottom = 10.px
                }
                onClick = {
                    localStorage.setItem("navValue", 1.toString())
                    localStorage.setItem("navigation", "transactions")
                    props.parent.onOutput(HomeComponent.Output.NavigateToTransactions)
                }
                Typography {
                    sx {
                        width = 60.pct
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.body1
                    +MainRes.string.transactions
                }
                state.dashboard?.monthlyTransactions?.map { transaction ->

                    val transactionType = transaction.type

                    Card {
                        sx {
                            marginLeft = 3.px
                            marginRight = 3.px
                            marginBottom = 10.px
                            marginTop = 4.px
                            //backgroundColor = surfaceColor
                            borderRadius = cardBorderRadius
                        }
                        elevation = 2

                        CardContent {

                            Box {
                                sx {
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceBetween
                                    width = 100.pct
                                }
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.row
                                        width = 100.pct
                                    }
                                    Box {
                                        sx {
                                            display = Display.flex
                                            flexDirection = FlexDirection.column
                                            //justifyContent = JustifyContent.spaceBetween
                                            //height = 100.pct
                                            marginLeft = 10.px
                                        }
                                        Typography {
                                            variant = TypographyVariant.subtitle2
                                            +(if (transactionType === "fs_receipt") transaction.getLocalTime()
                                                .toString().split(":").take(2)
                                                .joinToString(":", "") else MainRes.string.manual_transaction)
                                        }
                                        Typography {
                                            variant = TypographyVariant.body2
                                            sx {
                                                fontWeight = FontWeight.bold
                                            }
                                            +(if (transaction.type === "fs_receipt") transaction.organizationName else transaction.category?.replace(
                                                "/null",
                                                ""
                                            ))
                                        }
                                    }
                                }

                                Box {
                                    sx {
                                        width = 50.px
                                    }
                                    Typography {
                                        sx {
                                            width = 100.pct
                                            color = if (transaction.price!! >= 0) {graphIncomeColor} else {graphExpenseColor}
                                        }
                                        align = TypographyAlign.right
                                        variant = TypographyVariant.body2
                                        +"${transaction.price} €"
                                    }
                                }
                            }
                        }
                    }
                }
            }*/

            // Monthly expected income/expenses
            /*Box {
                sx {
                    cursor = Cursor.pointer
                }
                onClick = {
                    localStorage.setItem("navValue", 0.toString())
                    localStorage.setItem("navigation", "planning")
                    props.parent.onOutput(HomeComponent.Output.NavigateToPlanning)
                }
                Typography {
                    sx {
                        width = 60.pct
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.body1
                    +MainRes.string.dashboard_expected_income_expense
                }
                listOf(Pair("Income", 2000.0), Pair("Income from business", 0.0), Pair("Social benefits", 150.0),
                    Pair("Other", 35.90), Pair("Rent", -800.0), Pair("Electricity", -20.0), Pair("Water", -10.0),
                    Pair("Gas", -15.0), Pair("Heating", 0.0), Pair("Other living expenses", -41.90),
                    Pair("Telephone", -22.99), Pair("Tv", -12.0), Pair("Internet", -8.99),
                    Pair("Health/Medical supplies", -130.0), Pair("Food", -250.00), Pair("Transportation", -30.0) )
                    .map { pair ->
                    Card {
                        sx {
                            marginLeft = 3.px
                            marginRight = 3.px
                            marginBottom = 10.px
                            marginTop = 4.px
                            //backgroundColor = surfaceColor
                            borderRadius = cardBorderRadius
                        }
                        elevation = 2

                        CardContent {
                            Box {
                                sx {
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceBetween
                                    width = 100.pct
                                }
                                Typography {
                                    sx {
                                    }
                                    align = TypographyAlign.right
                                    variant = TypographyVariant.body2
                                    +pair.first
                                }
                                Typography {
                                    sx {
                                        color = if(pair.second >= 0) {graphIncomeColor} else {graphExpenseColor}
                                    }
                                    align = TypographyAlign.right
                                    variant = TypographyVariant.body2
                                    if(pair.second == 0.0) {
                                        +"-"
                                    } else {
                                        +"${pair.second} €"
                                    }
                                }
                            }
                        }
                    }
                }
            }*/
        }
    }
}

/**
 * Component styles
 * @property scrollable ClassName
 *    Scrollable style
 */
object ComponentStyles {
    var scrollable = emotion.css.ClassName {
        overflowY = Auto.auto
    }
}
package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.ProductItemModel
import kotlinx.serialization.Serializable

@Serializable
data class ProductItem(
    val id: Long?,
    val quantity: Double?,
    val product: Product,
    val discount: Product?
) {

    fun toModel(): ProductItemModel {
        val model = ProductItemModel(
            id,
            quantity,
            product.toModel(),
            discount?.toModel()
        )
        return model
    }

}
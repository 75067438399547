package com.erstedigital.overdokladapi.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Unit(
    @SerialName("cashRegisterCode") val cashRegisterCode : String?,
    @SerialName("buildingNumber") val buildingNumber : String?,
    @SerialName("country") val country : String?,
    @SerialName("municipality") val municipality : String?,
    @SerialName("postalCode") val postalCode : String?,
    @SerialName("propertyRegistrationNumber") val propertyRegistrationNumber : String?,
    @SerialName("streetName") val streetName : String?,
    @SerialName("name") val name : String?,
    @SerialName("unitType") val unitType : String?
)
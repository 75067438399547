package com.erstedigital.socialbank.ui.react.statistics

import com.erstedigital.socialbank.domain.models.StatisticsCustomRangeModel
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.statistics.components.*
import com.erstedigital.socialbank.ui.statistics.StatisticsComponent
import com.erstedigital.socialbank.ui.statistics.components.Mode
import com.erstedigital.socialbank.ui.statistics.store.StatisticsStore
import io.github.aakira.napier.Napier

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.datetime.LocalDate
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowForward
import mui.icons.material.EditCalendar
import mui.material.Box
import mui.material.CircularProgress
import mui.material.Grid
import mui.material.IconButton
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.aria.ariaColSpan
import react.useEffect
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.Visibility
import web.cssom.pct
import web.cssom.px


external interface StatisticsContentProps: Props {
    var component: StatisticsComponent
    var onChildClicked: (Boolean) -> Unit
    var handleShowDateRangePicker: () -> Unit
}

enum class PieChartType {
    NONE,
    CATEGORIES,
    ORGANIZATIONS,
    PRODUCTS,
    PRODUCTS_FILTERED
}

val StatisticsContent: FC<StatisticsContentProps> = FC { props ->

    val (state, setState) = useState(props.component.state.value)

    val (mode, setMode) = useState(Mode.MONTH)


    val (currStart, setCurrStart) = useState<LocalDate?>(null)
    val (currEnd, setCurrEnd) = useState<LocalDate?>(null)
    val (currSlice, setCurrSlice) = useState<StatisticsModel.DateSlice?>(null)
    val (customRange, setCustomRange) = useState<StatisticsCustomRangeModel?>(null)


    val (pagerState, setPagerState) = useState(0)

    val (showDatePicker, setShowDatePicker) = useState(false)
    val (piechartModal, setPiechartModal) = useState(PieChartType.NONE)
    val (datePickerUpdated, setDatePickerUpdated) = useState(false)

    useEffect {
        val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }

    useEffect(mode, currStart, currEnd, pagerState) {
        val currIndex = 0;

        if (!datePickerUpdated) {
            return@useEffect
        }

        if (mode == Mode.WEEK) {
            Napier.e("Weekly: ${currStart} - ${currStart?.toEpochDays()}")

            state.statistics?.weekly?.find { it.startDate.toEpochDays() == currStart?.toEpochDays() }?.let {
                Napier.e("Slice: $it" )
                setCurrSlice(it)
                val newIndex = state.statistics.weekly.indexOf(it)
                if (newIndex != pagerState) {
                    setPagerState(state.statistics.weekly.indexOf(it))
                }
            }
        } else if (mode == Mode.MONTH) {
            state.statistics?.monthly?.find { it.startDate.toEpochDays() == currStart?.toEpochDays() }?.let {
                setCurrSlice(it)
                Napier.e("Slice: $it" )
                Napier.e("Pager state: ${state.statistics.monthly.indexOf(it)}")
                val newIndex = state.statistics.monthly.indexOf(it)
                if (newIndex != pagerState) {
                    setPagerState(state.statistics.monthly.indexOf(it))
                }
            }
        }

        setDatePickerUpdated(false)
    }

    useEffect(state) {
        if (!state.loading && state.statistics != null && mode != Mode.CUSTOM) {

            var currIndex = 0
            if (mode == Mode.WEEK) {
                currIndex = state.statistics.weekly.size - 1
            } else {
                currIndex = state.statistics.monthly.size - 1
            }


            setCurrStart((if (mode == Mode.WEEK) state.statistics.weekly[currIndex] else state.statistics.monthly[currIndex]).startDate)
            setCurrEnd((if (mode == Mode.WEEK) state.statistics.weekly[currIndex] else state.statistics.monthly[currIndex]).endDate)
            setCurrSlice(if (mode == Mode.WEEK) state.statistics.weekly[currIndex] else state.statistics.monthly[currIndex])

            setPagerState(currIndex)
        } else if (!state.loading && state.customRangeStatistics != null && mode == Mode.CUSTOM) {
            setCustomRange(state.customRangeStatistics)

            setCurrStart(state.customRangeStatistics.startDate)
            setCurrEnd(state.customRangeStatistics.endDate)
        }
    }

    useEffect(pagerState) {
        if (!state.loading && state.statistics != null && !datePickerUpdated) {
            setCurrStart((if (mode == Mode.WEEK) state.statistics.weekly[pagerState] else state.statistics.monthly[pagerState]).startDate)
            setCurrEnd((if (mode == Mode.WEEK) state.statistics.weekly[pagerState] else state.statistics.monthly[pagerState]).endDate)
            setCurrSlice(if (mode == Mode.WEEK) state.statistics.weekly[pagerState] else state.statistics.monthly[pagerState])
        }
    }

    fun getPieChartStats(): List<Triple<String, Float, Float>> {
        return if (piechartModal == PieChartType.CATEGORIES) {
            currSlice?.categories?.map {
                Triple(it.name!!, it.amount.toFloat(), it.percentage.toFloat())
            } ?: listOf()
        } else if (piechartModal == PieChartType.ORGANIZATIONS) {
            currSlice?.organizations?.map {
                Triple(it.name!!, it.amount.toFloat(), it.percentage.toFloat())
            } ?: listOf()
        } else if (piechartModal == PieChartType.PRODUCTS) {
            currSlice?.productCategories?.map {
                Triple(it.name!!, it.amount.toFloat(), it.percentage.toFloat())
            } ?: listOf()
        } else if (piechartModal == PieChartType.PRODUCTS_FILTERED) {
            currSlice?.productCategoriesFiltered?.map {
                Triple(it.name!!, it.amount.toFloat(), it.percentage.toFloat())
            } ?: listOf()
        } else {
            listOf()
        }
    }



    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                width = 100.pct
                paddingTop = 10.px
                paddingBottom = 90.px
                position = Position.relative
            }

            PieChartModal {
                statistics = getPieChartStats()
                open = (piechartModal != PieChartType.NONE)
                onConfirm = {
                    setPiechartModal(PieChartType.NONE)
                }
                onDismiss = {
                    setPiechartModal(PieChartType.NONE)
                }
            }

            DatePickerModal {
                open = showDatePicker
                onDismiss = {
                    setShowDatePicker(false)
                }
                statisticsModel = state.statistics
                onConfirm = { mode, start, end ->
                    if (mode == Mode.CUSTOM) {
                        setShowDatePicker(false)
                        props.component.onEvent(StatisticsStore.Intent.FetchCustomRangeStatistics(start, end))
                        setMode(mode)
                    } else {
                        setShowDatePicker(false)
                        setCurrStart(start)
                        setCurrEnd(end)
                        setMode(mode)
                        setDatePickerUpdated(true)
                    }
                }
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.spaceBetween
                    width = 100.pct
                }

                IconButton {
                    sx {
                        visibility = if (mode == Mode.WEEK) Visibility.visible else Visibility.hidden
                    }
                    onClick = {
                        if (pagerState > 0) {
                            setPagerState(pagerState - 1)
                        }
                    }
                    ArrowBack()
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                    }
                    Typography {
                        variant = TypographyVariant.h6
                        +("${currStart.toString().split("-").reversed().joinToString(".")} - ${currEnd.toString().split("-").reversed().joinToString(".")}")
                    }
                    IconButton {
                        onClick = {
                            setShowDatePicker(true)
                        }
                        EditCalendar()
                    }
                }

                IconButton {
                    sx {
                        visibility = if (mode == Mode.WEEK) Visibility.visible else Visibility.hidden
                    }
                    onClick = {
                        if (pagerState < (if (mode == Mode.WEEK) state.statistics!!.weekly.size else state.statistics!!.monthly.size) - 1) {
                            setPagerState(pagerState + 1)
                        }
                    }
                    ArrowForward()
                }

            }
            if (currSlice != null) {
                Grid {
                    container = true
                    spacing = responsive(1)


                    sx {
                        paddingLeft = 10.px
                        paddingRight = 10.px
                        justifyContent = JustifyContent.center
                    }
                    Grid {
                        item = true
                        BarChartCard {
                            slice = if (mode != Mode.CUSTOM || customRange == null) currSlice else StatisticsModel.DateSlice(
                                categories = customRange.categories,
                                chart = customRange.chart!!,
                                organizations = customRange.organizations,
                                startDate = customRange.startDate!!,
                                endDate = customRange.endDate!!,
                                type = StatisticsModel.DateSlice.Type.WEEK,
                                number = 0
                            )
                        }
                    }
                    Grid {
                        item = true
                        BalanceChartCard {
                            slice = if (mode != Mode.CUSTOM || customRange == null) currSlice else StatisticsModel.DateSlice(
                                categories = customRange.categories,
                                chart = customRange.chart!!,
                                organizations = customRange.organizations,
                                startDate = customRange.startDate!!,
                                endDate = customRange.endDate!!,
                                type = StatisticsModel.DateSlice.Type.WEEK,
                                number = 0
                            )
                        }
                    }
                    Grid {
                        item = true
                        BudgetCard {
                            slice = if (mode != Mode.CUSTOM || customRange == null) currSlice else StatisticsModel.DateSlice(
                                categories = customRange.categories,
                                chart = customRange.chart!!,
                                organizations = customRange.organizations,
                                startDate = customRange.startDate!!,
                                endDate = customRange.endDate!!,
                                type = StatisticsModel.DateSlice.Type.WEEK,
                                number = 0
                            )
                            onPieChartClicked = {
                                setPiechartModal(it)
                            }

                        }
                    }
                    if (mode != Mode.CUSTOM || customRange == null) {
                        Grid {
                            item = true
                            CategoriesCardFiltered {
                                slice = if (mode != Mode.CUSTOM || customRange == null) currSlice else StatisticsModel.DateSlice(
                                    categories = customRange.categories,
                                    //categoriesFiltered = customRange.categories,
                                    chart = customRange.chart!!,
                                    organizations = customRange.organizations,
                                    startDate = customRange.startDate!!,
                                    endDate = customRange.endDate!!,
                                    type = StatisticsModel.DateSlice.Type.WEEK,
                                    number = 0,
                                )
                                onPieChartClicked = {
                                    setPiechartModal(PieChartType.PRODUCTS_FILTERED)
                                }
                            }
                        }
                    }
                    if (mode != Mode.CUSTOM || customRange == null) {
                        Grid {
                            item = true
                            CategoriesCard {
                                slice = if (mode != Mode.CUSTOM || customRange == null) currSlice else StatisticsModel.DateSlice(
                                    categories = customRange.categories,
                                    chart = customRange.chart!!,
                                    organizations = customRange.organizations,
                                    startDate = customRange.startDate!!,
                                    endDate = customRange.endDate!!,
                                    type = StatisticsModel.DateSlice.Type.WEEK,
                                    number = 0,

                                )
                                onPieChartClicked = {
                                    setPiechartModal(PieChartType.PRODUCTS)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
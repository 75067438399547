package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class SplitModel(
    val id: Long,
    val createdDate: LocalDateTime?,
    val productOwnership: List<ProductOwnershipModel>,
    val userPayments: List<UserPayment>
) {

    @Serializable
    data class UserPayment(
        val userModel: UserModel,
        val amount: Double
    )

}
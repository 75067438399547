package com.erstedigital.socialbank.domain.usecase.user

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.ChangePasswordRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateProfileRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.GetProfileResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class UpdateProfileUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val userRemoteDataSource: UserRemoteDataSource
) {

    suspend fun updateProfile(userProfileRequest: UpdateProfileRequest): ApiResponse<GetProfileResponse, ErrorResponse> {

        return userRemoteDataSource.updateProfile(userProfileRequest)
    }
    suspend fun changePassword(changePasswordRequest: ChangePasswordRequest): ApiResponse<Any, ErrorResponse> {

        return userRemoteDataSource.changePassword(changePasswordRequest)
    }
}
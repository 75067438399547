package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.domain.models.CompanyModel
import kotlinx.serialization.Serializable

@Serializable
data class CompanyListResponse(
    val id: Long,
    val name: String,
    val ico: String?,
    val postalCode: String?,
    val municipality: String?,
    val propertyRegistrationNumber: String?,
    val dic: String?,
    val buildingNumber: String?,
    val icDph: String?,
    val streetName: String?,
    val country: String?
) {
    fun toModel(): CompanyModel {
        val model = CompanyModel(
            id,
            name,
            ico
        )
        return model
    }
}
package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.domain.models.TransactionModel
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px

external interface InfoRowProps: Props {
    var label: String
    var value: String
}

val InfoRow: FC<InfoRowProps> = FC { props ->

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
            paddingTop = 4.px
            paddingBottom = 4.px
        }
        Typography {
            variant = TypographyVariant.body1
            +props.label
        }
        Typography {
            variant = TypographyVariant.body2
            +props.value
        }
    }
}
package com.erstedigital.socialbank.ui.transactions.detail.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.store.create
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.UpdateFsReceiptCategoryRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.domain.usecase.transactions.GetTransactionUsecase
import com.erstedigital.socialbank.domain.usecase.transactions.UpdateFsReceiptCategoryUsecase
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class DetailStoreFactory(
    private val storeFactory: StoreFactory,
    private val transactionId: Long
): KoinComponent {

    private val getTransactionUsecase by inject<GetTransactionUsecase>()
    private val updateFsReceiptCategoryUsecase by inject<UpdateFsReceiptCategoryUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): DetailStore = object : DetailStore, Store<DetailStore.Intent, DetailStore.State, Nothing> by storeFactory.create(
        name = "DetailStore",
        initialState = DetailStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchTransactionDetail)
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class TransactionData(val transaction: TransactionModel): Msg()
    }

    private sealed class Action {
        object FetchTransactionDetail : Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<DetailStore.Intent, Action, DetailStore.State, Msg, Nothing>() {

        override fun executeAction(action: Action, getState: () -> DetailStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchTransactionDetail -> {
                        dispatch(Msg.Loading)
                        when (val result = getTransactionUsecase.getTransaction(transactionId)) {
                            is ApiResponse.Success -> {
                                dispatch(Msg.TransactionData(transaction = result.body.toModel()))
                            }
                            is ApiResponse.Error.HttpError -> {
                                dispatch(
                                    Msg.Error(
                                        result.errorMessage ?: result.errorBody ?: "Unknown error"
                                    )
                                )
                            }
                            is ApiResponse.Error.GenericError -> {
                                dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                            }
                            is ApiResponse.Error.SerializationError -> {
                                dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                            }

                            else -> {}
                        }
                    }
                }
            }
        }




        override fun executeIntent(intent: DetailStore.Intent, getState: () -> DetailStore.State) {

            val state = getState()


            scope.launch {
                when (intent) {
                    is DetailStore.Intent.UpdateCategory -> {
                        scope.launch {
                            updateTransactionCategory(state.transaction?.id!!, "${intent.category.primary}/${intent.category.secondary}")
                        }
                    } else -> {}
                }
            }

        }

        suspend fun updateTransactionCategory(transactionId: Long, category: String) {
            dispatch(Msg.Loading)
            when (val result = updateFsReceiptCategoryUsecase(
                UpdateFsReceiptCategoryRequest(
                    id = transactionId,
                    category = category
                )
            )) {
                is ApiResponse.Success -> {
                    dispatch(Msg.TransactionData(transaction = result.body.toModel()))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(
                        Msg.Error(
                            result.errorMessage ?: result.errorBody ?: "Unknown error"
                        )
                    )
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }

                else -> {}
            }
        }


    }

    private object ReducerImpl: Reducer<DetailStore.State, Msg> {
        override fun DetailStore.State.reduce(msg: Msg): DetailStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.TransactionData -> copy(transaction = msg.transaction, error = null, loading = false)
        }
    }
}
package com.erstedigital.socialbank.ui.react.transactions

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.GroupedTransactionsModel
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.react.transactions.components.ProductItem
import com.erstedigital.socialbank.ui.transactions.products.ProductsComponent
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.icons.material.ArrowBack
import mui.material.Box
import mui.material.CircularProgress
import mui.material.IconButton
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Position
import web.cssom.pct
import web.cssom.px

external interface ProductsContentProps: Props {
    var products: List<ProductItemModel>?
    var onBack: () -> Unit
    var onProductClicked: (ProductItemModel) -> Unit
}

val ProductsContent: FC<ProductsContentProps> = FC { props ->



    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            paddingBottom = 90.px
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
            }

            IconButton {
                onClick = { props.onBack() }
                ArrowBack()
            }
            Typography {
                sx {
                    marginBottom = 10.px
                    width = 100.pct
                }
                variant = TypographyVariant.h4
                +MainRes.string.products
            }
        }

        props
            .products
            ?.sortedByDescending { it.getTotalPrice() }
            ?.map { p ->
            ProductItem {
                product = p
                onProductClick = { props.onProductClicked(p) }
            }
        }

    }


}
package com.erstedigital.socialbank.ui.login.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.*
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.GoogleSignIn
import com.erstedigital.socialbank.domain.usecase.auth.LoginAnonymousUsecase
import com.erstedigital.socialbank.domain.usecase.auth.LoginIdpUsecase
import com.erstedigital.socialbank.ui.login.store.LoginStore.*
import com.erstedigital.socialbank.domain.usecase.auth.LoginUsecase
import com.erstedigital.socialbank.domain.usecase.auth.RegisterUsecase
import com.erstedigital.socialbank.ui.verify_code.store.VerifyCodeStore
import com.erstedigital.socialbank.ui.verify_code.store.VerifyCodeStoreFactory
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.FirebaseAuth
import dev.gitlive.firebase.auth.auth
import org.koin.core.component.inject

import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import kotlin.js.JsName
import kotlin.math.log

class LoginStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    @JsName("loginUseCase")
    private val loginUsecase by inject<LoginUsecase>()
    @JsName("registerUseCase")
    private val registerUsecase by inject<RegisterUsecase>()
    private val loginAnonymousUsecase by inject<LoginAnonymousUsecase>()
    private val loginIdpUsecase by inject<LoginIdpUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): LoginStore = object : LoginStore, Store<Intent, State, Label> by storeFactory.create(
        name = "LoginStore",
        initialState = State(),
        bootstrapper = coroutineBootstrapper {},
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data class LoginChanged(val login: String, val valid: Boolean) : Msg()
        data class PasswordChanged(val password: String, val valid: Boolean) : Msg()
        data object Loading : Msg()
        data object UsernameAccepted : Msg()

        data class Error(val message: String): Msg()
        data object ActionSuccess: Msg()

    }

    private inner class ExecutorImpl: CoroutineExecutor<Intent, Unit, State, Msg, Label>() {

        override fun executeAction(action: Unit, getState: () -> State) {
            super.executeAction(action, getState)
        }

        override fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.Login -> login(intent.username, intent.password)
                is Intent.Register -> register(intent.username, intent.password)
                is Intent.Reset -> reset(intent.username)
                is Intent.IdpLogin -> loginIdp()
                is Intent.ChangeUsername -> changeUsername(intent.username)
                is Intent.ChangePassword -> changePassword(intent.password)
                is Intent.AnonymousLogin -> loginAnonymous()
                else -> {}
            }
        }

        private fun changeUsername(username: String) {
            val valid = username.isNotEmpty()
            dispatch(Msg.LoginChanged(username, valid))
        }

        private fun changePassword(password: String) {
            val valid = password.isNotEmpty()
            dispatch(Msg.PasswordChanged(password, valid))
        }

        private fun loginIdp() {
            dispatch(Msg.Loading)
            scope.launch {
                loginIdpUsecase.authorize(
                    scope = scope,
                    successCallback = {
                        publish(Label.AuthorizationSuccess)
                    },
                    errorCallback = {
                        publish(Label.AuthorizationFailure)
                    }
                )
            }
        }

        private fun login(username: String, password: String) {
            dispatch(Msg.Loading)
            scope.launch {
/*                val res = loginUsecase.signIn(LoginRequest(login = username, password = password))
                when (res) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.UsernameAccepted)
                        // log to web console
                        publish(LoginStore.Label.AuthorizationSuccess)
                    }
                    is ApiResponse.Error.HttpError -> {
                        dispatch(Msg.Error(res.errorMessage ?: res.errorBody ?: "Unknown error"))
                    }
                    is ApiResponse.Error.GenericError -> {
                        dispatch(Msg.Error(res.errorMessage ?: "Unknown error"))
                    }
                    is ApiResponse.Error.SerializationError -> {
                        dispatch(Msg.Error(res.errorMessage ?: "Unknown error"))
                    }

                    else -> {}
                }*/
                scope.launch {
                    val res = Firebase.auth.signInAnonymously()
                    when (val result = loginUsecase.logIn(
                        LoginRequest(
                        password = password,
                        fcmToken = res.user?.getIdToken(false),
                        isSocialBank = true,
                        login = username
                    )
                    )) {
                        is ApiResponse.Success -> {
                            dispatch(Msg.UsernameAccepted)
                            dispatch(Msg.ActionSuccess)
                            publish(Label.AuthorizationSuccess)
                        }
                        is ApiResponse.Error -> {
                            dispatch(Msg.Error(result.toString()))
                            publish(Label.AuthorizationFailure)
                        }
                    }
                }
            }
        }

        private fun register(username: String, password: String) {
            dispatch(Msg.Loading)
            scope.launch {
                scope.launch {
                    //val res = Firebase.auth.signInAnonymously()
                    when (val result = loginUsecase.register(
                        RegisterRequest(
                            email =  username,
                            password = password
                        )
                    )) {
                        true -> {
                            dispatch(Msg.ActionSuccess)
                            //publish(Label.AuthorizationSuccess)
                        }
                        false -> {
                            dispatch(Msg.Error(result.toString()))
                            //publish(Label.AuthorizationFailure)
                        }
                    }
                }
            }
        }

        private fun reset(username: String) {
            dispatch(Msg.Loading)
            scope.launch {
                scope.launch {
                    //val res = Firebase.auth.signInAnonymously()
                    when (val result = loginUsecase.reset(
                        ChangePasswordRequest(
                            login =  username,
                            password = "",
                            newPassword = ""
                        )
                    )) {
                        true -> {
                            dispatch(Msg.ActionSuccess)
                            //publish(Label.AuthorizationSuccess)
                        }
                        false -> {
                            dispatch(Msg.Error(result.toString()))
                            //publish(Label.AuthorizationFailure)
                        }
                    }
                }
            }
        }

        private fun loginAnonymous() {
            dispatch(Msg.Loading)
            scope.launch {
                try {
                    val res = Firebase.auth.signInAnonymously()
                    when (val result = loginAnonymousUsecase.signIn(LoginAnonymousRequest(res.user?.getIdToken(false), true),)) {
                        is ApiResponse.Success -> dispatch(Msg.UsernameAccepted)
                        is ApiResponse.Error.HttpError -> {
                            dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                        }
                        is ApiResponse.Error.GenericError -> {
                            dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                        }
                        is ApiResponse.Error.SerializationError -> {
                            dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                        }

                        else -> {}
                    }
                } catch (e: Exception) {
                    dispatch(Msg.Error(e.message ?: "Unknown error"))
                }
            }
        }
    }

    private object ReducerImpl: Reducer<State, Msg> {
        override fun State.reduce(msg: Msg): State = when (msg) {
            is Msg.LoginChanged -> copy(login = msg.login, valid = msg.valid)
            is Msg.PasswordChanged -> copy(password = msg.password)
            is Msg.Loading -> copy(loading = true)
            is Msg.UsernameAccepted -> copy(loading = false, loggedIn = true)
            is Msg.Error -> copy(loading = false, error = msg.message, actionSuccess = false)
            is Msg.ActionSuccess -> copy(loading = false, actionSuccess = true)
            else -> copy()
        }
    }
}
package com.erstedigital.socialbank.ui.react.statistics.components

import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.utils.ProductCategoryMapper
import io.github.aakira.napier.Napier
import kotlinx.datetime.LocalDate
import mui.material.Box
import mui.material.IconButton
import mui.material.Modal
import mui.icons.material.Close
import mui.system.sx
import react.FC
import react.Props
import react.useMemo
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import web.cssom.translate
import web.cssom.vh
import kotlin.math.roundToInt
import kotlin.random.Random

external interface PieChartModalProps: Props {
    var onConfirm: () -> Unit
    var onDismiss: () -> Unit
    var statistics: List<Triple<String, Float, Float>>
    var open: Boolean
}

@OptIn(ExperimentalStdlibApi::class)
val PieChartModal: FC<PieChartModalProps> = FC { props ->

    val random = Random(1)

    val colors = useMemo(props.statistics) {
        props.statistics.associate { v ->
            v.first to "#${random.nextInt(0, 255*255*255).toHexString().replace("0", "").padStart(6, '0')}"
        }
    }

    Modal {
        open = props.open
        onClose = { _, _ ->
            props.onDismiss()
        }

        Box {
            sx {
                width = 500.px
                maxWidth = 100.pct
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                backgroundColor = Color("#fff")
                borderRadius = 10.px
                padding = 15.px
            }

            Box {
                sx {
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.end
                }

                IconButton {
                    onClick = {
                        props.onDismiss()
                    }
                    Close()
                }
            }

            Box {

                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                }

                Box {
                    sx {
                        width = 420.px
                        height = 420.px
                    }
                    PieChart {
                        data = props.statistics.sortedByDescending { it.second }.map {
                            PieChartData(
                                name = ProductCategoryMapper.map(it.first),
                                value = it.third.toDouble(),
                                amount = it.second.toDouble(),
                                color = colors[it.first] ?: "#ff0000"
                            )
                        }
                        containerSize = 400.0
                        animDuration = 2
                        chartBarWidth = 30
                        highlightStrokeWidth = 3
                        onSliceClick = { data ->
                            console.log("Clicked on ${data.name}")
                        }
                        radiusOuter = 50
                    }
                }

                Box {
                    sx {
                        display = Display.block
                        width = 100.pct
                        maxHeight = 300.px
                        height = 300.px
                        overflow = Overflow.scroll
                        paddingLeft = 5.px
                        paddingRight = 5.px
                        //paddingTop = 50.px
                    }
                    props.statistics.sortedByDescending { it.second }.forEach {
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                alignItems = AlignItems.center
                                justifyContent = JustifyContent.spaceBetween
                                backgroundColor = Color((colors[it.first] + "66") ?: "#ff0000")
                                borderRadius = 5.px
                                marginBottom = 5.px
                                paddingLeft = 4.px
                                paddingRight = 4.px
                            }
                            Box {
                                +ProductCategoryMapper.map(it.first)
                            }
                            Box {
                                sx {
                                    display = Display.flex
                                    flexDirection = FlexDirection.column
                                    alignItems = AlignItems.end
                                }

                                Box {
                                    +"${(it.second * 100).roundToInt().div(100.0)} €"
                                }

                                Box {
                                    +"${(it.third * 100).roundToInt().div(100.0)} %"
                                }
                            }
                        }
                    }
                }
            }
        }
    }



}
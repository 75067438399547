package com.erstedigital.socialbank

import com.erstedigital.socialbank.images.MainResImages
import com.erstedigital.socialbank.strings.MainResStrings

public object MainRes {
  public val string: MainResStrings = MainResStrings

  public val image: MainResImages = MainResImages
}

package com.erstedigital.socialbank.ui.verify_code

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.verify_code.store.VerifyCodeStore
import com.erstedigital.socialbank.ui.verify_code.store.VerifyCodeStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class VerifyCodeComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    private val output: (Output) -> Unit,
): ComponentContext by componentContext {

    private val verifyCodeStore = instanceKeeper.getStore {
        VerifyCodeStoreFactory(storeFactory).create()
    }

    val label: Flow<VerifyCodeStore.Label> = verifyCodeStore.labels

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<VerifyCodeStore.State> = verifyCodeStore.stateFlow

    fun onEvent(event: VerifyCodeStore.Intent) {
        verifyCodeStore.accept(event)
    }

    fun onOutput(output: Output) {
        output(output)
    }

    sealed class Output {
        object NavigateBack : Output()
        object NavigateToHome : Output()
    }
}
package com.erstedigital.socialbank.ui.react.utils

import web.cssom.*

val headerDisplay = Display.flex
val headerPosition = Position.absolute
val headerPadding = 0.px
val headerTop = 0.px
val headerBottom = 0.px
val headerLeft = 0.px
val headerRight = 0.px
val headerBackgroundColor = primaryColor
val headerHeight = 8.pct
val headerJustifyContent = JustifyContent.flexEnd
val headerAlignItems = AlignItems.center

val headerImgHeight = 70.pct
val headerImgObjectFit = ObjectFit.contain
val headerImgMarginRight = 5.pct
//const val headerImgSrc = "images/eh_logo_screen_white.svg"
const val headerImgSrc = "images/ed_soc_bank_logo_v2.png"

val firstBoxMargin = 15.pct
package com.erstedigital.socialbank.images

import io.github.skeptick.libres.images.Image

public actual object MainResImages {
  public actual val food: Image
    get() = "images/food.png"

  public actual val aliments: Image
    get() = "images/aliments.png"

  public actual val kaufland: Image
    get() = "images/kaufland.svg"

  public actual val unpublished: Image
    get() = "images/unpublished.svg"

  public actual val planning: Image
    get() = "images/planning.png"

  public actual val auto_moto: Image
    get() = "images/auto_moto.png"

  public actual val editicon: Image
    get() = "images/editicon.png"

  public actual val eh_logo_screen_white: Image
    get() = "images/eh_logo_screen_white.svg"

  public actual val withdrawal: Image
    get() = "images/withdrawal.png"

  public actual val vacation: Image
    get() = "images/vacation.png"

  public actual val special_incomes: Image
    get() = "images/special_incomes.png"

  public actual val tax: Image
    get() = "images/tax.png"

  public actual val incomes: Image
    get() = "images/incomes.png"

  public actual val ed_ideas_logo: Image
    get() = "images/ed_ideas_logo.png"

  public actual val sent: Image
    get() = "images/sent.svg"

  public actual val incomes_old: Image
    get() = "images/incomes_old.svg"

  public actual val food_old: Image
    get() = "images/food_old.svg"

  public actual val transit: Image
    get() = "images/transit.png"

  public actual val dont_send: Image
    get() = "images/dont_send.svg"

  public actual val electronics: Image
    get() = "images/electronics.png"

  public actual val fees: Image
    get() = "images/fees.png"

  public actual val clothes: Image
    get() = "images/clothes.png"

  public actual val other_expenses: Image
    get() = "images/other_expenses.png"

  public actual val email: Image
    get() = "images/email.svg"

  public actual val uncategorized_incomes: Image
    get() = "images/uncategorized_incomes.png"

  public actual val online: Image
    get() = "images/online.png"

  public actual val google: Image
    get() = "images/google.svg"

  public actual val ed_soc_bank_logo_v2: Image
    get() = "images/ed_soc_bank_logo_v2.png"

  public actual val living_energies: Image
    get() = "images/living_energies.png"

  public actual val uncategorized_expenses: Image
    get() = "images/uncategorized_expenses.png"

  public actual val feature_image: Image
    get() = "images/feature_image.png"

  public actual val savings: Image
    get() = "images/savings.png"

  public actual val ed_soc_bank_logo: Image
    get() = "images/ed_soc_bank_logo.png"

  public actual val lactose_free: Image
    get() = "images/lactose_free.png"

  public actual val education: Image
    get() = "images/education.png"

  public actual val send: Image
    get() = "images/send.svg"

  public actual val fun_recreation: Image
    get() = "images/fun_recreation.png"

  public actual val health: Image
    get() = "images/health.png"
}

package com.erstedigital.socialbank.di

import com.erstedigital.socialbank.di.external.Constants
import com.erstedigital.socialbank.di.external.initializeApp
import com.erstedigital.socialbank.di.external.messaging
import kotlinx.coroutines.await

actual suspend fun getFcmToken(): String {
    val app = initializeApp(Constants)
    val messaging = app.messaging()
    return messaging.getToken().await()
}
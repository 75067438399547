package com.erstedigital.socialbank.ui.documents.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.usecase.documents.CreateFolderUsecase
import com.erstedigital.socialbank.domain.usecase.documents.DeleteDocumentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.DownloadAttachmentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.GetDocumentUsecase
import com.erstedigital.socialbank.domain.usecase.documents.GetFilesUseCase
import com.erstedigital.socialbank.domain.usecase.documents.UpdateDocumentUsecase
import com.erstedigital.socialbank.ui.documents.details.store.DetailsStoreFactory
import com.erstedigital.socialbank.ui.profile.store.ProfileStoreFactory
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class DmsStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val getDocumentUsecase by inject<GetDocumentUsecase>()
    private val getFilesUseCase by inject<GetFilesUseCase>()
    private val createFolderUseCase by inject<CreateFolderUsecase>()
    private val updateDocumentUseCase by inject<UpdateDocumentUsecase>()
    private val deleteDocumentUsecase by inject<DeleteDocumentUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): DmsStore = object : DmsStore, Store<DmsStore.Intent, DmsStore.State, DmsStore.Label> by storeFactory.create(
        name = "DmsStore",
        initialState = DmsStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchDocuments)
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class Documents(val document: Document): Msg()
        data class UpdateDocumentData(val document: Document): Msg()
        data class DeletedDocument(val documentId: Long): Msg()
        data class AddDocumentToCurrent(val document: Document, val currentRoot: Document): Msg()
        data class ChangeCurrentDirectory(val directory: Document, val editable: Boolean): Msg()
    }

    private sealed class Action {
        data object FetchDocuments: Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<DmsStore.Intent, Action, DmsStore.State, Msg, DmsStore.Label>() {

        override fun executeAction(action: Action, getState: () -> DmsStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchDocuments -> fetchDocuments()
                }
            }
        }


        override fun executeIntent(intent: DmsStore.Intent, getState: () -> DmsStore.State) {
            when (intent) {
                is DmsStore.Intent.NavigateToDocument -> {
                    val rootDocument = getState().document
                    val path = intent.document.findPathDocuments(rootDocument!!, intent.document.id!!)
                    var editable = false

                    if (path != null) {
                        var sharedFound = false
                        for (d in path) {
                            if (d.name == "shared") {
                                sharedFound = true
                                break
                            }
                        }

                        editable = !sharedFound
                    }

                    dispatch(Msg.ChangeCurrentDirectory(intent.document, editable))
                }
                is DmsStore.Intent.CreateFolder -> {

                    scope.launch {
                        createFolder(
                            intent.folderName,
                            getState().currentDirectory?.id ?: 0,
                            getState().currentDirectory?.isVisibleAdvisor ?: false,
                            getState().document!!,
                            getState().currentDirectory!!
                        )
                    }
                }
                is DmsStore.Intent.NavigateToParent -> {
                    val currentDirectory = getState().currentDirectory
                    if (currentDirectory != null) {
                        val parentName = currentDirectory.name

                        if (parentName != "/") {
                            val parent = findParentFromChildren(getState().document!!, Document(id = currentDirectory.id))
                            if (parent != null) {
                                if (currentDirectory.name == "shared") {
                                    dispatch(Msg.ChangeCurrentDirectory(parent, true))
                                    return
                                } else {
                                    dispatch(Msg.ChangeCurrentDirectory(parent, getState().isCurrentDirectoryEditable))
                                }
                            }
                        }

                    }
                }
                is DmsStore.Intent.AddDocumentToCurrent -> {
                    val currentDoc = getState().currentDirectory
                    val currentRoot = getState().document!!

                    val curRootDir = findInRoot(currentRoot, currentDoc!!)

                    // if already contains child with id, only replace
                    for (i in 0 until curRootDir!!.children.size) {
                        if (curRootDir.children[i].id == intent.document.id) {
                            val newChildren = curRootDir.children.toMutableList()
                            newChildren[i] = intent.document
                            curRootDir.children = newChildren
                            dispatch(Msg.AddDocumentToCurrent(curRootDir, currentRoot))
                            return
                        }
                    }

                    if (curRootDir != null) {
                        val newChildren = curRootDir.children.toMutableList()
                        newChildren.add(intent.document)
                        curRootDir.children = newChildren
                    }


                    dispatch(Msg.AddDocumentToCurrent(currentDoc, currentRoot))
                }
                is DmsStore.Intent.RenameFolder -> {
                    scope.launch {
                        updateDocument(
                            intent.folder
                        )
                    }
                }
                is DmsStore.Intent.Delete -> {
                    scope.launch {
                        deleteDocument(intent.id)
                    }
                }
                else -> {}
            }
        }

        private suspend fun deleteDocument(
            documentId: Long
        ) {
            dispatch(Msg.Loading)
            val result = deleteDocumentUsecase(documentId)
            when (result) {
                is ApiResponse.Success -> {
                    dispatch(Msg.DeletedDocument(documentId = documentId))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(message = result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error("Unknown error"))
                }
            }
        }

        private suspend fun updateDocument(
            document: Document
        ) {
            dispatch(Msg.Loading)
            val result = updateDocumentUseCase(
                document.id!!,
                document.name!!,
                document.isVisibleAdvisor!!,
                null,
                ""
            )
            when (result) {
                is ApiResponse.Success -> {
                    dispatch(Msg.UpdateDocumentData(document = result.body))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(message = result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error("Unknown error"))
                }
            }
        }

        private fun findParentFromChildren(root: Document, document: Document): Document? {
            for (d in root.children) {
                if (d.id == document.id) {
                    return root
                }
            }

            for (d in root.children) {
                val found = findParentFromChildren(d, document)
                if (found != null) {
                    return found
                }
            }

            return null
        }

        private fun findInRoot(root: Document, document: Document): Document? {
            if (root.id == document.id) {
                return root
            }

            for (d in root.children) {
                val found = findInRoot(d, document)
                if (found != null) {
                    return found
                }
            }

            return null
        }

        private suspend fun createFolder(
            folderName: String,
            parentId: Long,
            isVisibleAdvisor: Boolean,
            currentRoot: Document,
            currDocument: Document
        ) {
            dispatch(Msg.Loading)
            val result = createFolderUseCase(folderName, parentId, isVisibleAdvisor)
            when (result) {
                is ApiResponse.Success -> {

                    val curRootDir = findInRoot(currentRoot, currDocument)
                    if (curRootDir != null) {
                        val newChildren = curRootDir.children.toMutableList()
                        newChildren.add(result.body)
                        curRootDir.children = newChildren
                    }

                    dispatch(Msg.AddDocumentToCurrent(result.body, currentRoot))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(message = result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error(message = "Unknown error"))
                }
            }
        }

        private suspend fun fetchDocuments() {
            dispatch(Msg.Loading)
            val result = getFilesUseCase()
            when (result) {
                is ApiResponse.Success -> {
                    dispatch(Msg.Documents(result.body))
                }
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(message = result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(message = result.message ?: "Unknown error"))
                }
                else -> {
                    dispatch(Msg.Error(message = "Unknown error"))
                }
            }
        }
    }

    private object ReducerImpl: Reducer<DmsStore.State, Msg> {

        fun findParentInRoot(root: Document, document: Document): Document? {
            for (d in root.children) {
                if (d.id == document.id) {
                    return root
                }
            }

            for (d in root.children) {
                val found = findParentInRoot(d, document)
                if (found != null) {
                    return found
                }
            }

            return null
        }

        override fun DmsStore.State.reduce(msg: Msg): DmsStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.AddDocumentToCurrent -> copy(loading = false, currentDirectory = msg.document, document = msg.currentRoot)
            is Msg.Documents -> copy(loading = false, document = msg.document, currentDirectory = msg.document, isCurrentDirectoryEditable = true)
            is Msg.ChangeCurrentDirectory -> copy(currentDirectory = msg.directory, isCurrentDirectoryEditable = msg.editable)
            is Msg.UpdateDocumentData -> {
                val parent = document
                parent?.children?.forEachIndexed { index, document ->
                    if (document.id == msg.document.id) {
                        val newChildren = parent.children.toMutableList()
                        newChildren[index] = msg.document
                        parent.children = newChildren
                    }
                }

                val rootParent = findParentInRoot(document!!, parent!!)
                if (rootParent != null) {
                    val newChildren = rootParent.children.toMutableList()
                    newChildren.forEachIndexed { index, document ->
                        if (document.id == msg.document.id) {
                            newChildren[index] = msg.document
                        }
                    }
                    rootParent.children = newChildren
                }

                copy(currentDirectory = parent, document = document, loading = false)
            }
            is Msg.DeletedDocument -> {
                val parent = document
                parent?.children?.forEachIndexed { index, document ->
                    if (document.id == msg.documentId) {
                        val newChildren = parent.children.toMutableList()
                        newChildren.removeAt(index)
                        parent.children = newChildren
                    }
                }

                val rootParent = findParentInRoot(document!!, parent!!)
                if (rootParent != null) {
                    val newChildren = rootParent.children.toMutableList()
                    newChildren.forEachIndexed { index, document ->
                        if (document.id == msg.documentId) {
                            newChildren.removeAt(index)
                        }
                    }
                    rootParent.children = newChildren
                }

                copy(currentDirectory = parent, document = document, loading = false)
            }
            else -> copy()
        }
    }
}
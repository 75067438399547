package com.erstedigital.socialbank.domain.usecase.documents

import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse


class CreateFolderUsecase(
    private val dmsRemoteDataSource: DmsRemoteDataSource
) {

    suspend operator fun invoke(
        folderName: String,
        parentId: Long,
        isVisibleAdvisor: Boolean,
    ): ApiResponse<Document, ErrorResponse> {

        val document = Document(
            name = folderName,
            folder = true,
            parentId = parentId,
            isVisibleAdvisor = isVisibleAdvisor,
            isVisibleClient = true,
            note = ""
        )
        return dmsRemoteDataSource.createDocument(document, null)
    }
}
package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.OrgUnitModel
import kotlinx.serialization.Serializable

@Serializable
data class OrgUnit(
    val id: Long?,
    val name: String?,
    val streetName: String?,
    val buildingNumber: String?,
    val cashRegisterCode: String?,
    val country: String?,
    val municipality: String?,
    val postalCode: String?,
    val propertyRegistrationNumber: String?,
    val type: String?
) {

    fun toModel(): OrgUnitModel {
        val model = OrgUnitModel(
            id,
            name,
            streetName,
            buildingNumber,
            cashRegisterCode,
            country,
            municipality,
            postalCode,
            propertyRegistrationNumber,
            type
        )
        return model
    }
}
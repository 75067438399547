package com.erstedigital.socialbank.utils

import com.erstedigital.socialbank.MainRes

object ProductCategoryMapper {

    fun map(name: String): String {
        val id = when(name) {
            "auto/fuel" -> MainRes.string.category_auto_fuel
            "auto/care" -> MainRes.string.category_auto_care
            "baby/food" -> MainRes.string.category_baby_food
            "baby/fake-food" -> MainRes.string.category_baby_fake_food
            "baby/supplies-hygiene" -> MainRes.string.category_baby_supplies
            "basic/canned-fish" -> MainRes.string.category_basic_canned_fish
            "basic/canned-fruits-vegetable" -> MainRes.string.category_basic_canned_fruits_vegetable
            "basic/morning" -> MainRes.string.category_basic_morning
            "basic/flavoring" -> MainRes.string.category_basic_flavoring
            "basic/instant" -> MainRes.string.category_basic_instant
            "basic/jams-marmelade-honey" -> MainRes.string.category_jams_marmelade_honey
            "basic/oil-acid-grease" -> MainRes.string.category_basic_oil_acid_grease
            "basic/pasta" -> MainRes.string.category_basic_pasta
            "basic/sauces" -> MainRes.string.category_basic_sauces
            "basic/baking" -> MainRes.string.category_basic_baking
            "basic/soy_products" -> MainRes.string.category_basic_soy_products
            "basic/spices" -> MainRes.string.category_basic_spices
            "basic/spread" -> MainRes.string.category_basic_spread
            "basic/sugar-flour-rice" -> MainRes.string.category_basic_sugar_flour_rice
            "basic/tea-coffee-soups"-> MainRes.string.category_basic_tea_coffee_soups
            "basic/wheat-legumes"-> MainRes.string.category_basic_wheat_legumes
            "dairy/butter"-> MainRes.string.category_dairy_butter

            "dairy/cheese"-> MainRes.string.category_dairy_cheese
            "dairy/cream"-> MainRes.string.category_dairy_cream

            "dairy/eggs"-> MainRes.string.category_dairy_eggs
            "dairy/joghurts"-> MainRes.string.category_dairy_joghurts
            "dairy/lact-free"-> MainRes.string.category_dairy_lactfree
            "dairy/milk"-> MainRes.string.category_dairy_milk

            "drinks/alcohol"-> MainRes.string.category_drinks_alcohol
            "drinks/beer"-> MainRes.string.category_drinks_beer
            "drinks/juice"-> MainRes.string.category_drinks_juice
            "drinks/mineral-water"-> MainRes.string.category_mineral_water
            "drinks/non-alcoholic"-> MainRes.string.category_drinks_non_alcoholic
            "drinks/wine"-> MainRes.string.category_drinks_wine
            "drugstore/beauty"-> MainRes.string.category_drugstore_beauty

            "drugstore/dental"-> MainRes.string.category_drugstore_dental
            "drugstore/hygiene"-> MainRes.string.category_drugstore_hygiene
            "eating"-> MainRes.string.category_eating
            "other" -> MainRes.string.category_other
            "fashion/clothes"-> MainRes.string.category_fashion_clothes
            "fashion/kids"-> MainRes.string.category_fashion_kids
            "free-time/books-news-magazines"-> MainRes.string.category_freetime_books_magazines
            "free-time/other"-> MainRes.string.category_freetime_other
            "free-time/toys"-> MainRes.string.category_freetime_toys

            "free-time/visual"-> MainRes.string.category_freetime_visual
            "frozen/fish"-> MainRes.string.category_frozen_fish
            "frozen/fruit-vegetable"-> MainRes.string.category_frozen_fruit_vegetable

            "frozen/instant"-> MainRes.string.category_frozen_instant
            "frozen/meat"-> MainRes.string.category_frozen_meat
            "frozen/sweet"-> MainRes.string.category_frozen_sweet

            "fruits-veg/dried-nuts"-> MainRes.string.category_fruitsveg_dried_nuts
            "fruits-veg/fruit"-> MainRes.string.category_fruitsveg_fruit

            "fruits-veg/vegetable"-> MainRes.string.category_fruitsveg_vegetable
            "hazard"-> MainRes.string.category_hazard
            "health/lady"-> MainRes.string.category_health_lady

            "health/medical-supplies"-> MainRes.string.category_health_medical_supplies
            "health/sex"-> MainRes.string.category_health_sex

            "health/vitamins-ointments"-> MainRes.string.category_health_vitamins
            "health/vitamins"-> MainRes.string.category_health_vitamins
            "home/accessories"-> MainRes.string.category_home_accessories
            "home/bags"-> MainRes.string.category_home_bags
            "home/cleaning"-> MainRes.string.category_home_cleaning
            "home/decorations"-> MainRes.string.category_home_decorations
            "home/laundry"-> MainRes.string.category_home_laundry
            "home/maintenence"-> MainRes.string.category_home_maintenence
            "home/office"-> MainRes.string.category_home_office
            "home/plants-garden"-> MainRes.string.category_home_plants_garden
            "meat/deli"-> MainRes.string.category_meat_deli
            "meat/fish"-> MainRes.string.category_meat_fish
            "meat/meat"-> MainRes.string.category_meat_meat
            "meat/meat-products"-> MainRes.string.category_meat_meat_products
            "meat/smoked"-> MainRes.string.category_meat_smoked
            "pastry/basic"-> MainRes.string.category_pastry_basic
            "pastry/other"-> MainRes.string.category_pastry_other
            "pastry/salty"-> MainRes.string.category_pastry_salty
            "pastry/sweet"-> MainRes.string.category_pastry_sweet
            "pets"-> MainRes.string.category_pets
            "seasonal"-> MainRes.string.category_seasonal
            "snacks/salty"-> MainRes.string.category_snacks_salty
            "snacks/sweet"-> MainRes.string.category_snacks_sweet
            else -> null
        } ?: return name
        return id
    }
}
package com.erstedigital.socialbank.ui.statistics

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.transactions.detail.DetailComponent
import com.erstedigital.socialbank.ui.profile.store.ProfileStore
import com.erstedigital.socialbank.ui.profile.store.ProfileStoreFactory
import com.erstedigital.socialbank.ui.statistics.store.StatisticsStore
import com.erstedigital.socialbank.ui.statistics.store.StatisticsStoreFactory
import com.erstedigital.socialbank.ui.transactions.TransactionsComponent
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class StatisticsComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
): ComponentContext by componentContext {
    private val statisticsStore = instanceKeeper.getStore {
        StatisticsStoreFactory(storeFactory).create()
    }

    private val navigation = StackNavigation<Configuration>()


    private val stack = childStack(
        source = navigation,
        initialStack = { listOf(Configuration.Undefined) },
        childFactory = ::createChild,
    )

    val childStack: Value<ChildStack<*, Child>> = stack

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<StatisticsStore.State> = statisticsStore.stateFlow

    val label: Flow<StatisticsStore.Label> = statisticsStore.labels

    fun onEvent(event: StatisticsStore.Intent) {
        statisticsStore.accept(event)
    }

    fun onOutput(output: Output) {
        when (output) {
            is Output.NavigateToPieChart -> navigation.push(
                Configuration.PieChart(output.values, output.title))
            is Output.NavigateBack -> navigation.pop()
        }
    }

    private fun createChild(configuration: Configuration, componentContext: ComponentContext): Child =
        when (configuration) {
            is Configuration.Undefined -> Child.Undefined
            is Configuration.PieChart -> Child.PieChart(configuration.values, configuration.title)
        }


    sealed class Output {
        data class NavigateToPieChart(val values: List<Triple<String, Float, Float>>, val title: String): Output()
        data object NavigateBack: Output()
    }

    sealed class Child {
        data class PieChart(val values:  List<Triple<String, Float, Float>>, val title: String) : Child()

        data object Undefined : Child()

    }

    private sealed class Configuration: Parcelable {

        @Parcelize
        data class PieChart(val values: List<Triple<String, Float, Float>>, val title: String) : Configuration()

        @Parcelize
        data object Undefined : Configuration()

    }

}
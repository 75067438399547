package com.erstedigital.socialbank.ui.verify_code.store

import com.arkivanov.mvikotlin.core.store.Store

interface VerifyCodeStore: Store<VerifyCodeStore.Intent, VerifyCodeStore.State, VerifyCodeStore.Label> {

    sealed class Intent {
        data class Verify(val code: String, val username: String) : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val valid: Boolean = true,
        val error: String? = null,
        val loggedIn: Boolean = false,
    )

    sealed interface Label {
        object VerificationSuccess : Label
        object VerificationFailure : Label
    }
}
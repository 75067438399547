package com.erstedigital.socialbank.ui.root.store

import com.arkivanov.mvikotlin.core.store.Store

interface RootStore :
    Store<Nothing, RootStore.State, RootStore.Label> {

    sealed interface Label {
        object UserAlreadySigned : Label
        object UserNotSigned : Label
    }

    data class State(
        val loading: Boolean = true,
        val userSignedIn: Boolean = false
    )
}
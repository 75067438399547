package com.erstedigital.socialbank.ui.react.planning

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.home.HomeComponent
import com.erstedigital.socialbank.ui.planning.PlanningComponent
import com.erstedigital.socialbank.ui.react.planning.components.ExpenseView
import com.erstedigital.socialbank.ui.react.planning.components.IncomeView
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.*
import web.storage.localStorage

external interface PlanningComponentProps : Props {
    var component: PlanningComponent
    var parent: HomeComponent
    var onBack: () -> Unit
}

val PlanningContent: FC<PlanningComponentProps> = FC { props ->
    val (state, setState) = useState(props.component.state.value)
    val subPage = localStorage.getItem("navigation_subpage")
    val (selectedTab, setSelectedTab) = useState(if (subPage == "income" || subPage == null) 0 else 1)

    useEffect {
        props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }

    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                maxWidth = 600.px
                width = 90.pct
                height = 100.pct
                paddingTop = 20.px
                paddingBottom = 120.px
            }
            Box {
                sx {
                    display = Display.flex
                    // justify between
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.spaceBetween
                }
                Button {
                    sx {
                        width = 40.pct
                        marginBottom = 25.px
                        backgroundColor = if(selectedTab == 0) {primaryColor} else {Color("#ffffff")}
                    }
                    +MainRes.string.planning_expected_income_button
                    variant = if(selectedTab == 0) {ButtonVariant.contained} else {ButtonVariant.outlined}
                    onClick = {
                        //props.component.onEvent(LoginStore.Intent.Login(state.login, state.password))
                        setSelectedTab(0)
                    }
                }
                Button {
                    sx {
                        width = 40.pct
                        marginBottom = 25.px
                        backgroundColor = if(selectedTab == 1) {primaryColor} else {Color("#ffffff")}
                    }
                    +MainRes.string.planning_expected_expenses_button
                    variant = if(selectedTab == 1) {ButtonVariant.contained} else {ButtonVariant.outlined}
                    onClick = {
                        setSelectedTab(1)
                        //props.component.onEvent(LoginStore.Intent.Login(state.login, state.password))
                    }
                }

            }
            if(selectedTab == 0) {
                IncomeView {
                    parent = props.component
                }
            } else {
                ExpenseView {
                    parent = props.component
                }
            }
        }
    }
}

object ComponentStyles {
    var scrollable = emotion.css.ClassName {
        overflowY = Auto.auto
    }
}
package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.ProductOwnershipModel
import com.erstedigital.socialbank.domain.models.SplitModel
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Split(
    val id: Long,
    val userPayments: List<UserPayment> = emptyList(),
    val userProducts: List<ProductOwnership> = emptyList(),
    val createdDate: LocalDateTime?
) {

    /*@JsonClass(generateAdapter = true)
    data class UserPayment(
        val amount: Double,
        val user: AppUser
    ) {
        fun toModel(): UserPaymentModel
    }*/

    @Serializable
    data class ProductOwnership(
        val productItem: ProductItem,
        val appUser: com.erstedigital.socialbank.data.network.models.common.AppUser,
        val quantity: Double = 0.0
    ) {
        fun toModel(): ProductOwnershipModel {
            return ProductOwnershipModel(
                productItem.toModel(),
                appUser.toModel(),
                quantity
            )
        }
    }

    @Serializable
    data class UserPayment(
        val appUser: com.erstedigital.socialbank.data.network.models.common.AppUser,
        val amount: Double
    ) {
        fun toModel(): SplitModel.UserPayment {
            return SplitModel.UserPayment(appUser.toModel(), amount)
        }
    }

    fun toModel(): SplitModel {
        return SplitModel(
            id,
            createdDate,
            userProducts.map { it.toModel() },
            userPayments.map { it.toModel() }
        )
    }
}
package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.DeleteTransactionRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionListResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class DeleteTransactionUsecase(
    private val transactionsRemoteDataSource: TransactionRemoteDataSource
) {
    suspend fun deleteTransaction(transactionId: Long): ApiResponse<Any, ErrorResponse> {
        return transactionsRemoteDataSource.deleteTransactions(
            DeleteTransactionRequest(
                listOf(
                    DeleteTransactionRequest.Transaction(
                        transactionId
                    )
                )
            )
        )
    }
}
package com.erstedigital.socialbank.ui.react.home.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.home.HomeComponent
import com.erstedigital.socialbank.ui.home.store.HomeStore
import com.erstedigital.socialbank.ui.react.utils.surfaceLight
import mui.icons.material.Add
import mui.icons.material.AddCard
import mui.icons.material.BarChart
import mui.icons.material.Dashboard
import mui.icons.material.List
import mui.icons.material.Person
import mui.material.BottomNavigation
import mui.material.BottomNavigationAction
import mui.material.Fab
import mui.material.Paper
import mui.material.styles.ZIndex
import mui.system.sx
import react.*
import web.cssom.*
import web.storage.localStorage

external interface BottomNavBarProps: Props {
    var show: Boolean
    var component: HomeComponent
}

val BottomNavBar: FC<BottomNavBarProps> = FC { props ->

    val (navValue, setNavValue) = useState(0)

    useEffect{
        val storageNavValue = localStorage.getItem("navValue")?.toIntOrNull()
        if (storageNavValue != null) {
            setNavValue(storageNavValue)
        }
    }

    Paper {
        sx {
            position = Position.fixed
            height = 65.px
            bottom = 0.vh
            width = 100.vw
            left = 0.vw
            right = 0.vw
            visibility = if (props.show) Visibility.visible else Visibility.hidden
            //zIndex = ZIndex.tooltip
            zIndex = "tooltip" as web.cssom.ZIndex
        }
        BottomNavigation {
            sx {
                width = 100.pct
                backgroundColor = surfaceLight
                height = 100.pct
                justifyContent = JustifyContent.left
            }
            value = navValue
            onChange = { _, newValue ->
                setNavValue(newValue as Int)
            }
            //showLabels = true
            BottomNavigationAction {
                onClick = {
                    localStorage.setItem("navValue", 0.toString())
                    localStorage.setItem("navigation", "dashboard")
                    props.component.onOutput(HomeComponent.Output.NavigateToDashboard)
                }
                icon = Dashboard.create()
                label = ReactNode(MainRes.string.dashboard)
                sx {
                    maxWidth = 80.px
                    minWidth = 80.px
                    width = 80.px
                }
            }
            BottomNavigationAction {
                icon = List.create()
                onClick = {
                    localStorage.setItem("navValue", 1.toString())
                    localStorage.setItem("navigation", "transactions")
                    props.component.onOutput(HomeComponent.Output.NavigateToTransactions)
                }
                label = ReactNode(MainRes.string.transactions)
                sx {
                    maxWidth = 80.px
                    minWidth = 80.px
                    width = 80.px
                }
            }

            BottomNavigationAction {
                icon = BarChart.create()
                onClick = {
                    localStorage.setItem("navValue", 2.toString())
                    localStorage.setItem("navigation", "statistics")
                    props.component.onOutput(HomeComponent.Output.NavigateToStatistics)
                }
                label = ReactNode(MainRes.string.statistics)
                sx {
                    maxWidth = 80.px
                    minWidth = 80.px
                    width = 80.px
                }
            }

            BottomNavigationAction {
                icon = Person.create()
                onClick = {
                    localStorage.setItem("navValue", 3.toString())
                    localStorage.setItem("navigation", "profile")
                    props.component.onOutput(HomeComponent.Output.NavigateToProfile)
                }

                label = ReactNode(MainRes.string.profile)
                sx {
                    maxWidth = 80.px
                    minWidth = 80.px
                    width = 80.px
                }
            }

            BottomNavigationAction {
                icon = AddCard.create()
                onClick = {
                    localStorage.setItem("navValue", 4.toString())
                    localStorage.setItem("navigation", "planning")
                    props.component.onOutput(HomeComponent.Output.NavigateToPlanning)
                }

                label = ReactNode(MainRes.string.planning)
                sx {
                    maxWidth = 80.px
                    minWidth = 80.px
                    width = 80.px
                }
            }

            Fab {
                sx {
                    position = Position.absolute
                    bottom = 80.px
                    right = 20.px
                    backgroundColor = surfaceLight
                }
                onClick = {
                    props.component.onEvent(HomeStore.Intent.ScanQrCode)
                }
                Add()

            }
        }
    }
}

package com.erstedigital.socialbank.ui.react.statistics.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.statistics.PieChartType
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.icons.material.PieChartRounded
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import react.ReactNode
import react.useRef
import react.useState
import web.cssom.*
import kotlin.math.roundToInt

external interface BudgetCardProps: Props {
    var slice: StatisticsModel.DateSlice
    var onPieChartClicked: (PieChartType) -> Unit
}

val BudgetCard: FC<BudgetCardProps> = FC { props ->

    val (tabValue, setTabValue) = useState(0)

    fun getPieChartType(): PieChartType {
        return when (tabValue) {
            0 -> PieChartType.CATEGORIES
            1 -> PieChartType.ORGANIZATIONS
            else -> PieChartType.ORGANIZATIONS
        }
    }

    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 20.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
        }

        elevation = 2

        CardContent {
            Box {
                sx {
                    width = 300.px
                    height = 300.px
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.center
                    }
                    Typography {
                        variant = TypographyVariant.body1
                        +MainRes.string.budget
                    }
                    IconButton {
                        onClick = {
                            props.onPieChartClicked(getPieChartType())
                        }
                        PieChartRounded()
                    }
                }

                Tabs {
                    value = tabValue
                    onChange = { _, newValue ->
                        setTabValue(newValue as Int)
                    }
                    variant = TabsVariant.scrollable

                    Tab {
                        label = ReactNode(MainRes.string.category)
                    }
                    Tab {
                        label = ReactNode(MainRes.string.organization)
                    }
                    Tab {
                        label = ReactNode(MainRes.string.org_unit)
                    }
                }

                when (tabValue) {
                    1 -> {
                        props.slice.organizations.sortedByDescending { it.percentage }.map {
                            Box {
                                sx {
                                    width = 100.pct
                                    paddingTop = 3.px
                                    paddingBottom = 3.px
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = AlignItems.center
                                }
                                Typography {
                                    variant = TypographyVariant.caption
                                    +(it.name ?: MainRes.string.unknown)
                                }
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        justifyContent = JustifyContent.spaceBetween
                                    }

                                    Typography {
                                        variant = TypographyVariant.body1
                                        +(it.amount.times(100).roundToInt().div(100f).toString() + " €")
                                    }

                                    Typography {
                                        variant = TypographyVariant.caption
                                        +((it.percentage.times(100).roundToInt().div(100f)).toString() + " %")
                                    }
                                }
                            }
                        }
                    }
                    0 -> {
                        props.slice.categories.sortedByDescending { it.percentage }.map {
                            Box {
                                sx {
                                    width = 100.pct
                                    paddingTop = 3.px
                                    paddingBottom = 3.px
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = AlignItems.center
                                }
                                Typography {
                                    variant = TypographyVariant.body1
                                    +(it.name ?: MainRes.string.unknown)
                                }
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        justifyContent = JustifyContent.spaceBetween
                                    }

                                    Typography {
                                        variant = TypographyVariant.body1
                                        +(it.amount.times(100).roundToInt().div(100f).toString() + " €")
                                    }

                                    Typography {
                                        variant = TypographyVariant.caption
                                        +((it.percentage.times(100).roundToInt().div(100f)).toString() + " %")
                                    }
                                }
                            }
                        }
                    }
                    2 -> {
                        props.slice.orgUnits.sortedByDescending { it.percentage }.map {
                            Box {
                                sx {
                                    width = 100.pct
                                    paddingTop = 3.px
                                    paddingBottom = 3.px
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceBetween
                                    alignItems = AlignItems.center
                                }
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        justifyContent = JustifyContent.spaceBetween
                                    }

                                    Typography {
                                        variant = TypographyVariant.body1
                                        +(it.organizationName ?: MainRes.string.unknown)
                                    }

                                    Typography {
                                        variant = TypographyVariant.caption
                                        +(it.address ?: MainRes.string.unknown)
                                    }
                                }
                                Box {
                                    sx {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        justifyContent = JustifyContent.spaceBetween
                                    }

                                    Typography {
                                        variant = TypographyVariant.body1
                                        +(it.amount.times(100).roundToInt().div(100f).toString() + " €")
                                    }

                                    Typography {
                                        variant = TypographyVariant.caption
                                        +((it.percentage.times(100).roundToInt().div(100f)).toString() + " %")
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

package com.erstedigital.socialbank.data.network.models.request

import kotlinx.serialization.Serializable

@Serializable
data class UpdateExpectedExpenseRequest (
    var rent: Double?,
    var recurringRent: Boolean?,
    var electricity: Double?,
    var recurringElectricity: Boolean?,
    var water: Double?,
    var recurringWater: Boolean?,
    var gas: Double?,
    var recurringGas: Boolean?,
    var heating: Double?,
    var recurringHeating: Boolean?,
    var otherLivingExpenses: Double?,
    var recurringOtherLivingExpenses: Boolean?,
    var telephone: Double?,
    var recurringTelephone: Boolean?,
    var tv: Double?,
    var recurringTv: Boolean?,
    var internet: Double?,
    var recurringInternet: Boolean?,
    var medical: Double?,
    var recurringMedical: Boolean?,
    var food: Double?,
    var recurringFood: Boolean?,
    var transportation: Double?,
    var recurringTransportation: Boolean?,
)

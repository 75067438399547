package com.erstedigital.socialbank.ui.home

import com.arkivanov.decompose.Child
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.*
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.dashboard.DashboardComponent
import com.erstedigital.socialbank.ui.documents.DmsComponent
import com.erstedigital.socialbank.ui.documents.details.DetailsComponent
import com.erstedigital.socialbank.ui.home.store.HomeStore
import com.erstedigital.socialbank.ui.home.store.HomeStoreFactory
import com.erstedigital.socialbank.ui.planning.PlanningComponent
import com.erstedigital.socialbank.ui.profile.ProfileComponent
import com.erstedigital.socialbank.ui.statistics.StatisticsComponent
import com.erstedigital.socialbank.ui.transactions.TransactionsComponent
import com.erstedigital.socialbank.ui.transactions.detail.DetailComponent
import com.erstedigital.socialbank.ui.transactions.manual.ManualTransactionComponent
import com.erstedigital.socialbank.ui.transactions.products.ProductsComponent
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class HomeComponent(
    private val storeFactory: StoreFactory,
    componentContext: ComponentContext,
    private val openAuthorizationFlow: () -> Unit
): ComponentContext by componentContext {

    private val homeStore = instanceKeeper.getStore {
        HomeStoreFactory(storeFactory).create()
    }

    private val navigation = StackNavigation<Configuration>()
    private val stack = childStack(
        source = navigation,
        initialStack = { listOf(Configuration.Dashboard) },
        childFactory = ::createChild,
    )

    val childStack: Value<ChildStack<*, Child>> = stack

    val label: Flow<HomeStore.Label> = homeStore.labels

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<HomeStore.State> = homeStore.stateFlow

    fun onEvent(event: HomeStore.Intent) {
        homeStore.accept(event)
    }

    fun onOutput(output: Output) {
        when (output) {
            is Output.NavigateToDashboard -> navigation.bringToFront(Configuration.Dashboard)
            is Output.NavigateToTransactions -> navigation.bringToFront(Configuration.Transactions)
            is Output.NavigateToStatistics -> navigation.bringToFront(Configuration.Statistics)
            is Output.NavigateToProfile -> navigation.bringToFront(Configuration.Profile)
            is Output.NavigateToDms -> navigation.bringToFront(Configuration.Dms)
            is Output.NavigateToPlanning -> navigation.bringToFront(Configuration.Planning)

            is Output.NavigateToTransactionDetail -> navigation.bringToFront(Configuration.TransactionDetail(output.transactionId))
            is Output.NavigateBack -> navigation.pop()
            is Output.NavigateToProducts -> navigation.push(
                Configuration.Products(output.products))
            is Output.NavigateToDoctumentDetail -> navigation.push(
                Configuration.Document(output.documentId)
            )
            is Output.NavigateToManualTransaction -> navigation.push(Configuration.ManualTransaction)
            else -> {}
        }
    }

    private fun createChild(configuration: Configuration, componentContext: ComponentContext): Child =
        when (configuration) {
            is Configuration.Dashboard -> Child.Dashboard(
                DashboardComponent(
                    componentContext,
                    storeFactory
                )
            )
            is Configuration.Transactions -> Child.Transactions(
                TransactionsComponent(
                    componentContext,
                    storeFactory
                )
            )
            is Configuration.Statistics -> Child.Statistics(
                StatisticsComponent(
                    componentContext,
                    storeFactory
                )
            )
            is Configuration.Profile -> Child.Profile(
                ProfileComponent(
                    componentContext,
                    storeFactory,
                    openAuthorizationFlow
                )
            )
            is Configuration.TransactionDetail -> Child.TransactionDetail(
                DetailComponent(
                    componentContext,
                    storeFactory,
                    configuration.transactionId
                )
            )
            is Configuration.Products -> Child.Products(
                ProductsComponent(
                    componentContext,
                    storeFactory,
                    configuration.products
                )
            )
            is Configuration.Dms -> Child.Dms(
                DmsComponent(
                    componentContext,
                    storeFactory,
                )
            )
            is Configuration.Document -> Child.Document(
                DetailsComponent(
                    componentContext,
                    storeFactory,
                    configuration.documentId
                )
            )
            is Configuration.ManualTransaction -> Child.ManualTransaction(
                ManualTransactionComponent(
                    componentContext,
                    storeFactory
                )
            )
            is Configuration.Planning -> Child.Planning(
                PlanningComponent(
                    componentContext,
                    storeFactory
                )
            )
        }

    sealed class Output {
        data object NavigateToDashboard: Output()
        data object NavigateToTransactions: Output()
        data object NavigateToStatistics: Output()
        data object NavigateToProfile: Output()
        data object NavigateToDms: Output()
        data class NavigateToDoctumentDetail(val documentId: Long): Output()
        data class NavigateToTransactionDetail(val transactionId: Long): Output()
        data class NavigateToProducts(val products: List<ProductItemModel>) : Output()
        data object NavigateToManualTransaction : Output()
        data object NavigateBack : Output()
        data object NavigateToPlanning : Output()
    }

    sealed class Child {
        data class Dashboard(val dashboardComponent: DashboardComponent) : Child()
        data class Transactions(val transactionsComponent: TransactionsComponent) : Child()
        data class Statistics(val statisticsComponent: StatisticsComponent) : Child()
        data class Profile(val profileComponent: ProfileComponent) : Child()
        data class TransactionDetail(val transactionDetailComponent: DetailComponent): Child()
        data class Products(val productsComponent: ProductsComponent) : Child()
        data class Dms(val dmsComponent: DmsComponent) : Child()
        data class Document(val documentComponent: DetailsComponent) : Child()
        data class ManualTransaction(val manualTransactionComponent: ManualTransactionComponent) : Child()
        data class Planning(val planningComponent: PlanningComponent) : Child()
    }

    private sealed class Configuration: Parcelable {

        @Parcelize
        data object Dashboard : Configuration()

        @Parcelize
        data object Transactions : Configuration()

        @Parcelize
        data object Statistics : Configuration()

        @Parcelize
        data object Profile : Configuration()
        @Parcelize
        data object Dms : Configuration()

        @Parcelize
        data class Document(val documentId: Long) : Configuration()

        @Parcelize
        data class TransactionDetail(val transactionId: Long): Configuration()

        @Parcelize
        data class Products(val products: List<ProductItemModel>) : Configuration()

        @Parcelize
        data object ManualTransaction : Configuration()

        @Parcelize
        data object Planning : Configuration()
    }
}
package com.erstedigital.socialbank.domain.models

import kotlinx.serialization.Serializable

@Serializable
data class OrganizationModel(
    val id: Long?,
    var buildingNumber: String? = null,
    var country: String? = null,
    var dic: String? = null,
    var icDph: String? = null,
    var ico: String? = null,
    var municipality: String? = null,
    var name: String? = null,
    var postalCode: String? = null,
    var propertyRegistrationNumber: String? = null,
    var streetName: String? = null,
    var vatPayer: Boolean? = null,
    val category: String? = null
)
package com.erstedigital.socialbank.ui.react.statistics.components

import io.github.aakira.napier.Napier
import org.w3c.dom.*
import react.*
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.canvas
import react.dom.html.ReactHTML.div
import kotlin.math.*
external interface PieChartProps : Props {
    var data: List<PieChartData>
    var containerSize: Double
    var radiusOuter: Int
    var chartBarWidth: Int
    var animDuration: Int
    var highlightStrokeWidth: Int
    var onSliceClick: (PieChartData) -> Unit
}

data class PieChartData (
    var name: String,
    var amount: Double,
    var value: Double,
    var color: String
)

val PieChart: FC<PieChartProps> = FC { props  ->
    val canvasRef = useRef<web.html.HTMLCanvasElement>(null)
    val (selectedIndex, setSelectedIndex) = useState<Int?>(null)

    val newData = useMemo(props.data) {
        val threshold = 5
        val (largeCategories, smallCategories) = props.data.partition { it.value >= threshold }
        val otherSum = smallCategories.sumOf { it.amount }
        val otherSumPercentage = smallCategories.sumOf { it.value }

        val newData = largeCategories.toMutableList()
        if (otherSum > 0) {
            newData.add(PieChartData(
                "Other",
                color = "#c2d6d6",
                amount = otherSum,
                value = otherSumPercentage
            ))
        }
        newData
    }


    useEffect() {

        val canvas = canvasRef.current as HTMLCanvasElement?
        val context = canvas?.getContext("2d") as CanvasRenderingContext2D?
        if (context != null) {
            clearCanvas(context, props.containerSize, props.containerSize)

            val totalSum = newData.sumOf { it.value }
            var lastValue = 0f
            val centerX = props.containerSize / 2
            val centerY = props.containerSize / 2
            val startAngles = mutableListOf<Float>()

            // Draw all unselected slices and store start angles
            newData.forEachIndexed { index, data ->
                val sliceAngle = (360 * (data.value / totalSum)).toFloat()
                startAngles.add(lastValue)

                if (index != selectedIndex) {
                    drawSlice(
                        context,
                        centerX,
                        centerY,
                        props.radiusOuter,
                        props.chartBarWidth,
                        lastValue,
                        sliceAngle,
                        data.color,
                        isSelected = false,
                        props.highlightStrokeWidth,
                        label = data.name
                    )
                }
                lastValue += sliceAngle
            }

            // Draw the selected slice in its correct position
            selectedIndex?.let { index ->
                val data = newData[index]
                val sliceAngle = (360 * (data.value / totalSum)).toFloat()
                val startAngle = startAngles[index]

                drawSlice(
                    context,
                    centerX,
                    centerY,
                    props.radiusOuter,
                    props.chartBarWidth,
                    startAngle,
                    sliceAngle,
                    data.color,
                    isSelected = true,
                    props.highlightStrokeWidth,
                    label = data.name
                )
            }
        }
    }

    div {
        canvas {
            ref = canvasRef
            width = props.containerSize
            height = props.containerSize
            onClick = { event ->
                handleClick(event, props, newData = newData) {
                    setSelectedIndex(it)
                }
            }
        }
    }
}

fun clearCanvas(context: CanvasRenderingContext2D, width: Double, height: Double) {
    context.clearRect(0.0, 0.0, width, height)
}

fun drawSlice(
    context: CanvasRenderingContext2D,
    centerX: Double,
    centerY: Double,
    radius: Int,
    chartBarWidth: Int,
    startAngle: Float,
    sweepAngle: Float,
    color: String,
    isSelected: Boolean,
    highlightStrokeWidth: Int,
    label: String
) {
    startAngle.toDouble() * (PI / 180.0)

    val radians = startAngle.toDouble() * (PI / 180.0)
    val endRadians = (startAngle.toDouble() + sweepAngle) * (PI / 180.0)


    context.beginPath()
    context.moveTo(centerX, centerY)
    context.arc(
        centerX,
        centerY,
        radius.toDouble(),
        radians,
        endRadians,
        false
    )
    context.closePath()
    context.fillStyle = color
    context.fill()
    if (isSelected) {
        context.lineWidth = (highlightStrokeWidth).toDouble()
        context.strokeStyle = color // change for highlight color if needed
        context.stroke()
    }

    // Measure the text
    context.font = "16px Arial" // Adjust font style as needed
    val textMetrics = context.measureText(label)
    val textWidth = textMetrics.width
    val textHeight = 16.0 // Approximate height based on font size

    // Calculate the position for the slice's label
    val textRadius = radius + chartBarWidth + 20 // Adjust the distance from the pie chart
    val midAngle = (startAngle + sweepAngle / 2.0).toDouble() * (PI / 180.0)
    val textX = centerX + textRadius * cos(midAngle)
    val textY = centerY + textRadius * sin(midAngle)

    // Draw the rectangle
    context.fillStyle = color + "80" // Adjust color as needed
    context.fillRect(
        textX - textWidth / 2 - 5, // Adjust for padding
        textY - textHeight / 2 - 5, // Adjust for padding
        textWidth + 10, // Width with padding
        textHeight + 10 // Height with padding
    )

    // Draw the label
    context.fillStyle = "black" // Adjust text color as needed
    context.fillText(label, textX - textWidth / 2, textY + textHeight / 4)
}

fun handleClick(event: MouseEvent<web.html.HTMLCanvasElement, *>, props: PieChartProps, newData: List<PieChartData>, setSelectedIndex: (Int) -> Unit) {
    val canvas = event.currentTarget
    val rect = canvas.getBoundingClientRect()
    val x = event.clientX - rect.left
    val y = event.clientY - rect.top
    val centerX = props.containerSize / 2
    val centerY = props.containerSize / 2

    // Calculate angle and adjust it to be in the range [0, 360)
    var angle = atan2(y - centerY, x - centerX) * (180 / PI)
    if (angle < 0) {
        angle += 360
    }

    val distance = sqrt((x - centerX).pow(2) + (y - centerY).pow(2))
    var startAngle = 0.0

    for ((index, data) in newData.withIndex()) {
        val sliceAngle = 360 * (data.value / newData.sumOf { it.value })
        val endAngle = startAngle + sliceAngle
        if (angle >= startAngle && angle < endAngle && distance <= props.radiusOuter) {
            setSelectedIndex(index)
            props.onSliceClick(data)
            break
        }
        startAngle = endAngle
    }
}

package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.UpdateFsReceiptCategoryRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class UpdateFsReceiptCategoryUsecase(
    private val transactionRemoteDataSource: TransactionRemoteDataSource
) {

    suspend operator fun invoke(params: UpdateFsReceiptCategoryRequest): ApiResponse<TransactionResponse, ErrorResponse> {
        return transactionRemoteDataSource.updateFsReceiptCategory(params)
    }
}
package com.erstedigital.socialbank.ui.login.store

import com.arkivanov.mvikotlin.core.store.Store

interface LoginStore: Store<LoginStore.Intent, LoginStore.State, LoginStore.Label> {

    sealed class Intent {
        data class Login(val username: String, val password: String) : Intent()
        data class Register(val username: String, val password: String) : Intent()
        data class Reset(val username: String) : Intent()
        data object IdpLogin : Intent()
        data object AnonymousLogin : Intent()
        data class ChangeUsername(val username: String) : Intent()
        data class ChangePassword(val password: String) : Intent()
    }

    data class State(
        val login: String = "",
        val password: String = "",
        val loading: Boolean = false,
        val valid: Boolean = true,
        val error: String? = null,
        var loggedIn: Boolean = false,
        var actionSuccess: Boolean = false
    )

    sealed interface Label {
        object AuthorizationSuccess : Label

        //object EmailLoginSuccess : Label
        object AuthorizationFailure : Label
        object Initial: Label
    }
}
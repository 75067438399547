package com.erstedigital.socialbank.domain.models

import kotlinx.serialization.Serializable

@Serializable
data class GroupModel(
    val id: String,
    val name: String,
    val lastUpdated: String = "2 days ago",
    val users: List<UserModel> = emptyList()
)
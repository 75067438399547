package com.erstedigital.socialbank.ui.react.verify_code

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.login.LoginComponent
import com.erstedigital.socialbank.ui.login.store.LoginStore
import com.erstedigital.socialbank.ui.utils.RProps
import com.erstedigital.socialbank.ui.verify_code.VerifyCodeComponent
import com.erstedigital.socialbank.ui.verify_code.store.VerifyCodeStore
import io.github.aakira.napier.Napier
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.dom.onChange
import react.useEffect
import react.useRef
import react.useState
import web.cssom.AlignItems
import web.cssom.BoxSizing
import web.cssom.Display
import web.cssom.Flex
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLInputElement
import web.html.InputType

val VerifyCodeContent: FC<RProps<Pair<VerifyCodeComponent, String>>> = FC { props ->


    var (code, setCode) = useState("")
    val textFieldsRefs = Array(6) { useRef<HTMLDivElement>() }

    val (state, setState) = useState(props.component.first.state.value)

    useEffect{
        val job = props.component.first.state.onEach { setState(it) }.launchIn(GlobalScope)
    }

    useEffect(code) {
        if (code.length in 1..5) {
            val nextInput = document.getElementById("code-${code.length}") as HTMLInputElement?
            nextInput?.focus()
        }
    }

    useEffect(state) {

        if (!state.loading && state.loggedIn) {
            props.component.first.onOutput(VerifyCodeComponent.Output.NavigateToHome)
        }

    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            position = Position.fixed
            padding = 0.px
            top = 0.px
            bottom = 0.px
            left = 0.px
            right = 0.px
        }

        Box {
            sx {
                width = 100.pct
                boxSizing = BoxSizing.borderBox
                flex = Flex(grow = number(1.0), shrink = number(0.0), basis = 0.px)
                overflowY = Overflow.clip
            }
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                    width = 100.pct
                    height = 100.pct
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                        width = 70.pct
                        maxWidth = 400.px
                        height = 100.pct
                    }

                    Typography {
                        sx {
                            marginBottom = 25.px
                        }
                        variant = TypographyVariant.h3
                        align = TypographyAlign.center
                        +MainRes.string.verify_code
                    }

                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            width = 100.pct
                            marginBottom = 25.px
                        }
                        repeat(6) {
                            TextField {
                                sx {
                                    marginRight = if (it != 5) 5.px else 0.px
                                }
                                id = "code-$it"
                                value = if (code.length >= it) code[it].toString() else ""
                                disabled = code.length < it
                                type = InputType.number
                                variant = FormControlVariant.filled
                                ref = textFieldsRefs[it]
                                onKeyDown = { e ->
                                    if (e.key == "Backspace" && it > 0) {
                                        val newCode = code.substring(0, it - 1) + code.substring(it)
                                        setCode(newCode)

                                        val prevInput = textFieldsRefs[it - 1].current
                                        prevInput?.focus()
                                    }
                                }
                                onChange = { e ->
                                    val input = (e.target as HTMLInputElement).value
                                    //console.log(input)
                                    if (input.isNotEmpty() && input[input.length-1].isDigit() && code.length < 6) {
                                        val newCode = if (it == code.length) {
                                            code + input
                                        } else {
                                            code.substring(0, it) + input + code.substring(it + 1)
                                        }
                                        setCode(newCode)
                                        console.log(code)
                                        if (it < 5) {
                                            val nextInput = textFieldsRefs[it + 1].current
                                            nextInput?.focus()
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // error text
                    if (state.error != null) {
                        Typography {
                            sx {
                                marginBottom = 25.px
                            }
                            variant = TypographyVariant.body1
                            align = TypographyAlign.center
                            +"${MainRes.string.error}: ${MainRes.string.verify_code_error}"
                        }
                    }

                    Button {
                        sx {
                            width = 100.pct
                        }
                        +MainRes.string.verify_code
                        variant = ButtonVariant.contained
                        disabled = code.length < 6
                        onClick = {
                            props.component.first.onEvent(VerifyCodeStore.Intent.Verify(code, props.component.second))
                        }
                    }

                }
            }
        }
    }
}
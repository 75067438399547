package com.erstedigital.socialbank.data.datasources.statistics

import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.StatisticsCustomRangeResponse
import com.erstedigital.socialbank.data.network.models.response.StatisticsResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.NetworkResultState
import com.erstedigital.socialbank.data.network.utils.safeRequest
import com.erstedigital.socialbank.domain.models.DateRange
import com.erstedigital.socialbank.domain.models.StatisticsCustomRangeModel
import com.erstedigital.socialbank.domain.models.StatisticsModel
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.path
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.datetime.LocalDate

class StatisticsRemoteDataSource constructor(private val httpClient: HttpClient):
    StatisticsDataSource.Remote {
    override suspend fun getStatistics(): ApiResponse<StatisticsResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("statistics")
            }
        }
    }

    override suspend fun getCustomRangeStatistics(
        dateRange: DateRange
    ): ApiResponse<StatisticsCustomRangeResponse, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("statistics/custom")
                parameters.append("startDate", dateRange.startDate.toString())
                parameters.append("endDate", dateRange.endDate.toString())
            }
        }

    }
}
package com.erstedigital.overdokladapi.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Receipt(
        @SerialName("receiptId") val receiptId : String,
        @SerialName("ico") val ico : String,
        @SerialName("cashRegisterCode") val cashRegisterCode : String,
        @SerialName("issueDate") val issueDate : String,
        @SerialName("createDate") val createDate : String,
        @SerialName("customerId") val customerId : String?,
        @SerialName("dic") val dic : String,
        @SerialName("icDph") val icDph : String?,
        @SerialName("invoiceNumber") val invoiceNumber : String?,
        @SerialName("okp") val okp : String?,
        @SerialName("paragon") val paragon : Boolean,
        @SerialName("paragonNumber") val paragonNumber : String?,
        @SerialName("pkp") val pkp : String,
        @SerialName("receiptNumber") val receiptNumber : Int,
        @SerialName("type") val type : String,
        @SerialName("taxBaseBasic") val taxBaseBasic : Double?,
        @SerialName("taxBaseReduced") val taxBaseReduced : Double?,
        @SerialName("totalPrice") val totalPrice : Double,
        @SerialName("freeTaxAmount") val freeTaxAmount : Double?,
        @SerialName("vatAmountBasic") val vatAmountBasic : Double?,
        @SerialName("vatAmountReduced") val vatAmountReduced : Double?,
        @SerialName("vatRateBasic") val vatRateBasic : Double?,
        @SerialName("vatRateReduced") val vatRateReduced : Double?,
        @SerialName("items") val _items: List<Item>? = emptyList(),
        @SerialName("organization") val organization : Organization?,
        @SerialName("unit") val unit : Unit?,
        @SerialName("exemption") val exemption : Boolean
) {


    val items: List<Item>
        get() = _items ?: emptyList()

}
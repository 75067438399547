package com.erstedigital.socialbank.ui.transactions

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.bringToFront
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pop
import com.arkivanov.decompose.router.stack.push
import com.arkivanov.decompose.router.stack.replaceAll
import com.arkivanov.decompose.value.Value
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.domain.models.ProductModel
import com.erstedigital.socialbank.ui.transactions.detail.DetailComponent
import com.erstedigital.socialbank.ui.home.HomeComponent
import com.erstedigital.socialbank.ui.login.store.LoginStore
import com.erstedigital.socialbank.ui.profile.ProfileComponent
import com.erstedigital.socialbank.ui.root.RootComponent
import com.erstedigital.socialbank.ui.statistics.StatisticsComponent
import com.erstedigital.socialbank.ui.transactions.products.ProductsComponent
import com.erstedigital.socialbank.ui.transactions.store.TransactionStore
import com.erstedigital.socialbank.ui.transactions.store.TransactionStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.StateFlow

class TransactionsComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
): ComponentContext by componentContext {
    private val transactionStore = instanceKeeper.getStore {
        TransactionStoreFactory(storeFactory).create()
    }

    private val navigation = StackNavigation<Configuration>()


    private val stack = childStack(
        source = navigation,
        initialStack = { listOf(Configuration.Undefined) },
        childFactory = ::createChild,
    )

    val childStack: Value<ChildStack<*, Child>> = stack

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<TransactionStore.State> = transactionStore.stateFlow

    fun onEvent(event: TransactionStore.Intent) {
        transactionStore.accept(event)
    }

    private fun createChild(configuration: Configuration, componentContext: ComponentContext): Child =
        when (configuration) {
            is Configuration.Undefined -> Child.Undefined
            is Configuration.Details -> Child.Details(configuration.id)
            is Configuration.Products -> Child.Products
            is Configuration.Categories -> Child.Categories(configuration.product)
        }

    fun onOutput(output: Output) {
        when (output) {
            is Output.NavigateToDetails -> navigation.push(Configuration.Details(output.id))
            is Output.NavigateToProducts -> navigation.push(Configuration.Products)
            is Output.NavigateToCategories -> navigation.push(Configuration.Categories(output.product))
            is Output.NavigateBack -> navigation.pop()

        }
    }

    sealed class Output {
        object NavigateBack : Output()
        data class NavigateToDetails(val id: Long): Output()
        data object NavigateToProducts : Output()
        data class NavigateToCategories(val product: ProductItemModel) : Output()
    }

    sealed class Child {
        data class Details(val id: Long) : Child()
        data object Products : Child()
        data class Categories(val product: ProductItemModel) : Child()

        data object Undefined : Child()

    }

    private sealed class Configuration: Parcelable {

        @Parcelize
        data class Details(val id: Long) : Configuration()

        @Parcelize
        data object Products : Configuration()

        @Parcelize
        data class Categories(val product: ProductItemModel) : Configuration()
        @Parcelize
        data object Undefined : Configuration()


    }

}
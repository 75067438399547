package com.erstedigital.socialbank.ui.react.home.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.home.components.formatDate
import com.erstedigital.socialbank.ui.home.components.formatPrice
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.utils.parseCategoryInput
import io.github.aakira.napier.Napier
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.DrawerAnchor
import mui.material.IconButton
import mui.material.SwipeableDrawer
import mui.material.Typography
import mui.icons.material.Close
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useState
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px

external interface ScannerResultDrawerProps: Props {
    var open: Boolean
    var onDetailClicked: () -> Unit
    var onCategoryClicked: (CategoryInput) -> Unit
    var transactionModel: TransactionModel?
    var onClose: () -> Unit
}

val ScannerResultDrawer: FC<ScannerResultDrawerProps> = FC { props ->

    var (currentCategory, setCurrentCategory) = useState(props.transactionModel?.getCategory().parseCategoryInput())

    SwipeableDrawer {
        open = props.open
        anchor = DrawerAnchor.bottom

        Box {
            sx {
                backgroundColor = Color("#fff")
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                width = 100.pct
            }
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    justifyContent = JustifyContent.end
                    width = 100.pct
                }

                IconButton {
                    sx {
                        marginTop = 10.px
                        marginRight = 10.px
                    }
                    onClick = {
                        props.onClose()
                    }
                    Close()
                }
            }
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    width = 90.pct
                    paddingTop = 20.px
                    paddingBottom = 20.px
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.spaceBetween
                        marginBottom = 10.px
                        width = 100.pct
                    }

                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.start
                        }

                        Typography {
                            variant = TypographyVariant.caption
                            +MainRes.string.date_time_purchase
                        }
                        Typography {
                            variant = TypographyVariant.body1
                            +"${props.transactionModel?.fsReceiptModel?.createDate}"
                        }
                    }

                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.start
                        }

                        Typography {
                            variant = TypographyVariant.caption
                            +MainRes.string.amount
                        }
                        Typography {
                            variant = TypographyVariant.body1
                            +formatPrice(props.transactionModel?.getTotalAmount())
                        }
                    }
                }

                Typography {
                    variant = TypographyVariant.caption
                    +MainRes.string.merchant_name
                }
                Typography {
                    sx {
                        marginBottom = 10.px
                    }
                    variant = TypographyVariant.body1
                    +props.transactionModel?.getOrganization()?.name ?: ""
                }

                Typography {
                    variant = TypographyVariant.body1
                    +MainRes.string.category
                }

                CategorySelector {
                    category = currentCategory
                    expanded = true
                    type = CategoryType.EXPENSE
                    onCategoryClicked = {
                        setCurrentCategory(it)
                        props.onCategoryClicked(it)
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.end
                        marginTop = 20.px
                    }

                    Button {
                        sx {
                            backgroundColor = primaryColor
                        }
                        onClick = {
                            props.onDetailClicked()
                        }
                        variant = ButtonVariant.contained
                        +MainRes.string.detail
                    }
                }


            }
        }
    }
}
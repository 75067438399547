package com.erstedigital.socialbank.ui.onboarding.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.entity.UserEntity

interface OnboardingStore: Store<OnboardingStore.Intent, OnboardingStore.State, OnboardingStore.Label> {

    sealed class Intent {
        data object AnonymousLogin : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val error: String? = null,
        val loggedIn: Boolean = false,
    )

    sealed interface Label {
        data class SignInError(val message: String) : Label
        data class SignInSuccess(val user: UserEntity) : Label
    }
}
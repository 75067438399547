package com.erstedigital.socialbank.strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val app_name: String = "Bločker"

  override val category: String = "Kategória"

  override val settings: String = "Nastavenia"

  override val title_scanner: String = "Skenovací modul"

  override val profile: String = "Profil"

  override val planning: String = "Plánovanie"

  override val statistics: String = "Štatistiky"

  override val dashboard: String = "Nástenka"

  override val email: String = "E-mail"

  override val history: String = "História"

  override val amount: String = "Suma"

  override val merchant_name: String = "Meno obchodníka"

  override val detail: String = "Detail"

  override val upload_photo: String = "Nahrať fotografiu"

  override val share: String = "Zdieľať"

  override val delete: String = "Vymazať"

  override val edit: String = "Upraviť"

  override val edit_values: String = "Upraviť hodnoty"

  override val date_time_purchase: String = "Dátum a čas nákupu"

  override val Choose_secondary_category: String = "Vyberte sekundárnu kategóriu"

  override val date: String = "Dátum"

  override val pick: String = "Vybrať"

  override val week: String = "Týždeň"

  override val month: String = "Mesiac"

  override val full_name: String = "Celé meno"

  override val phone_number: String = "Telefónne číslo"

  override val log_out: String = "Odhlásiť sa"

  override val iban: String = "IBAN"

  override val welcome_message: WelcomeMessage = WelcomeMessage("Vitajte %1${'$'}s.")

  override val anonymous: String = "Anonymný"

  override val your_current_balance_message: YourCurrentBalanceMessage =
      YourCurrentBalanceMessage("Vaša aktuálna zostatok je %1${'$'}s €.")

  override val transactions: String = "Transakcie"

  override val how_to_split: String = "Ako rozdeliť účet"

  override val settlement: String = "Urovnávanie"

  override val total: String = "Celkom:"

  override val label_settlement_instructions: String =
      "Kliknutím na odoslanie sa urovnávanie odosiela používateľom s vyplneným e-mailom a vyzve vás k výberu správy pre používateľov bez vyplneného e-mailu."

  override val app_url_www: String = "www.blocker.sk"

  override val app_url: String = "blocker.sk"

  override val transactions_endpoint: String = "/transakcie"

  override val cancel: String = "Zrušiť"

  override val sign_in: String = "Prihlásiť sa"

  override val sign_up: String = "Registrovať sa"

  override val reset_password: String = "Vyresetovať heslo"

  override val failed_login: String = "Prihlásenie zlyhalo!"

  override val failed_password_reset: String = "Nepodarilo sa resetovať heslo!"

  override val failed_password_change: String = "Nepodarilo sa zmeniť heslo!"

  override val failed_user_registration: String = "Registrácia nového užívateľa zlyhala!"

  override val successful_user_registration: String = "Nový užívateľ bol úspešne zaregistovaný!"

  override val success_password_reset: String = "Heslo bolo úspešne vyresetované!"

  override val success_password_change: String = "Heslo bolo úspešne zmenené!"

  override val passwords_do_not_match: String = "Heslá sa nezhodujú!"

  override val submit_pin: String = "Prejsť na obrazovku overovacieho kódu"

  override val skip: String = "Preskočiť"

  override val try_out: String = "skúsiť ako anonym"

  override val try_as_anonymous: String = "<p><u>Preskočiť</u> pre vyskúšanie ako anonym</p>"

  override val verify_code: String = "Overiť kód"

  override val verify_code_error: String = "Neplatný kód"

  override val verify_code_message: VerifyCodeMessage = VerifyCodeMessage("""
      |Na váš e-mail 
      | %1${'$'}s 
      | sme vám poslali 6-miestny kód. Ak ho nenájdete, skontrolujte si zložku spam.
      """.trimMargin())

  override val resend_email_underlined: String = "<p><u>Znovu odoslať e-mail</u></p>"

  override val receipt_added_message: String = "Pridané do vašich transakcií."

  override val user_no: UserNo = UserNo("Používateľ %1${'$'}s")

  override val products_count: ProductsCount = ProductsCount("%1${'$'}s produktov")

  override val company_name: String = "Názov spoločnosti"

  override val company_ico: String = "IČO spoločnosti"

  override val save: String = "Uložiť"

  override val create: String = "Vytvoriť"

  override val create_company: String = "Vytvoriť spoločnosť"

  override val allow_analysis_by_socialbank: String =
      "Povoliť analýzu mojich transakcií zamestnancovi Sociálnej banky"

  override val income: String = "Príjem"

  override val expense: String = "Výdavok"

  override val agree: String = "Súhlasiť"

  override val allow_analysis_consent: String =
      "Vaše transakcie budú analyzovať zamestnanci Sociálnej banky"

  override val company_not_found: String =
      "Spoločnosť nenájdená! Skontrolujte prosím pravopis, IČO alebo pomôžte vytvoriť spoločnosť."

  override val from_advisor: String = "Od poradcu"

  override val update: String = "Aktualizovať"

  override val share_with: String = "Zdieľať s"

  override val copy: String = "Kopírovať"

  override val dont_send: String = "Neposielať"

  override val already_sent: String = "Už odoslané"

  override val use_users_email: String = "Použiť e-mail používateľa"

  override val next: String = "Ďalej"

  override val back: String = "Späť"

  override val unknown: String = "Neznáme"

  override val by_products: String = "Podľa produktov"

  override val by_percentage: String = "Podľa percenta"

  override val organization_search_description: String =
      "Vyhľadajte organizáciu podľa jej názvu alebo IČO"

  override val split: String = "Rozdeliť"

  override val products: String = "Produkty"

  override val show_all: String = "Zobraziť všetko"

  override val unit_details: String = "Podrobnosti o jednotke"

  override val organization_details: String = "Podrobnosti o organizácii"

  override val vat_details: String = "Podrobnosti o DPH"

  override val other_receipt_details: String = "Ďalšie podrobnosti o účte"

  override val show_more_details: String = "Zobraziť ďalšie podrobnosti"

  override val organization: String = "Organizácia"

  override val no_transactions_button: String =
      "Žiadne transakcie, môžete pridať niektoré pomocou tlačidla"

  override val enter_email: String = "Zadajte svoj e-mail"

  override val enter_password: String = "Zadajte svoje heslo"

  override val enter_password_confirmation: String = "Potvrďte zadané heslo"

  override val enter_old_password: String = "Zadajte svoje staré heslo"

  override val enter_new_password: String = "Zadajte svoje nové heslo"

  override val log_in: String = "Prihlásiť sa"

  override val change_password: String = "Zmeniť heslo"

  override val label_login_existing_account: String = "<p>Už máte účet? <u>Prihláste sa</u> tu.</p>"

  override val details: String = "Podrobnosti"

  override val show: String = "Zobraziť"

  override val debts: String = "Dlhy"

  override val balance_over_time: String = "Zostatok časom"

  override val show_in_pie_chart: String = "Zobraziť v koláčovom grafe"

  override val no_transactions: String = "Žiadne transakcie"

  override val label_construction: String = """
      |Táto funkcia je v procese výstavby.
      | Držte sa v obraze!
      """.trimMargin()

  override val users: String = "Používatelia"

  override val search_for_users: String = "Vyhľadávať používateľov"

  override val done: String = "Hotovo"

  override val merge: String = "Spárovať"

  override val create_transaction_manually: String = "Vytvoriť transakciu manuálne"

  override val choose_term: String = "Vyberte termín"

  override val default_notification_channel_id: String = "social_bank_notif_channel"

  override val group_by_day: String = "Zoskupiť podľa dňa"

  override val group_by_week: String = "Zoskupiť podľa týždňa"

  override val group_by_month: String = "Zoskupiť podľa mesiaca"

  override val group_by: String = "Zoskupiť podľa"

  override val week_no_year: WeekNoYear =
      WeekNoYear("Týždeň %1${'$'}s %2${'$'}s (%3${'$'}s - %4${'$'}s)")

  override val upload: String = "Nahrať"

  override val no_upload_advisor: String = "Ešte ste nepridali svoje dáta svojmu poradcovi."

  override val split_with: SplitWith =
      SplitWith("Rozdelenie s %1${'$'}s ďalšími. Celková cena: %2${'$'}s.")

  override val label_budget_empty: String = "Rozpočet je prázdny. Môžete pokračovať."

  override val label_budget_user_empty: String =
      "Používateľ nemá priradené žiadne produkty. Môžete ich priradiť potiahnutím na meno používateľa."

  override val kaufland: String = "Kvalita Kauflandu"

  override val lactofree: String = "Laktózové"

  override val bio: String = "Bio"

  override val billa: String = "Kvalita BILLA"

  override val clever: String = "CLEVER"

  override val tesco_finest: String = "Tesco Finest"

  override val tesco: String = "Tesco"

  override val f_and_f: String = "F & F"

  override val label_expenses: String = "Výdavky"

  override val label_paid: String = "Zaplatené"

  override val label_total: String = "Celkom"

  override val show_less_details: String = "Zobraziť menej podrobností"

  override val receipt_details: String = "Podrobnosti o účte"

  override val total_price: String = "Celková cena"

  override val name: String = "Meno"

  override val building_number: String = "Číslo budovy"

  override val country: String = "Krajina"

  override val dic: String = "DIČ"

  override val ico: String = "IČO"

  override val ic_dph: String = "DPH"

  override val municipality: String = "Obec"

  override val postal_code: String = "PSČ"

  override val street_name: String = "Názov ulice"

  override val property_registration_number: String = "Registračné číslo nehnuteľnosti"

  override val vat_payer: String = "Platca DPH"

  override val cash_register_code: String = "Kód pokladne"

  override val unit_type: String = "Typ jednotky"

  override val invalid_iban: String = "Neplatný formát IBAN"

  override val is_owed: IsOwed = IsOwed("Dlhujete %1${'$'}s.")

  override val owes: Owes = Owes("Dluží %1${'$'}s.")

  override val org_unit: String = "Organizačná jednotka"

  override val label_product_categories: String = "Kategórie produktov (zo všetkých transakcií)"

  override val label_product_categories_filtered: String =
      "Vytriedené kategórie produktov (iba z transakcií typu Zdravie a Stravovanie)"

  override val other: String = "Iné"

  override val mine_products: String = "Moje produkty"

  override val new_split: String = "Nové rozdelenie"

  override val last_split: String = "Posledné rozdelenie"

  override val label_settlement_title: LabelSettlementTitle =
      LabelSettlementTitle("Urovnávanie s %1${'$'}s.")

  override val label_settlement: LabelSettlement =
      LabelSettlement("Urovnávanie transakcií od %1${'$'}s.")

  override val leaderboard_title: LeaderboardTitle = LeaderboardTitle("""
      |Doteraz ste naskenovali %1${'$'}s nových produktov do našej databázy, čo vás robí %2${'$'}s. najväčším prispievateľom.
      |
      |Ďakujeme za vašu prispievanie !
      """.trimMargin())

  override val leaderboard_title_empty: String = """
      |Skenovaním produktov, ktoré ešte nie sú v našej databáze, prispievate k zlepšeniu aplikácie. Tu je zoznam používateľov s najväčším príspevkom.
      |
      |Ďakujeme za vašu prispievanie !
      """.trimMargin()

  override val feature_title_scanning: String = "Možnosti skenovania"

  override val feature_description_scanning: String =
      "Fotografujte svoj účet a pridajte ho k vašim výdavkom."

  override val feature_title_statistics: String = "Štatistiky"

  override val feature_description_statistics: String =
      "Zobrazujeme vám štatistiky, ako dobre ste spravovali svoj rozpočet pre každý týždeň/mesiac."

  override val feature_title_split: String = "Rozdelenie účtu"

  override val feature_description_split: String =
      "Rozdelenie účtov nikdy nebolo jednoduchšie! Existuje niekoľko spôsobov, ako sa vyrovnať so svojimi priateľmi."

  override val by_parts: String = "Podľa častí"

  override val by_parts_description: String =
      "Rozdeľte svoj účet priradením každému účastníkovi konečnej sumy, ktorú má zaplatiť."

  override val by_products_description: String =
      "Rozdeľte svoj účet priradením produktov používateľom a potom špecifikujte, koľko vám už zaplatili."

  override val is_owed_you: IsOwedYou = IsOwedYou("dlžíte %1${'$'}s.")

  override val owes_you: OwesYou = OwesYou("dlží vám %1${'$'}s.")

  override val me: String = "Ja"

  override val split_from: SplitFrom = SplitFrom("Rozdelenie od %1${'$'}s.")

  override val debt_user_accepted: String = "Používateľ uviedol, že dostal platbu."

  override val debt_user_not_received: String = "Používateľ ešte neuviedol, že dostal platbu."

  override val debt_user_paid: String = "Používateľ uviedol, že poslal platbu."

  override val debt_user_not_paid: String = "Používateľ ešte neuviedol, že zaplatil."

  override val ico_format: IcoFormat = IcoFormat("IČO %1${'$'}s")

  override val enter_amount: String = "Zadajte sumu"

  override val manual_transaction: String = "Manuálna transakcia"

  override val all: String = "Všetko"

  override val title_continue: String = "Pokračovať?"

  override val message_continue: String = "Všetky nepriradené položky budú priradené vám."

  override val continue_: String = "Pokračovať"

  override val assign_items: String = "Priradiť položky"

  override val send_to: SendTo = SendTo("Odoslať do %1${'$'}s")

  override val settlement_with: SettlementWith =
      SettlementWith("Urovnávanie s %1${'$'}s: %2${'$'}s.")

  override val iban_for: IbanFor = IbanFor("IBAN pre %1${'$'}s")

  override val title_receipt_added: String = "Účet už bol pridaný"

  override val yes: String = "Áno"

  override val no: String = "Nie"

  override val message_receipt_added: String =
      "Tento účet ste už pridali do vašich transakcií. Chcete ho pridať ako duplikát?"

  override val category_auto_fuel: String = "Auto - palivo"

  override val category_auto_care: String = "Auto - starostlivosť"

  override val category_baby_food: String = "Bábätko - jedlo"

  override val category_baby_fake_food: String = "Bábätko - falošné mlieko"

  override val category_baby_supplies: String = "Bábätko - potreby"

  override val category_basic_canned_fish: String = "Základné - konzervovaná ryba"

  override val category_basic_canned_fruits_vegetable: String =
      "Základné/Konzervované ovocie alebo zelenina"

  override val category_basic_morning: String = "Základné/Ráno"

  override val category_basic_flavoring: String = "Základné/Aromatizácia"

  override val category_basic_instant: String = "Základné/Okamžité"

  override val category_jams_marmelade_honey: String = "Základné - džemy, marmelády, med"

  override val category_basic_oil_acid_grease: String = "Základné - oleje, octy, tuky"

  override val category_basic_pasta: String = "Základné - cestoviny"

  override val category_basic_sauces: String = "Základné - omáčky"

  override val category_basic_soy_products: String = "Základné - sójové výrobky"

  override val category_basic_spices: String = "Základné - korenie"

  override val category_basic_baking: String = "Základné - pečenie"

  override val category_basic_spread: String = "Základné - šírenie"

  override val category_basic_sugar_flour_rice: String = "Základné - cukor, múka, ryža"

  override val category_basic_tea_coffee_soups: String = "Základné - čaj, káva, polievky"

  override val category_basic_wheat_legumes: String = "Základné - pšenica, strukoviny"

  override val category_dairy_butter: String = "Mliečne výrobky - maslo"

  override val category_dairy_cheese: String = "Mliečne výrobky - syr"

  override val category_dairy_cream: String = "Mliečne výrobky - smotana"

  override val category_dairy_eggs: String = "Mliečne výrobky - vajcia"

  override val category_dairy_joghurts: String = "Mliečne výrobky - jogurty"

  override val category_dairy_lactfree: String = "Mliečne výrobky - laktózové"

  override val category_dairy_milk: String = "Mliečne výrobky - mlieko"

  override val category_drinks_alcohol: String = "Nápoje - alkohol"

  override val category_drinks_beer: String = "Nápoje - pivo"

  override val category_drinks_juice: String = "Nápoje - džús"

  override val category_mineral_water: String = "Nápoje - minerálna voda"

  override val category_drinks_non_alcoholic: String = "Nápoje - nealkoholické"

  override val category_drinks_wine: String = "Nápoje - víno"

  override val category_drugstore_beauty: String = "Drogeria - krása"

  override val category_drugstore_dental: String = "Drogeria - zubné"

  override val category_drugstore_hygiene: String = "Drogeria - hygiena"

  override val category_eating: String = "Stravovanie"

  override val category_fashion_clothes: String = "Móda - oblečenie"

  override val category_fashion_kids: String = "Móda - deti"

  override val category_freetime_books_magazines: String = "Voľný čas - knihy, časopisy, novinky"

  override val category_freetime_toys: String = "Voľný čas - hračky"

  override val category_freetime_other: String = "Voľný čas - iné"

  override val category_frozen_fish: String = "Mrazené - ryby"

  override val category_frozen_fruit_vegetable: String = "Mrazené - ovocie a zelenina"

  override val category_frozen_instant: String = "Mrazené - okamžité"

  override val category_frozen_meat: String = "Mrazené - mäso"

  override val category_frozen_sweet: String = "Mrazené - sladké"

  override val category_fruitsveg_dried_nuts: String = "Ovocie a zelenina - sušené ovocie a orechy"

  override val category_fruitsveg_fruit: String = "Ovocie a zelenina - ovocie"

  override val category_fruitsveg_vegetable: String = "Ovocie a zelenina - zelenina"

  override val category_hazard: String = "Nebezpečenstvo"

  override val category_health_lady: String = "Zdravie - dáma"

  override val category_health_medical_supplies: String = "Zdravie - lekárske potreby"

  override val category_health_vitamins: String = "Zdravie - vitamíny"

  override val category_health_sex: String = "Zdravie - sex"

  override val category_home_accessories: String = "Domácnosť - doplnky"

  override val category_home_bags: String = "Domácnosť - tašky"

  override val category_home_cleaning: String = "Domácnosť - čistenie"

  override val category_home_decorations: String = "Domácnosť - dekorácie"

  override val category_home_laundry: String = "Domácnosť - práčovňa"

  override val category_home_maintenence: String = "Domácnosť - údržba"

  override val category_home_office: String = "Domácnosť - kancelária"

  override val category_home_plants_garden: String = "Domácnosť - rastliny a záhrada"

  override val category_meat_deli: String = "Mäso - delikatesy"

  override val category_meat_fish: String = "Mäso - ryba"

  override val category_meat_meat: String = "Mäso - mäso"

  override val category_meat_meat_products: String = "Mäso - mäsové výrobky"

  override val category_meat_smoked: String = "Mäso - uzené"

  override val category_pastry_basic: String = "Cesto - základné"

  override val category_pastry_other: String = "Cesto - iné"

  override val category_pastry_salty: String = "Cesto - slané"

  override val category_pastry_sweet: String = "Cesto - sladké"

  override val category_pets: String = "Zvieratá"

  override val category_seasonal: String = "Sezónne"

  override val category_snacks_salty: String = "Pochutiny - slané"

  override val category_snacks_sweet: String = "Pochutiny - sladké"

  override val category_freetime_visual: String = "Voľný čas - vizuál"

  override val tax_base_basic: String = "Základ dane - základný"

  override val tax_base_reduced: String = "Základ dane - zľavnený"

  override val vat_amount_basic: String = "Daňový odvod - základný"

  override val vat_amount_reduced: String = "Daňový odvod - zľavnený"

  override val vat_rate_basic: String = "Sadzba DPH - základná"

  override val vat_rate_reduced: String = "Sadzba DPH - zľavnená"

  override val receipt_type: String = "Typ účtenky"

  override val invoice_number: String = "Číslo faktúry"

  override val customer_id: String = "Identifikátor zákazníka"

  override val label_add_email_to_account: String = "Pridať e-mail k účtu"

  override val message_settlement: MessageSettlement = MessageSettlement("""
      |Dlžíte %1${'$'}s:
      |IBAN: %2${'$'}s
      |Množstvo: %3${'$'}s
      |Platobný odkaz: %4${'$'}s
      """.trimMargin())

  override val selected_no: SelectedNo = SelectedNo("%1${'$'}s vybratých")

  override val who_paid_how_much: String = "Kto zaplatil koľko"

  override val choose_users: String = "Vyberte používateľov"

  override val show_week: ShowWeek = ShowWeek("Zobraziť týždeň %1${'$'}s z %2${'$'}s")

  override val show_month: ShowMonth = ShowMonth("Zobraziť %1${'$'}s %2${'$'}s")

  override val budget: String = "Rozpočet"

  override val is_all_settled: String = "Je všetko vyrovnané"

  override val you: String = "Vy"

  override val artificial: String = "Pridať umelého používateľa"

  override val total_price_: TotalPrice = TotalPrice("Celková cena: %1${'$'}s")

  override val settlement_created: String = "Rozdelenie transakcie vytvorené"

  override val owes_to: OwesTo = OwesTo("Dlhuje %1${'$'}s %2${'$'}s.")

  override val is_owed_from: IsOwedFrom = IsOwedFrom("Je dlžný %1${'$'}s od %2${'$'}s.")

  override val my_price: String = "Moja suma"

  override val suggested: String = "Navrhované"

  override val unlock_someone: String = "Odomknúť niekoho"

  override val unlock_someone_desc: String = "Odomknúť niekoho, aby ste mohli zmeniť sumu."

  override val income_created: String = "Príjem vytvorený"

  override val income_updated: String = "Príjem aktualizovaný"

  override val income_deleted: String = "Príjem vymazaný"

  override val shared: String = "Zdieľané"

  override val expense_deleted: String = "Výdavok vymazaný"

  override val expense_updated: String = "Výdavok aktualizovaný"

  override val expense_created: String = "Výdavok vytvorený"

  override val message_choose_category: String = "Musíte vybrať kategóriu"

  override val error: String = "Chyba"

  override val profile_updated: String = "Profil aktualizovaný"

  override val category_other: String = "Iné"

  override val title_delete: String = "Zmazať?"

  override val message_delete: String = "Ste si istí, že chcete zmazať toto rozdelenie?"

  override val custom: String = "Vlastný"

  override val expensive_products: String = "Drahé produkty"

  override val expensive_products_description: String =
      "Nahrajte fotografiu vášho účtu a drahých produktov, aby sme mohli uplatniť záruku."

  override val images: String = "Obrázky"

  override val january: String = "Január"

  override val february: String = "Február"

  override val march: String = "Marec"

  override val april: String = "Apríl"

  override val may: String = "Máj"

  override val june: String = "Jún"

  override val july: String = "Júl"

  override val august: String = "August"

  override val september: String = "September"

  override val october: String = "Október"

  override val november: String = "November"

  override val december: String = "December"

  override val notes_from_advisor: String = "Poznámky od poradcu"

  override val documents_from_advisor: String = "Dokumenty od poradcu"

  override val dashboard_balance_string: String = "Mesačný zostatok:"

  override val dashboard_total_balance_string: String = "Celkový zostatok:"

  override val dashboard_balance: DashboardBalance = DashboardBalance("%1${'$'}s €")

  override val dashboard_income: String = "+ Príjem"

  override val dashboard_expected_income: String = "+ Očakávaný príjem"

  override val dashboard_expense: String = "- Výdavky"

  override val dashboard_expected_expense: String = "- Očakávané výdavky"

  override val dashboard_previous_months: String = "Predchádzajúce mesiace"

  override val dashboard_expected_income_expense: String = "Mesačné očakávania"

  override val dashboard_add_transaction: String = "Pridať transakciu"

  override val documents: String = "Dokumenty"

  override val document: String = "Dokument"

  override val new_document: String = "Nový dokument"

  override val all_documents: String = "Všetky dokumenty"

  override val attachments: String = "Prílohy"

  override val existing_attachments: String = "Existujúce prílohy"

  override val no_attachments: String = "Žiadne prílohy"

  override val new_attachments: String = "Nové prílohy"

  override val upload_attachment: String = "Nahrať prílohu"

  override val visible_to_advisor: String = "Viditeľné pre poradcu"

  override val no_documents_from_advisor: String = "Váš poradca zatiaľ neposkytol žiadne dokumenty"

  override val rename: String = "Premenovať"

  override val select_month: String = "Vyberte mesiac"

  override val select_week: String = "Vyberte týždeň"

  override val from: String = "Od"

  override val to: String = "Do"

  override val planning_expected_income: String = "Mesačne očakávané príjmy"

  override val planning_expected_income_button: String = "Príjem"

  override val planning_expected_expenses: String = "Mesačne očakávané výdavky"

  override val planning_expected_expenses_button: String = "Výdavky"

  override val planning_sum: PlanningSum = PlanningSum("Súčet %1${'$'}s €")

  override val planning_income_legend: String = "Nastavte vaše hodnoty"

  override val planning_income_legend_switch: String = "Vyberte, či sa opakuje"

  override val planning_income: String = "Príjem"

  override val planning_income_business: String = "Príjem z podnikania"

  override val planning_income_social: String = "Sociálne dávky"

  override val planning_income_other: String = "Iné"

  override val planning_expense_rent: String = "Nájomné"

  override val planning_expense_electricity: String = "Elektrina"

  override val planning_expense_water: String = "Voda"

  override val planning_expense_gas: String = "Plyn"

  override val planning_expense_heating: String = "Kúrenie"

  override val planning_expense_otherLivingExpenses: String = "Ostatné životné náklady"

  override val planning_expense_telephone: String = "Telefón"

  override val planning_expense_tv: String = "TV"

  override val planning_expense_internet: String = "Internet"

  override val planning_expense_medical: String = "Zdravotné"

  override val planning_expense_food: String = "Strava"

  override val planning_expense_transportation: String = "Doprava"

  override val no_upload_days: NoUploadDays = NoUploadDays(PluralForms(zero =
      "Dnes ste nahrali svoje transakcie.", one =
      "Dnes ste neposlali svoje transakcie svojmu poradcovi %1${'$'}s deň.", few =
      "Dnes ste neposlali svoje transakcie svojmu poradcovi %1${'$'}s dni.", other =
      "Dnes ste neposlali svoje transakcie svojmu poradcovi %1${'$'}s dní.", ), "en")
}

package com.erstedigital.socialbank.data.datasources.transaction_image

import com.erstedigital.socialbank.data.network.models.common.TransactionImage
import com.erstedigital.socialbank.data.network.models.request.CreateTransactionImageRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.path

class TransactionImageRemoteDataSource constructor(private val httpClient: HttpClient):
    TransactionImageDataSource.Remote {
    override suspend fun create(request: CreateTransactionImageRequest): ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                method = HttpMethod.Post
                path("transaction-images")
            }
            setBody(request)
        }
    }

    override suspend fun getByTransactionId(transactionId: Long): ApiResponse<List<TransactionImage>, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                method = HttpMethod.Get
                path("transaction-images")
                parameters.append("transactionId", transactionId.toString())
            }
        }
    }
}
package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionDataSource
import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class GetTransactionUsecase(
    private val transactionRemoteDataSource: TransactionRemoteDataSource
){
    suspend fun getTransaction(params: Long): ApiResponse<TransactionResponse, ErrorResponse> {
        return transactionRemoteDataSource.getTransaction(params)
    }
}
package com.erstedigital.socialbank.data.datasources.company

import com.erstedigital.socialbank.data.network.models.common.Organization
import com.erstedigital.socialbank.data.network.models.request.CreateCompanyRequest
import com.erstedigital.socialbank.data.network.models.response.CompanyListResponse
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import io.ktor.http.path

class CompanyRemoteDataSource constructor(private val httpClient: HttpClient): CompanyDataSource.Remote {
    override suspend fun search(q: String): ApiResponse<List<CompanyListResponse>, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("organizations/search")
                parameters.append("q", q)
            }
        }
    }

    override suspend fun create(request: CreateCompanyRequest): ApiResponse<Organization, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("organizations")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }
}
package com.erstedigital.socialbank.strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsSk : Strings {
  override val app_name: String = "Bločker"

  override val category: String = "Category"

  override val settings: String = "Settings"

  override val title_scanner: String = "Scanner Module"

  override val profile: String = "Profile"

  override val planning: String = "Planning"

  override val statistics: String = "Statistics"

  override val dashboard: String = "Dashboard"

  override val email: String = "e-mail"

  override val history: String = "History"

  override val amount: String = "Amount"

  override val merchant_name: String = "Merchant name"

  override val detail: String = "Detail"

  override val upload_photo: String = "Upload photo"

  override val share: String = "Share"

  override val delete: String = "Delete"

  override val edit: String = "Edit"

  override val edit_values: String = "Edit values"

  override val date_time_purchase: String = "Date and time of purchase"

  override val Choose_secondary_category: String = "Choose secondary category"

  override val date: String = "Date"

  override val pick: String = "Pick"

  override val week: String = "Week"

  override val month: String = "Month"

  override val full_name: String = "Full name"

  override val phone_number: String = "Phone number"

  override val log_out: String = "Log out"

  override val iban: String = "IBAN"

  override val welcome_message: WelcomeMessage = WelcomeMessage("Welcome %1${'$'}s.")

  override val anonymous: String = "Anonymous"

  override val your_current_balance_message: YourCurrentBalanceMessage =
      YourCurrentBalanceMessage("Your current balance is %1${'$'}s €.")

  override val transactions: String = "Transactions"

  override val how_to_split: String = "Jak rozdělit účet"

  override val settlement: String = "Settlement"

  override val total: String = "Total:"

  override val label_settlement_instructions: String =
      "Clicking send sends the settlement to users with filled e-mail and will prompt you to choose messaging service for users without filled e-mail."

  override val app_url_www: String = "www.blocker.sk"

  override val app_url: String = "blocker.sk"

  override val transactions_endpoint: String = "/transactions"

  override val cancel: String = "Cancel"

  override val sign_in: String = "Log in"

  override val sign_up: String = "Sign up"

  override val reset_password: String = "Reset password"

  override val failed_login: String = "Login failed!"

  override val failed_password_reset: String = "Failed to reset password!"

  override val failed_password_change: String = "Failed to change password!"

  override val failed_user_registration: String = "Registration of new user failed!"

  override val successful_user_registration: String = "New user registered successfully!"

  override val success_password_reset: String = "Password reset was successful!"

  override val success_password_change: String = "Password changed successfully!"

  override val passwords_do_not_match: String = "Passwords don't match!"

  override val submit_pin: String = "Go to verification code screen"

  override val skip: String = "Skip"

  override val try_out: String = "to try as anonymous"

  override val try_as_anonymous: String = "<p><u>Skip</u> to try as anonymous</p>"

  override val verify_code: String = "Verify code"

  override val verify_code_error: String = "Invalid code"

  override val verify_code_message: VerifyCodeMessage = VerifyCodeMessage("""
      |We have sent you an email to 
      | %1${'$'}s 
      | with 6-digit code. If you can\'t find it try checking your spam folder.
      """.trimMargin())

  override val resend_email_underlined: String = "<p><u>Resend email</u></p>"

  override val receipt_added_message: String = "Added to your transactions."

  override val user_no: UserNo = UserNo("User %1${'$'}s")

  override val products_count: ProductsCount = ProductsCount("%1${'$'}s products")

  override val company_name: String = "Company name"

  override val company_ico: String = "Company ICO"

  override val save: String = "Save"

  override val create: String = "Create"

  override val create_company: String = "Create company"

  override val allow_analysis_by_socialbank: String =
      "Allow analysis of my transaction by employee of Social bank"

  override val income: String = "Income"

  override val expense: String = "Expense"

  override val agree: String = "Agree"

  override val allow_analysis_consent: String =
      "Your transactions will be analysed by employees of socialbank"

  override val company_not_found: String =
      "Company not found! Please check the spelling, ICO or help us create the company."

  override val from_advisor: String = "From advisor"

  override val update: String = "Update"

  override val share_with: String = "Share with"

  override val copy: String = "Copy"

  override val dont_send: String = "Don\\'t send"

  override val already_sent: String = "Already sent"

  override val use_users_email: String = "Use user\\'s e-mail"

  override val next: String = "Next"

  override val back: String = "Back"

  override val unknown: String = "Unknown"

  override val by_products: String = "By products"

  override val by_percentage: String = "By percentage"

  override val organization_search_description: String =
      "Search for an organization by it\\'s name or ico"

  override val split: String = "Split"

  override val products: String = "Products"

  override val show_all: String = "Show all"

  override val unit_details: String = "Unit details"

  override val organization_details: String = "Organization details"

  override val vat_details: String = "VAT details"

  override val other_receipt_details: String = "Other receipt details"

  override val show_more_details: String = "Show more details"

  override val organization: String = "Organization"

  override val no_transactions_button: String = "No transactions, you can add some using the button"

  override val enter_email: String = "Enter your email"

  override val enter_password: String = "Enter your password"

  override val enter_password_confirmation: String = "Confirm your password"

  override val enter_old_password: String = "Enter your old password"

  override val enter_new_password: String = "Enter your new password"

  override val log_in: String = "Log in"

  override val change_password: String = "Change password"

  override val label_login_existing_account: String =
      "<p>Already have an account? <u>Login</u> here.</p>"

  override val details: String = "Details"

  override val show: String = "Show"

  override val debts: String = "Debts"

  override val balance_over_time: String = "Balance over time"

  override val show_in_pie_chart: String = "Show in pie chart"

  override val no_transactions: String = "No transactions"

  override val label_construction: String = """
      |This feature is under construction.
      | Stay tuned!
      """.trimMargin()

  override val users: String = "Users"

  override val search_for_users: String = "Search for users"

  override val done: String = "Done"

  override val merge: String = "Merge"

  override val create_transaction_manually: String = "Create transaction manually"

  override val choose_term: String = "Choose term"

  override val default_notification_channel_id: String = "social_bank_notif_channel"

  override val group_by_day: String = "Group by day"

  override val group_by_week: String = "Group by week"

  override val group_by_month: String = "Group by month"

  override val group_by: String = "Group by"

  override val week_no_year: WeekNoYear =
      WeekNoYear("Week %1${'$'}s %2${'$'}s (%3${'$'}s - %4${'$'}s)")

  override val upload: String = "Upload"

  override val no_upload_advisor: String = "You haven't uploaded your data to your advisor yet."

  override val split_with: SplitWith =
      SplitWith("Split with %1${'$'}s others. Total price: %2${'$'}s.")

  override val label_budget_empty: String = "Budget is empty. You are ready to proceed."

  override val label_budget_user_empty: String =
      "User has no products assigned. You can assign them by dragging them to user\\'s name."

  override val kaufland: String = "Kaufland quality"

  override val lactofree: String = "Lactofree"

  override val bio: String = "Bio"

  override val billa: String = "BILLA quality"

  override val clever: String = "CLEVER"

  override val tesco_finest: String = "Tesco Finest"

  override val tesco: String = "Tesco"

  override val f_and_f: String = "F & F"

  override val label_expenses: String = "Expenses"

  override val label_paid: String = "Paid"

  override val label_total: String = "Total"

  override val show_less_details: String = "Show less details"

  override val receipt_details: String = "Receipt details"

  override val total_price: String = "Total price"

  override val name: String = "Name"

  override val building_number: String = "Building number"

  override val country: String = "Country"

  override val dic: String = "TIN"

  override val ico: String = "CIN"

  override val ic_dph: String = "inTax"

  override val municipality: String = "Municipality"

  override val postal_code: String = "Postal code"

  override val street_name: String = "Street name"

  override val property_registration_number: String = "Property registration number"

  override val vat_payer: String = "Vat payer"

  override val cash_register_code: String = "Cash register code"

  override val unit_type: String = "Unit type"

  override val invalid_iban: String = "Invalid IBAN format"

  override val is_owed: IsOwed = IsOwed("Is owed %1${'$'}s.")

  override val owes: Owes = Owes("Owes %1${'$'}s.")

  override val org_unit: String = "OrgUnit"

  override val label_product_categories: String = "Product categories (from all transactions)"

  override val label_product_categories_filtered: String =
      "Filtered Product categories (only from Health and Food transactions)"

  override val other: String = "Other"

  override val mine_products: String = "Mine products"

  override val new_split: String = "New split"

  override val last_split: String = "Last split"

  override val label_settlement_title: LabelSettlementTitle =
      LabelSettlementTitle("Settlement with %1${'$'}s.")

  override val label_settlement: LabelSettlement =
      LabelSettlement("Transaction settlement from %1${'$'}s.")

  override val leaderboard_title: LeaderboardTitle = LeaderboardTitle("""
      |So far you have scanned %1${'$'}s new products into our database, which makes you %2${'$'}s. biggest contributor.
      |
      |Thank you for your contribution !
      """.trimMargin())

  override val leaderboard_title_empty: String = """
      |By scanning products, which are not yet in our database, you contribute to improvement of app. Here is a list of users with most contributions.
      |
      |Thank you for your contribution !
      """.trimMargin()

  override val feature_title_scanning: String = "Scanning capabilities"

  override val feature_description_scanning: String =
      "Take a photo of your receipt and add it to your spending."

  override val feature_title_statistics: String = "Statistics"

  override val feature_description_statistics: String =
      "We show you statistics of how well did you manage your budget for each week/month."

  override val feature_title_split: String = "Bill split"

  override val feature_description_split: String =
      "Splitting bills has never been easier! There are multiple ways how you can settle with your friends."

  override val by_parts: String = "By parts"

  override val by_parts_description: String =
      "Split your bill by assigning each participant a final amount to be paid."

  override val by_products_description: String =
      "Split your bill by assigning products to users and then specify how much they have already paid you."

  override val is_owed_you: IsOwedYou = IsOwedYou("you owe %1${'$'}s.")

  override val owes_you: OwesYou = OwesYou("owes you %1${'$'}s.")

  override val me: String = "Me"

  override val split_from: SplitFrom = SplitFrom("Split from %1${'$'}s.")

  override val debt_user_accepted: String = "User says he received the payment."

  override val debt_user_not_received: String =
      "User has not yet indicated that he has received the payment."

  override val debt_user_paid: String = "User indicates that he has sent the payment."

  override val debt_user_not_paid: String = "User hasn\\'t yet indicated that he had paid."

  override val ico_format: IcoFormat = IcoFormat("IČO %1${'$'}s")

  override val enter_amount: String = "Enter amount"

  override val manual_transaction: String = "Manual transaction"

  override val all: String = "All"

  override val title_continue: String = "Continue?"

  override val message_continue: String = "All not assigned items, will be assigned to you."

  override val continue_: String = "Continue"

  override val assign_items: String = "Assign items"

  override val send_to: SendTo = SendTo("Send to %1${'$'}s")

  override val settlement_with: SettlementWith =
      SettlementWith("Settlement with %1${'$'}s: %2${'$'}s.")

  override val iban_for: IbanFor = IbanFor("IBAN for %1${'$'}s")

  override val title_receipt_added: String = "Receipt already added"

  override val yes: String = "Yes"

  override val no: String = "No"

  override val message_receipt_added: String =
      "You have already added this receipt to your transactions. Do you want to add it as duplicate?"

  override val category_auto_fuel: String = "Car - fuel"

  override val category_auto_care: String = "Car - care"

  override val category_baby_food: String = "Baby - food"

  override val category_baby_fake_food: String = "Baby - fake milk"

  override val category_baby_supplies: String = "Baby - supplies"

  override val category_basic_canned_fish: String = "Basic - canned fish"

  override val category_basic_canned_fruits_vegetable: String = "Basic/Canned fruits or vegetable"

  override val category_basic_morning: String = "Basic/Morning"

  override val category_basic_flavoring: String = "Basic/Flavoring"

  override val category_basic_instant: String = "Basic/Instant"

  override val category_jams_marmelade_honey: String = "Basic - jams, marmelade, honey"

  override val category_basic_oil_acid_grease: String = "Basic - oils, vinegars, grease"

  override val category_basic_pasta: String = "Basic - pasta"

  override val category_basic_sauces: String = "Basic - sauces"

  override val category_basic_soy_products: String = "Basic - soy products"

  override val category_basic_spices: String = "Basic - spices"

  override val category_basic_baking: String = "Basic - baking"

  override val category_basic_spread: String = "Basic - spreads"

  override val category_basic_sugar_flour_rice: String = "Basic - sugar, flour, rice"

  override val category_basic_tea_coffee_soups: String = "Basic - tea, coffee, soups"

  override val category_basic_wheat_legumes: String = "Basic - wheat, legumes"

  override val category_dairy_butter: String = "Dairy - butter"

  override val category_dairy_cheese: String = "Dairy - cheese"

  override val category_dairy_cream: String = "Dairy - cream"

  override val category_dairy_eggs: String = "Dairy - eggs"

  override val category_dairy_joghurts: String = "Dairy - joghurts"

  override val category_dairy_lactfree: String = "Dairy - lactofree"

  override val category_dairy_milk: String = "Dairy - milk"

  override val category_drinks_alcohol: String = "Drinks - alcohol"

  override val category_drinks_beer: String = "Drinks - beer"

  override val category_drinks_juice: String = "Drinks - juice"

  override val category_mineral_water: String = "Drinks - mineral water"

  override val category_drinks_non_alcoholic: String = "Drinks - non-alcoholic"

  override val category_drinks_wine: String = "Drinks - wine"

  override val category_drugstore_beauty: String = "Drugstore - beauty"

  override val category_drugstore_dental: String = "Drugstore - dental"

  override val category_drugstore_hygiene: String = "Drugstore - hygiene"

  override val category_eating: String = "Eating"

  override val category_fashion_clothes: String = "Fashion - clothes"

  override val category_fashion_kids: String = "Fashion - kids"

  override val category_freetime_books_magazines: String = "Freetime - books, magazines, news"

  override val category_freetime_toys: String = "Freetime - toys"

  override val category_freetime_other: String = "Freetime - other"

  override val category_frozen_fish: String = "Frozen - fish"

  override val category_frozen_fruit_vegetable: String = "Frozen - fruit and vegetable"

  override val category_frozen_instant: String = "Frozen - instant"

  override val category_frozen_meat: String = "Frozen - meat"

  override val category_frozen_sweet: String = "Frozen - sweet"

  override val category_fruitsveg_dried_nuts: String = "Fruits & vegetable - dried fruits & nuts"

  override val category_fruitsveg_fruit: String = "Fruits & vegetable - fruit"

  override val category_fruitsveg_vegetable: String = "Fruits & Vegetable - vegetable"

  override val category_hazard: String = "Hazard"

  override val category_health_lady: String = "Health - lady"

  override val category_health_medical_supplies: String = "Health - medical supplies"

  override val category_health_vitamins: String = "Health - vitamins"

  override val category_health_sex: String = "Health - sex"

  override val category_home_accessories: String = "Home - accessories"

  override val category_home_bags: String = "Home - bags"

  override val category_home_cleaning: String = "Home - cleaning"

  override val category_home_decorations: String = "Home - decorations"

  override val category_home_laundry: String = "Home - laundy"

  override val category_home_maintenence: String = "Home - maintenence"

  override val category_home_office: String = "Home - office"

  override val category_home_plants_garden: String = "Home - plants & garden"

  override val category_meat_deli: String = "Meat - deli"

  override val category_meat_fish: String = "Meat - fish"

  override val category_meat_meat: String = "Meat - meat"

  override val category_meat_meat_products: String = "Meat - meat products"

  override val category_meat_smoked: String = "Meat - smoked"

  override val category_pastry_basic: String = "Pastry - basic"

  override val category_pastry_other: String = "Pastry - other"

  override val category_pastry_salty: String = "Pastry - salty"

  override val category_pastry_sweet: String = "Pastry - sweet"

  override val category_pets: String = "Pets"

  override val category_seasonal: String = "Seasonal"

  override val category_snacks_salty: String = "Snacks - salty"

  override val category_snacks_sweet: String = "Snacks - sweet"

  override val category_freetime_visual: String = "Freetime - visual"

  override val tax_base_basic: String = "Tax base basic"

  override val tax_base_reduced: String = "Tax base reduced"

  override val vat_amount_basic: String = "VAT amount basic"

  override val vat_amount_reduced: String = "VAT amount reduced"

  override val vat_rate_basic: String = "VAT rate basic"

  override val vat_rate_reduced: String = "VAT rate reduced"

  override val receipt_type: String = "Receipt type"

  override val invoice_number: String = "Invoice number"

  override val customer_id: String = "Customer id"

  override val label_add_email_to_account: String = "Add e-mail to account"

  override val message_settlement: MessageSettlement = MessageSettlement("""
      |You owe %1${'$'}s:
      |IBAN: %2${'$'}s
      |Amount: %3${'$'}s
      |payme link: %4${'$'}s
      """.trimMargin())

  override val selected_no: SelectedNo = SelectedNo("%1${'$'}s selected")

  override val who_paid_how_much: String = "Who paid how much"

  override val choose_users: String = "Choose users"

  override val show_week: ShowWeek = ShowWeek("Show week %1${'$'}s of %2${'$'}s")

  override val show_month: ShowMonth = ShowMonth("Show %1${'$'}s %2${'$'}s")

  override val budget: String = "Budget"

  override val is_all_settled: String = "Má všetko vyrovnané"

  override val you: String = "You"

  override val artificial: String = "Add artificial user"

  override val total_price_: TotalPrice = TotalPrice("Total price: %1${'$'}s")

  override val settlement_created: String = "Transaction split created"

  override val owes_to: OwesTo = OwesTo("Owes %1${'$'}s to %2${'$'}s.")

  override val is_owed_from: IsOwedFrom = IsOwedFrom("Is owed %1${'$'}s from %2${'$'}s.")

  override val my_price: String = "My amount"

  override val suggested: String = "Suggested"

  override val unlock_someone: String = "Unlock someone"

  override val unlock_someone_desc: String = "Unlock someone to change the amount."

  override val income_created: String = "Income created"

  override val income_updated: String = "Income updated"

  override val income_deleted: String = "Income deleted"

  override val shared: String = "Shared"

  override val expense_deleted: String = "Expense deleted"

  override val expense_updated: String = "Expense updated"

  override val expense_created: String = "Expense created"

  override val message_choose_category: String = "You need to choose category"

  override val error: String = "Error"

  override val profile_updated: String = "Profile updated"

  override val category_other: String = "Other"

  override val title_delete: String = "Delete?"

  override val message_delete: String = "Are you sure you want to delete this settlement?"

  override val custom: String = "Custom"

  override val expensive_products: String = "Expensive products"

  override val expensive_products_description: String =
      "Upload a photo of your receipt and of your expensive products so we can exercise your warranty."

  override val images: String = "Images"

  override val january: String = "January"

  override val february: String = "February"

  override val march: String = "March"

  override val april: String = "April"

  override val may: String = "May"

  override val june: String = "June"

  override val july: String = "July"

  override val august: String = "August"

  override val september: String = "September"

  override val october: String = "October"

  override val november: String = "November"

  override val december: String = "December"

  override val notes_from_advisor: String = "Notes From Advisor"

  override val documents_from_advisor: String = "Documents From Advisor"

  override val dashboard_balance_string: String = "Monthly Balance:"

  override val dashboard_total_balance_string: String = "Total Balance:"

  override val dashboard_balance: DashboardBalance = DashboardBalance("%1${'$'}s €")

  override val dashboard_income: String = "+ Income"

  override val dashboard_expected_income: String = "+ Expected Income"

  override val dashboard_expense: String = "- Expenses"

  override val dashboard_expected_expense: String = "- Expected Expenses"

  override val dashboard_previous_months: String = "Previous Months"

  override val dashboard_expected_income_expense: String = "Monthly Expectations"

  override val dashboard_add_transaction: String = "Add Transaction"

  override val documents: String = "Documents"

  override val document: String = "Document"

  override val new_document: String = "New Document"

  override val all_documents: String = "All Documents"

  override val attachments: String = "Attachments"

  override val existing_attachments: String = "Existing Attachments"

  override val no_attachments: String = "No Attachments"

  override val new_attachments: String = "New Attachments"

  override val upload_attachment: String = "Upload Attachment"

  override val visible_to_advisor: String = "Visible to Advisor"

  override val no_documents_from_advisor: String = "Your advisor has not yet provided any documents"

  override val rename: String = "Rename"

  override val select_month: String = "Select Month"

  override val select_week: String = "Select Week"

  override val from: String = "From"

  override val to: String = "To"

  override val planning_expected_income: String = "Monthly Expected Income"

  override val planning_expected_income_button: String = "Income"

  override val planning_expected_expenses: String = "Monthly Expected Expenses"

  override val planning_expected_expenses_button: String = "Expenses"

  override val planning_sum: PlanningSum = PlanningSum("Sum %1${'$'}s €")

  override val planning_income_legend: String = "Set your values"

  override val planning_income_legend_switch: String = "Choose if it repeats"

  override val planning_income: String = "Income"

  override val planning_income_business: String = "Income from business"

  override val planning_income_social: String = "Social benefits"

  override val planning_income_other: String = "Other"

  override val planning_expense_rent: String = "Rent"

  override val planning_expense_electricity: String = "Electricity"

  override val planning_expense_water: String = "Water"

  override val planning_expense_gas: String = "Gas"

  override val planning_expense_heating: String = "Heating"

  override val planning_expense_otherLivingExpenses: String = "Other Living Expenses"

  override val planning_expense_telephone: String = "Telephone"

  override val planning_expense_tv: String = "Tv"

  override val planning_expense_internet: String = "Internet"

  override val planning_expense_medical: String = "Medical"

  override val planning_expense_food: String = "Food"

  override val planning_expense_transportation: String = "Transportation"

  override val no_upload_days: NoUploadDays = NoUploadDays(PluralForms(zero =
      "You have uploaded your transactions today.", one =
      "You didn\\'t upload your transactions to your advisor for %1${'$'}s day.", few =
      "You didn\\'t upload your transactions to your advisor for %1${'$'}s days.", other =
      "You didn\\'t upload your transactions to your advisor for %1${'$'}s days.", ), "sk")
}

package com.erstedigital.socialbank.domain.usecase.company

import com.erstedigital.socialbank.data.datasources.company.CompanyRemoteDataSource
import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Organization
import com.erstedigital.socialbank.data.network.models.request.CreateCompanyRequest
import com.erstedigital.socialbank.data.network.models.request.CreateFsReceiptRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse


class CreateCompanyUsecase(
    private val companyRemoteDataSource: CompanyRemoteDataSource
) {

    suspend operator fun invoke(params: CreateCompanyRequest): ApiResponse<Organization, ErrorResponse> {
        return companyRemoteDataSource.create(params)
    }
}
package com.erstedigital.overdokladapi.data.response

import com.erstedigital.overdokladapi.data.Receipt
import com.erstedigital.overdokladapi.data.SearchIdentification
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ReceiptFindResponse(
    @SerialName("returnValue") val returnValue : Int,
    @SerialName("receipt") val receipt: Receipt?,
    @SerialName("searchIdentification") val searchIdentification : SearchIdentification
)
package com.erstedigital.socialbank.domain.usecase.documents

import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import io.github.aakira.napier.Napier
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.readBytes
import io.ktor.http.isSuccess

class DownloadAttachmentUsecase(
    private val dmsRemoteDataSource: DmsRemoteDataSource
) {

    suspend operator fun invoke(attachmentId: Long): Pair<ByteArray, String>? {
        val response = dmsRemoteDataSource.downloadAttachment(attachmentId)

        try {
            if (response.status.isSuccess()) {
                val contentDisposition = response.headers["Content-Disposition"]
                val fileName = contentDisposition?.substringAfter("filename=") ?: "file"
                return Pair(response.readBytes(), fileName)
            } else {
                return null
            }
        } catch (e: Exception) {
            return null
        }
    }
}
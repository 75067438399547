package com.erstedigital.socialbank.data.network.models.common

import kotlinx.serialization.Serializable

@Serializable
data class Attachment(
    var id: Long? = null,
    var data: ByteArray? = null,
    var name: String? = "",
    var contentType: String? = "",
    var size: Double? = 0.0
)
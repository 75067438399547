package com.erstedigital.socialbank.ui.home.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.request.CreateFsReceiptRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateFsReceiptCategoryRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.domain.usecase.transactions.CreateFsReceiptUsecase
import com.erstedigital.socialbank.domain.usecase.transactions.CreateFsReceiptV2Usecase
import com.erstedigital.socialbank.domain.usecase.transactions.UpdateFsReceiptCategoryUsecase
import com.erstedigital.socialbank.ui.utils.openCamera
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class HomeStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val createFsReceiptUsecase by inject<CreateFsReceiptUsecase>()
    private val createFsReceiptV2Usecase by inject<CreateFsReceiptV2Usecase>()
    private val updateFsReceiptCategoryUsecase by inject<UpdateFsReceiptCategoryUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): HomeStore = object : HomeStore, Store<HomeStore.Intent, HomeStore.State, HomeStore.Label> by storeFactory.create(
        name = "HomeStore",
        initialState = HomeStore.State(),
        bootstrapper = coroutineBootstrapper {},
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class GenericError(val message: String): Msg()
        data class DuplicateError(val fsReceipt: CreateFsReceiptRequest?): Msg()
        data class ReceiptData(val transaction: TransactionModel?): Msg()

        data class CategoryUpdated(val category: String?): Msg()

        data object DismissResultBottomSheet: Msg()

        data class ManualTransaction(val show: Boolean): Msg()

        data object DismissDuplicateDialog : Msg()
    }

    private inner class ExecutorImpl: CoroutineExecutor<HomeStore.Intent, Unit, HomeStore.State, Msg, HomeStore.Label>() {

        override fun executeAction(action: Unit, getState: () -> HomeStore.State) {
            super.executeAction(action, getState)
        }

        override fun executeIntent(intent: HomeStore.Intent, getState: () -> HomeStore.State) {
            when (intent) {
                is HomeStore.Intent.ScanQrCode -> scan()
                is HomeStore.Intent.UpdateCategory -> updateCategory(intent.id, intent.category)
                is HomeStore.Intent.DismissDuplicateDialog -> dispatch(Msg.DismissDuplicateDialog)
                is HomeStore.Intent.DuplicateTransaction -> forceCreateTransaction(getState().fsReceipt!!)
                is HomeStore.Intent.DismissResultBottomSheet -> {
                    publish(HomeStore.Label.DismissResultBottomSheet)
                    dispatch(Msg.DismissResultBottomSheet)
                }
                is HomeStore.Intent.DismissManualTransaction -> dispatch(Msg.ManualTransaction(false))
                else -> {}
            }
        }

        private fun onCameraOutput(result: String) {
            dispatch(Msg.Loading)
            scope.launch {

                when (val response = createFsReceiptV2Usecase.createFsReceipt(result)) {
                    is ApiResponse.Success -> {
                        val body = response.body
                        when (body.result) {
                            CreateFsReceiptV2Usecase.Result.Type.CREATED -> {
                                dispatch(Msg.ReceiptData(body.model))
                                publish(HomeStore.Label.TransactionCreated)
                            }
                            CreateFsReceiptV2Usecase.Result.Type.EXISTS -> {
                                dispatch(Msg.DuplicateError(body.receipt))
                            }
                            CreateFsReceiptV2Usecase.Result.Type.UNKNOWN -> {
                                dispatch(Msg.GenericError("Unknown error"))
                            }
                        }
                    }
                    is ApiResponse.Error.HttpError -> {
                        dispatch(Msg.GenericError(response.errorMessage ?: "Unknown network error"))
                    }
                    is ApiResponse.Error.GenericError -> {
                        dispatch(Msg.GenericError(response.message ?: "Unknown error"))
                    } else -> {
                        dispatch(Msg.GenericError("Unknown error"))
                    }
                }
            }
        }


        private fun scan() {
            openCamera(::onCameraOutput, ::onManualTransactionClick) {}
        }

        private fun onManualTransactionClick() {
            dispatch(Msg.ManualTransaction(true))
        }

        private fun updateCategory(id: Long, category: String) {
            val request = UpdateFsReceiptCategoryRequest(id, category)

            scope.launch {
                when (val response = updateFsReceiptCategoryUsecase(request)) {
                    is ApiResponse.Success -> {
                        val body = response.body
                        val model = body.toModel()
                        val newCategory = model.getCategory()

                        if (newCategory != null) {
                            dispatch(Msg.CategoryUpdated(model.getCategory()))
                        }
                    } else -> {
                        dispatch(Msg.GenericError("Unknown error while updating category"))
                    }
                }
            }

        }

        private fun forceCreateTransaction(fsReceipt: CreateFsReceiptRequest) {

            scope.launch {
                when (val response = createFsReceiptUsecase(fsReceipt)) {
                    is ApiResponse.Success -> {
                        val body = response.body
                        val model = body.toModel()
                        dispatch(Msg.ReceiptData(model))
                        publish(HomeStore.Label.TransactionCreated)
                    } else -> {
                        dispatch(Msg.GenericError("Unknown error while creating transaction"))
                    }
                }
            }

        }

    }


    private object ReducerImpl: Reducer<HomeStore.State, Msg> {
        override fun HomeStore.State.reduce(msg: Msg): HomeStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.GenericError -> copy(loading = false, error = msg.message, transactionState = null)
            is Msg.DuplicateError -> copy(
                loading = false,
                error = MainRes.string.message_receipt_added,
                fsReceipt = msg.fsReceipt!!,
                transactionState = TransactionState.DUPLICATE
            )
            is Msg.ReceiptData -> copy(loading = false, transaction = msg.transaction, transactionState = TransactionState.CREATED)
            is Msg.CategoryUpdated -> copy(
                transaction = transaction?.copy(
                    fsReceiptModel = transaction.fsReceiptModel?.copy(category = msg.category)
                ))
            is Msg.DismissDuplicateDialog -> copy(error = null, fsReceipt = null, transaction = null, transactionState = null)
            is Msg.DismissResultBottomSheet -> copy(transactionState = null)
            is Msg.ManualTransaction -> copy(manualTransactionClicked = msg.show)
        }
    }
}
package com.erstedigital.socialbank.ui.react.transactions

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.react.transactions.components.CategoryItem
import com.erstedigital.socialbank.ui.react.transactions.components.ProductItem
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import com.erstedigital.socialbank.ui.transactions.products.ProductsComponent
import com.erstedigital.socialbank.ui.transactions.products.store.ProductsStore
import com.erstedigital.socialbank.utils.ProductCategoryMapper
import com.erstedigital.socialbank.utils.categories
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.icons.material.ArrowBack
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.CircularProgress
import mui.material.IconButton
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import kotlin.math.roundToInt

external interface CategoriesContentProps: Props {
    var selectedProduct: ProductItemModel
    var categoryUpdated: Boolean
    var onBack: () -> Unit
    var onUpdateCategory: (String) -> Unit
}

val CategoriesContent: FC<CategoriesContentProps> = FC { props ->

    useEffect(props.categoryUpdated) {
        if (props.categoryUpdated) {
            props.onBack()
        }
    }


    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            paddingBottom = 90.px
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
            }

            IconButton {
                onClick = { props.onBack() }
                ArrowBack()
            }
            Typography {
                sx {
                    marginBottom = 10.px
                    width = 100.pct
                }
                variant = TypographyVariant.h4
                +MainRes.string.category
            }
        }

        Typography {
            sx {
                marginBottom = 10.px
                marginTop = 20.px
                fontWeight = FontWeight.bold
            }
            variant = TypographyVariant.body1
            +MainRes.string.suggested
        }

        props.selectedProduct.product?.categories?.distinct()?.map { c ->
            CategoryItem {
                category = c
                onCategoryClick = { props.onUpdateCategory(c) }
            }
        }

        Typography {
            sx {
                marginBottom = 10.px
                marginTop = 10.px
                fontWeight = FontWeight.bold
            }
            variant = TypographyVariant.body1
            +MainRes.string.other
        }

        categories.map { c ->
            CategoryItem {
                category = c
                onCategoryClick = { props.onUpdateCategory(c) }
            }
        }

    }


}
package com.erstedigital.socialbank.data.network.models.response

import androidx.compose.ui.graphics.Color
import com.erstedigital.socialbank.domain.models.UserModel
import com.erstedigital.socialbank.domain.utils.toIntColor
import kotlinx.serialization.Serializable

@Serializable
data class GetProfileResponse(
        val id: Long?,
        val fullName: String?,
        val email: String?,
        val phoneNumber: String?,
        val iban: String?,
        val color: String?,
        val allowAnalysis: Boolean? = null
//        val groups: List<>
) {
    fun toModel(): UserModel {
        val model = UserModel(
                id,
                fullName,
                email = email,
                phoneNumber = phoneNumber,
                iban = iban,
                _color = color?.let {
                    return@let try {
                         it.toIntColor()
                    } catch (e: Exception) {
                        e.printStackTrace()
                        null
                    }
                },
                allowAnalysis = allowAnalysis
        )
        return model
    }
}
package com.erstedigital.socialbank.ui.react.planning.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedExpenseRequest
import com.erstedigital.socialbank.ui.planning.PlanningComponent
import com.erstedigital.socialbank.ui.planning.store.PlanningStore
import com.erstedigital.socialbank.ui.react.utils.graphExpenseColor
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.onChange
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType

external interface ExpenseViewProps: Props {
    var values: List<Pair<String, Double>>
    var parent: PlanningComponent
}

val ExpenseView: FC<ExpenseViewProps> = FC { props ->
    val (state, setState) = useState(props.parent.state.value)

    val (rent, setRent) = useState(state.expectedExpense?.rent ?: 0.0)
    val (recurringRent, setRecurringRent) = useState(state.expectedExpense?.recurringRent ?: false)
    val (electricity, setElectricity) = useState(state.expectedExpense?.electricity ?: 0.0)
    val (recurringElectricity, setRecurringElectricity) = useState(state.expectedExpense?.recurringElectricity ?: false)
    val (water, setWater) = useState(state.expectedExpense?.water ?: 0.0)
    val (recurringWater, setRecurringWater) = useState(state.expectedExpense?.recurringWater ?: false)
    val (gas, setGas) = useState(state.expectedExpense?.gas ?: 0.0)
    val (recurringGas, setRecurringGas) = useState(state.expectedExpense?.recurringGas ?: false)
    val (heating, setHeating) = useState(state.expectedExpense?.heating ?: 0.0)
    val (recurringHeating, setRecurringHeating) = useState(state.expectedExpense?.recurringHeating ?: false)
    val (otherLivingExpenses, setOtherLivingExpenses) = useState(state.expectedExpense?.otherLivingExpenses ?: 0.0)
    val (recurringOtherLivingExpenses, setRecurringOtherLivingExpenses) = useState(state.expectedExpense?.recurringOtherLivingExpenses ?: false)
    val (telephone, setTelephone) = useState(state.expectedExpense?.telephone ?: 0.0)
    val (recurringTelephone, setRecurringTelephone) = useState(state.expectedExpense?.recurringTelephone ?: false)
    val (tv, setTv) = useState(state.expectedExpense?.tv ?: 0.0)
    val (recurringTv, setRecurringTv) = useState(state.expectedExpense?.recurringTv ?: false)
    val (internet, setInternet) = useState(state.expectedExpense?.internet ?: 0.0)
    val (recurringInternet, setRecurringInternet) = useState(state.expectedExpense?.recurringInternet ?: false)
    val (medical, setMedical) = useState(state.expectedExpense?.medical ?: 0.0)
    val (recurringMedical, setRecurringMedical) = useState(state.expectedExpense?.recurringMedical ?: false)
    val (food, setFood) = useState(state.expectedExpense?.food ?: 0.0)
    val (recurringFood, setRecurringFood) = useState(state.expectedExpense?.recurringFood ?: false)
    val (transportation, setTransportation) = useState(state.expectedExpense?.transportation ?: 0.0)
    val (recurringTransportation, setRecurringTransportation) = useState(state.expectedExpense?.recurringTransportation ?: false)
    val (sum, setSum) = useState(0.0)

    val prevRentSwitchedRef = useRef(recurringRent)
    val prevElectricitySwitchedRef = useRef(recurringElectricity)
    val prevWaterSwitchedRef = useRef(recurringWater)
    val prevGasSwitchedRef = useRef(recurringGas)
    val prevHeatingSwitchedRef = useRef(recurringHeating)
    val prevOtherLivingExpensesSwitchedRef = useRef(recurringOtherLivingExpenses)
    val prevTelephoneSwitchedRef = useRef(recurringTelephone)
    val prevTvSwitchedRef = useRef(recurringTv)
    val prevInternetSwitchedRef = useRef(recurringInternet)
    val prevMedicalSwitchedRef = useRef(recurringMedical)
    val prevFoodSwitchedRef = useRef(recurringFood)
    val prevTransportationSwitchedRef = useRef(recurringTransportation)

    useEffect(listOf(rent, electricity, water, gas, heating, otherLivingExpenses, telephone, tv, internet, medical, food, transportation)) {
        setSum(rent + electricity + water + gas + heating + otherLivingExpenses + telephone + tv + internet + medical + food + transportation)
    }

    fun updateExpenses() {
        val requestBody = UpdateExpectedExpenseRequest(
            rent = rent,
            recurringRent = recurringRent,
            electricity = electricity,
            recurringElectricity = recurringElectricity,
            water = water,
            recurringWater = recurringWater,
            gas = gas,
            recurringGas = recurringGas,
            heating = heating,
            recurringHeating = recurringHeating,
            otherLivingExpenses = otherLivingExpenses,
            recurringOtherLivingExpenses = recurringOtherLivingExpenses,
            telephone = telephone,
            recurringTelephone = recurringTelephone,
            tv = tv,
            recurringTv = recurringTv,
            internet = internet,
            recurringInternet = recurringInternet,
            medical = medical,
            recurringMedical = recurringMedical,
            food = food,
            recurringFood = recurringFood,
            transportation = transportation,
            recurringTransportation = recurringTransportation
        )
        props.parent.onEvent(PlanningStore.Intent.UpdateExpectedExpense(requestBody))
    }

    useEffect(listOf(recurringRent, recurringElectricity, recurringWater, recurringGas, recurringHeating,
        recurringOtherLivingExpenses, recurringTelephone, recurringTv, recurringInternet, recurringMedical,
        recurringFood, recurringTransportation)) {
        if (prevRentSwitchedRef.current != recurringRent) {
            prevRentSwitchedRef.current = recurringRent
            updateExpenses()
        }
        if (prevElectricitySwitchedRef.current != recurringElectricity) {
            prevElectricitySwitchedRef.current = recurringElectricity
            updateExpenses()
        }
        if (prevWaterSwitchedRef.current != recurringWater) {
            prevWaterSwitchedRef.current = recurringWater
            updateExpenses()
        }
        if (prevGasSwitchedRef.current != recurringGas) {
            prevGasSwitchedRef.current = recurringGas
            updateExpenses()
        }
        if (prevHeatingSwitchedRef.current != recurringHeating) {
            prevHeatingSwitchedRef.current = recurringHeating
            updateExpenses()
        }
        if (prevOtherLivingExpensesSwitchedRef.current != recurringOtherLivingExpenses) {
            prevOtherLivingExpensesSwitchedRef.current = recurringOtherLivingExpenses
            updateExpenses()
        }
        if (prevTelephoneSwitchedRef.current != recurringTelephone) {
            prevTelephoneSwitchedRef.current = recurringTelephone
            updateExpenses()
        }
        if (prevTvSwitchedRef.current != recurringTv) {
            prevTvSwitchedRef.current = recurringTv
            updateExpenses()
        }
        if (prevInternetSwitchedRef.current != recurringInternet) {
            prevInternetSwitchedRef.current = recurringInternet
            updateExpenses()
        }
        if (prevMedicalSwitchedRef.current != recurringMedical) {
            prevMedicalSwitchedRef.current = recurringMedical
            updateExpenses()
        }
        if (prevFoodSwitchedRef.current != recurringFood) {
            prevFoodSwitchedRef.current = recurringFood
            updateExpenses()
        }
        if (prevTransportationSwitchedRef.current != recurringTransportation) {
            prevTransportationSwitchedRef.current = recurringTransportation
            updateExpenses()
        }
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                marginLeft = 10.px
            }
            Typography {
                sx {
                    marginBottom = 10.px
                    color = graphExpenseColor
                    //marginTop = 50.px
                }
                variant = TypographyVariant.h5
                +MainRes.string.planning_expected_expenses
            }
            Typography {
                sx {
                    marginBottom = 10.px
                }
                variant = TypographyVariant.h6
                +MainRes.string.planning_sum.format(sum.toString())
            }
        }
    }

    // Legend
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        Typography {
            sx {
                marginBottom = 10.px
                fontWeight = FontWeight.bold
                width = 70.pct
                marginRight = 5.pct
            }
            variant = TypographyVariant.body1
            +MainRes.string.planning_income_legend
        }
        Typography {
            sx {
                marginBottom = 10.px
                fontWeight = FontWeight.bold
                width = 25.pct
            }
            variant = TypographyVariant.body1
            +MainRes.string.planning_income_legend_switch
        }
    }

    // Rent
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (rent == 0.0) 0 else rent.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_rent)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setRent(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringRent
            onChange = { e, checked ->
                setRecurringRent(checked)
            }
        }
    }

    // Electricity
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (electricity == 0.0) 0 else electricity.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_electricity)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setElectricity(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
        }
        Switch {
            sx {}
            checked = recurringElectricity
            onChange = { e, checked ->
                setRecurringElectricity(checked)
            }
        }
    }

    // Water
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (water == 0.0) 0 else water.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_water)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setWater(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringWater
            onChange = { e, checked ->
                setRecurringWater(checked)
            }
        }
    }

    // Gas
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (gas == 0.0) 0 else gas.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_gas)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setGas(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringGas
            onChange = { e, checked ->
                setRecurringGas(checked)
            }
        }
    }

    // Heating
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (heating == 0.0) 0 else heating.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_heating)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setHeating(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringHeating
            onChange = { e, checked ->
                setRecurringHeating(checked)
            }
        }
    }

    // Other Living Expenses
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (otherLivingExpenses == 0.0) 0 else otherLivingExpenses.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_otherLivingExpenses)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setOtherLivingExpenses(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringOtherLivingExpenses
            onChange = { e, checked ->
                setRecurringOtherLivingExpenses(checked)
            }
        }
    }

    // Telephone
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (telephone == 0.0) 0 else telephone.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_telephone)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setTelephone(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringTelephone
            onChange = { e, checked ->
                setRecurringTelephone(checked)
            }
        }
    }

    // Tv
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (tv == 0.0) 0 else tv.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_tv)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setTv(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringTv
            onChange = { e, checked ->
                setRecurringTv(checked)
            }
        }
    }

    // Internet
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (internet == 0.0) 0 else internet.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_internet)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setInternet(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringInternet
            onChange = { e, checked ->
                setRecurringInternet(checked)
            }
        }
    }

    // Medical
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (medical == 0.0) 0 else medical.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_medical)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setMedical(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringMedical
            onChange = { e, checked ->
                setRecurringMedical(checked)
            }
        }
    }

    // Food
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (food == 0.0) 0 else food.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_food)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setFood(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringFood
            onChange = { e, checked ->
                setRecurringFood(checked)
            }
        }
    }

    // Transportation
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (transportation == 0.0) 0 else transportation.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_expense_transportation)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setTransportation(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateExpenses()
            }
        }
        Switch {
            sx {}
            checked = recurringTransportation
            onChange = { e, checked ->
                setRecurringTransportation(checked)
            }
        }
    }
}
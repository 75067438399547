package com.erstedigital.socialbank.data.network.models.request

import kotlinx.serialization.Serializable

@Serializable
data class DeleteTransactionRequest(
    val transactions: List<Transaction>
) {
    @Serializable
    data class Transaction(
        val id: Long
    )
}
package com.erstedigital.socialbank.ui.onboarding.utils

import com.erstedigital.socialbank.MainRes
import io.github.skeptick.libres.images.Image

class OnBoardingItems(
    val image: Image,
    val title: String,
    val desc: String
) {
    companion object{
        fun getData(): List<OnBoardingItems>{
            return listOf(
                OnBoardingItems(MainRes.image.feature_image, MainRes.string.feature_title_scanning, MainRes.string.feature_description_scanning),
                OnBoardingItems(MainRes.image.feature_image, MainRes.string.feature_title_statistics, MainRes.string.feature_description_statistics),
                OnBoardingItems(MainRes.image.feature_image, MainRes.string.feature_title_split, MainRes.string.feature_description_split)
            )
        }
    }
}
package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import com.erstedigital.socialbank.utils.ProductCategoryMapper
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import kotlin.math.roundToInt

external interface CategoryItemProps: Props {
    var category: String
    var onCategoryClick: (String) -> Unit
}

val CategoryItem: FC<CategoryItemProps> = FC { props ->

    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 10.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
            cursor = Cursor.pointer
        }

        elevation = 2

        CardContent {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
            }

            onClick = { props.onCategoryClick(props.category) }

            Typography {
                variant = TypographyVariant.body1
                +ProductCategoryMapper.map(props.category)
            }


        }
    }

}
package com.erstedigital.socialbank.ui.planning.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedExpenseRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedIncomeRequest
import com.erstedigital.socialbank.domain.models.ExpectedExpenseModel
import com.erstedigital.socialbank.domain.models.ExpectedIncomeModel
import com.erstedigital.socialbank.domain.usecase.planning.GetExpectedExpenseUsecase
import com.erstedigital.socialbank.domain.usecase.planning.GetExpectedIncomeUsecase
import com.erstedigital.socialbank.domain.usecase.planning.UpdateExpectedExpenseUsecase
import com.erstedigital.socialbank.domain.usecase.planning.UpdateExpectedIncomeUsecase
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class PlanningStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val getExpectedIncomeUsecase by inject<GetExpectedIncomeUsecase>()
    private val updateExpectedIncomeUsecase by inject<UpdateExpectedIncomeUsecase>()
    private val getExpectedExpenseUsecase by inject<GetExpectedExpenseUsecase>()
    private val updateExpectedExpenseUsecase by inject<UpdateExpectedExpenseUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): PlanningStore = object : PlanningStore, Store<PlanningStore.Intent, PlanningStore.State, PlanningStore.Label> by storeFactory.create(
        name = "PlanningStore",
        initialState = PlanningStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchExpectedIncome)
                dispatch(Action.FetchExpectedExpense)
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class ExpectedIncomeData(val expectedIncome: ExpectedIncomeModel): Msg()
        data class ExpectedExpenseData(val expectedExpense: ExpectedExpenseModel): Msg()
    }

    private sealed class Action {
        data object FetchExpectedIncome: Action()
        data object FetchExpectedExpense: Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<PlanningStore.Intent, Action, PlanningStore.State, Msg, PlanningStore.Label>() {

        override fun executeAction(action: Action, getState: () -> PlanningStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchExpectedIncome -> getExpectedIncome()
                    is Action.FetchExpectedExpense -> getExpectedExpense()
                }
            }
        }

        private suspend fun getExpectedIncome () {
            dispatch(Msg.Loading)
            val result = getExpectedIncomeUsecase.getExpectedIncome()
            dispatch(Msg.ExpectedIncomeData(expectedIncome = result))
        }

        private suspend fun getExpectedExpense () {
            dispatch(Msg.Loading)
            val result = getExpectedExpenseUsecase.getExpectedExpense()
            dispatch(Msg.ExpectedExpenseData(expectedExpense = result))
        }


        override fun executeIntent(intent: PlanningStore.Intent, getState: () -> PlanningStore.State) {
            val state = getState()
            when (intent) {
                is PlanningStore.Intent.UpdateExpectedIncome -> updateExpectedIncome (
                    intent.expectedIncome
                )
                is PlanningStore.Intent.UpdateExpectedExpense -> updateExpectedExpense(
                    intent.expectedExpense
                )

                else -> {}
            }
        }

        fun updateExpectedIncome(expectedIncome: UpdateExpectedIncomeRequest) {
            scope.launch {
                dispatch(Msg.Loading)
                val result = updateExpectedIncomeUsecase.updateExpectedIncome(expectedIncome)
                dispatch(Msg.ExpectedIncomeData(expectedIncome = result))
            }
        }

        fun updateExpectedExpense(expectedExpense: UpdateExpectedExpenseRequest) {
            scope.launch {
                dispatch(Msg.Loading)
                val result = updateExpectedExpenseUsecase.updateExpectedExpense(expectedExpense)
                dispatch(Msg.ExpectedExpenseData(expectedExpense = result))
            }
        }
    }

    private object ReducerImpl: Reducer<PlanningStore.State, Msg> {
        override fun PlanningStore.State.reduce(msg: Msg): PlanningStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.ExpectedIncomeData -> copy(loading = false, expectedIncome = msg.expectedIncome, error = null)
            is Msg.ExpectedExpenseData -> copy(loading = false, expectedExpense = msg.expectedExpense, error = null)
            else -> copy()
        }
    }
}
package com.erstedigital.socialbank.ui.transactions.manual

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.ui.transactions.manual.store.ManualTransactionStore
import com.erstedigital.socialbank.ui.transactions.manual.store.ManualTransactionStoreFactory
import com.erstedigital.socialbank.ui.transactions.products.store.ProductsStore
import com.erstedigital.socialbank.ui.transactions.products.store.ProductsStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.StateFlow

class ManualTransactionComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
): ComponentContext by componentContext {
    private val manualTransactionStore = instanceKeeper.getStore {
        ManualTransactionStoreFactory(storeFactory).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<ManualTransactionStore.State> = manualTransactionStore.stateFlow

    fun onEvent(event: ManualTransactionStore.Intent) {
        manualTransactionStore.accept(event)
    }
    sealed class Output {
        object NavigateBack : Output()
    }

}
package com.erstedigital.socialbank.ui.dashboard.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.domain.models.DashboardModel
import com.erstedigital.socialbank.domain.models.StatisticsModel

interface DashboardStore:
    Store<DashboardStore.Intent, DashboardStore.State, DashboardStore.Label> {

    sealed class Intent {

        data object SignOut: Intent()
    }

    data class State(
        val loading: Boolean = true,
        val error: String? = null,
        val dashboard: DashboardModel? = null,
    )

    sealed interface Label {

        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }
}
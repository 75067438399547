package com.erstedigital.overdokladapi

import com.erstedigital.overdokladapi.data.request.ReceiptFindRequest
import com.erstedigital.overdokladapi.data.request.ReceiptReportRequest
import com.erstedigital.overdokladapi.data.response.BaseResponse
import com.erstedigital.overdokladapi.data.response.ReceiptFindResponse
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.logging.DEFAULT
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.client.utils.EmptyContent.contentType
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.URLProtocol
import io.ktor.http.contentType
import io.ktor.http.path
import io.ktor.util.InternalAPI
import kotlinx.serialization.json.Json

class OverDokladApi {


    private val client = getClient(true)

    @OptIn(InternalAPI::class)
    suspend fun find(receiptId: String): ReceiptFindResponse? {
        return try {
            val response: HttpResponse = client.post{
                contentType(ContentType.Application.Json)
                url {
                    path("receipt/find")
                }
                setBody(ReceiptFindRequest(receiptId))
            }
            if (response.status == HttpStatusCode.OK) {
                response.body<ReceiptFindResponse>()
            } else {
                Napier.e("Error: ${response.status}")
                null
            }
        } catch (e: Exception) {
            Napier.e("Exception: ${e.message}")
            null
        }
    }

    @OptIn(InternalAPI::class)
    suspend fun report(request: ReceiptReportRequest): BaseResponse? {
        return try {
            val response: HttpResponse = client.post("${Constants.OVER_DOKLAD_API_URL}/search/set") {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status == HttpStatusCode.OK) {
                return response.body()
            } else {
                null // or handle error differently
            }
        } catch (e: Exception) {
            null // or handle error differently
        }
    }

}
package com.erstedigital.socialbank.data.datasources.split

import com.erstedigital.socialbank.data.network.models.common.UserTransaction
import com.erstedigital.socialbank.data.network.models.request.CreateSplitRequest
import com.erstedigital.socialbank.data.network.models.request.UserTransactionSettleRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.path

class SplitRemoteDataSource constructor(private val httpClient: HttpClient): SplitDataSource.Remote {
    override suspend fun createSplit(request: CreateSplitRequest): ApiResponse<TransactionResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("splits")
            }
            setBody(request)
        }
    }

    override suspend fun deleteSplit(id: Long): ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Delete
                path("splits/$id")
            }
        }
    }

    override suspend fun updateSettle(request: UserTransactionSettleRequest): ApiResponse<UserTransaction, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Delete
                path("user-transactions/${request.id}/settle")
            }
            setBody(request)
        }
    }

}
package com.erstedigital.overdokladapi

import io.ktor.client.HttpClient
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.addDefaultResponseValidation
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logger
import io.ktor.client.plugins.logging.Logging
import io.ktor.http.ContentType
import io.ktor.http.HttpHeaders
import io.ktor.http.URLProtocol
import io.ktor.http.append
import io.ktor.http.headers
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
external val navigator: dynamic

class JSPlatform : Platform {
    override val name: String
        get() = "${navigator.appName} ${navigator.appVersion}"
}

actual fun getPlatform(): Platform = JSPlatform()

actual fun getClient(enableNetworkLogs: Boolean): HttpClient {
    return HttpClient(Js) {

        install(ContentNegotiation) {
            expectSuccess = true
            addDefaultResponseValidation()


            json(
                Json {
                    ignoreUnknownKeys = true
                    isLenient = true
                }
            )
        }

        defaultRequest {
            url {
                protocol = URLProtocol.HTTPS
                host = Constants.OVER_DOKLAD_API_URL
            }
            headers {
                append(HttpHeaders.ContentType, ContentType.Application.Json)
            }
        }

    }
}
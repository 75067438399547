package com.erstedigital.socialbank.ui.verify_code.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.erstedigital.socialbank.data.network.models.request.LoginAnonymousRequest
import com.erstedigital.socialbank.data.network.models.request.LoginRequest
import com.erstedigital.socialbank.data.network.models.request.VerifyCodeRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.GoogleSignIn
import com.erstedigital.socialbank.domain.usecase.auth.LoginAnonymousUsecase
import com.erstedigital.socialbank.domain.usecase.auth.LoginIdpUsecase
import com.erstedigital.socialbank.domain.usecase.auth.LoginUsecase
import com.erstedigital.socialbank.domain.usecase.auth.VerifyCodeUsecase
import com.erstedigital.socialbank.ui.login.store.LoginStore
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class VerifyCodeStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val loginUsecase by inject<LoginUsecase>()
    private val verifyCodeUsecase by inject<VerifyCodeUsecase>()

    fun create(): VerifyCodeStore = object : VerifyCodeStore, Store<VerifyCodeStore.Intent, VerifyCodeStore.State, VerifyCodeStore.Label> by storeFactory.create(
        name = "VerifyCodeStore",
        initialState = VerifyCodeStore.State(),
        bootstrapper = SimpleBootstrapper(Unit),
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data object CodeAccepted : Msg()

        data class Error(val message: String): Msg()
    }

    private inner class ExecutorImpl: CoroutineExecutor<VerifyCodeStore.Intent, Unit, VerifyCodeStore.State, Msg, VerifyCodeStore.Label>() {

        override fun executeAction(action: Unit, getState: () -> VerifyCodeStore.State) {
            super.executeAction(action, getState)
        }

        override fun executeIntent(intent: VerifyCodeStore.Intent, getState: () -> VerifyCodeStore.State) {
            when (intent) {
                is VerifyCodeStore.Intent.Verify -> verifyCode(intent.code, intent.username)
            }
        }

        private fun verifyCode(code: String, username: String) {
            dispatch(Msg.Loading)
            scope.launch {
                val res = Firebase.auth.signInAnonymously()
                when (val result = verifyCodeUsecase.verifyCode(VerifyCodeRequest(
                    code = code,
                    fcmToken = res.user?.getIdToken(false),
                    isSocialBank = true,
                    login = username
                ))) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.CodeAccepted)
                        publish(VerifyCodeStore.Label.VerificationSuccess)
                    }
                    is ApiResponse.Error -> {
                        dispatch(Msg.Error(result.toString()))
                        publish(VerifyCodeStore.Label.VerificationFailure)
                    }
                }
            }
        }

    }

    private object ReducerImpl: Reducer<VerifyCodeStore.State, Msg> {
        override fun VerifyCodeStore.State.reduce(msg: Msg): VerifyCodeStore.State = when (msg) {
            is Msg.CodeAccepted -> copy(loading = false, error = null, loggedIn = true)
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
        }
    }
}
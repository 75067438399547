package com.erstedigital.socialbank.data.datasources.planning

import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedExpenseRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedIncomeRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.ExpectedExpenseResponse
import com.erstedigital.socialbank.data.network.models.response.ExpectedIncomeResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.http.*

class PlanningRemoteDataSource constructor(private val httpClient: HttpClient): PlanningDataSource.Remote {
    override suspend fun getExpectedIncome(): ApiResponse<ExpectedIncomeResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("expectedIncome")
            }
        }
    }

    override suspend fun updateExpectedIncome(request: UpdateExpectedIncomeRequest): ApiResponse<ExpectedIncomeResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Put
                path("expectedIncome")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }
    }

    override suspend fun getExpectedExpenses(): ApiResponse<ExpectedExpenseResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("expectedExpense")
            }
        }
    }

    override suspend fun updateExpectedExpenses(request: UpdateExpectedExpenseRequest): ApiResponse<ExpectedExpenseResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Put
                path("expectedExpense")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }
    }
}
package com.erstedigital.socialbank.data.datasources.product

import com.erstedigital.socialbank.data.network.models.request.UpdateProductRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import io.ktor.http.path


class ProductRemoteDataSource constructor(private val httpClient: HttpClient): ProductDataSource.Remote {

    override suspend fun updateProductCategory(request: UpdateProductRequest): ApiResponse<UpdateProductResponse, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Put
                path("products/category")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }
}
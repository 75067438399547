package com.erstedigital.socialbank.ui.react.transactions

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.GroupedTransactionsModel
import com.erstedigital.socialbank.ui.react.transactions.components.TransactionGroupItem
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.pct
import web.cssom.px

external interface TransactionListContentProps: Props {
    var transactions: List<GroupedTransactionsModel>?
    var onTransactionClick: (Long) -> Unit
}

val TransactionListContent: FC<TransactionListContentProps> = FC { props ->

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            paddingBottom = 90.px
            height = 100.pct
            maxHeight = 100.pct
        }
        Typography {
            sx {
                marginBottom = 10.px
                width = 100.pct
            }
            variant = TypographyVariant.h4
            +MainRes.string.transactions
        }

        props.transactions?.map { transaction ->
            TransactionGroupItem {
                groupedTransactionsModel = transaction
                onTransactionClick = props.onTransactionClick
            }
        }
    }
}
package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class ExpectedExpenseModel (
    var rent: Double?,
    var recurringRent: Boolean?,
    var electricity: Double?,
    var recurringElectricity: Boolean?,
    var water: Double?,
    var recurringWater: Boolean?,
    var gas: Double?,
    var recurringGas: Boolean?,
    var heating: Double?,
    var recurringHeating: Boolean?,
    var otherLivingExpenses: Double?,
    var recurringOtherLivingExpenses: Boolean?,
    var telephone: Double?,
    var recurringTelephone: Boolean?,
    var tv: Double?,
    var recurringTv: Boolean?,
    var internet: Double?,
    var recurringInternet: Boolean?,
    var medical: Double?,
    var recurringMedical: Boolean?,
    var food: Double?,
    var recurringFood: Boolean?,
    var transportation: Double?,
    var recurringTransportation: Boolean?,
    var date: LocalDate?
)
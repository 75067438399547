package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDate

data class GroupedTransactionsModel(
    val date: LocalDate,
    val transactions: List<TransactionListModel> = emptyList()
) {

    val income: Double by lazy {
        transactions.sumByDouble {
            if (it.price != null && it.price > 0.0) {
                it.price
            } else {
                0.0
            }
        }
    }

    val expense: Double by lazy {
        transactions.sumByDouble {
            if (it.price != null && it.price < 0.0) {
                it.price
            } else {
                0.0
            }
        }
    }
}
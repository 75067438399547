package com.erstedigital.socialbank.ui.documents.details

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.documents.details.store.DetailsStore
import com.erstedigital.socialbank.ui.documents.details.store.DetailsStoreFactory
import com.erstedigital.socialbank.ui.documents.store.DmsStore
import com.erstedigital.socialbank.ui.documents.store.DmsStoreFactory
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStore
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class DetailsComponent internal constructor(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val documentId: Long?
): ComponentContext by componentContext {
    private val detailStore = instanceKeeper.getStore {
        DetailsStoreFactory(storeFactory, documentId).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<DetailsStore.State> = detailStore.stateFlow

    val label: Flow<DetailsStore.Label> = detailStore.labels

    fun onEvent(event: DetailsStore.Intent) {
        detailStore.accept(event)
    }

    fun onOutput(output: Output){
        when (output) {
            else -> {}
        }
    }


    sealed class Output {
    }
}
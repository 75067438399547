package com.erstedigital.socialbank.strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.formatString
import io.github.skeptick.libres.strings.getPluralizedString
import kotlin.Int
import kotlin.String

public class WelcomeMessage(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class YourCurrentBalanceMessage(
  private val `value`: String,
) {
  public fun format(balance: String): String = formatString(value, arrayOf(balance))
}

public class VerifyCodeMessage(
  private val `value`: String,
) {
  public fun format(user: String): String = formatString(value, arrayOf(user))
}

public class UserNo(
  private val `value`: String,
) {
  public fun format(user: String): String = formatString(value, arrayOf(user))
}

public class ProductsCount(
  private val `value`: String,
) {
  public fun format(count: String): String = formatString(value, arrayOf(count))
}

public class WeekNoYear(
  private val `value`: String,
) {
  public fun format(
    from: String,
    til: String,
    fromd: String,
    tild: String,
  ): String = formatString(value, arrayOf(from,til,fromd,tild))
}

public class SplitWith(
  private val `value`: String,
) {
  public fun format(count: String, price: String): String = formatString(value,
      arrayOf(count,price))
}

public class IsOwed(
  private val `value`: String,
) {
  public fun format(sum: String): String = formatString(value, arrayOf(sum))
}

public class Owes(
  private val `value`: String,
) {
  public fun format(sum: String): String = formatString(value, arrayOf(sum))
}

public class LabelSettlementTitle(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class LabelSettlement(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class LeaderboardTitle(
  private val `value`: String,
) {
  public fun format(count: String, place: String): String = formatString(value,
      arrayOf(count,place))
}

public class IsOwedYou(
  private val `value`: String,
) {
  public fun format(sum: String): String = formatString(value, arrayOf(sum))
}

public class OwesYou(
  private val `value`: String,
) {
  public fun format(sum: String): String = formatString(value, arrayOf(sum))
}

public class SplitFrom(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class IcoFormat(
  private val `value`: String,
) {
  public fun format(ico: String): String = formatString(value, arrayOf(ico))
}

public class SendTo(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class SettlementWith(
  private val `value`: String,
) {
  public fun format(name: String, sum: String): String = formatString(value, arrayOf(name,sum))
}

public class IbanFor(
  private val `value`: String,
) {
  public fun format(name: String): String = formatString(value, arrayOf(name))
}

public class MessageSettlement(
  private val `value`: String,
) {
  public fun format(
    name: String,
    iban: String,
    amount: String,
    link: String,
  ): String = formatString(value, arrayOf(name,iban,amount,link))
}

public class SelectedNo(
  private val `value`: String,
) {
  public fun format(no: String): String = formatString(value, arrayOf(no))
}

public class ShowWeek(
  private val `value`: String,
) {
  public fun format(week: String, of: String): String = formatString(value, arrayOf(week,of))
}

public class ShowMonth(
  private val `value`: String,
) {
  public fun format(month: String, no: String): String = formatString(value, arrayOf(month,no))
}

public class TotalPrice(
  private val `value`: String,
) {
  public fun format(price: String): String = formatString(value, arrayOf(price))
}

public class OwesTo(
  private val `value`: String,
) {
  public fun format(sum: String, name: String): String = formatString(value, arrayOf(sum,name))
}

public class IsOwedFrom(
  private val `value`: String,
) {
  public fun format(sum: String, name: String): String = formatString(value, arrayOf(sum,name))
}

public class DashboardBalance(
  private val `value`: String,
) {
  public fun format(balance: String): String = formatString(value, arrayOf(balance))
}

public class PlanningSum(
  private val `value`: String,
) {
  public fun format(sum: String): String = formatString(value, arrayOf(sum))
}

public class NoUploadDays(
  private val forms: PluralForms,
  private val languageCode: String,
) {
  public fun format(number: Int, count: String): String = formatString(getPluralizedString(forms,
      languageCode, number), arrayOf(count))
}

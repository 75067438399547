package com.erstedigital.socialbank.data.network.models.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VerifyCodeResponse(
    @SerialName("app_user") val user: LoginIdpResponse.User?,
    @SerialName("id_token") val token: String?
) {

}
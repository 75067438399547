package com.erstedigital.socialbank.domain.usecase.planning

import com.erstedigital.socialbank.data.datasources.planning.PlanningRemoteDataSource
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.ExpectedExpenseModel

class GetExpectedExpenseUsecase (
    private val planningRemoteDataSource: PlanningRemoteDataSource
) {
    suspend fun getExpectedExpense() : ExpectedExpenseModel {
        planningRemoteDataSource.getExpectedExpenses()
        var expectedExpenseModel  = ExpectedExpenseModel(
            rent = null,
            recurringRent = null,
            electricity = null,
            recurringElectricity = null,
            water = null,
            recurringWater = null,
            gas = null,
            recurringGas = null,
            heating = null,
            recurringHeating = null,
            otherLivingExpenses = null,
            recurringOtherLivingExpenses = null,
            telephone = null,
            recurringTelephone = null,
            tv = null,
            recurringTv = null,
            internet = null,
            recurringInternet = null,
            medical = null,
            recurringMedical = null,
            food = null,
            recurringFood = null,
            transportation = null,
            recurringTransportation = null,
            date = null
        )
        return when (val networkRes = planningRemoteDataSource.getExpectedExpenses()) {
            is ApiResponse.Success -> {
                expectedExpenseModel = networkRes.body.toModel()
                expectedExpenseModel
            }

            is ApiResponse.Error -> {
                expectedExpenseModel
            }
        }
    }

}
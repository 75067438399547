package com.erstedigital.socialbank.data.network.models.request

import kotlinx.serialization.Serializable

@Serializable
data class UpdateExpectedIncomeRequest (
    var income: Double?,
    var recurringIncome: Boolean?,
    var businessIncome: Double?,
    var recurringBusinessIncome: Boolean?,
    var socialBenefits: Double?,
    var recurringSocialBenefits: Boolean?,
    var otherIncome: Double?,
    var recurringOtherIncome: Boolean?
)
package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.entity.toEntity
import com.erstedigital.socialbank.data.network.models.request.LoginRequest
import com.erstedigital.socialbank.data.network.models.request.RegisterRequest
import com.erstedigital.socialbank.data.network.models.request.VerifyCodeRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import io.github.aakira.napier.Napier
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.util.Identity
import kotlin.js.JsName


class RegisterUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    @JsName("userRemoteDataSource") private val userRemoteDataSource: UserRemoteDataSource,
    private val httpClient: HttpClient
) {
    suspend fun register(registerRequest: RegisterRequest): Boolean {
        val remoteResult = userRemoteDataSource.register(registerRequest)

        return when (remoteResult) {
            is ApiResponse.Success -> {
                true
            }

            is ApiResponse.Error -> {
                false
            }
        }
    }
}
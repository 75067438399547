package com.erstedigital.socialbank.domain.usecase.dashboard

import com.erstedigital.socialbank.data.datasources.dashboard.DashboardDataSource
import com.erstedigital.socialbank.data.datasources.dashboard.DashboardRemoteDataSource
import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.entity.toUser
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.StatisticsResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.onSuccess
import com.erstedigital.socialbank.domain.models.DashboardModel
import com.erstedigital.socialbank.domain.models.StatisticsModel
import kotlin.math.log

class GetDashboardUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val dashboardRemoteDataSource: DashboardRemoteDataSource
) {
    suspend fun getDashboard(): DashboardModel {

        val localRes = userLocalDataSource.getUser()?.toUser()
        var dashboardModel = DashboardModel(
            balance = null,
            appUser = localRes
        )

        return when (val networkRes = dashboardRemoteDataSource.getDashboard()) {
            is ApiResponse.Success -> {
                dashboardModel = networkRes.body.toModel()
                dashboardModel
            }

            is ApiResponse.Error -> {
                dashboardModel
            }
        }
    }
}
package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.useMemo
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.pct
import web.cssom.px

external interface OrgUnitInfoCardProps: Props {
    var transaction: TransactionModel?
}

val OrgUnitInfoCard: FC<OrgUnitInfoCardProps> = FC { props ->

    val details = useMemo(props.transaction) {
        val details = ArrayList<Pair<String, String>>()

        props.transaction?.fsReceiptModel?.orgUnit?.name?.let { details.add(MainRes.string.name to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.streetName?.let { details.add(MainRes.string.street_name to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.buildingNumber?.let { details.add(MainRes.string.building_number to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.cashRegisterCode?.let { details.add(MainRes.string.cash_register_code to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.country?.let { details.add(MainRes.string.country to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.municipality?.let { details.add(MainRes.string.municipality to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.postalCode?.let { details.add(MainRes.string.postal_code to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.type?.let { details.add(MainRes.string.unit_type to it) }
        props.transaction?.fsReceiptModel?.orgUnit?.propertyRegistrationNumber?.let { details.add(MainRes.string.property_registration_number to it) }

        details
    }

    Box{
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
        }

        Typography {
            sx {
                width = 60.pct
            }
            +MainRes.string.unit_details
        }

        Card {
            sx {
                marginLeft = 3.px
                marginRight = 3.px
                marginBottom = 20.px
                marginTop = 4.px
                backgroundColor = surfaceColor
                borderRadius = cardBorderRadius
            }

            elevation = 2

            CardContent {

                details.map {
                    InfoRow {
                        label = it.first
                        value = it.second
                    }
                }

            }
        }

    }
}
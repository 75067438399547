package com.erstedigital.socialbank.ui.react.transactions

import com.erstedigital.socialbank.ui.transactions.TransactionsComponent
import com.erstedigital.socialbank.ui.transactions.store.TransactionStore
import com.erstedigital.socialbank.ui.utils.useAsState
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.Box
import mui.material.CircularProgress
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.*

external interface TransactionsComponentProps: Props {
    var component: TransactionsComponent
    var onSubpageClicked: (Boolean) -> Unit
}

val TransactionsContent: FC<TransactionsComponentProps> = FC { props ->

    val (childStack, _) = props.component.childStack.useAsState()
    val (state, setState) = useState(props.component.state.value)


    useEffect{
        val job = props.component.state.onEach {
            setState(it)
        }.launchIn(GlobalScope)
    }

    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 90.pct
                height = 100.pct
                maxWidth = 600.px
                maxHeight = 100.pct
                paddingTop = 25.px
            }
            when (val child = childStack.active.instance) {
                is TransactionsComponent.Child.Undefined -> {
                    TransactionListContent {
                        onTransactionClick = { transaction ->
                            props.onSubpageClicked(false)
                            props.component.onEvent(TransactionStore.Intent.SelectTransaction(transaction))
                            props.component.onOutput(
                                TransactionsComponent.Output.NavigateToDetails(transaction)
                            )
                        }
                        transactions = state.groupedTransactions
                    }
                }
                is TransactionsComponent.Child.Details -> {
                    TransactionDetailsContent {
                        transaction = state.selectedTransaction
                        onBack = { showNavbar ->
                            props.onSubpageClicked(showNavbar)
                            props.component.onEvent(TransactionStore.Intent.ClearSelectTransaction)
                            props.component.onEvent(TransactionStore.Intent.RefreshTransactions)
                            props.component.onOutput(TransactionsComponent.Output.NavigateBack)
                        }
                        onTransactionDelete = {
                            props.component.onEvent(TransactionStore.Intent.DeleteTransaction(state.selectedTransaction!!.id))
                        }
                        onTransactionEdit = {
                            transaction -> props.component.onEvent(TransactionStore.Intent.UpdateManualTransaction(transaction))
                            props.component.onOutput(TransactionsComponent.Output.NavigateBack)
                            props.component.onEvent(TransactionStore.Intent.ClearSelectTransaction)
                            props.component.onEvent(TransactionStore.Intent.RefreshTransactions)
                        }
                        onProductsClick = { products -> props.component.onOutput(TransactionsComponent.Output.NavigateToProducts) }
                        onCategoryChanged = {category -> props.component.onEvent(TransactionStore.Intent.UpdateCategory(category))}
                    }
                }
                is TransactionsComponent.Child.Products -> {
                    ProductsContent {
                        products = state.selectedTransaction?.fsReceiptModel?.items
                        onBack = {
                            props.onSubpageClicked(false)
                            props.component.onOutput(TransactionsComponent.Output.NavigateBack) }
                        onProductClicked = { product -> props.component.onOutput(TransactionsComponent.Output.NavigateToCategories(product))}
                    }
                }
                is TransactionsComponent.Child.Categories -> {
                    CategoriesContent {
                        selectedProduct = child.product
                        categoryUpdated = state.categoryUpdate
                        onUpdateCategory = { category ->
                            props.component.onEvent(TransactionStore.Intent.UpdateProductCategory(child.product.product?.id!!, category))
                        }
                        onBack = {
                            props.component.onEvent(TransactionStore.Intent.SelectTransaction(state.selectedTransaction!!.id))
                            props.onSubpageClicked(false)
                            props.component.onOutput(TransactionsComponent.Output.NavigateBack)
                        }
                    }
                }
            }
        }
    }


}
package com.erstedigital.socialbank.data.network.models.request

import kotlinx.serialization.Serializable

@Serializable
data class UpdateProfileRequest(
    val id: Long,
    val fullName: String? = null,
    val iban: String? = null,
    val phoneNumber: String? = null,
    val color: String? = null,
    val allowAnalysis: Boolean? = null,
    val fcmToken: String? = null
)
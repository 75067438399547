package com.erstedigital.socialbank.ui.onboarding.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.erstedigital.socialbank.data.entity.toEntity
import com.erstedigital.socialbank.data.network.models.request.LoginAnonymousRequest
import com.erstedigital.socialbank.data.network.models.request.LoginRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.di.getFcmToken
import com.erstedigital.socialbank.domain.usecase.auth.LoginAnonymousUsecase
import com.erstedigital.socialbank.domain.usecase.auth.LoginUsecase
import com.erstedigital.socialbank.ui.login.store.LoginStore
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class OnboardingStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val loginAnonymousUsecase by inject<LoginAnonymousUsecase>()

    fun create(): OnboardingStore = object : OnboardingStore, Store<OnboardingStore.Intent, OnboardingStore.State, OnboardingStore.Label> by storeFactory.create(
        name = "OnboardingStore",
        initialState = OnboardingStore.State(),
        bootstrapper = SimpleBootstrapper(Unit),
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data object LoggedIn : Msg()
        data class Error(val message: String): Msg()
    }

    private inner class ExecutorImpl: CoroutineExecutor<OnboardingStore.Intent, Unit, OnboardingStore.State, Msg, OnboardingStore.Label>() {

        override fun executeAction(action: Unit, getState: () -> OnboardingStore.State) {
            super.executeAction(action, getState)
        }

        override fun executeIntent(intent: OnboardingStore.Intent, getState: () -> OnboardingStore.State) {
            when (intent) {
                is OnboardingStore.Intent.AnonymousLogin -> loginAnonymous()
            }
        }

        private fun loginAnonymous() {
            dispatch(Msg.Loading)
            scope.launch {
                try {
                    val token = getFcmToken()
                    when (val result = loginAnonymousUsecase.signIn(LoginAnonymousRequest(token, true),)) {
                        is ApiResponse.Success -> {
                            withContext(Dispatchers.Main) {
                                println("authorize success")
                                publish(OnboardingStore.Label.SignInSuccess(result.body.user?.toEntity()!!))
                            }
                        }
                        is ApiResponse.Error.HttpError -> {
                            dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                        }
                        is ApiResponse.Error.GenericError -> {
                            dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                        }
                        is ApiResponse.Error.SerializationError -> {
                            dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                        }
                        else -> {}
                    }
                } catch (e: Exception) {
                    dispatch(Msg.Error(e.message ?: "Unknown error"))
                }
            }
        }
    }

    private object ReducerImpl: Reducer<OnboardingStore.State, Msg> {
        override fun OnboardingStore.State.reduce(msg: Msg): OnboardingStore.State = when (msg) {
            is Msg.LoggedIn -> copy(loading = false, loggedIn = true)
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
        }
    }
}
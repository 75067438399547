package com.erstedigital.socialbank.ui.react.root

import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import com.erstedigital.socialbank.theme.md_theme_dark_error
import com.erstedigital.socialbank.ui.home.HomeScreen
import com.erstedigital.socialbank.ui.react.home.HomeContent
import com.erstedigital.socialbank.ui.react.login.LoginContent
import com.erstedigital.socialbank.ui.react.onboarding.OnboardingContent
import com.erstedigital.socialbank.ui.react.verify_code.VerifyCodeContent
import com.erstedigital.socialbank.ui.root.RootComponent
import com.erstedigital.socialbank.ui.utils.RProps
import com.erstedigital.socialbank.ui.utils.componentContent
import com.erstedigital.socialbank.ui.utils.useAsState
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.system.sx
import react.FC
import mui.material.Box
import mui.material.CircularProgress
import react.useEffect
import react.useState
import web.cssom.*

@OptIn(ExperimentalComposeUiApi::class)
var RootContent: FC<RProps<RootComponent>> = FC { props ->

    val (childStack, _) = props.component.childStack.useAsState()
    val (state, setState) = useState(props.component.state.value)

    useEffect{
        val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }


    useEffect(state) {
        if (!state.loading && state.userSignedIn) {
            props.component.onOutput(RootComponent.Output.OpenContent)
        } else if (!state.loading && !state.userSignedIn) {
            props.component.onOutput(RootComponent.Output.OpenAuthorizationFlow)
        }
    }


    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            height = 100.pct
            minHeight = 100.pct
            flexGrow = number(1.0)
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                height = 100.pct
                minHeight = 100.pct
                flexGrow = number(1.0)
                width = 100.pct
            }

            when (val child = childStack.active.instance) {
                is RootComponent.Child.Login -> componentContent(component = child.component, content = LoginContent)
                is RootComponent.Child.Home -> {
                    componentContent(component = child.component, content = HomeContent)
                }
                is RootComponent.Child.VerifyCode -> componentContent(component = Pair(child.component, child.username), content = VerifyCodeContent)
                is RootComponent.Child.Undefined -> {
                    CircularProgress {
                        sx {
                            position = Position.absolute
                            top = 50.pct
                            left = 50.pct
                        }
                    }
                }
                is RootComponent.Child.Onboarding -> {
                    componentContent(component = child.component, content = OnboardingContent)
                }

                else -> {}
            }
        }
    }

}

package com.erstedigital.socialbank.ui.documents.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.network.models.common.Document

interface DmsStore: Store<DmsStore.Intent, DmsStore.State, DmsStore.Label> {

    sealed class Intent {
        data class NavigateToDocument(val document: Document): Intent()
        data object NavigateToParent: Intent()
        data class AddDocumentToCurrent(val document: Document): Intent()
        data object NavigateToNewDocument: Intent()
        data class CreateFolder(val folderName: String): Intent()
        data class RenameFolder(val folder: Document): Intent()
        data class Delete(val id: Long): Intent()
        data object NavigateBack: Intent()
    }

    data class State(
        val loading: Boolean = false,
        val error: String? = null,
        val document: Document? = null,
        val currentDirectory: Document? = null,
        val isCurrentDirectoryEditable: Boolean = false,
    )

    sealed interface Label {
    }
}
package com.erstedigital.socialbank.ui.react.dms

import com.erstedigital.socialbank.ui.documents.DmsComponent
import com.erstedigital.socialbank.ui.documents.store.DmsStore
import com.erstedigital.socialbank.ui.react.dms.screens.DmsScreen
import com.erstedigital.socialbank.ui.react.dms.screens.DetailsScreen
import com.erstedigital.socialbank.ui.utils.useAsState
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.CircularProgress
import mui.system.sx
import react.FC
import react.Props
import react.useEffect
import react.useState
import web.cssom.Position
import web.cssom.pct

external interface DmsProps: Props {
    var component: DmsComponent
    var onBack: (Boolean) -> Unit
}


val Dms: FC<DmsProps> = FC { props ->

    val (state, setState) = useState(props.component.state.value)
    val (childStack, _) = props.component.childStack.useAsState()

    useEffect{
        val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }

    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        when (val child = childStack.active.instance) {
            is DmsComponent.Child.Undefined -> {

                DmsScreen {
                    root = state.document
                    currentDirectory = state.currentDirectory
                    onCreateFolder = { props.component.onEvent(DmsStore.Intent.CreateFolder(it))}
                    onNavigateToFolder = {
                        props.component.onEvent(DmsStore.Intent.NavigateToDocument(it))
                    }
                    onNavigateToNewDocument = {
                        props.component.onOutput(DmsComponent.Output.NavigateToNewDocument)
                    }
                    onNavigateToDocument = {
                        props.component.onOutput(DmsComponent.Output.NavigateToDetails(it, state.isCurrentDirectoryEditable))
                    }
                    isCurrentDirectoryEditable = state.isCurrentDirectoryEditable
                    onBack = {
                        if (state.currentDirectory?.name != "/") {
                            props.component.onEvent(DmsStore.Intent.NavigateToParent)
                        } else {
                            props.onBack(true)
                        }
                    }
                    onDelete = {
                        props.component.onEvent(DmsStore.Intent.Delete(it.id!!))
                    }
                    onRename = {
                        props.component.onEvent(DmsStore.Intent.RenameFolder(it))
                    }
                }

            }
            is DmsComponent.Child.Details -> {
                DetailsScreen {
                    component = child.detailsComponent
                    onBack = {
                        props.component.onOutput(DmsComponent.Output.NavigateBack)
                        if (it != null) {
                            props.component.onEvent(DmsStore.Intent.AddDocumentToCurrent(it))
                        }
                    }
                    editable = child.editable
                }
            }
            is DmsComponent.Child.NewDocumentDetails -> {
                DetailsScreen {
                    component = child.detailsComponent
                    onBack = {
                        props.component.onOutput(DmsComponent.Output.NavigateBack)
                        if (it != null) {
                            props.component.onEvent(DmsStore.Intent.AddDocumentToCurrent(it))
                        }
                    }
                    editable = true
                    parentId = state.currentDirectory?.id ?: 0
                }
            }

            else -> {}
        }
    }

}
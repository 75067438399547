package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.FsReceiptModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FsReceipt(
        val id: Long? = null,
        val receiptId: String? = null,
        val ico: String? = null,
        val cashRegisterCode: String? = null,
        val issueDate: String? = null,
        val createDate: String? = null,
        val customerId: String? = null,
        val dic: String? = null,
        val icDph: String? = null,
        val invoiceNumber: String? = null,
        val okp: String? = null,
        val paragon: Boolean? = null,
        val paragonNumber: String? = null,
        val pkp: String? = null,
        val receiptNumber: Long? = null,
        val type: String? = null,
        val taxBaseBasic: Double? = null,
        val taxBaseReduced: Double? = null,
        val totalPrice: Double? = null,
        val freeTaxAmount: Double? = null,
        val vatAmountBasic: Double? = null,
        val vatAmountReduced: Double? = null,
        val vatRateBasic: Double? = null,
        val vatRateReduced: Double? = null,
        val exemption: Boolean? = null,
        val category: String? = null,
        val organization: Organization? = null,
        val orgUnit: OrgUnit? = null,
        @SerialName("productItems") val items: List<ProductItem> = emptyList()
) {
    fun toModel(): FsReceiptModel {
        val model = FsReceiptModel(
                id,
                receiptId,
                ico,
                cashRegisterCode,
                issueDate,
                createDate,
                customerId,
                dic,
                icDph,
                invoiceNumber,
                okp,
                paragon,
                paragonNumber,
                pkp,
                receiptNumber,
                type,
                taxBaseBasic,
                taxBaseReduced,
                totalPrice,
                freeTaxAmount,
                vatAmountBasic,
                vatAmountReduced,
                vatRateBasic,
                vatRateReduced,
                exemption,
                category,
                organization?.toModel(),
                orgUnit?.toModel(),
                items.map(ProductItem::toModel)
        )
        return model
    }
}
package com.erstedigital.socialbank.domain.models

enum class TransactionType {
    FS_RECEIPT, INCOME, EXPENSE, UNKNOWN, MULTI, FS_RECEIPT_SPLIT, EXPENSE_SPLIT, FS_RECEIPT_SPLIT_RECEIVED;

    fun isSplit(): Boolean {
        return this == FS_RECEIPT_SPLIT || this == EXPENSE_SPLIT || this == FS_RECEIPT_SPLIT_RECEIVED
    }

    fun isReceived(): Boolean {
        return this == FS_RECEIPT_SPLIT_RECEIVED
    }

    fun isManual(): Boolean {
        return this == EXPENSE || this == INCOME
    }

}
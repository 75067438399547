package com.erstedigital.socialbank.ui.react.profile

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.profile.ProfileComponent
import com.erstedigital.socialbank.ui.profile.store.ProfileStore
import com.erstedigital.socialbank.ui.react.utils.onPrimaryColor
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.ui.utils.RProps
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.*
import mui.system.sx
import react.FC
import react.ReactNode
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType.Companion.password

val ProfileContent: FC<RProps<ProfileComponent>> = FC { props ->

    val (state, setState) = useState(props.component.state.value)

    val (fullName, setFullName) = useState("")
    val (email, setEmail) = useState("")
    val (phoneNumber, setPhoneNumber) = useState("")
    val (iban, setIban) = useState("")
    val (isChangePassword, setIsChangePassword) = useState(false)
    val (oldPassword, setOldPassword) = useState("")
    val (newPassword, setNewPassword) = useState("")
    val (newConfirmPassword, setNewConfirmPassword) = useState("")

    useEffect{
        val job = props.component.state.onEach { setState(it) }.launchIn(GlobalScope)
    }


    useEffect(state) {

        if (!state.loading && state.signedOut) {
            props.component.onOutput(ProfileComponent.Output.OpenAuthorizationFlow)
        }

        if (!state.loading && state.profile != null) {
            setFullName(state.profile.fullName ?: "")
            setEmail(state.profile.email ?: "")
            setPhoneNumber(state.profile.phoneNumber ?: "")
            setIban(state.profile.iban ?: "")
        }

    }

    if (state.loading) {
        CircularProgress {
            sx {
                position = Position.absolute
                top = 50.pct
                left = 50.pct
            }
        }
    } else {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                width = 90.pct
                maxWidth = 600.px
                paddingTop = 20.px
                paddingBottom = 90.px
            }

            Avatar {
                sx {
                    backgroundColor = onPrimaryColor
                    width = 100.px
                    height = 100.px
                    marginBottom = 40.px
                }
                +state.profile?.getInitials() ?: ""
            }

            TextField {
                sx {
                    width = 100.pct
                    marginBottom = 25.px
                }
                value = fullName
                label = ReactNode(MainRes.string.full_name)
                variant = FormControlVariant.outlined
                onChange = { e ->
                    setFullName((e.target as HTMLInputElement).value)
                }
            }

            TextField {
                sx {
                    width = 100.pct
                    marginBottom = 25.px
                }
                value = email
                label = ReactNode(MainRes.string.email)
                variant = FormControlVariant.outlined
                disabled = true
                onChange = { e ->
                    setEmail((e.target as HTMLInputElement).value)
                }
            }

            TextField {
                sx {
                    width = 100.pct
                    marginBottom = 25.px
                }
                value = phoneNumber
                label = ReactNode(MainRes.string.phone_number)
                variant = FormControlVariant.outlined
                onChange = { e ->
                    setPhoneNumber((e.target as HTMLInputElement).value)
                }
            }

            TextField {
                sx {
                    width = 100.pct
                    marginBottom = 25.px
                }
                value = iban
                label = ReactNode(MainRes.string.iban)
                variant = FormControlVariant.outlined
                onChange = { e ->
                    setIban((e.target as HTMLInputElement).value)
                }
            }
            if (isChangePassword) {
                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 25.px
                    }
                    value = oldPassword
                    placeholder = MainRes.string.enter_old_password
                    type = password
                    onChange = { e ->
                        setOldPassword((e.target as HTMLInputElement).value)
                    }
                }

                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 25.px
                    }
                    value = newPassword
                    placeholder = MainRes.string.enter_new_password
                    type = password
                    onChange = { e ->
                        setNewPassword((e.target as HTMLInputElement).value)
                    }
                }

                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 5.px
                    }
                    value = newConfirmPassword
                    placeholder = MainRes.string.enter_password_confirmation
                    type = password
                    onChange = { e ->
                        setNewConfirmPassword((e.target as HTMLInputElement).value)
                    }
                }
                if (newPassword != newConfirmPassword && newConfirmPassword.isNotEmpty()) {
                    Typography {
                        sx {
                            color = Color("#FF0000")
                            marginBottom = 25.px
                        }
                        +MainRes.string.passwords_do_not_match
                    }
                }
            }

            Button {
                sx {
                    width = 100.pct
                    backgroundColor = primaryColor
                    marginBottom = 25.px
                }
                +MainRes.string.change_password
                variant = ButtonVariant.contained
                disabled = newPassword != newConfirmPassword || newPassword.isEmpty() && isChangePassword
                onClick = {
                    if(isChangePassword) {
                        props.component.onEvent(ProfileStore.Intent.ChangePassword(
                            email,
                            oldPassword,
                            newPassword
                        ))
                        setIsChangePassword(false)
                    } else {
                        setIsChangePassword(true)
                    }
                }
            }

            Button {
                sx {
                    width = 100.pct
                    backgroundColor = primaryColor
                    marginBottom = 15.px
                }
                +MainRes.string.save
                variant = ButtonVariant.contained
                onClick = {
                    props.component.onEvent(ProfileStore.Intent.SaveProfile(
                        fullName,
                        email,
                        phoneNumber,
                        iban
                    ))
                }
            }

            Button {
                sx {
                    width = 100.pct
                    backgroundColor = Color("#ffffff")
                }
                +MainRes.string.log_out
                variant = ButtonVariant.outlined
                onClick = {
                    props.component.onEvent(ProfileStore.Intent.SignOut)
                }
            }


            Snackbar {
                open = state.error != null
                autoHideDuration = 3000
                /*onClose = { _, _ ->
                        props.component.onEvent(

                        )
                }*/
                Alert {
                    severity = AlertColor.error
                    +MainRes.string.failed_password_change
                }
            }
            Snackbar {
                open = state.actionSuccess
                autoHideDuration = 3000
                /*onClose = { _, _ ->
                        props.component.onEvent(

                        )
                }*/
                Alert {
                    severity = AlertColor.success
                    +MainRes.string.success_password_change
                }
            }
        }
    }
}
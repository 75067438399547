package com.erstedigital.socialbank.ui.statistics.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.domain.models.StatisticsCustomRangeModel
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.domain.models.TransactionListModel
import com.erstedigital.socialbank.domain.models.UserModel
import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.LocalDate

interface StatisticsStore: Store<StatisticsStore.Intent, StatisticsStore.State, StatisticsStore.Label> {

    sealed class Intent {

        data object SignOut: Intent()
        data class FetchCustomRangeStatistics(val startDate: LocalDate, val endDate: LocalDate): Intent()
    }

    data class State(
        val loading: Boolean = false,
        val error: String? = null,
        val statistics: StatisticsModel? = null,
        val customRangeStatistics: StatisticsCustomRangeModel? = null
    )

    sealed interface Label {

        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }
}
package com.erstedigital.socialbank.ui.planning

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.ui.planning.store.PlanningStore
import com.erstedigital.socialbank.ui.planning.store.PlanningStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class PlanningComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
): ComponentContext by componentContext {
    private val planningStore = instanceKeeper.getStore {
        PlanningStoreFactory(storeFactory).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<PlanningStore.State> = planningStore.stateFlow

    val label: Flow<PlanningStore.Label> = planningStore.labels

    fun onEvent(event: PlanningStore.Intent) {
        planningStore.accept(event)
    }

    fun onOutput(output: Output){
        when (output) {
            else -> Unit
        }
    }


    sealed class Output {
        object OpenAuthorizationFlow  : Output()
    }

}
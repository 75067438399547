package com.erstedigital.socialbank.data.entity

import com.erstedigital.socialbank.data.network.models.response.LoginIdpResponse
import com.erstedigital.socialbank.domain.models.UserModel
import com.erstedigital.socialbank.domain.utils.toIntColor
import kotlinx.serialization.Serializable

@Serializable
data class UserEntity(
    val id: Long = 0L,
    val email: String? = null,
    var phoneNumber: String? = null,
    var fullName: String? = null,
    var iban: String? = null,
    var token: String? = null,
    var color: String? = null,
    val allowAnalysis: Boolean? = null,
    val groups: List<GroupEntity> = emptyList()
)

fun LoginIdpResponse.User.toEntity(): UserEntity {
    return UserEntity(
        id,
        email,
        phoneNumber,
        fullName,
        iban,
        color = color,
        allowAnalysis = allowAnalysis
    )
}

fun UserEntity.toUser(): UserModel {
    return UserModel(
        id,
        fullName ?: "",
        color?.let {
            return@let try {
                it.toIntColor()
            } catch (e: Exception) {
                e.printStackTrace()
                null
            }
        },
        email,
        phoneNumber,
        iban = iban,
        allowAnalysis = allowAnalysis
    )
}
package com.erstedigital.socialbank.ui.react.utils

import web.cssom.Color
import web.cssom.px

val cardBorderRadius = 10.px


val primaryColor = Color("#2870ED")
val onPrimaryColor = Color("#FF6130")

val cautionColor = Color("#EFD5D3")

val surfaceColor = Color("#E4EAF0")
val surfaceLight = Color("#E4EAF0")

val graphIncomeColor = Color("#02A3A4")
val graphExpenseColor = Color("#FF6130")

val whiteColor = Color("#FFFFFF")
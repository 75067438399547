package com.erstedigital.socialbank.data.network.models.request

import com.erstedigital.socialbank.ui.login.store.LoginStore
import kotlinx.serialization.Serializable

@Serializable
data class LoginRequest(
    val password: String,
    val login: String,
    val fcmToken: String?,
    val isSocialBank: Boolean
)
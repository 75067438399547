package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.react.statistics.components.PieChart
import com.erstedigital.socialbank.ui.react.statistics.components.PieChartData
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import mui.icons.material.Close
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.Modal
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.useMemo
import react.useState
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import web.cssom.translate
import web.html.HTMLInputElement
import kotlin.random.Random

external interface NewCompanyModalProps: Props {
    var onConfirm: (String, String?) -> Unit
    var onDismiss: () -> Unit
    var name: String
    var open: Boolean
}

@OptIn(ExperimentalStdlibApi::class)
val NewCompanyModal: FC<NewCompanyModalProps> = FC { props ->

    val (name, setName) = useState(props.name)
    val (ico, setIco) = useState("")


    Modal {
        open = props.open
        onClose = { _, _ ->
            props.onDismiss()
        }

        Box {
            sx {
                width = 300.px
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                backgroundColor = Color("#fff")
                borderRadius = 10.px
                padding = 15.px
            }

            Box {
                sx {
                    display= Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    width = 100.pct
                }

                Typography {
                    variant = TypographyVariant.h6
                    +MainRes.string.create_company
                    sx {
                        marginBottom = 10.px
                    }
                }

                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 10.px
                        marginTop = 20.px
                    }
                    value = name
                    onChange = { event ->
                        setName((event.target as HTMLInputElement).value)
                    }
                    label = ReactNode(MainRes.string.company_name)
                }

                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 30.px
                        marginTop = 10.px
                    }
                    value = ico
                    onChange = { event ->
                        setIco((event.target as HTMLInputElement).value)
                    }
                    label = ReactNode(MainRes.string.company_ico)
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }

                    Button {
                        sx {
                            color = primaryColor
                        }
                        variant = ButtonVariant.text
                        +"Dismiss"
                        onClick = {
                            props.onDismiss()
                        }
                    }

                    Button {
                        sx {
                            backgroundColor = primaryColor
                        }
                        variant = ButtonVariant.contained
                        +"Confirm"
                        onClick = {
                            props.onConfirm(
                                name,
                                ico.ifEmpty { null }
                            )
                        }
                    }
                }
            }
        }
    }



}
package com.erstedigital.socialbank.di.external

import kotlinx.serialization.Serializable

@Serializable
object Constants {
    val apiKey = "AIzaSyBffqg5AlcESM0e7rEVqkQQbt_biHV9sGI"
    val authDomain = "socialbank-777a8.firebaseapp.com"
    val databaseURL= "https://socialbank-777a8.firebaseio.com"
    val projectId = "socialbank-777a8"
    val storageBucket = "socialbank-777a8.appspot.com"
    val messagingSenderId = "636164535147"
    val appId = "1:636164535147:web:3eea18ce6cb4535122522e"
    val measurementId = "G-WFPNTE1ZBB"
}
package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.entity.toEntity
import com.erstedigital.socialbank.data.network.models.request.VerifyCodeRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.di.getToken
import io.ktor.client.HttpClient
import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.auth.providers.BearerAuthProvider
import io.ktor.client.plugins.auth.providers.BearerTokens
import io.ktor.client.plugins.auth.providers.bearer
import io.ktor.client.plugins.plugin

class VerifyCodeUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val userRemoteDataSource: UserRemoteDataSource,
    private val httpClient: HttpClient
) {

    suspend fun verifyCode(verifyCodeRequest: VerifyCodeRequest): ApiResponse<Any, ErrorResponse> {
        val remoteResult = userRemoteDataSource.verifyCode(verifyCodeRequest)

        return when (remoteResult) {
            is ApiResponse.Success -> {
                val user = remoteResult.body.user?.toEntity()
                user?.token = remoteResult.body.token
                userLocalDataSource.setUser(user)

                val auth = httpClient.plugin(Auth)

                // reload token provider
                auth
                    .providers
                    .removeAt(auth.providers.indexOfFirst { it is BearerAuthProvider })

                auth.bearer {
                    this.loadTokens {
                        BearerTokens(user?.token!!, "")
                    }
                }

                remoteResult
            }

            is ApiResponse.Error -> {
                remoteResult
            }
        }
    }
}
package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class ExpectedIncomeModel(
    var income: Double?,
    var recurringIncome: Boolean?,
    var businessIncome: Double?,
    var recurringBusinessIncome: Boolean?,
    var socialBenefits: Double?,
    var recurringSocialBenefits: Boolean?,
    var otherIncome: Double?,
    var recurringOtherIncome: Boolean?,
    var date: LocalDate?
)
package com.erstedigital.socialbank.domain.models

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
class ManualTransactionModel(
    val id: Long?,
    val amount: Double?,
    val date: LocalDate?,
    val category: String?,
    val organization: OrganizationModel?
)
package com.erstedigital.socialbank.ui.react.dms.components

import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.documents.DmsComponent
import com.erstedigital.socialbank.ui.documents.store.DmsStore
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.icons.material.Folder
import mui.icons.material.FolderShared
import mui.icons.material.InsertDriveFile
import mui.material.Grid
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.px


external interface DocumentListGridProps: Props {
    var currentDirectory: Document?
    var isCurrentDirectoryEditable: Boolean
    var navigateToFolder: (Document) -> Unit
    var navigateToDocument: (Long) -> Unit
    var onRename: (Document) -> Unit
    var onDelete: (Document) -> Unit
}

val DocumentListGrid: FC<DocumentListGridProps> = FC { props ->

    Grid {
        container = true
        props.currentDirectory?.children?.sortedByDescending { it.folder }?.map { doc ->
            DocumentItem {
                onOpen = {
                    if (doc.folder == true) {
                        props.navigateToFolder(doc)
                    } else {
                        props.navigateToDocument(doc.id!!)
                    }
                }
                name = doc.name ?: ""
                type = if (doc.folder == true) {
                    if (doc.name?.contains("shared") == true) {
                        DocumentType.SHAREDFOLDER
                    } else {
                        DocumentType.FOLDER
                    }
                } else {
                    DocumentType.FILE
                }
                onDelete = {
                    props.onDelete(doc)

                }
                onRename = {
                    props.onRename(doc)
                }
                editable = props.isCurrentDirectoryEditable
            }
        }
    }
}

package com.erstedigital.socialbank.ui.transactions.products

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.domain.models.ProductModel
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStore
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStoreFactory
import com.erstedigital.socialbank.ui.transactions.products.store.ProductsStore
import com.erstedigital.socialbank.ui.transactions.products.store.ProductsStoreFactory
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.StateFlow

class ProductsComponent(
    componentContext: ComponentContext,
    private val storeFactory: StoreFactory,
    private val products: List<ProductItemModel>
): ComponentContext by componentContext {
    private val productsStore = instanceKeeper.getStore {
        ProductsStoreFactory(storeFactory, products).create()
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<ProductsStore.State> = productsStore.stateFlow

    fun onEvent(event: ProductsStore.Intent) {
        productsStore.accept(event)
    }
    sealed class Output {
        object NavigateBack : Output()
    }

}
package com.erstedigital.socialbank.ui.home.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.overdokladapi.data.response.ReceiptFindResponse
import com.erstedigital.socialbank.data.network.models.request.CreateFsReceiptRequest
import com.erstedigital.socialbank.domain.models.TransactionModel


enum class TransactionState {
    CREATED,
    DUPLICATE,
    DONE
}

interface HomeStore: Store<HomeStore.Intent, HomeStore.State, HomeStore.Label> {

    sealed class Intent {
        data object ScanQrCode : Intent()
        data class UpdateCategory(val id: Long, val category: String) : Intent()
        data object DismissDuplicateDialog : Intent()
        data object DismissManualTransaction : Intent()
        data object DuplicateTransaction : Intent()
        data object DismissResultBottomSheet : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val valid: Boolean = true,
        val error: String? = null,
        val transaction: TransactionModel? = null,
        val fsReceipt: CreateFsReceiptRequest? = null,
        var transactionState: TransactionState? = null,
        val manualTransactionClicked: Boolean = false,
    )

    sealed interface Label {
        data object TransactionCreated : Label
        data object DismissResultBottomSheet : Label

    }
}
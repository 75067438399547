package com.erstedigital.socialbank.utils

import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.daysUntil
import kotlinx.datetime.monthsUntil
import kotlinx.datetime.periodUntil
import kotlinx.datetime.plus
import kotlinx.datetime.until
import kotlinx.datetime.yearsUntil
import kotlin.time.Duration.Companion.days


fun LocalDate.generateRange(to: LocalDate): List<LocalDate> {
    val days = this.daysUntil(to)
    val result = ArrayList<LocalDate>()

    for (i in 0..days) {
        result.add(this.plus(i, DateTimeUnit.DAY))
    }

    return result
}

fun LocalDate.generateWeekRange(to: LocalDate): List<LocalDate> {
    val weeks = this.daysUntil(to) / 7

    val result = ArrayList<LocalDate>()
    for (i in 0..weeks) {
        result.add(this.plus(i, DateTimeUnit.WEEK))
    }
    return result
}

fun LocalDate.generateMonthRange(to: LocalDate): List<LocalDate>{
    val months = this.monthsUntil(to)

    val result = ArrayList<LocalDate>()
    for (i in 0..months) {
        result.add(this.plus(i, DateTimeUnit.MONTH))
    }

    result.lastOrNull()?.let {
        if (it.month != to.month){
            result.add(to)
        }
    }
    return result
}

fun LocalDate.generateYearRange(to: LocalDate): List<LocalDate>{
    val years = this.yearsUntil(to)

    val result = ArrayList<LocalDate>()
    for (i in 0..(years + 1)) {
        result.add(this.plus(i, DateTimeUnit.YEAR))
    }
    return result
}

fun LocalDate.weekOfYear(): Int {
    return this.dayOfYear / 7
}
package com.erstedigital.socialbank.data.network.models.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoginIdpResponse(
    @SerialName("app_user") val user: User?,
    @SerialName("id_token") val token: String?
) {
    @Serializable
    data class User(
        val id: Long,
        val email: String?,
        val phoneNumber: String?,
        val fullName: String?,
        val loginCode: String?,
        val loginCodeExpires: String?,
        val fromIdp: Boolean?,
        val iban: String?,
        val color: String?,
        val allowAnalysis: Boolean?
    )
}
package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction_image.TransactionImageRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.CreateTransactionImageRequest

class CreateTransactionImageUsecase(
    private val transactionImageRemoteDataSource: TransactionImageRemoteDataSource
) {
    suspend fun createTransactionImage(request: CreateTransactionImageRequest) {
        transactionImageRemoteDataSource.create(request)
    }
}
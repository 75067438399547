package com.erstedigital.socialbank.domain.usecase.documents

import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Attachment
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.data.network.models.request.UpdateAttachmentDTO
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class UpdateAttachmentUsecase(
    private val dmsRemoteDataSource: DmsRemoteDataSource
) {

    suspend operator fun invoke(request: UpdateAttachmentDTO): ApiResponse<Attachment, ErrorResponse> {
        return dmsRemoteDataSource.updateAttachment(request)
    }
}
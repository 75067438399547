package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.data.network.models.common.AppUser
import com.erstedigital.socialbank.domain.models.DashboardModel
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.toLocalDate
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class DashboardResponse(
    val balance: Double? = null,
    val appUser: AppUser? = null,
    val scanningDTO: ScanningDTO? = null,
    val notes: List<Note>? = null,
    val userTransactions: List<UserTransaction> = emptyList(),
    val recentDocuments: List<Document> = emptyList(),
    val monthlyTransactions: List<MonthlyTransaction> = emptyList(),
    val monthlyIncome: Double? = null,
    val monthlyExpenses: Double? = null,
    val monthlyExpectedIncome: Double? = null,
    val monthlyExpectedExpenses: Double? = null
) {

    fun toModel(): DashboardModel {
        return DashboardModel(
            balance,
            appUser?.toModel(),
            scanningDTO?.toModel(),
            notes?.map(Note::toModel) ?: emptyList(),
            userTransactions.map(UserTransaction::toModel),
            recentDocuments.map(Document::toModel),
            monthlyTransactions.map(MonthlyTransaction::toModel),
            monthlyIncome,
            monthlyExpenses,
            monthlyExpectedIncome,
            monthlyExpectedExpenses
        )
    }

    @Serializable
    data class ScanningDTO(
        val userPosition: Int,
        val count: Int,
        val leaders: List<Leader>
    ) {

        fun toModel(): DashboardModel.LeaderboardModel {
            return DashboardModel.LeaderboardModel(
                userPosition,
                count,
                leaders.map(Leader::toModel)
            )
        }

        @Serializable
        data class Leader(
            val name: String? = null,
            val amount: Int = 0
        ) {
            fun toModel(): DashboardModel.LeaderModel {
                return DashboardModel.LeaderModel(name, amount)
            }
        }
    }

    @Serializable
    data class Document(
        val id: Long? = null,
        val name: String? = null,
        val modifiedAt: String? = null
    ) {
        fun toModel(): DashboardModel.DocumentModel {
            return DashboardModel.DocumentModel(
                id,
                name,
                modifiedAt?.split("+")?.get(0)?.toLocalDateTime(),
            )
        }
    }


    @Serializable
    data class Note(
        val text: String? = null,
        val appUserVisibility: Boolean? = null,
        val createdAt: String? = null,
        val modifiedAt: String? = null,
        val notePeriods: List<NotePeriod>? = null,
        val type: String? = null,
        val intervalFrom : String? = null,
        val intervalTo : String? = null
    ) {
        fun toModel(): DashboardModel.NoteModel {
            return DashboardModel.NoteModel(
                text,
                appUserVisibility ?: false,
                createdAt?.split("Z")?.get(0)?.toLocalDateTime(),
                notePeriods?.map(NotePeriod::toModel) ?: emptyList(),
                type,
                intervalFrom?.toLocalDate(),
                intervalTo?.toLocalDate()
            )
        }
    }

    @Serializable
    data class NotePeriod(
        val id: Long? = null,
        val state: String? = null,
        val start: String? = null,
        val end: String? = null,
        val createdAt: String? = null,
        val modifiedAt: String? = null
    ) {
        fun toModel(): DashboardModel.NotePeriod {

            return DashboardModel.NotePeriod(
                start?.split("Z")?.get(0)?.toLocalDate(),
                end?.split("Z")?.get(0)?.toLocalDate(),
                state
            )
        }
    }

    @Serializable
    data class UserTransaction(
        val from: AppUser?,
        val to: AppUser?,
        val settledFrom: Boolean?,
        val settledTo: Boolean?,
        val amount: Double?,
        val id: Long,
        val organizationName: String?,
        val date: LocalDateTime?,
        val transactionId: Long?
    ) {
        fun toModel(): DashboardModel.UserTransactionModel {
            return DashboardModel.UserTransactionModel(
                id,
                from?.toModel(),
                to?.toModel(),
                settledFrom ?: false,
                settledTo ?: false,
                amount ?: 0.0,
                organizationName,
                date,
                transactionId
            )
        }
    }

    @Serializable
    data class MonthlyTransaction(
        val id: Long? = null,
        val price: Double? = null,
        val organizationName: String? = null,
        val type: String? = null,
        val date: String? = null,
        val category: String? = null,
        val organizationId: Long? = null,
        val splitCreatorName: String? = null,
        val splitTotalPrice: Double? = null,
        val splitPeopleCount: Int? = null,
        var hasImages: Boolean = false
    ) {
        fun toModel(): DashboardModel.MonthlyTransactionModel {
            return DashboardModel.MonthlyTransactionModel(
                id,
                price ?: 0.0,
                organizationName,
                type,
                date,
                category,
                organizationId,
                splitCreatorName,
                splitTotalPrice ?: 0.0,
                splitPeopleCount ?: 0,
                hasImages
            )
        }
    }
}
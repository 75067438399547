package com.erstedigital.socialbank.domain.models

import androidx.compose.ui.graphics.Color
import com.arkivanov.essenty.parcelable.Parcelable
import com.arkivanov.essenty.parcelable.Parcelize
import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.Gray
import com.erstedigital.socialbank.ui.Kaufland
import io.github.skeptick.libres.images.Image
import kotlinx.serialization.Serializable

@Serializable
@Parcelize
class ProductModel(
    val id: Long?,
    val name: String?,
    val itemType: String?,
    val price: Double?,
    var category: String?,
    val categories: List<String>
): Parcelable {

    val tags: Set<Tag> by lazy {
        val result = mutableSetOf<Tag>()
        val name = name?.replace(".", " ") ?: return@lazy result

        if (name.startsWith(KFREE)) {
            result.add(Tag.KAUFLAND)
            result.add(Tag.LACTOFREE)
        }
        if (name.startsWith(K)) {
            result.add(Tag.KAUFLAND)
        }
        if (name.startsWith(KBIO)) {
            result.add(Tag.KAUFLAND)
            result.add(Tag.BIO)
        }
        if (name.contains(BILLA, true)) {
            result.add(Tag.BILLA)
        }
        if (name.startsWith(TS) || name.contains(TESCO, true)) {
            result.add(Tag.TESCO)
        }
        if (name.startsWith(TF)) {
            result.add(Tag.TESCO_FINEST)
        }
        if (name.contains(CLEVER, true)) {
            result.add(Tag.CLEVER)
        }
        if (name.startsWith("$BIO ", true)
            || name.contains(" $BIO ", true)
            || name.endsWith(" $BIO", true)) {

            result.add(Tag.BIO)
        }
        if (name.startsWith("$FAF ")) {
            result.add(Tag.FANDF)
        }


        return@lazy result
    }

    enum class Tag(
        val title: String,
        val color: Color,
        val icon: Image? = null
    ) {
        KAUFLAND(MainRes.string.kaufland, Kaufland, MainRes.image.kaufland),
        LACTOFREE(MainRes.string.lactofree, Gray, MainRes.image.lactose_free),
        BIO(MainRes.string.bio, Gray,),
        BILLA(MainRes.string.billa, Gray),
        CLEVER(MainRes.string.clever, Gray),
        TESCO_FINEST(MainRes.string.tesco_finest, Gray),
        TESCO(MainRes.string.tesco, Gray),
        FANDF(MainRes.string.f_and_f, Gray)
//        PROTEIN(R)
    }

    companion object {
        const val KFREE = "KFree."
        const val KBIO = "KBio."
        const val K =  "K."
        const val TF = "TF"
        const val TS = "TS"
        const val TESCO = "TESCO"
        const val BILLA = "BILLA"
        const val CLEVER = "clev"
        const val BIO = "bio"
        const val FAF = "F"

    }




}
package com.erstedigital.socialbank.ui.dashboard.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Card
import androidx.compose.material3.CardElevation
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.DashboardModel
@Composable
fun NoteCard(
    notes: List<DashboardModel.NoteModel>,
) {

    Column(
        modifier = Modifier.padding(vertical = 10.dp)
    ) {
        Column {
            notes.forEach { note ->
                NoteCardListItem(note = note)
                Spacer(modifier = Modifier.height(10.dp))
            }
        }
    }

}

@Composable
fun NoteCardListItem(
    note: DashboardModel.NoteModel,
) {
    val elements = parseHtml(note.text ?: "")
    val plainNote = parseHtmlToPlainText(note.text ?: "")

    Card(
        modifier = Modifier.fillMaxWidth()
    ){
        Text(
            plainNote.replace("\n", " "),
            overflow = TextOverflow.Ellipsis,
            modifier = Modifier.fillMaxWidth(.7f).padding(vertical = 10.dp, horizontal = 10.dp), maxLines = 2
        )
    }


    //RenderHtmlElements(elements)
}

fun parseHtmlToPlainText(html: String): String {
    val regex = Regex("<[^>]+>")
    return regex.replace(html, "")
}

fun parseHtml(html: String): List<HtmlElement> {
    val elements = mutableListOf<HtmlElement>()

    var tempHtml = html
    while (tempHtml.isNotEmpty()) {
        when {
            tempHtml.contains("<h") -> {
                val level = tempHtml[tempHtml.indexOf("<h") + 2].toString().toInt()
                val textStart = tempHtml.indexOf(">", tempHtml.indexOf("<h")) + 1
                val textEnd = tempHtml.indexOf("</h")
                val text = tempHtml.substring(textStart, textEnd)
                elements.add(Heading(level, text))
                tempHtml = tempHtml.substring(textEnd + 5)
            }
            tempHtml.contains("<p>") -> {
                val textStart = tempHtml.indexOf("<p>") + 3
                val textEnd = tempHtml.indexOf("</p>")
                val text = tempHtml.substring(textStart, textEnd)
                elements.add(Paragraph(text))
                tempHtml = tempHtml.substring(textEnd + 4)
            }
            tempHtml.contains("<ul>") -> {
                val listEnd = tempHtml.indexOf("</ul>")
                val listHtml = tempHtml.substring(tempHtml.indexOf("<ul>"), listEnd)
                val items = listHtml.split("<li>", "</li>").filterNot { it.isBlank() || it == "<ul>" || it == "</ul>" }
                elements.add(UnorderedList(items))
                tempHtml = tempHtml.substring(listEnd + 5)
            }
            tempHtml.contains("<ol>") -> {
                val listEnd = tempHtml.indexOf("</ol>")
                val listHtml = tempHtml.substring(tempHtml.indexOf("<ol>"), listEnd)
                val items = listHtml.split("<li>", "</li>").filterNot { it.isBlank() || it == "<ol>" || it == "</ol>" }
                elements.add(OrderedList(items))
                tempHtml = tempHtml.substring(listEnd + 5)
            }
            else -> break  // Exit loop if no more recognized tags
        }
    }

    return elements
}

sealed class HtmlElement
data class Paragraph(val text: String) : HtmlElement()
data class Heading(val level: Int, val text: String) : HtmlElement()
data class UnorderedList(val items: List<String>) : HtmlElement()
data class OrderedList(val items: List<String>) : HtmlElement()

@Composable
fun RenderHtmlElements(elements: List<HtmlElement>) {
    elements.forEach { element ->
        when (element) {
            is Paragraph -> {
                Text(text = element.text)
            }
            is Heading -> {
                Text(
                    text = element.text,
                    style = MaterialTheme.typography.headlineMedium.copy(
                        fontWeight = FontWeight.Bold,
                        fontSize = with(LocalDensity.current) { (element.level * 4).sp }
                    )
                )
            }
            is UnorderedList -> {
                Column {
                    element.items.forEach { item ->
                        Text(text = "• $item")
                    }
                }
            }
            is OrderedList -> {
                Column {
                    element.items.forEachIndexed { index, item ->
                        Text(text = "${index + 1}. $item")
                    }
                }
            }
        }
    }
}
package com.erstedigital.socialbank.ui.react.dms.screens

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.documents.DmsComponent
import com.erstedigital.socialbank.ui.documents.store.DmsStore
import com.erstedigital.socialbank.ui.react.dms.components.DocumentListGrid
import com.erstedigital.socialbank.ui.react.dms.components.FolderDialog
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import mui.icons.material.ArrowBack
import mui.icons.material.CreateNewFolder
import mui.icons.material.FileUpload
import mui.material.Box
import mui.material.IconButton
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useState
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px

external interface DmsScreenProps: Props {
    var root: Document?
    var currentDirectory: Document?
    var isCurrentDirectoryEditable: Boolean
    var onCreateFolder: (String) -> Unit
    var onNavigateToFolder: (Document) -> Unit
    var onNavigateToDocument: (Long) -> Unit
    var onNavigateToNewDocument: () -> Unit
    var onBack: () -> Unit
    var onRename: (Document) -> Unit
    var onDelete: (Document) -> Unit
}


val DmsScreen: FC<DmsScreenProps> = FC { props ->

    val (newFolderDialog, setNewFolderDialog) = useState(false)
    val (renamedFolder, setRenamedFolder) = useState<Document?>(null)
    val path = props.currentDirectory?.findPathDocuments(props.root!!, props.currentDirectory?.id!!)

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            width = 100.pct
            maxWidth = 600.px
            paddingTop = 10.px
        }

        if (newFolderDialog) {
            FolderDialog {
                open = newFolderDialog
                onDismiss = {
                    setNewFolderDialog(false)
                }
                onConfirm = { name ->
                    props.onCreateFolder(name)
                    setNewFolderDialog(false)
                }
                setShowDialog = {
                    setNewFolderDialog(it)
                }
            }
        }

        FolderDialog {
            open = renamedFolder != null
            onDismiss = {
                setRenamedFolder(null)
            }
            onConfirm = { name ->
                props.onRename(renamedFolder?.copy(name = name)!!)
                setRenamedFolder(null)
            }
            setShowDialog = {
                setNewFolderDialog(it)
            }
            initName = renamedFolder?.name
        }


        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
            }

            IconButton {
                sx {
                    marginRight = 10.px
                }
                onClick = {
                    props.onBack()
                }
                ArrowBack()
            }
            Typography {
                sx {
                    width = 100.pct
                }
                variant = TypographyVariant.h4
                +MainRes.string.documents
            }
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
                justifyContent = JustifyContent.spaceBetween
            }
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    paddingLeft = 10.px
                }

                path?.mapIndexed { index, doc ->
                    Typography {
                        sx {
                            cursor = Cursor.pointer
                        }
                        variant = TypographyVariant.body1
                        onClick = {
                            props.onNavigateToFolder(doc)
                        }
                        +(if (index == 0) doc.name else "${doc.name}/")
                    }
                }
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    paddingRight = 10.px
                }
                if (props.isCurrentDirectoryEditable) {
                    IconButton {
                        onClick = {
                            props.onNavigateToNewDocument()
                        }
                        FileUpload {
                            sx {
                                fontSize = 20.px
                                color = primaryColor
                            }
                        }
                    }
                    IconButton {
                        onClick = {
                            setNewFolderDialog(true)
                        }
                        CreateNewFolder {
                            sx {
                                fontSize = 20.px
                                color = primaryColor
                            }
                        }
                    }
                }
            }
        }

        DocumentListGrid {
            navigateToDocument = {
               props.onNavigateToDocument(it)
            }
            navigateToFolder = {
                props.onNavigateToFolder(it)
            }
            currentDirectory = props.currentDirectory
            onDelete = {
                props.onDelete(it)
            }
            onRename = {
                setRenamedFolder(it)
            }
            isCurrentDirectoryEditable = props.isCurrentDirectoryEditable
        }
    }
}
package com.erstedigital.socialbank.ui.react.statistics.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import com.erstedigital.socialbank.ui.statistics.components.Mode
import io.data2viz.time.date
import io.github.aakira.napier.Napier
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import mui.icons.material.ArrowBack
import mui.icons.material.ArrowForward
import mui.material.BaseTextFieldProps
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.Icon
import mui.material.IconButton
import mui.material.Modal
import mui.material.Tab
import mui.material.Tabs
import mui.material.TextField
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import muix.pickers.AdapterDateFns
import muix.pickers.CalendarPicker
import muix.pickers.CalendarPickerClasses
import muix.pickers.CalendarPickerProps
import muix.pickers.DateAdapter
import muix.pickers.DateField
import muix.pickers.DatePicker
import muix.pickers.DatePickerProps
import muix.pickers.LocalizationProvider
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.onChange
import react.useMemo
import react.useState
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.Translate
import web.cssom.pct
import web.cssom.px
import web.cssom.translate
import web.html.HTMLInputElement
import web.html.InputType
import kotlin.js.Date

external interface DatePickerModalProps: Props {
    var onConfirm: (Mode, LocalDate, LocalDate) -> Unit
    var onDismiss: () -> Unit
    var statisticsModel: StatisticsModel?
    var open: Boolean
}

val DatePickerModal: FC<DatePickerModalProps> = FC { props ->

    val (tabValue, setTabValue) = useState(1)
    val (dateFrom, setDateFrom) = useState<LocalDate>(LocalDateTime.parse(
        js("new Date().toISOString().split(\"Z\")[0]") as String
    ).date)

    val (dateTo, setDateTo) = useState<LocalDate>(LocalDateTime.parse(
        js("new Date().toISOString().split(\"Z\")[0]") as String
    ).date)

    val startEpochDate = useMemo(
        dateFrom,
    ) {
        dateFrom.toEpochDays()
    }

    val months = listOf(
        MainRes.string.january,
        MainRes.string.february,
        MainRes.string.march,
        MainRes.string.april,
        MainRes.string.may,
        MainRes.string.june,
        MainRes.string.july,
        MainRes.string.august,
        MainRes.string.september,
        MainRes.string.october,
        MainRes.string.november,
        MainRes.string.december
    )

    val monthsSubs = listOf(
        MainRes.string.january.substring(0, 3),
        MainRes.string.february.substring(0, 3),
        MainRes.string.march.substring(0, 3),
        MainRes.string.april.substring(0, 3),
        MainRes.string.may.substring(0, 3),
        MainRes.string.june.substring(0, 3),
        MainRes.string.july.substring(0, 3),
        MainRes.string.august.substring(0, 3),
        MainRes.string.september.substring(0, 3),
        MainRes.string.october.substring(0, 3),
        MainRes.string.november.substring(0, 3),
        MainRes.string.december.substring(0, 3)
    )

    val weeksInMonth = useMemo(dateFrom, dateTo) {
        val firstDayOfMonth = LocalDate(dateFrom.year, dateFrom.monthNumber, 1)
        val lastDayOfMonth = firstDayOfMonth.plus(1, DateTimeUnit.MONTH).minus(1, DateTimeUnit.DAY)

        // Find the first Monday before or on the first day of the month
        var currentStart = firstDayOfMonth.minus((firstDayOfMonth.dayOfWeek.ordinal - DayOfWeek.MONDAY.ordinal + 7) % 7, DateTimeUnit.DAY)

        if (currentStart.monthNumber != firstDayOfMonth.monthNumber) {
            currentStart = currentStart.plus(7, DateTimeUnit.DAY)
        }

        val weeks = mutableListOf<Pair<LocalDate, LocalDate>>()

        while (currentStart.plus(6, DateTimeUnit.DAY) <= lastDayOfMonth || currentStart.monthNumber == firstDayOfMonth.monthNumber) {
            val endOfWeek = currentStart.plus(6, DateTimeUnit.DAY)
            if (currentStart.monthNumber == firstDayOfMonth.monthNumber || currentStart.monthNumber == firstDayOfMonth.minus(1, DateTimeUnit.MONTH).monthNumber) {
                weeks.add(currentStart to endOfWeek)
            }
            currentStart = endOfWeek.plus(1, DateTimeUnit.DAY)
        }
        weeks
    }


    Modal {
        open = props.open
        onClose = { _, _ ->
            props.onDismiss()
        }

        Box {
            sx {
                width = 500.px
                maxWidth = 100.pct
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                backgroundColor = Color("#fff")
                borderRadius = 10.px
                padding = 15.px
            }

            Box {
                sx {
                    display= Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    width = 100.pct
                }

                Tabs {
                    value = tabValue
                    onChange = { _, newValue ->
                        setTabValue(newValue as Int)
                    }

                    Tab {
                        label = ReactNode(MainRes.string.week)
                    }
                    Tab {
                        label = ReactNode(MainRes.string.month)
                    }
                    Tab {
                        label = ReactNode(MainRes.string.custom)
                    }
                }

                when (tabValue) {
                    0 -> {
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.column
                                justifyContent = JustifyContent.center
                                alignItems = AlignItems.center
                                paddingBottom = 20.px
                            }

                            Box {
                                sx {
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceAround
                                    alignItems = AlignItems.center
                                }

                                IconButton {
                                    onClick = {
                                        setDateFrom(dateFrom.minus(
                                            1, DateTimeUnit.YEAR
                                        ))
                                    }
                                    ArrowBack()
                                }

                                Typography {
                                    variant = TypographyVariant.h6
                                    +("${dateFrom.year}")
                                }

                                IconButton {
                                    onClick = {
                                        setDateFrom(dateFrom.plus(
                                            1, DateTimeUnit.YEAR
                                        ))
                                    }
                                    ArrowForward()
                                }
                            }

                            Grid {
                                container = true
                                spacing = responsive(2)
                                sx {
                                    justifyContent = JustifyContent.center
                                    alignItems = AlignItems.center
                                }

                                Typography {
                                    variant = TypographyVariant.body1
                                    align = TypographyAlign.center
                                    sx {
                                        width = 100.pct
                                        marginTop = 20.px
                                    }
                                    +MainRes.string.select_month
                                }

                                monthsSubs.mapIndexed { index, month ->

                                    val notPresent = props.statisticsModel?.monthly?.find {
                                        it.startDate.monthNumber == index + 1 && it.startDate.year == dateFrom.year
                                    } == null


                                    Grid {
                                        item = true

                                        sx {
                                            minWidth = 110.px
                                            width = 110.px
                                            maxWidth = 110.px
                                        }

                                        Button {
                                            sx {
                                                width = 100.pct
                                                backgroundColor = if (index + 1 == dateFrom.monthNumber) {
                                                    surfaceColor
                                                } else {
                                                    Color("#fff")
                                                }
                                            }
                                            disabled = notPresent

                                            onClick = {
                                                val newStartDate = LocalDate(
                                                    dateFrom.year,
                                                    index + 1,
                                                    1
                                                )
                                                val newEndDate = if (index + 1 == 12) {
                                                    LocalDate(
                                                        dateFrom.year + 1,
                                                        1,
                                                        1
                                                    ).minus(1, DateTimeUnit.DAY)
                                                } else {
                                                    LocalDate(
                                                        dateFrom.year,
                                                        index + 2,
                                                        1
                                                    ).minus(1, DateTimeUnit.DAY)
                                                }
                                                setDateFrom(newStartDate)
                                                setDateTo(newEndDate)
                                            }
                                            variant = ButtonVariant.text
                                            Typography {
                                                variant = TypographyVariant.caption
                                                align = TypographyAlign.center
                                                sx {
                                                    width = 100.pct
                                                }
                                                +month
                                            }
                                        }
                                    }
                                }

                                Typography {
                                    variant = TypographyVariant.body1
                                    align = TypographyAlign.center
                                    sx {
                                        width = 100.pct
                                        marginTop = 20.px
                                    }
                                    +MainRes.string.select_week
                                }

                                weeksInMonth.mapIndexed { index, (weekStart, weekEnd) ->
                                    val notPresent = props.statisticsModel?.weekly?.find {
                                        it.startDate.dayOfYear == weekStart.dayOfYear && it.startDate.year == weekStart.year
                                    } == null
                                    Grid {
                                        item = true

                                        sx {
                                            minWidth = 110.px
                                            width = 110.px
                                            maxWidth = 110.px
                                        }

                                        Button {
                                            sx {
                                                width = 100.pct
                                                backgroundColor = if (weekStart.toEpochDays() == startEpochDate) {
                                                    surfaceColor
                                                } else {
                                                    Color("#fff")
                                                }
                                            }
                                            onClick = {
                                                setDateFrom(weekStart)
                                                setDateTo(weekEnd)
                                            }
                                            disabled = notPresent
                                            variant = ButtonVariant.text
                                            Typography {
                                                variant = TypographyVariant.caption
                                                align = TypographyAlign.center
                                                sx {
                                                    width = 100.pct
                                                }
                                                +"${weekStart.dayOfMonth}.${weekStart.monthNumber}  - ${weekEnd.dayOfMonth}.${weekEnd.monthNumber}"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    1 -> {
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.column
                                justifyContent = JustifyContent.center
                                alignItems = AlignItems.center
                                paddingBottom = 20.px
                            }

                            Box {
                                sx {
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    justifyContent = JustifyContent.spaceAround
                                    alignItems = AlignItems.center
                                }

                                IconButton {
                                    onClick = {
                                        setDateFrom(dateFrom.minus(
                                            1, DateTimeUnit.YEAR
                                        ))
                                    }
                                    ArrowBack()
                                }

                                Typography {
                                    variant = TypographyVariant.h6
                                    +("${dateFrom.year}")
                                }

                                IconButton {
                                    onClick = {
                                        setDateFrom(dateFrom.plus(
                                            1, DateTimeUnit.YEAR
                                        ))
                                    }
                                    ArrowForward()
                                }
                            }

                            Grid {
                                container = true
                                spacing = responsive(2)
                                sx {
                                    justifyContent = JustifyContent.center
                                    alignItems = AlignItems.center
                                }

                                months.mapIndexed { index, month ->
                                    val notPresent = props.statisticsModel?.monthly?.find {
                                        it.startDate.monthNumber == index + 1 && it.startDate.year == dateFrom.year
                                    } == null
                                    Grid {
                                        item = true

                                        sx {
                                            minWidth = 110.px
                                            width = 110.px
                                            maxWidth = 110.px
                                        }
                                        Button {
                                            sx {
                                                width = 100.pct
                                                backgroundColor = if (index + 1 == dateFrom.monthNumber) {
                                                    surfaceColor
                                                } else {
                                                    Color("#fff")
                                                }
                                            }
                                            disabled = notPresent
                                            variant = ButtonVariant.text
                                            onClick = {
                                                val newStartDate = LocalDate(
                                                    dateFrom.year,
                                                    index + 1,
                                                    1
                                                )
                                                val newEndDate = LocalDate(
                                                    dateFrom.year,
                                                    index + 2,
                                                    1
                                                ).minus(1, DateTimeUnit.DAY)
                                                setDateFrom(newStartDate)
                                                setDateTo(newEndDate)
                                            }
                                            Typography {
                                                variant = TypographyVariant.caption
                                                align = TypographyAlign.center
                                                sx {
                                                    width = 100.pct
                                                }
                                                +month
                                            }
                                        }
                                    }
                                }
                            }


                        }
                    }
                    2 -> {
                        Box {
                            sx {
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                alignItems = AlignItems.center
                                justifyContent = JustifyContent.spaceBetween
                                width = 100.pct
                                paddingLeft = 20.px
                                paddingRight = 20.px
                                paddingTop = 20.px
                                paddingBottom = 20.px
                            }

                            TextField {
                                sx {
                                    width = 40.pct
                                }
                                label = ReactNode(MainRes.string.from)
                                value = dateFrom
                                type = InputType.date
                                variant = FormControlVariant.standard
                                onClick = {
                                    val target = it.target as HTMLInputElement
                                    //target.type = InputType.date.toString()
                                }
                                onBlur = {
                                    val target = it.target as HTMLInputElement
                                    //target.type = InputType.text.toString()
                                }
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    setDateFrom(LocalDate.parse(target.value))
                                }
                            }

                            TextField {
                                sx {
                                    width = 40.pct
                                }
                                label = ReactNode(MainRes.string.to)
                                value = dateTo
                                type = InputType.date
                                variant = FormControlVariant.standard
                                onClick = {
                                    val target = it.target as HTMLInputElement
                                    //target.type = InputType.date.toString()
                                }
                                onBlur = {
                                    val target = it.target as HTMLInputElement
                                    //target.type = InputType.text.toString()
                                }
                                onChange = {
                                    val target = it.target as HTMLInputElement
                                    //setDateTo(LocalDate.parse(target.value))
                                    setDateTo(LocalDate.parse(it.target.asDynamic().value as String))
                                }
                            }

                        }
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }

                    Button {
                        sx {
                            color = primaryColor
                        }
                        variant = ButtonVariant.text
                        +MainRes.string.cancel
                        onClick = {
                            props.onDismiss()
                        }
                    }

                    Button {
                        sx {
                            backgroundColor = primaryColor
                        }
                        variant = ButtonVariant.contained
                        +MainRes.string.done
                        onClick = {
                            Napier.e("${dateFrom} ${dateTo}")
                            props.onConfirm(
                                when (tabValue) {
                                    0 -> Mode.WEEK
                                    1 -> Mode.MONTH
                                    2 -> Mode.CUSTOM
                                    else -> Mode.WEEK
                                },
                                dateFrom,
                                dateTo
                            )
                        }
                    }
                }
            }
        }
    }
}

package com.erstedigital.socialbank.ui.utils

import com.erstedigital.socialbank.MainRes
import io.github.aakira.napier.Napier
import js.core.Void
import kotlinx.browser.document
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import kotlin.reflect.KFunction1

actual fun openCamera(
    onSuccess: KFunction1<String, Unit>,
    onManualTransactionClick : () -> Unit,
    onClose: () -> Unit
) {

    (document.getElementById("app-wrapper") as HTMLElement).appendChild(document.createElement("div").apply {
        id = "reader"
    })
    (document.getElementById("reader") as HTMLElement).style.display = "block"

    val newButton = document.createElement("button")
    val closeButton = document.createElement("a")

    newButton.textContent = MainRes.string.manual_transaction
    newButton.attributes.setNamedItem(document.createAttribute("type").apply {
        value = "button"
    })
    newButton.className = "pure-material-button-contained manual-button"
    closeButton.className = "close"
    newButton.addEventListener("click", {
        onManualTransactionClick()
        cleanUp(newButton, closeButton, null)
    })

    closeButton.addEventListener("click", {
        cleanUp(newButton, closeButton, null)
        onClose()
    })

    (document.getElementById("app-wrapper") as HTMLElement).appendChild(newButton)
    (document.getElementById("app-wrapper") as HTMLElement).appendChild(closeButton)

    js("import('html5-qrcode')").then { qr ->
        var config = js("""
        {
          fps: 10,
          qrbox: {width: 400, height: 400},        
          supportedScanTypes: [qr.Html5QrcodeScanType.SCAN_TYPE_CAMERA],
          videoConstraints: {
                    facingMode: { exact: "environment" },
          },
        }
        """)
        val html5CodeScanner = js("new qr.Html5QrcodeScanner(\"reader\", config, false);")
        js("console.log(html5CodeScanner);")

        (document.getElementById("app") as HTMLElement).style.display = "none"

        // Modified part
        html5CodeScanner.render { decodedText ->

            html5CodeScanner.pause()
            cleanUp(newButton, closeButton, html5CodeScanner)
            (document.getElementById("reader") as HTMLElement).remove()
            onSuccess(decodedText as String)

        }
    }
}

fun cleanUp(newButton: Element, closeButton: Element, html5CodeScanner: dynamic) {
    (document.getElementById("app") as HTMLElement).style.display = "block"
    (document.getElementById("reader") as HTMLElement).style.display = "none"
    newButton.remove()
    closeButton.remove()
}
package com.erstedigital.socialbank.ui.statistics.store

import androidx.compose.ui.graphics.Color
import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.UpdateProfileRequest
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.StatisticsCustomRangeModel
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.domain.models.TransactionListModel
import com.erstedigital.socialbank.domain.models.UserModel
import com.erstedigital.socialbank.domain.usecase.auth.SignoutUsecase
import com.erstedigital.socialbank.domain.usecase.statistics.GetCustomRangeStatisticsUsecase
import com.erstedigital.socialbank.domain.usecase.statistics.GetStatisticsUsecase
import com.erstedigital.socialbank.domain.usecase.transactions.GetTransactionsUsecase
import com.erstedigital.socialbank.domain.usecase.user.GetProfileUsecase
import com.erstedigital.socialbank.domain.usecase.user.UpdateProfileUsecase
import com.erstedigital.socialbank.domain.utils.toHexColor
import com.erstedigital.socialbank.ui.transactions.store.TransactionStore
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class StatisticsStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    private val getStatisticsUsecase by inject<GetStatisticsUsecase>()
    private val getCustomRangeStatisticsUsecase by inject<GetCustomRangeStatisticsUsecase>()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): StatisticsStore = object : StatisticsStore, Store<StatisticsStore.Intent, StatisticsStore.State, StatisticsStore.Label> by storeFactory.create(
        name = "StatisticsStore",
        initialState = StatisticsStore.State(),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchStatistics)
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()
        data class Error(val message: String): Msg()
        data class StatisticsData(val statistics: StatisticsModel): Msg()
        data class CustomRangeStatisticsData(val statistics: StatisticsCustomRangeModel): Msg()

    }

    private sealed class Action {
        data object FetchStatistics: Action()
        data class FetchCustomRangeStatistics(val startDate: LocalDate, val endDate: LocalDate): Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<StatisticsStore.Intent, Action, StatisticsStore.State, Msg, StatisticsStore.Label>() {

        override fun executeAction(action: Action, getState: () -> StatisticsStore.State) {


            scope.launch {
                when (action) {
                    is Action.FetchStatistics -> getStatistics()
                    is Action.FetchCustomRangeStatistics -> getCustomRangeStatistics(action.startDate, action.endDate)
                }
            }
        }

        private suspend fun getStatistics () {
            dispatch(Msg.Loading)
            when (val result = getStatisticsUsecase.getStatistics()) {
                is ApiResponse.Success -> dispatch(Msg.StatisticsData(statistics = result.body.toModel()))
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }

                else -> {}
            }
        }

        private suspend fun getCustomRangeStatistics (startDate: LocalDate, endDate: LocalDate) {
            dispatch(Msg.Loading)
            when (val result = getCustomRangeStatisticsUsecase.getStatistics(startDate, endDate)) {
                is ApiResponse.Success -> dispatch(Msg.CustomRangeStatisticsData(statistics = result.body.toModel()))
                is ApiResponse.Error.HttpError -> {
                    dispatch(Msg.Error(result.errorMessage ?: result.errorBody ?: "Unknown error"))
                }
                is ApiResponse.Error.GenericError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }
                is ApiResponse.Error.SerializationError -> {
                    dispatch(Msg.Error(result.errorMessage ?: "Unknown error"))
                }

                else -> {}
            }
        }


        override fun executeIntent(intent: StatisticsStore.Intent, getState: () -> StatisticsStore.State) {
            val state = getState()
            when (intent) {
                is StatisticsStore.Intent.FetchCustomRangeStatistics -> {
                    scope.launch {
                        getCustomRangeStatistics(intent.startDate, intent.endDate)
                    }
                }
                else -> {}
            }
        }


    }

    private object ReducerImpl: Reducer<StatisticsStore.State, Msg> {
        override fun StatisticsStore.State.reduce(msg: Msg): StatisticsStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.StatisticsData -> copy(loading = false, statistics = msg.statistics, error = null)
            is Msg.CustomRangeStatisticsData -> copy(loading = false, customRangeStatistics = msg.statistics, error = null)
            else -> copy()
        }
    }
}
package com.erstedigital.socialbank.data.network.models.common

import com.erstedigital.socialbank.domain.models.ProductModel
import kotlinx.serialization.Serializable

@Serializable
data class Product(
    val id: Long?,
    val name: String? = null,
    val itemType: String? = null,
    val price: Double? = null,
    val vatRate: Double? = null,
    val organization: Organization? = null,
    val orgUnit: OrgUnit? = null,
    val category: String? = null,
    val categories: List<Category>
) {

    @Serializable
    data class Category(
        val category: String?
    )

    fun toModel(): ProductModel {
        val model = ProductModel(
            id,
            name,
            itemType,
            price,
            category,
            categories.mapNotNull { it.category }
        )
        return model
    }

}
package com.erstedigital.socialbank.data.datasources.transaction

import com.erstedigital.socialbank.data.network.models.request.CreateFsReceiptRequest
import com.erstedigital.socialbank.data.network.models.request.CreateManualTransactionRequest
import com.erstedigital.socialbank.data.network.models.request.CreateMultiTransactionRequest
import com.erstedigital.socialbank.data.network.models.request.DeleteTransactionRequest
import com.erstedigital.socialbank.data.network.models.request.GetTransactionsFilterRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateFsReceiptCategoryRequest
import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionListResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponseV2
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.github.aakira.napier.Napier
import io.ktor.client.HttpClient
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import io.ktor.http.path


class TransactionRemoteDataSource constructor(private val httpClient: HttpClient):
    TransactionDataSource.Remote {

    override suspend fun createManualTransaction(request: CreateManualTransactionRequest): ApiResponse<TransactionResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("transactions/manual")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun createFsReceipt(request: CreateFsReceiptRequest): ApiResponse<TransactionResponse, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("transactions/fs_receipt")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun createFsReceiptV2(request: CreateFsReceiptRequest): ApiResponse<TransactionResponseV2, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("transactions/fs_receipt/v2")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }
    }

    override suspend fun createMultiTransaction(request: CreateMultiTransactionRequest): ApiResponse<TransactionResponse, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("transactions/multi")
            }
            setBody(request)
        }

    }

    override suspend fun getTransactions(): ApiResponse<List<TransactionListResponse>, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("transactions")
            }
        }

    }

    override suspend fun getTransaction(id: Long): ApiResponse<TransactionResponse, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("transactions/$id")
            }
        }
    }

    override suspend fun updateFsReceiptCategory(request: UpdateFsReceiptCategoryRequest): ApiResponse<TransactionResponse, ErrorResponse>{

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Put
                path("transactions/${request.id}/fsReceipt")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun updateManualTransaction(request: UpdateManualTransactionRequest): ApiResponse<TransactionResponse, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Put
                path("transactions/${request.id}/manual")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun deleteTransactions(request: DeleteTransactionRequest): ApiResponse<Any, ErrorResponse> {

        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("transactions/delete")
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun getTransactionsFilter(request: GetTransactionsFilterRequest): ApiResponse<List<TransactionListResponse>, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Post
                path("transactions/filter")
            }
            setBody(request)
        }

    }

}
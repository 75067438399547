package com.erstedigital.socialbank.utils

val categories = listOf("other","auto/care", "auto/fuel", "baby/fake-food", "baby/food",
    "baby/supplies-hygiene", "basic/baking", "basic/canned-fish",
    "basic/canned-fruits-vegetable", "basic/flavoring", "basic/instant",
    "basic/jams-marmelade-honey", "basic/morning", "basic/oil-acid-grease",
    "basic/pasta", "basic/sauces", "basic/soy-products", "basic/spices",
    "basic/spread", "basic/sugar-flour-rice", "basic/tea-coffee-soups",
    "basic/wheat-legumes", "dairy/butter", "dairy/cheese", "dairy/cream",
    "dairy/eggs", "dairy/joghurts", "dairy/lact-free", "dairy/milk",
    "drinks/alcohol", "drinks/beer", "drinks/juice", "drinks/mineral-water",
    "drinks/non-alcoholic", "drinks/wine", "drugstore/beauty",
    "drugstore/dental", "drugstore/hygiene", "eating", "fashion/clothes",
    "fashion/kids", "free-time/books-news-magazines", "free-time/other",
    "free-time/sport", "free-time/toys", "free-time/visual",
    "frozen/fruit-vegetable", "frozen/instant", "frozen/sweet",
    "fruits-veg/dried-nuts", "fruits-veg/fruit", "fruits-veg/vegetable",
    "hazard", "health/lady", "health/medical-supplies", "health/sex",
    "health/vitamins-ointments", "home/accessories", "home/bags",
    "home/cleaning", "home/decorations", "home/laundry", "home/maintenence",
    "home/office", "home/plants-garden", "meat/deli", "meat/fish", "meat/meat",
    "meat/meat-products", "meat/smoked", "pastry/basic", "pastry/other",
    "pastry/salty", "pastry/sweet", "pets", "seasonal", "snacks/salty",
    "snacks/sweet"
)
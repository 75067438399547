package com.erstedigital.socialbank.data.network.models.common

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.toArgb
import com.erstedigital.socialbank.domain.models.UserModel
import kotlinx.serialization.Serializable


@Serializable
data class AppUser(
    val id: Long,
    val fullName: String?,
    val email: String?,
    val phoneNumber: String?,
    val iban: String?,
    val allowAnalysis: Boolean?,
    val color: String?
) {
    fun toModel(): UserModel {
        return UserModel(id,
                fullName,
                email = email,
                phoneNumber = phoneNumber,
                iban = iban,
                allowAnalysis = allowAnalysis,
                _color = color?.let {
                    return@let try {
                        Color(it.removePrefix("#").toLong(16)).toArgb()
                    } catch (e: Exception) {
                        e.printStackTrace()
                        null
                    }
                }
        )
    }
}

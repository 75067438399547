package com.erstedigital.socialbank.data.datasources.dashboard


import com.erstedigital.socialbank.data.network.models.response.DashboardResponse
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.HttpClient
import io.ktor.http.HttpMethod
import io.ktor.http.path

class DashboardRemoteDataSource constructor(private val httpClient: HttpClient): DashboardDataSource.Remote {
    override suspend fun getDashboard(): ApiResponse<DashboardResponse, ErrorResponse> {
        return httpClient.safeRequest{
            url {
                method = HttpMethod.Get
                path("dashboard")
            }
        }
    }

}
package com.erstedigital.socialbank.ui.transactions.products.store

import com.arkivanov.mvikotlin.core.store.Store
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.domain.models.ProductItemModel

interface ProductsStore: Store<ProductsStore.Intent, ProductsStore.State, Nothing> {

    sealed class Intent {
        data class UpdateProductCategory(val productId: Long, val category: String) : Intent()
    }

    data class State(
        val loading: Boolean = false,
        val error: String? = null,
        val products: List<ProductItemModel> = emptyList(),
        val updatedProduct: UpdateProductResponse? = null
    )

    sealed interface Label {
        object LocalStoreError : Label
        object NetworkAccessError : Label
        object UnknownError : Label
    }

}
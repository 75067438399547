package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.entity.toEntity
import com.erstedigital.socialbank.data.network.models.request.ChangePasswordRequest
import com.erstedigital.socialbank.data.network.models.request.LoginRequest
import com.erstedigital.socialbank.data.network.models.request.RegisterRequest
import com.erstedigital.socialbank.data.network.models.request.VerifyCodeRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import io.github.aakira.napier.Napier
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.util.Identity
import kotlin.js.JsName


class LoginUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    @JsName("userRemoteDataSource") private val userRemoteDataSource: UserRemoteDataSource,
    private val httpClient: HttpClient
) {
    suspend fun signIn(loginRequest: LoginRequest): ApiResponse<Any, ErrorResponse> {
        return userRemoteDataSource.login(loginRequest)
    }

    suspend fun logIn(verifyCodeRequest: LoginRequest): ApiResponse<Any, ErrorResponse> {
        val remoteResult = userRemoteDataSource.verifyCode(verifyCodeRequest)

        return when (remoteResult) {
            is ApiResponse.Success -> {
                val user = remoteResult.body.user?.toEntity()
                user?.token = remoteResult.body.token
                userLocalDataSource.setUser(user)

                val auth = httpClient.plugin(Auth)

                // reload token provider
                auth
                    .providers
                    .removeAt(auth.providers.indexOfFirst { it is BearerAuthProvider })

                auth.bearer {
                    this.loadTokens {
                        BearerTokens(user?.token!!, "")
                    }
                }

                remoteResult
            }

            is ApiResponse.Error -> {
                remoteResult
            }
        }
    }

    suspend fun register(registerRequest: RegisterRequest): Boolean {
        val remoteResult = userRemoteDataSource.register(registerRequest)

        return when (remoteResult) {
            is ApiResponse.Success -> {
                true
            }

            is ApiResponse.Error -> {
                false
            }
        }
    }

    suspend fun reset(resetRequest: ChangePasswordRequest): Boolean {
        val remoteResult = userRemoteDataSource.resetPassword(resetRequest)

        return when (remoteResult) {
            is ApiResponse.Success -> {
                true
            }

            is ApiResponse.Error -> {
                false
            }
        }
    }
}
package com.erstedigital.socialbank.data.network.models.request

import com.erstedigital.socialbank.domain.models.TransactionListModel
import kotlinx.serialization.Serializable

@Serializable
data class CreateMultiTransactionRequest(
    val transactions: List<Transaction>
) {

    @Serializable
    data class Transaction(val id: Long)


}

fun TransactionListModel.toRequest(): CreateMultiTransactionRequest.Transaction {
    return CreateMultiTransactionRequest.Transaction(id!!)
}
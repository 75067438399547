package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.domain.models.ExpectedIncomeModel
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class ExpectedIncomeResponse (
    var income: Double?,
    var recurringIncome: Boolean?,
    var businessIncome: Double?,
    var recurringBusinessIncome: Boolean?,
    var socialBenefits: Double?,
    var recurringSocialBenefits: Boolean?,
    var otherIncome: Double?,
    var recurringOtherIncome: Boolean?,
    var date: LocalDate?
) {
    fun toModel(): ExpectedIncomeModel {
        return ExpectedIncomeModel(
            income,
            recurringIncome,
            businessIncome,
            recurringBusinessIncome,
            socialBenefits,
            recurringSocialBenefits,
            otherIncome,
            recurringOtherIncome,
            date
        )
    }
}
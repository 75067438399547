package com.erstedigital.socialbank.ui.login

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.mvikotlin.core.instancekeeper.getStore
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.labels
import com.arkivanov.mvikotlin.extensions.coroutines.stateFlow
import com.erstedigital.socialbank.domain.usecase.auth.LoginUsecase
import com.erstedigital.socialbank.ui.login.store.LoginStore
import com.erstedigital.socialbank.ui.login.store.LoginStoreFactory
import com.erstedigital.socialbank.ui.onboarding.store.OnboardingStore
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

class LoginComponent(
    componentContext: ComponentContext,
    storeFactory: StoreFactory,
    private val output: (Output) -> Unit
): ComponentContext by componentContext {

    private val loginStore = instanceKeeper.getStore {
        LoginStoreFactory(storeFactory).create()
    }

    val label: Flow<LoginStore.Label> = loginStore.labels

    @OptIn(ExperimentalCoroutinesApi::class)
    val state: StateFlow<LoginStore.State> = loginStore.stateFlow

    fun onEvent(event: LoginStore.Intent) {
        loginStore.accept(event)
    }

    /**
     * This function is called when the user has successfully logged in.
     * It will navigate the user to the home screen.
     */
    fun onOutput(output: Output) {
        output(output)
    }

    sealed class Output {
        data object NavigateBack : Output()
        data object NavigateToHome : Output()
        data class NavigateToVerifyCode(val username: String) : Output()
    }
}
package com.erstedigital.socialbank.ui.react.home.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.home.utils.getExpenseCategories
import com.erstedigital.socialbank.ui.home.utils.getIncomeCategories
import com.erstedigital.socialbank.ui.home.utils.getResource
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import js.core.jso
import mui.icons.material.Add
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useState
import web.cssom.*

enum class CategoryType {
    INCOME, EXPENSE
}

external interface CategorySelectorProps: Props {
    var category: CategoryInput?
    var type: CategoryType?
    var expanded: Boolean?
    var minimal : Boolean?
    var onCategoryClicked: (CategoryInput) -> Unit
}

val CategorySelector: FC<CategorySelectorProps> = FC { props ->

    val items = if (props.type === CategoryType.EXPENSE) getExpenseCategories() else getIncomeCategories()
    val (showPrimary, setShowPrimary) = useState(false)
    val (showSecondary, setShowSecondary) = useState(false)
    val (selectedPrimaryCategory, setSelectedPrimaryCategory) = useState<CategoryInput?>(null)
    val (selectedSecondaryCategory, setSelectedSecondaryCategory) = useState("")

    Grid {
        container = true
        spacing = responsive(1)
        columns = responsive(1)
            items.map { catItem ->
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        width = 100.pct
                        padding = 10.px
                        marginTop = 5.px
                        marginBottom = 5.px
                        cursor = Cursor.pointer

                        backgroundColor =
                            if (props.category?.primary === catItem.main) surfaceColor else Color(
                                "#fff"
                            )
                        borderRadius = 10.px
                        border = Border(2.px, LineStyle.solid, Color("#2870ED"))
                        //marginTop = 5.px
                        //marginBottom = 5.px
                    }

                    onClick = {
                        props.onCategoryClicked(CategoryInput(catItem.main))
                        if (props.expanded === false) {
                            setShowPrimary(false)
                        }
                        setSelectedPrimaryCategory(CategoryInput(catItem.main))
                        setSelectedSecondaryCategory("")
                    }

                    ReactHTML.img {
                        src = catItem.drawable
                        style = jso {
                            width = 10.vw
                            maxWidth = 50.px
                            height = 10.vw
                            maxHeight = 50.px
                        }
                    }
                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center
                        sx {
                            paddingLeft = 10.px
                        }
                        +catItem.main
                    }
                }
                if (selectedPrimaryCategory?.primary == catItem.main) {
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            alignItems = AlignItems.start
                            width = 100.pct
                            borderRadius = 10.px
                            backgroundColor = surfaceColor
                        }

                        items
                            .find { it.main === props.category!!.primary }
                            ?.subcategories
                            ?.map { cat ->
                                Box {
                                    sx {
                                        width = 100.pct
                                        cursor = Cursor.pointer
                                        paddingTop = 3.px
                                        paddingBottom = 3.px
                                        if (selectedSecondaryCategory == cat) {
                                            backgroundColor = Color("#2870ED")
                                            borderRadius = 10.px
                                        }
                                    }
                                    onClick = {
                                        setShowSecondary(false)
                                        props.onCategoryClicked(CategoryInput(props.category!!.primary, cat))
                                        setSelectedSecondaryCategory(cat)
                                    }
                                    Typography {
                                        sx {
                                            width = 100.pct
                                            paddingLeft = 10.px
                                            color = if (selectedSecondaryCategory == cat) Color("#fff") else Color("#000")
                                        }
                                        variant = TypographyVariant.body1
                                        align = TypographyAlign.left
                                        +cat
                                    }
                                }
                            }
                    }
                }

            }
    }
}

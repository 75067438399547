package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.TransactionResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class UpdateManualTransactionUsecase(
    private val transactionsRemoteDataSource: TransactionRemoteDataSource
) {
    suspend fun updateManualTransaction(request: UpdateManualTransactionRequest): ApiResponse<TransactionResponse, ErrorResponse> {
        return transactionsRemoteDataSource.updateManualTransaction(request)
    }
}
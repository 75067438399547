package com.erstedigital.socialbank.ui.react.dms.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.react.home.components.DuplicateDialogProps
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Modal
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.pct
import web.cssom.px
import web.cssom.translate
import web.html.HTMLInputElement

external interface FolderDialogProps: Props {
    var setShowDialog: (Boolean) -> Unit
    var onConfirm: (String) -> Unit
    var onDismiss: () -> Unit
    var open: Boolean
    var initName: String?
}

val FolderDialog: FC<FolderDialogProps> = FC { props ->

    val (name, setName) = useState(props.initName ?: "")


    Modal {
        open = props.open
        onClose = { _, _ ->
            props.setShowDialog(false)
            props.onDismiss()
        }

        Box {
            sx {
                width = 300.px
                position = Position.absolute
                top = 50.pct
                left = 50.pct
                transform = translate((-50).pct, (-50).pct)
                backgroundColor = Color("#fff")
                borderRadius = 10.px
                padding = 15.px
            }

            Box {
                sx {
                    display= Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.center
                    width = 100.pct
                }

                Typography {
                    variant = TypographyVariant.h6
                    +(if (props.initName.isNullOrEmpty()) "New Folder" else "Rename Folder")
                    sx {
                        marginBottom = 20.px
                    }
                }

                TextField {
                    sx {
                        width = 100.pct
                        marginBottom = 20.px
                        marginTop = 20.px
                    }
                    value = name
                    onChange = { event ->
                        setName((event.target as HTMLInputElement).value)
                    }
                    label = ReactNode("Folder name")
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }

                    Button {
                        sx {
                            color = primaryColor
                        }
                        variant = ButtonVariant.text
                        +"Dismiss"
                        onClick = {
                            props.onDismiss()
                            props.setShowDialog(false)
                        }
                    }

                    Button {
                        sx {
                            backgroundColor = primaryColor
                        }
                        variant = ButtonVariant.contained
                        +"Confirm"
                        onClick = {
                            if (name.contains("/") || name.contains("shared")) {
                                props.onDismiss()
                                props.setShowDialog(false)
                            } else {
                                props.onConfirm(name)
                                props.setShowDialog(false)
                            }
                        }
                    }
                }
            }
        }
    }
}
package com.erstedigital.socialbank.ui.transactions.manual.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.request.CreateCompanyRequest
import com.erstedigital.socialbank.data.network.models.request.CreateManualTransactionRequest
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.CompanyModel
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.domain.usecase.company.CreateCompanyUsecase
import com.erstedigital.socialbank.domain.usecase.org.GetCompaniesUsecase
import com.erstedigital.socialbank.domain.usecase.transactions.CreateManualTransactionUsecase
import com.erstedigital.socialbank.domain.usecase.transactions.UpdateProductCategoryUsecase
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.transactions.detail.store.DetailStoreFactory
import com.erstedigital.socialbank.ui.transactions.products.store.ProductsStore
import io.github.aakira.napier.Napier
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ManualTransactionStoreFactory(
    private val storeFactory: StoreFactory
): KoinComponent {

    val createManualTransactionUsecase: CreateManualTransactionUsecase by inject()
    val getCompaniesUsecase: GetCompaniesUsecase by inject()
    val createCompanyUsecase: CreateCompanyUsecase by inject()
    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): ManualTransactionStore = object : ManualTransactionStore, Store<ManualTransactionStore.Intent, ManualTransactionStore.State, Nothing> by storeFactory.create(
        name = "ManualTransactionStore",
        initialState = ManualTransactionStore.State(
            companyList = emptyList()
        ),
        bootstrapper = coroutineBootstrapper {
            launch {
                dispatch(Action.FetchOrganizations(""))
            }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()

        data object Loaded : Msg()

        data object TransactionCreated : Msg()

        data class CompaniesData(val companies: List<CompanyModel>): Msg()
        data class NewCompanyData(val newCompanyData: CompanyModel): Msg()
        data class Error(val message: String): Msg()
    }

    private sealed class Action {
        data class FetchOrganizations(var q: String) : Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<ManualTransactionStore.Intent, Action, ManualTransactionStore.State, Msg, Nothing>() {

        override fun executeAction(action: Action, getState: () -> ManualTransactionStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchOrganizations -> getCompanies(action.q)
                    else -> {}
                }
            }
        }


        override fun executeIntent(intent: ManualTransactionStore.Intent, getState: () -> ManualTransactionStore.State) {
            scope.launch {
                when (intent) {
                    is ManualTransactionStore.Intent.CreateManualTransaction -> createManualTransaction(
                        amount = intent.amount,
                        date = intent.date,
                        category = intent.category,
                        companyId = intent.companyId
                    )
                    is ManualTransactionStore.Intent.SearchCompany -> getCompanies(intent.q)
                    is ManualTransactionStore.Intent.CreateCompany -> createCompany(intent.name, intent.ico)
                    else -> {}
                }
            }
        }

        fun createCompany(name: String, ico: String?) {
            scope.launch {
                dispatch(Msg.Loading)
                val response = createCompanyUsecase(CreateCompanyRequest(name, ico))
                when (response) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.Loaded)
                        dispatch(Msg.NewCompanyData(
                            CompanyModel(
                                id = response.body.id,
                                name = response.body.name,
                                ico = response.body.ico,
                            )
                        ))
                    }
                    is ApiResponse.Error -> {
                        Napier.e("Error creating company: ${response}")
                        dispatch(Msg.Error("Error creating company"))
                    }
                }
            }
        }

        fun getCompanies(q: String) {
            scope.launch {
                dispatch(Msg.Loading)
                when (val response = getCompaniesUsecase(q)) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.Loaded)
                        dispatch(Msg.CompaniesData(response.body.map { it.toModel() }))
                    }
                    is ApiResponse.Error -> {
                        dispatch(Msg.Error("Error fetching companies"))
                    }
                }
            }
        }

        fun createManualTransaction(
            amount: Double,
            date: LocalDate,
            category: CategoryInput,
            companyId: Long?
        ) {
            scope.launch {
                dispatch(Msg.Loading)
                val response = createManualTransactionUsecase.createManualTransaction(
                    CreateManualTransactionRequest(
                        price = amount,
                        date = date,
                        category = category.let { "${it.primary}/${it.secondary}" },
                        companyId = companyId
                    )
                )
                when (response) {
                    is ApiResponse.Success -> {
                        dispatch(Msg.TransactionCreated)
                    }
                    is ApiResponse.Error -> {
                        Napier.e("Error creating transaction: ${response}")
                        dispatch(Msg.Error("Error creating transaction"))
                    }
                }
            }
        }

    }

    private object ReducerImpl: Reducer<ManualTransactionStore.State, Msg> {
        override fun ManualTransactionStore.State.reduce(msg: Msg): ManualTransactionStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true, newCompanyCreated = false, transactionCreated = false)
            is Msg.Loaded -> copy(loading = false)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.CompaniesData -> copy(companyList = msg.companies)
            is Msg.NewCompanyData -> {
                val newList = companyList.toMutableList()
                newList.add(msg.newCompanyData)
                copy(companyList = newList, loading = false, newCompanyCreated = true)
            }
            is Msg.TransactionCreated -> copy(transactionCreated = true, loading = false)
        }
    }
}
package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.request.UpdateManualTransactionRequest
import com.erstedigital.socialbank.domain.models.CompanyModel
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.ui.react.home.components.CategoryType
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import com.erstedigital.socialbank.ui.react.utils.whiteColor
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.onChange
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType
import kotlin.math.absoluteValue

external interface BasicInfoEditCardProps: Props {
    var transaction: TransactionModel?
    var edit: (UpdateManualTransactionRequest) -> Unit
    var onBack: (Boolean) -> Unit
    var type : CategoryType
    var category: CategoryInput?
}

val BasicInfoEditCard: FC<BasicInfoEditCardProps> = FC { props ->
    val (state, setState) = useState(props.transaction)

    val (price, setPrice) = useState(state?.manualTransaction?.amount)
    val (selectedCompany, setSelectedCompany) = useState<CompanyModel?>(null)
    val (date, setDate) = useState(
        LocalDateTime.parse(
            js("new Date().toISOString().split(\"Z\")[0]") as String
        ).date)
    val (showCreateCompanyModal, setShowCreateCompanyModal) = useState(false)

    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 20.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
        }

        elevation = 2

        CardContent {
            Typography {
                sx {
                    marginBottom = 10.px
                    fontWeight = FontWeight.bold
                    width = 70.pct
                    marginRight = 5.pct
                }
                variant = TypographyVariant.body1
                +MainRes.string.edit_values
            }
            TextField {
                sx {
                    width = 70.pct
                    marginBottom = 25.px
                }
                value = if (price == 0.0) 0 else price.toString().trimStart('0')
                label = ReactNode(MainRes.string.total_price)
                variant = FormControlVariant.outlined
                type = InputType.number
                onChange = { e ->
                    setPrice(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
                }

            }
            TextField {
                sx {
                    width = 100.pct
                }
                label = ReactNode("")
                value = date
                type = InputType.date
                variant = FormControlVariant.outlined
                onClick = {
                    val target = it.target as HTMLInputElement
                    //setDate(LocalDate.parse(target.value))
                    setDate(LocalDate.parse(it.target.asDynamic().value))
                }
                onBlur = {
                    val target = it.target as HTMLInputElement
                    //target.type = InputType.text.toString()
                }
                onChange = {
                    val target = it.target as HTMLInputElement
                    setDate(LocalDate.parse(target.value))
                }
            }
            InfoRow {
                label = MainRes.string.merchant_name
                value = props.transaction?.getOrganization()?.name ?: MainRes.string.unknown
            }

            Button {
                sx {
                    color = whiteColor
                    marginTop = 10.px
                    marginBottom = 10.px
                    marginTop = 10.px
                }
                onClick = {
                    GlobalScope.launch {
                        props.edit(UpdateManualTransactionRequest(
                            id = state?.id ?: 0,
                            date = date,
                            price = if(props.type == CategoryType.EXPENSE) price!!.absoluteValue *(-1.0) else price!!.absoluteValue,
                            organizationId = selectedCompany?.id,
                            category = "${props.category?.primary}/${props.category?.secondary}"
                        ))
                    }
                }
                variant = ButtonVariant.contained
                +MainRes.string.save
            }
        }
    }
}

package com.erstedigital.socialbank.ui.home.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.ModalBottomSheetLayout
import androidx.compose.material.ModalBottomSheetValue
import androidx.compose.material.Text
import androidx.compose.material.rememberModalBottomSheetState
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.ui.home.store.HomeStore
import com.erstedigital.socialbank.ui.home.utils.AppCategoryModel
import com.erstedigital.socialbank.ui.home.utils.CategoryInput
import com.erstedigital.socialbank.utils.parseCategoryInput
import com.netguru.multiplatform.charts.round
import io.github.aakira.napier.Napier
import kotlinx.coroutines.flow.Flow
import kotlinx.datetime.LocalDate


fun formatDate(date: LocalDate?): String {
    if (date == null) return ""
    return "${date.dayOfMonth}.${date.monthNumber}.${date.year}"
}

fun formatPrice(price: Double?): String {
    if (price == null) return ""
    return "${price.round(2)} €"
}

@OptIn(ExperimentalMaterialApi::class)
@Composable
fun ScannerResultBottomSheet(
    label: Flow<HomeStore.Label>,
    transactionModel: TransactionModel?,
    onCategoryChanged: (CategoryInput) -> Unit,
    onDetailClicked: () -> Unit,
    content: @Composable () -> Unit,
) {
    val modalBottomSheetState = rememberModalBottomSheetState(
        initialValue = ModalBottomSheetValue.Hidden
    )

    LaunchedEffect(label) {
        label.collect { label ->
            Napier.e("label: $label")
            when (label) {
                is HomeStore.Label.TransactionCreated -> {
                    modalBottomSheetState.show()
                }
                is HomeStore.Label.DismissResultBottomSheet -> {
                    modalBottomSheetState.hide()
                }
            }
        }
    }

    val currentCategory = transactionModel?.fsReceiptModel?.category.parseCategoryInput()

    ModalBottomSheetLayout(
        sheetState = modalBottomSheetState,
        modifier = Modifier.zIndex(1f),
        sheetShape = RoundedCornerShape(topStart = 16.dp, topEnd = 16.dp),
        sheetContent = {
            Column(
                modifier = Modifier.fillMaxWidth().padding(horizontal = 16.dp, vertical = 20.dp)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Column {
                        Text(MainRes.string.date_time_purchase, style = MaterialTheme.typography.labelMedium)
                        Text(formatDate(transactionModel?.fsReceiptModel?.getIssueLocalDate()), style = MaterialTheme.typography.bodyLarge)
                    }
                    Column {
                        Text(MainRes.string.amount, style = MaterialTheme.typography.labelMedium)
                        Text(formatPrice(transactionModel?.getTotalAmount()), style = MaterialTheme.typography.bodyLarge)
                    }
                }
                Spacer(modifier = Modifier.height(16.dp))
                Text(
                    MainRes.string.merchant_name,
                    style = MaterialTheme.typography.labelMedium
                )
                Text(transactionModel?.getOrganization()?.name ?: "", style = MaterialTheme.typography.bodyLarge)
                Spacer(modifier = Modifier.height(16.dp))
                Text("Category")
                CategorySelector(currentCategory, onCategoryChanged)
                Spacer(modifier = Modifier.height(16.dp))
                Button(
                    onClick = { onDetailClicked() },
                    modifier = Modifier.padding(0.dp)
                ) {
                    androidx.compose.material3.Text(
                        text = MainRes.string.detail,
                        style = MaterialTheme.typography.bodyMedium,
                    )
                }
            }
        }
    ) {
        content()
    }
}
package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.domain.models.TransactionListModel
import kotlinx.serialization.Serializable

@Serializable
data class TransactionListResponse(
    val id: Long? = null,
    val price: Double? = null,
    val date: String? = null,
    val type: String? = null,
    val organizationName: String? = null,
    val organizationId: Long? = null,
    val category: String? = null,
    val splitCreatorName: String? = null,
    val splitTotalPrice: Double? = null,
    val splitPeopleCount: Int? = null,
) {
    fun toModel(): TransactionListModel {
        val model = TransactionListModel(
            id,
            price,
            date,
            type,
            organizationName,
            organizationId,
            category,
            splitCreatorName,
            splitTotalPrice,
            splitPeopleCount
        )
        return model
    }
}
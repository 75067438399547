package com.erstedigital.socialbank.data.network.models.request

import kotlinx.serialization.Serializable

@Serializable
data class LoginIdpRequest(
    val fullName: String,
    val email: String,
    val fcmToken: String?,
    val isSocialBank: Boolean
)
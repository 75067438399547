package com.erstedigital.socialbank.ui.react.statistics.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.StatisticsModel
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import io.data2viz.charts.chart.chart
import io.data2viz.charts.chart.discrete
import io.data2viz.charts.chart.mark.bar
import io.data2viz.charts.chart.quantitative
import io.data2viz.charts.viz.newVizContainer
import io.data2viz.color.Colors
import io.data2viz.geom.Size
import kotlinx.browser.document
import mui.material.Box
import mui.material.Card
import mui.material.CardContent
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import react.useEffect
import react.useRef
import web.cssom.*
import kotlin.math.roundToInt

external interface BarChartCardProps: Props {
    var slice: StatisticsModel.DateSlice
}

val BarChartCard: FC<BarChartCardProps> = FC { props ->

    val containerRef = useRef<HTMLDivElement>(null)
    val weekDays = listOf("Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun")

    useEffect(props.slice) {

        val d = document.getElementById("chart") as HTMLDivElement
        d.innerHTML = ""

        val distinctVals = props.slice.chart.points.filter { it.expense > 0 }
        val isExpenseConstant = distinctVals.size == 0

        if (isExpenseConstant) {
            d.innerHTML = "<div style=\"display: flex; justify-content: center; align-items: center; height: 100%;\"><h3>No expenses throughout this period.</h3></div>"
            return@useEffect
        }

        val vc = d.newVizContainer().apply {
            size = Size(300.0, 200.0)
        }
        vc.chart(data = props.slice.chart.points) {

            config {
                backgroundColor = Colors.rgb(0xE4EAF0)
                yAxis {
                    enableAxisLine = false
                    enableTicks = false
                    //enableAxis = false
                }
                xAxis {
                    enableAxisLine = false
                    enableTicks = false
                }
                mark {
                    fills = listOf(Colors.rgb(0xFF6130))
                    fillsHighlight = listOf(Colors.rgb(0xFF6130))
                    fillsSelect = listOf(Colors.rgb(0xFF6130))
                    strokeColors = listOf(Colors.rgb(0xFF6130))
                    strokeColorsHighlight = listOf(Colors.rgb(0xFF6130))
                    strokeColorsSelect = listOf(Colors.rgb(0xFF6130))
                }
            }

            val dayDimension =  if (props.slice.type === StatisticsModel.DateSlice.Type.WEEK) {
                discrete({ weekDays[domain.date?.dayOfWeek?.ordinal!!]})
            } else {
                discrete({ domain.date?.dayOfMonth.toString() })
            }

            val expenseDimension = quantitative({ domain.expense.roundToInt().toDouble() })
            bar(dayDimension, expenseDimension)
        }
    }

    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 20.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
        }

        elevation = 2

        CardContent {
            Box {
                sx {
                    width = 300.px
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    variant = TypographyVariant.body1
                    +MainRes.string.expense
                }
                Box {
                    sx {
                        width = 100.pct
                        height = 300.px
                    }
                    id = "chart"
                }
            }
        }
    }
}
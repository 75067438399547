package com.erstedigital.socialbank.ui.react.planning.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.request.UpdateExpectedIncomeRequest
import com.erstedigital.socialbank.ui.planning.PlanningComponent
import com.erstedigital.socialbank.ui.planning.store.PlanningStore
import com.erstedigital.socialbank.ui.react.utils.graphIncomeColor
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.onChange
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType

external interface IncomeViewProps: Props {
    var values: List<Pair<String, Double>>
    var parent: PlanningComponent
}

val IncomeView: FC<IncomeViewProps> = FC { props ->
    val (state, setState) = useState(props.parent.state.value)

    val (income, setIncome) = useState(state.expectedIncome?.income ?: 0.0)
    val (incomeSwitched, setIncomeSwitched) = useState(state.expectedIncome?.recurringIncome ?: false)
    val (businessIncome, setBusinessIncome) = useState(state.expectedIncome?.businessIncome ?: 0.0)
    val (businessIncomeSwitched, setBusinessIncomeSwitched) = useState(state.expectedIncome?.recurringBusinessIncome ?: false)
    val (socialBenefits, setSocialBenefits) = useState(state.expectedIncome?.socialBenefits ?: 0.0)
    val (socialBenefitsSwitched, setSocialBenefitsSwitched) = useState(state.expectedIncome?.recurringSocialBenefits ?: false)
    val (other, setOther) = useState(state.expectedIncome?.otherIncome ?: 0.0)
    val (otherSwitched, setOtherSwitched) = useState(state.expectedIncome?.recurringOtherIncome ?: false)
    val (sum, setSum) = useState(0.0)

    val prevIncomeSwitchedRef = useRef(incomeSwitched)
    val prevBusinessIncomeSwitchedRef = useRef(businessIncomeSwitched)
    val prevSocialBenefitsSwitchedRef = useRef(socialBenefitsSwitched)
    val prevOtherSwitchedRef = useRef(otherSwitched)

    useEffect(listOf(income, businessIncome, socialBenefits, other)) {
        setSum(income + businessIncome + socialBenefits + other)
        console.log(state.expectedIncome)
    }

    fun updateIncome() {
        val requestBody = UpdateExpectedIncomeRequest(
            income = income,
            recurringIncome = incomeSwitched,
            businessIncome = businessIncome,
            recurringBusinessIncome = businessIncomeSwitched,
            socialBenefits = socialBenefits,
            recurringSocialBenefits = socialBenefitsSwitched,
            otherIncome = other,
            recurringOtherIncome = otherSwitched
        )
        props.parent.onEvent(PlanningStore.Intent.UpdateExpectedIncome(requestBody))
    }

    useEffect(listOf(incomeSwitched, businessIncomeSwitched, socialBenefitsSwitched, otherSwitched)) {
        if (prevIncomeSwitchedRef.current != incomeSwitched) {
            updateIncome()
        } else {
            prevIncomeSwitchedRef.current = incomeSwitched
        }
        if (prevBusinessIncomeSwitchedRef.current != businessIncomeSwitched) {
            updateIncome()
        } else {
            prevBusinessIncomeSwitchedRef.current = businessIncomeSwitched
        }
        if (prevSocialBenefitsSwitchedRef.current != socialBenefitsSwitched) {
            updateIncome()
        } else {
            prevSocialBenefitsSwitchedRef.current = socialBenefitsSwitched
        }
        if (prevOtherSwitchedRef.current != otherSwitched) {
            updateIncome()
        } else {
            prevOtherSwitchedRef.current = otherSwitched
        }
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                //justifyContent = JustifyContent.spaceBetween
                //height = 100.pct
                marginLeft = 10.px
            }
            Typography {
                sx {
                    marginBottom = 10.px
                    //marginTop = 50.px
                }
                variant = TypographyVariant.h5
                +MainRes.string.planning_expected_income
            }
            Typography {
                sx {
                    marginBottom = 10.px
                    color = graphIncomeColor
                    //marginTop = 50.px
                }
                variant = TypographyVariant.h6
                +MainRes.string.planning_sum.format(sum.toString())
            }
        }
    }

    // Legend
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        Typography {
            sx {
                marginBottom = 10.px
                fontWeight = FontWeight.bold
                width = 70.pct
                marginRight = 5.pct
            }
            variant = TypographyVariant.body1
            +MainRes.string.planning_income_legend
        }
        Typography {
            sx {
                marginBottom = 10.px
                fontWeight = FontWeight.bold
                width = 25.pct
            }
            variant = TypographyVariant.body1
            +MainRes.string.planning_income_legend_switch
        }
    }

    // Income
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (income == 0.0) 0 else income.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_income)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setIncome(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateIncome()
            }
        }
        Switch {
            sx {
                //marginTop = 25.px
            }
            checked = incomeSwitched
            onChange = { e, checked ->
                setIncomeSwitched(checked)
            }
            //color = primaryColor
        }
    }

    // Income from business
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (businessIncome == 0.0) 0 else businessIncome.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_income_business)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setBusinessIncome(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateIncome()
            }
        }
        Switch {
            sx {
                //marginTop = 25.px
            }
            checked = businessIncomeSwitched
            onChange = { e, checked ->
                setBusinessIncomeSwitched(checked)
            }
        }
    }

    // Social benefits
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (socialBenefits == 0.0) 0 else socialBenefits.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_income_social)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setSocialBenefits(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateIncome()
            }
        }
        Switch {
            sx {
                //marginTop = 25.px
            }
            checked = socialBenefitsSwitched
            onChange = { e, checked ->
                setSocialBenefitsSwitched(checked)
            }
        }
    }

    // Other
    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            width = 100.pct
        }
        TextField {
            sx {
                width = 70.pct
                marginBottom = 25.px
            }
            value = if (other == 0.0) 0 else other.toString().trimStart('0')
            label = ReactNode(MainRes.string.planning_income_other)
            variant = FormControlVariant.outlined
            type = InputType.number
            onChange = { e ->
                setOther(if ((e.target as HTMLInputElement).value.trimStart('0').isEmpty()) 0.0 else (e.target as HTMLInputElement).value.trimStart('0').toDouble())
            }
            onBlur = { e ->
                updateIncome()
            }
        }
        Switch {
            sx {
                //marginTop = 25.px
            }
            checked = otherSwitched
            onChange = { e, checked ->
                setOtherSwitched(checked)
            }
        }
    }

    /*listOf(Pair("Income", 2000.0), Pair("Income from business", 0.0), Pair("Social benefits", 150.0), Pair("Other", 35.90)).map { pair ->
        Card {
            sx {
                marginLeft = 3.px
                marginRight = 3.px
                marginBottom = 10.px
                marginTop = 4.px
                //backgroundColor = surfaceColor
                borderRadius = cardBorderRadius
            }
            elevation = 2

            CardContent {
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }
                    Typography {
                        sx {
                        }
                        align = TypographyAlign.right
                        variant = TypographyVariant.body1
                        +pair.first
                    }
                    Typography {
                        sx {
                            color = graphIncomeColor
                        }
                        align = TypographyAlign.right
                        variant = TypographyVariant.body1
                        if(pair.second == 0.0) {
                            +"-"
                        } else {
                            +"${pair.second} €"
                        }
                    }
                }
            }
        }
    }*/
}
package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.entity.toEntity
import com.erstedigital.socialbank.data.network.models.request.LoginAnonymousRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.LoginIdpResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class LoginAnonymousUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val userRemoteDataSource: UserRemoteDataSource
) {
    suspend fun signIn(loginAnonymousRequest: LoginAnonymousRequest): ApiResponse<LoginIdpResponse, ErrorResponse> {
        val res = userRemoteDataSource.loginAnonymous(loginAnonymousRequest)

        return when (res) {
            is ApiResponse.Success -> {
                val user = res.body.user?.toEntity()
                user?.token = res.body.token

                userLocalDataSource.setUser(user)
                res
            }

            is ApiResponse.Error -> {
                res
            }
        }

    }
}
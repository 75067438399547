package com.erstedigital.socialbank.data.network.models.common

import kotlinx.serialization.Serializable

@Serializable
data class Attribute(
    val id: Long? = 0,
    val key: String? = "",
    val value: String? = "",
    val type: String? = ""
)
package com.erstedigital.socialbank.ui.transactions.products.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.core.utils.ExperimentalMviKotlinApi
import com.arkivanov.mvikotlin.extensions.coroutines.CoroutineExecutor
import com.arkivanov.mvikotlin.extensions.coroutines.coroutineBootstrapper
import com.erstedigital.socialbank.data.network.models.response.UpdateProductResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.domain.models.ProductItemModel
import com.erstedigital.socialbank.domain.models.ProductModel
import com.erstedigital.socialbank.domain.usecase.transactions.UpdateProductCategoryUsecase
import io.github.aakira.napier.Napier
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ProductsStoreFactory(
    private val storeFactory: StoreFactory,
    private val products: List<ProductItemModel>
): KoinComponent {

    val updateProductCategoryUsecase: UpdateProductCategoryUsecase by inject()

    @OptIn(ExperimentalMviKotlinApi::class)
    fun create(): ProductsStore = object : ProductsStore, Store<ProductsStore.Intent, ProductsStore.State, Nothing> by storeFactory.create(
        name = "ProductsStore",
        initialState = ProductsStore.State(
            products = products
        ),
        bootstrapper = coroutineBootstrapper {
             launch {
                 dispatch(Action.FetchProducts)
             }
        },
        executorFactory = ::ExecutorImpl,
        reducer = ReducerImpl
    ) {}

    private sealed class Msg {
        data object Loading : Msg()

        data object Loaded : Msg()
        data class ProductUpdated(val updatedProduct: UpdateProductResponse) : Msg()
        data class Error(val message: String): Msg()
    }

    private sealed class Action {
        object FetchProducts : Action()
    }

    private inner class ExecutorImpl: CoroutineExecutor<ProductsStore.Intent, Action, ProductsStore.State, Msg, Nothing>() {

        override fun executeAction(action: Action, getState: () -> ProductsStore.State) {
            scope.launch {
                when (action) {
                    is Action.FetchProducts -> {
                        dispatch(Msg.Loaded)
                    }
                }
            }
        }


        override fun executeIntent(intent: ProductsStore.Intent, getState: () -> ProductsStore.State) {
            scope.launch {
                when (intent) {
                    is ProductsStore.Intent.UpdateProductCategory -> {
                        updateProductCategory(intent.productId, intent.category)
                    }
                }
            }
        }

        private suspend fun updateProductCategory(productId: Long, category: String) {
            dispatch(Msg.Loading)

            val response = updateProductCategoryUsecase(productId, category)
            when (response) {
                is ApiResponse.Success -> {
                    dispatch(Msg.ProductUpdated(response.body))
                }
                else -> {
                    Napier.e("${response}")
                    dispatch(Msg.Error("Error updating product category"))
                }
            }
        }


    }

    private object ReducerImpl: Reducer<ProductsStore.State, Msg> {
        override fun ProductsStore.State.reduce(msg: Msg): ProductsStore.State = when (msg) {
            is Msg.Loading -> copy(loading = true)
            is Msg.Loaded -> copy(loading = false)
            is Msg.Error -> copy(loading = false, error = msg.message)
            is Msg.ProductUpdated -> copy(loading = false, updatedProduct = msg.updatedProduct)
        }
    }
}
package com.erstedigital.socialbank.ui.react.dms.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.data.network.models.common.Document
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.primaryColor
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import io.github.aakira.napier.Napier
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.base.ClickAwayListener
import mui.icons.material.Folder
import mui.icons.material.FolderShared
import mui.icons.material.InsertDriveFile
import mui.material.Grid
import mui.material.MenuItem
import mui.material.MenuList
import mui.material.Paper
import mui.material.Popper
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Fragment
import react.Props
import react.useRef
import react.useState
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.px
import web.html.HTMLDivElement


enum class DocumentType {
    SHAREDFOLDER,
    FOLDER,
    FILE
}
external interface DocumentItemProps: Props {
    var name: String
    var type: DocumentType
    var editable: Boolean
    var onOpen: () -> Unit
    var onDelete: () -> Unit
    var onRename: () -> Unit
}

val DocumentItem: FC<DocumentItemProps> = FC { props ->
    val anchorRef = useRef<HTMLDivElement>(null)
    val (menuOpen, setMenuOpen) = useState(false)

    Fragment {
        Grid {
            item = true
            onClick = {
                props.onOpen()
            }
            onContextMenu = {
                it.preventDefault()
                if (props.editable && props.type != DocumentType.SHAREDFOLDER) {
                    setMenuOpen(true)
                }
            }
            ref = anchorRef

            sx {
                marginLeft = 10.px
                marginRight = 10.px
                marginBottom = 3.px
                marginTop = 3.px
                backgroundColor = surfaceColor
                borderRadius = cardBorderRadius
                height = 80.px
                width = 60.px
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                paddingTop = 5.px
                cursor = Cursor.pointer

            }
            if (props.type == DocumentType.FOLDER) {
                Folder {
                    sx {
                        fontSize = 50.px
                        color = primaryColor
                    }
                }
            } else if (props.type == DocumentType.SHAREDFOLDER) {
                FolderShared {
                    sx {
                        fontSize = 50.px
                        color = primaryColor
                    }
                }

            } else {
                InsertDriveFile {
                    sx {
                        fontSize = 50.px
                        color = primaryColor
                    }
                }
            }

            Typography {
                variant = TypographyVariant.caption
                align = TypographyAlign.center
                +props.name
            }
        }
        Popper {
            open = menuOpen
            anchorEl = anchorRef.current
            //disablePortal = true

            Paper {

                MenuList {
                    autoFocusItem = true
                    MenuItem {
                        key = MainRes.string.delete
                        onClick = {
                            setMenuOpen(false)
                            props.onDelete()
                        }
                        +MainRes.string.delete
                    }
                    MenuItem {
                        key = MainRes.string.rename
                        onClick = {
                            setMenuOpen(false)
                            props.onRename()
                        }
                        +MainRes.string.rename
                    }
                    MenuItem {
                        key = MainRes.string.cancel
                        onClick = {
                            setMenuOpen(false)
                        }
                        +MainRes.string.cancel
                    }
                }


            }
        }
    }
}
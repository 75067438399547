package com.erstedigital.socialbank.ui.react.transactions.components

import com.erstedigital.socialbank.MainRes
import com.erstedigital.socialbank.domain.models.TransactionModel
import com.erstedigital.socialbank.domain.models.TransactionType
import com.erstedigital.socialbank.ui.react.utils.cardBorderRadius
import com.erstedigital.socialbank.ui.react.utils.surfaceColor
import mui.material.Card
import mui.material.CardContent
import mui.system.sx
import react.FC
import react.Props
import web.cssom.px

external interface BasicInfoCardProps: Props {
    var transaction: TransactionModel?
}

val BasicInfoCard: FC<BasicInfoCardProps> = FC { props ->

    Card {
        sx {
            marginLeft = 3.px
            marginRight = 3.px
            marginBottom = 20.px
            marginTop = 4.px
            backgroundColor = surfaceColor
            borderRadius = cardBorderRadius
        }

        elevation = 2

        CardContent {
            InfoRow {
                label = MainRes.string.total_price
                value = "${props.transaction?.getTotalAmount()} €"
            }
            InfoRow {
                label = if (props.transaction?.getType() == TransactionType.FS_RECEIPT) MainRes.string.date_time_purchase else MainRes.string.date
                value = if (props.transaction?.getType() == TransactionType.FS_RECEIPT) props.transaction?.fsReceiptModel?.createDate.toString() else props.transaction?.getDate().toString().split("-").reversed().joinToString(".")
            }
            InfoRow {
                label = MainRes.string.merchant_name
                value = props.transaction?.getOrganization()?.name ?: MainRes.string.unknown
            }
        }
    }
}

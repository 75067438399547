package com.erstedigital.socialbank.di

//import com.erstedigital.socialbank.domain.GoogleSignInImpl
import com.erstedigital.socialbank.data.datasources.company.CompanyRemoteDataSource
import com.erstedigital.socialbank.data.datasources.dashboard.DashboardRemoteDataSource
import com.erstedigital.socialbank.data.datasources.dms.DmsRemoteDataSource
import com.erstedigital.socialbank.data.datasources.planning.PlanningRemoteDataSource
import com.erstedigital.socialbank.data.datasources.product.ProductRemoteDataSource
import com.erstedigital.socialbank.data.datasources.split.SplitRemoteDataSource
import com.erstedigital.socialbank.data.datasources.statistics.StatisticsRemoteDataSource
import com.erstedigital.socialbank.data.datasources.transaction.TransactionRemoteDataSource
import com.erstedigital.socialbank.data.datasources.transaction_image.TransactionImageRemoteDataSource
import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.domain.usecase.auth.*
import com.erstedigital.socialbank.domain.usecase.company.CreateCompanyUsecase
import com.erstedigital.socialbank.domain.usecase.dashboard.GetDashboardUsecase
import com.erstedigital.socialbank.domain.usecase.documents.*
import com.erstedigital.socialbank.domain.usecase.org.GetCompaniesUsecase
import com.erstedigital.socialbank.domain.usecase.planning.GetExpectedExpenseUsecase
import com.erstedigital.socialbank.domain.usecase.planning.GetExpectedIncomeUsecase
import com.erstedigital.socialbank.domain.usecase.planning.UpdateExpectedExpenseUsecase
import com.erstedigital.socialbank.domain.usecase.planning.UpdateExpectedIncomeUsecase
import com.erstedigital.socialbank.domain.usecase.statistics.GetCustomRangeStatisticsUsecase
import com.erstedigital.socialbank.domain.usecase.statistics.GetStatisticsUsecase
import com.erstedigital.socialbank.domain.usecase.transactions.*
import com.erstedigital.socialbank.domain.usecase.user.GetProfileUsecase
import com.erstedigital.socialbank.domain.usecase.user.UpdateProfileUsecase
import com.russhwolf.settings.ExperimentalSettingsApi
import io.ktor.client.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.coroutineScope
import org.koin.dsl.module
import kotlin.io.encoding.ExperimentalEncodingApi

fun remoteDataSourceModule(enableNetworkLogs: Boolean) = module {
    single { UserRemoteDataSource(get()) }
    single { TransactionRemoteDataSource(get()) }
    single { CompanyRemoteDataSource(get()) }
    single { DashboardRemoteDataSource(get()) }
    single { StatisticsRemoteDataSource(get()) }
    single { SplitRemoteDataSource(get()) }
    single { ProductRemoteDataSource(get()) }
    single { TransactionImageRemoteDataSource(get()) }
    single { DmsRemoteDataSource(get()) }
    single { PlanningRemoteDataSource(get()) }
}

@OptIn(ExperimentalSettingsApi::class)
fun localDataSourceModule(enableNetworkLogs: Boolean) = module {
    single { UserLocalDataSource(get()) }
}

fun networkModule(enableNetworkLogs: Boolean) = module {
    single { getRemoteClient( get(), enableNetworkLogs) }
    //single {GoogleSignInImpl() as GoogleSignIn}
}

fun usecaseModule(enableNetworkLogs: Boolean) = module {
    single { LoginUsecase(get(), get(), get()) }
    single { LoginIdpUsecase(get(), get(), get()) }
    single { LoginAnonymousUsecase(get(), get()) }
    single { VerifyCodeUsecase(get(), get(), get()) }
    single { SignoutUsecase(get(), get()) }
    single { UpdateProfileUsecase(get(), get())}
    single { GetTransactionsUsecase(get()) }
    single { GetTransactionUsecase(get()) }
    single { GetProfileUsecase(get(), get()) }
    single { GetStatisticsUsecase(get()) }
    single { UserSignedInUsecase(get(), get()) }
    single { GetDashboardUsecase(get(), get()) }
    single { CreateFsReceiptV2Usecase(get()) }
    single { CreateFsReceiptUsecase(get())}
    single { CreateManualTransactionUsecase(get()) }
    single { CreateOrganizationUsecase(get()) }
    single { CreateTransactionImageUsecase(get()) }
    single { UpdateFsReceiptCategoryUsecase(get()) }
    single { GetCustomRangeStatisticsUsecase(get()) }
    single { UpdateProductCategoryUsecase(get()) }
    single { GetExpectedIncomeUsecase(get()) }
    single { GetExpectedExpenseUsecase(get()) }
    single { UpdateExpectedIncomeUsecase(get()) }
    single { UpdateExpectedExpenseUsecase (get()) }

    single { DeleteAttachmentUsecase(get()) }
    single { DeleteDocumentUsecase(get()) }
    single { DownloadAttachmentUsecase(get()) }
    single { GetDocumentUsecase(get()) }
    single { GetFilesUseCase(get()) }
    single { UpdateAttachmentUsecase(get()) }
    single { CreateFolderUsecase(get()) }
    single { CreateDocumentUsecase(get()) }
    single { GetCompaniesUsecase(get()) }
    single { DeleteTransactionUsecase(get()) }
    single { UpdateManualTransactionUsecase(get()) }
    single { CreateCompanyUsecase(get()) }
    single { UpdateDocumentUsecase(get()) }
}

@OptIn(ExperimentalEncodingApi::class)
suspend fun getToken(userLocalDataSource: UserDataSource.Local): String = coroutineScope {
    val user = userLocalDataSource.getUser()
    val token = user?.token

    /*if (!token.isNullOrEmpty()) {

        val parts = token.split(".")
        val content = Base64.decode(parts[1].encodeToByteArray()).decodeToString()
        val exp = content.split("\"exp\":")[1].split(Regex("[},]"))[0].toLong()
        val now = Clock.System.now().epochSeconds

        if (now > exp) {
            val newToken = Firebase.auth.signInAnonymously().user?.getIdToken(forceRefresh = true)
            user.token = newToken
            userLocalDataSource.setUser(user)
        }
    } */

    user?.token ?: ""
}

@OptIn(ExperimentalEncodingApi::class, DelicateCoroutinesApi::class)
fun getRemoteClient(userLocalDataSource: UserLocalDataSource, enableNetworkLogs: Boolean): HttpClient {
    return getClient(enableNetworkLogs).config {
        install(Auth) {
            bearer  {

                this.loadTokens {
                    val token = getToken(userLocalDataSource)
                    BearerTokens(token, "")
                }
            }
        }
    }
}


expect fun getClient(enableNetworkLogs: Boolean): HttpClient

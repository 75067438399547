package com.erstedigital.socialbank.data.network.models.response

import com.erstedigital.socialbank.domain.models.ProductModel
import kotlinx.serialization.Serializable

@Serializable
class UpdateProductResponse(
    val id: Long? = null,
    val name: String? = null,
    val itemType: String? = null,
    val price: Double? = null,
    val vatRate: Double? = null,
    val category: String? = null
) {
    fun toModel(): ProductModel {
        return ProductModel(id, name, itemType,price, category, emptyList())
    }
}
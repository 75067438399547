package com.erstedigital.socialbank.ui.home.utils

data class CategoryInput(
    var primary: String,
    var secondary: String? = null
) {

    override fun toString(): String {
        if (primary.isEmpty()) {
            return ""
        }
        if (!secondary.isNullOrEmpty()) {
            return "$primary/$secondary"
        }
        return primary
    }
}

fun CategoryInput.isIncome(): Boolean {
    return getIncomeCategories().any { primary == it.main }
}

fun String.isIncome(): Boolean {
    return getIncomeCategories().any { this == it.main }
}
package com.erstedigital.socialbank.data.datasources.user

import com.erstedigital.socialbank.data.network.models.request.*
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.models.response.GetProfileResponse
import com.erstedigital.socialbank.data.network.models.response.LoginIdpResponse
import com.erstedigital.socialbank.data.network.models.response.UserListResponse
import com.erstedigital.socialbank.data.network.models.response.VerifyCodeResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse
import com.erstedigital.socialbank.data.network.utils.safeRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import io.ktor.http.path
import kotlin.js.JsName

class UserRemoteDataSource(
    private val httpClient: HttpClient
): UserDataSource.Remote {

    override suspend fun loginIdp(loginIdpRequest: LoginIdpRequest): ApiResponse<LoginIdpResponse, ErrorResponse> {
            return httpClient.safeRequest{
                url {
                    method = HttpMethod.Post
                    path("login-idp")
                }
                setBody(loginIdpRequest)
                contentType(ContentType.Application.Json)
            }

    }

    override suspend fun verifyCode(verifyCodeRequest: VerifyCodeRequest): ApiResponse<VerifyCodeResponse, ErrorResponse> {

        return httpClient.safeRequest {
            url {
                path("login")
                method = HttpMethod.Post
            }
            setBody(verifyCodeRequest)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun verifyCode(verifyCodeRequest: LoginRequest): ApiResponse<VerifyCodeResponse, ErrorResponse> {

        return httpClient.safeRequest {
            url {
                path("login")
                method = HttpMethod.Post
            }
            setBody(verifyCodeRequest)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun getProfile(): ApiResponse<GetProfileResponse, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("profile")
                method = HttpMethod.Get
            }
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun login(loginRequest: LoginRequest): ApiResponse<Any, ErrorResponse> {

        return httpClient.safeRequest {
            url {
                path("login")
                method = HttpMethod.Post
            }
            setBody(loginRequest)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun searchUsers(query: String): ApiResponse<List<UserListResponse>, ErrorResponse> {

        return httpClient.safeRequest {
            url {
                path("users/search")
                parameters.append("q", query)
                method = HttpMethod.Get
            }
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun loginAnonymous(request: LoginAnonymousRequest): ApiResponse<LoginIdpResponse, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("login-anonymous")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun updateProfile(request: UpdateProfileRequest): ApiResponse<GetProfileResponse, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("profile")
                method = HttpMethod.Put
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun updateEmail(request: UpdateEmailRequest) : ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("update-email")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun updateEmailIdp(request: UpdateEmailIdpRequest): ApiResponse<Any, ErrorResponse> {

        return httpClient.safeRequest {
            url {
                path("update-email-idp")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun verifyCodeEmail(request: VerifyCodeEmailRequest): ApiResponse<Any, ErrorResponse> {

        return httpClient.safeRequest {
            url {
                path("verify-code-email")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun register(request: RegisterRequest): ApiResponse<LoginIdpResponse, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("register")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }

    }

    override suspend fun resetPassword(request: ChangePasswordRequest): ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("reset-password")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }
    }

    override suspend fun changePassword(request: ChangePasswordRequest): ApiResponse<Any, ErrorResponse> {
        return httpClient.safeRequest {
            url {
                path("change-password")
                method = HttpMethod.Post
            }
            setBody(request)
            contentType(ContentType.Application.Json)
        }
    }
}
package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import com.erstedigital.socialbank.data.datasources.user.UserRemoteDataSource
import com.erstedigital.socialbank.data.network.models.request.LoginRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class UserSignedInUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val userRemoteDataSource: UserRemoteDataSource
) {
    suspend fun isSignedIn(): Boolean {
        val localRes = userLocalDataSource.isLoggedIn()

        if (!localRes) {
            return false
        }

        val remoteRes = userRemoteDataSource.getProfile()

        return when (remoteRes) {
            is ApiResponse.Success -> true
            is ApiResponse.Error -> {
                if (remoteRes is ApiResponse.Error.HttpError) {
                    userLocalDataSource.setUser(null)
                    false
                } else {
                    true
                }
            }
        }

    }

}
package com.erstedigital.socialbank.domain.usecase.auth

import com.erstedigital.socialbank.data.datasources.user.UserDataSource
import com.erstedigital.socialbank.data.datasources.user.UserLocalDataSource
import io.ktor.client.HttpClient
import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.auth.providers.BearerAuthProvider
import io.ktor.client.plugins.plugin

class SignoutUsecase(
    private val userLocalDataSource: UserLocalDataSource,
    private val httpClient: HttpClient
) {

    suspend fun signOut() {
        userLocalDataSource.setUser(null)
        httpClient.plugin(Auth).providers.filterIsInstance<BearerAuthProvider>().first().clearToken()
    }
}
package com.erstedigital.socialbank.strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object MainResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("sk" to StringsSk, "en" to StringsEn)

  public val app_name: String
    get() = locales[getCurrentLanguageCode()]?.app_name ?: baseLocale.app_name

  public val category: String
    get() = locales[getCurrentLanguageCode()]?.category ?: baseLocale.category

  public val settings: String
    get() = locales[getCurrentLanguageCode()]?.settings ?: baseLocale.settings

  public val title_scanner: String
    get() = locales[getCurrentLanguageCode()]?.title_scanner ?: baseLocale.title_scanner

  public val profile: String
    get() = locales[getCurrentLanguageCode()]?.profile ?: baseLocale.profile

  public val planning: String
    get() = locales[getCurrentLanguageCode()]?.planning ?: baseLocale.planning

  public val statistics: String
    get() = locales[getCurrentLanguageCode()]?.statistics ?: baseLocale.statistics

  public val dashboard: String
    get() = locales[getCurrentLanguageCode()]?.dashboard ?: baseLocale.dashboard

  public val email: String
    get() = locales[getCurrentLanguageCode()]?.email ?: baseLocale.email

  public val history: String
    get() = locales[getCurrentLanguageCode()]?.history ?: baseLocale.history

  public val amount: String
    get() = locales[getCurrentLanguageCode()]?.amount ?: baseLocale.amount

  public val merchant_name: String
    get() = locales[getCurrentLanguageCode()]?.merchant_name ?: baseLocale.merchant_name

  public val detail: String
    get() = locales[getCurrentLanguageCode()]?.detail ?: baseLocale.detail

  public val upload_photo: String
    get() = locales[getCurrentLanguageCode()]?.upload_photo ?: baseLocale.upload_photo

  public val share: String
    get() = locales[getCurrentLanguageCode()]?.share ?: baseLocale.share

  public val delete: String
    get() = locales[getCurrentLanguageCode()]?.delete ?: baseLocale.delete

  public val edit: String
    get() = locales[getCurrentLanguageCode()]?.edit ?: baseLocale.edit

  public val edit_values: String
    get() = locales[getCurrentLanguageCode()]?.edit_values ?: baseLocale.edit_values

  public val date_time_purchase: String
    get() = locales[getCurrentLanguageCode()]?.date_time_purchase ?: baseLocale.date_time_purchase

  public val Choose_secondary_category: String
    get() = locales[getCurrentLanguageCode()]?.Choose_secondary_category ?:
        baseLocale.Choose_secondary_category

  public val date: String
    get() = locales[getCurrentLanguageCode()]?.date ?: baseLocale.date

  public val pick: String
    get() = locales[getCurrentLanguageCode()]?.pick ?: baseLocale.pick

  public val week: String
    get() = locales[getCurrentLanguageCode()]?.week ?: baseLocale.week

  public val month: String
    get() = locales[getCurrentLanguageCode()]?.month ?: baseLocale.month

  public val full_name: String
    get() = locales[getCurrentLanguageCode()]?.full_name ?: baseLocale.full_name

  public val phone_number: String
    get() = locales[getCurrentLanguageCode()]?.phone_number ?: baseLocale.phone_number

  public val log_out: String
    get() = locales[getCurrentLanguageCode()]?.log_out ?: baseLocale.log_out

  public val iban: String
    get() = locales[getCurrentLanguageCode()]?.iban ?: baseLocale.iban

  public val welcome_message: WelcomeMessage
    get() = locales[getCurrentLanguageCode()]?.welcome_message ?: baseLocale.welcome_message

  public val anonymous: String
    get() = locales[getCurrentLanguageCode()]?.anonymous ?: baseLocale.anonymous

  public val your_current_balance_message: YourCurrentBalanceMessage
    get() = locales[getCurrentLanguageCode()]?.your_current_balance_message ?:
        baseLocale.your_current_balance_message

  public val transactions: String
    get() = locales[getCurrentLanguageCode()]?.transactions ?: baseLocale.transactions

  public val how_to_split: String
    get() = locales[getCurrentLanguageCode()]?.how_to_split ?: baseLocale.how_to_split

  public val settlement: String
    get() = locales[getCurrentLanguageCode()]?.settlement ?: baseLocale.settlement

  public val total: String
    get() = locales[getCurrentLanguageCode()]?.total ?: baseLocale.total

  public val label_settlement_instructions: String
    get() = locales[getCurrentLanguageCode()]?.label_settlement_instructions ?:
        baseLocale.label_settlement_instructions

  public val app_url_www: String
    get() = locales[getCurrentLanguageCode()]?.app_url_www ?: baseLocale.app_url_www

  public val app_url: String
    get() = locales[getCurrentLanguageCode()]?.app_url ?: baseLocale.app_url

  public val transactions_endpoint: String
    get() = locales[getCurrentLanguageCode()]?.transactions_endpoint ?:
        baseLocale.transactions_endpoint

  public val cancel: String
    get() = locales[getCurrentLanguageCode()]?.cancel ?: baseLocale.cancel

  public val sign_in: String
    get() = locales[getCurrentLanguageCode()]?.sign_in ?: baseLocale.sign_in

  public val sign_up: String
    get() = locales[getCurrentLanguageCode()]?.sign_up ?: baseLocale.sign_up

  public val reset_password: String
    get() = locales[getCurrentLanguageCode()]?.reset_password ?: baseLocale.reset_password

  public val failed_login: String
    get() = locales[getCurrentLanguageCode()]?.failed_login ?: baseLocale.failed_login

  public val failed_password_reset: String
    get() = locales[getCurrentLanguageCode()]?.failed_password_reset ?:
        baseLocale.failed_password_reset

  public val failed_password_change: String
    get() = locales[getCurrentLanguageCode()]?.failed_password_change ?:
        baseLocale.failed_password_change

  public val failed_user_registration: String
    get() = locales[getCurrentLanguageCode()]?.failed_user_registration ?:
        baseLocale.failed_user_registration

  public val successful_user_registration: String
    get() = locales[getCurrentLanguageCode()]?.successful_user_registration ?:
        baseLocale.successful_user_registration

  public val success_password_reset: String
    get() = locales[getCurrentLanguageCode()]?.success_password_reset ?:
        baseLocale.success_password_reset

  public val success_password_change: String
    get() = locales[getCurrentLanguageCode()]?.success_password_change ?:
        baseLocale.success_password_change

  public val passwords_do_not_match: String
    get() = locales[getCurrentLanguageCode()]?.passwords_do_not_match ?:
        baseLocale.passwords_do_not_match

  public val submit_pin: String
    get() = locales[getCurrentLanguageCode()]?.submit_pin ?: baseLocale.submit_pin

  public val skip: String
    get() = locales[getCurrentLanguageCode()]?.skip ?: baseLocale.skip

  public val try_out: String
    get() = locales[getCurrentLanguageCode()]?.try_out ?: baseLocale.try_out

  public val try_as_anonymous: String
    get() = locales[getCurrentLanguageCode()]?.try_as_anonymous ?: baseLocale.try_as_anonymous

  public val verify_code: String
    get() = locales[getCurrentLanguageCode()]?.verify_code ?: baseLocale.verify_code

  public val verify_code_error: String
    get() = locales[getCurrentLanguageCode()]?.verify_code_error ?: baseLocale.verify_code_error

  public val verify_code_message: VerifyCodeMessage
    get() = locales[getCurrentLanguageCode()]?.verify_code_message ?: baseLocale.verify_code_message

  public val resend_email_underlined: String
    get() = locales[getCurrentLanguageCode()]?.resend_email_underlined ?:
        baseLocale.resend_email_underlined

  public val receipt_added_message: String
    get() = locales[getCurrentLanguageCode()]?.receipt_added_message ?:
        baseLocale.receipt_added_message

  public val user_no: UserNo
    get() = locales[getCurrentLanguageCode()]?.user_no ?: baseLocale.user_no

  public val products_count: ProductsCount
    get() = locales[getCurrentLanguageCode()]?.products_count ?: baseLocale.products_count

  public val company_name: String
    get() = locales[getCurrentLanguageCode()]?.company_name ?: baseLocale.company_name

  public val company_ico: String
    get() = locales[getCurrentLanguageCode()]?.company_ico ?: baseLocale.company_ico

  public val save: String
    get() = locales[getCurrentLanguageCode()]?.save ?: baseLocale.save

  public val create: String
    get() = locales[getCurrentLanguageCode()]?.create ?: baseLocale.create

  public val create_company: String
    get() = locales[getCurrentLanguageCode()]?.create_company ?: baseLocale.create_company

  public val allow_analysis_by_socialbank: String
    get() = locales[getCurrentLanguageCode()]?.allow_analysis_by_socialbank ?:
        baseLocale.allow_analysis_by_socialbank

  public val income: String
    get() = locales[getCurrentLanguageCode()]?.income ?: baseLocale.income

  public val expense: String
    get() = locales[getCurrentLanguageCode()]?.expense ?: baseLocale.expense

  public val agree: String
    get() = locales[getCurrentLanguageCode()]?.agree ?: baseLocale.agree

  public val allow_analysis_consent: String
    get() = locales[getCurrentLanguageCode()]?.allow_analysis_consent ?:
        baseLocale.allow_analysis_consent

  public val company_not_found: String
    get() = locales[getCurrentLanguageCode()]?.company_not_found ?: baseLocale.company_not_found

  public val from_advisor: String
    get() = locales[getCurrentLanguageCode()]?.from_advisor ?: baseLocale.from_advisor

  public val update: String
    get() = locales[getCurrentLanguageCode()]?.update ?: baseLocale.update

  public val share_with: String
    get() = locales[getCurrentLanguageCode()]?.share_with ?: baseLocale.share_with

  public val copy: String
    get() = locales[getCurrentLanguageCode()]?.copy ?: baseLocale.copy

  public val dont_send: String
    get() = locales[getCurrentLanguageCode()]?.dont_send ?: baseLocale.dont_send

  public val already_sent: String
    get() = locales[getCurrentLanguageCode()]?.already_sent ?: baseLocale.already_sent

  public val use_users_email: String
    get() = locales[getCurrentLanguageCode()]?.use_users_email ?: baseLocale.use_users_email

  public val next: String
    get() = locales[getCurrentLanguageCode()]?.next ?: baseLocale.next

  public val back: String
    get() = locales[getCurrentLanguageCode()]?.back ?: baseLocale.back

  public val unknown: String
    get() = locales[getCurrentLanguageCode()]?.unknown ?: baseLocale.unknown

  public val by_products: String
    get() = locales[getCurrentLanguageCode()]?.by_products ?: baseLocale.by_products

  public val by_percentage: String
    get() = locales[getCurrentLanguageCode()]?.by_percentage ?: baseLocale.by_percentage

  public val organization_search_description: String
    get() = locales[getCurrentLanguageCode()]?.organization_search_description ?:
        baseLocale.organization_search_description

  public val split: String
    get() = locales[getCurrentLanguageCode()]?.split ?: baseLocale.split

  public val products: String
    get() = locales[getCurrentLanguageCode()]?.products ?: baseLocale.products

  public val show_all: String
    get() = locales[getCurrentLanguageCode()]?.show_all ?: baseLocale.show_all

  public val unit_details: String
    get() = locales[getCurrentLanguageCode()]?.unit_details ?: baseLocale.unit_details

  public val organization_details: String
    get() = locales[getCurrentLanguageCode()]?.organization_details ?:
        baseLocale.organization_details

  public val vat_details: String
    get() = locales[getCurrentLanguageCode()]?.vat_details ?: baseLocale.vat_details

  public val other_receipt_details: String
    get() = locales[getCurrentLanguageCode()]?.other_receipt_details ?:
        baseLocale.other_receipt_details

  public val show_more_details: String
    get() = locales[getCurrentLanguageCode()]?.show_more_details ?: baseLocale.show_more_details

  public val organization: String
    get() = locales[getCurrentLanguageCode()]?.organization ?: baseLocale.organization

  public val no_transactions_button: String
    get() = locales[getCurrentLanguageCode()]?.no_transactions_button ?:
        baseLocale.no_transactions_button

  public val enter_email: String
    get() = locales[getCurrentLanguageCode()]?.enter_email ?: baseLocale.enter_email

  public val enter_password: String
    get() = locales[getCurrentLanguageCode()]?.enter_password ?: baseLocale.enter_password

  public val enter_password_confirmation: String
    get() = locales[getCurrentLanguageCode()]?.enter_password_confirmation ?:
        baseLocale.enter_password_confirmation

  public val enter_old_password: String
    get() = locales[getCurrentLanguageCode()]?.enter_old_password ?: baseLocale.enter_old_password

  public val enter_new_password: String
    get() = locales[getCurrentLanguageCode()]?.enter_new_password ?: baseLocale.enter_new_password

  public val log_in: String
    get() = locales[getCurrentLanguageCode()]?.log_in ?: baseLocale.log_in

  public val change_password: String
    get() = locales[getCurrentLanguageCode()]?.change_password ?: baseLocale.change_password

  public val label_login_existing_account: String
    get() = locales[getCurrentLanguageCode()]?.label_login_existing_account ?:
        baseLocale.label_login_existing_account

  public val details: String
    get() = locales[getCurrentLanguageCode()]?.details ?: baseLocale.details

  public val show: String
    get() = locales[getCurrentLanguageCode()]?.show ?: baseLocale.show

  public val debts: String
    get() = locales[getCurrentLanguageCode()]?.debts ?: baseLocale.debts

  public val balance_over_time: String
    get() = locales[getCurrentLanguageCode()]?.balance_over_time ?: baseLocale.balance_over_time

  public val show_in_pie_chart: String
    get() = locales[getCurrentLanguageCode()]?.show_in_pie_chart ?: baseLocale.show_in_pie_chart

  public val no_transactions: String
    get() = locales[getCurrentLanguageCode()]?.no_transactions ?: baseLocale.no_transactions

  public val label_construction: String
    get() = locales[getCurrentLanguageCode()]?.label_construction ?: baseLocale.label_construction

  public val users: String
    get() = locales[getCurrentLanguageCode()]?.users ?: baseLocale.users

  public val search_for_users: String
    get() = locales[getCurrentLanguageCode()]?.search_for_users ?: baseLocale.search_for_users

  public val done: String
    get() = locales[getCurrentLanguageCode()]?.done ?: baseLocale.done

  public val merge: String
    get() = locales[getCurrentLanguageCode()]?.merge ?: baseLocale.merge

  public val create_transaction_manually: String
    get() = locales[getCurrentLanguageCode()]?.create_transaction_manually ?:
        baseLocale.create_transaction_manually

  public val choose_term: String
    get() = locales[getCurrentLanguageCode()]?.choose_term ?: baseLocale.choose_term

  public val default_notification_channel_id: String
    get() = locales[getCurrentLanguageCode()]?.default_notification_channel_id ?:
        baseLocale.default_notification_channel_id

  public val group_by_day: String
    get() = locales[getCurrentLanguageCode()]?.group_by_day ?: baseLocale.group_by_day

  public val group_by_week: String
    get() = locales[getCurrentLanguageCode()]?.group_by_week ?: baseLocale.group_by_week

  public val group_by_month: String
    get() = locales[getCurrentLanguageCode()]?.group_by_month ?: baseLocale.group_by_month

  public val group_by: String
    get() = locales[getCurrentLanguageCode()]?.group_by ?: baseLocale.group_by

  public val week_no_year: WeekNoYear
    get() = locales[getCurrentLanguageCode()]?.week_no_year ?: baseLocale.week_no_year

  public val upload: String
    get() = locales[getCurrentLanguageCode()]?.upload ?: baseLocale.upload

  public val no_upload_advisor: String
    get() = locales[getCurrentLanguageCode()]?.no_upload_advisor ?: baseLocale.no_upload_advisor

  public val split_with: SplitWith
    get() = locales[getCurrentLanguageCode()]?.split_with ?: baseLocale.split_with

  public val label_budget_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_budget_empty ?: baseLocale.label_budget_empty

  public val label_budget_user_empty: String
    get() = locales[getCurrentLanguageCode()]?.label_budget_user_empty ?:
        baseLocale.label_budget_user_empty

  public val kaufland: String
    get() = locales[getCurrentLanguageCode()]?.kaufland ?: baseLocale.kaufland

  public val lactofree: String
    get() = locales[getCurrentLanguageCode()]?.lactofree ?: baseLocale.lactofree

  public val bio: String
    get() = locales[getCurrentLanguageCode()]?.bio ?: baseLocale.bio

  public val billa: String
    get() = locales[getCurrentLanguageCode()]?.billa ?: baseLocale.billa

  public val clever: String
    get() = locales[getCurrentLanguageCode()]?.clever ?: baseLocale.clever

  public val tesco_finest: String
    get() = locales[getCurrentLanguageCode()]?.tesco_finest ?: baseLocale.tesco_finest

  public val tesco: String
    get() = locales[getCurrentLanguageCode()]?.tesco ?: baseLocale.tesco

  public val f_and_f: String
    get() = locales[getCurrentLanguageCode()]?.f_and_f ?: baseLocale.f_and_f

  public val label_expenses: String
    get() = locales[getCurrentLanguageCode()]?.label_expenses ?: baseLocale.label_expenses

  public val label_paid: String
    get() = locales[getCurrentLanguageCode()]?.label_paid ?: baseLocale.label_paid

  public val label_total: String
    get() = locales[getCurrentLanguageCode()]?.label_total ?: baseLocale.label_total

  public val show_less_details: String
    get() = locales[getCurrentLanguageCode()]?.show_less_details ?: baseLocale.show_less_details

  public val receipt_details: String
    get() = locales[getCurrentLanguageCode()]?.receipt_details ?: baseLocale.receipt_details

  public val total_price: String
    get() = locales[getCurrentLanguageCode()]?.total_price ?: baseLocale.total_price

  public val name: String
    get() = locales[getCurrentLanguageCode()]?.name ?: baseLocale.name

  public val building_number: String
    get() = locales[getCurrentLanguageCode()]?.building_number ?: baseLocale.building_number

  public val country: String
    get() = locales[getCurrentLanguageCode()]?.country ?: baseLocale.country

  public val dic: String
    get() = locales[getCurrentLanguageCode()]?.dic ?: baseLocale.dic

  public val ico: String
    get() = locales[getCurrentLanguageCode()]?.ico ?: baseLocale.ico

  public val ic_dph: String
    get() = locales[getCurrentLanguageCode()]?.ic_dph ?: baseLocale.ic_dph

  public val municipality: String
    get() = locales[getCurrentLanguageCode()]?.municipality ?: baseLocale.municipality

  public val postal_code: String
    get() = locales[getCurrentLanguageCode()]?.postal_code ?: baseLocale.postal_code

  public val street_name: String
    get() = locales[getCurrentLanguageCode()]?.street_name ?: baseLocale.street_name

  public val property_registration_number: String
    get() = locales[getCurrentLanguageCode()]?.property_registration_number ?:
        baseLocale.property_registration_number

  public val vat_payer: String
    get() = locales[getCurrentLanguageCode()]?.vat_payer ?: baseLocale.vat_payer

  public val cash_register_code: String
    get() = locales[getCurrentLanguageCode()]?.cash_register_code ?: baseLocale.cash_register_code

  public val unit_type: String
    get() = locales[getCurrentLanguageCode()]?.unit_type ?: baseLocale.unit_type

  public val invalid_iban: String
    get() = locales[getCurrentLanguageCode()]?.invalid_iban ?: baseLocale.invalid_iban

  public val is_owed: IsOwed
    get() = locales[getCurrentLanguageCode()]?.is_owed ?: baseLocale.is_owed

  public val owes: Owes
    get() = locales[getCurrentLanguageCode()]?.owes ?: baseLocale.owes

  public val org_unit: String
    get() = locales[getCurrentLanguageCode()]?.org_unit ?: baseLocale.org_unit

  public val label_product_categories: String
    get() = locales[getCurrentLanguageCode()]?.label_product_categories ?:
        baseLocale.label_product_categories

  public val label_product_categories_filtered: String
    get() = locales[getCurrentLanguageCode()]?.label_product_categories_filtered ?:
        baseLocale.label_product_categories_filtered

  public val other: String
    get() = locales[getCurrentLanguageCode()]?.other ?: baseLocale.other

  public val mine_products: String
    get() = locales[getCurrentLanguageCode()]?.mine_products ?: baseLocale.mine_products

  public val new_split: String
    get() = locales[getCurrentLanguageCode()]?.new_split ?: baseLocale.new_split

  public val last_split: String
    get() = locales[getCurrentLanguageCode()]?.last_split ?: baseLocale.last_split

  public val label_settlement_title: LabelSettlementTitle
    get() = locales[getCurrentLanguageCode()]?.label_settlement_title ?:
        baseLocale.label_settlement_title

  public val label_settlement: LabelSettlement
    get() = locales[getCurrentLanguageCode()]?.label_settlement ?: baseLocale.label_settlement

  public val leaderboard_title: LeaderboardTitle
    get() = locales[getCurrentLanguageCode()]?.leaderboard_title ?: baseLocale.leaderboard_title

  public val leaderboard_title_empty: String
    get() = locales[getCurrentLanguageCode()]?.leaderboard_title_empty ?:
        baseLocale.leaderboard_title_empty

  public val feature_title_scanning: String
    get() = locales[getCurrentLanguageCode()]?.feature_title_scanning ?:
        baseLocale.feature_title_scanning

  public val feature_description_scanning: String
    get() = locales[getCurrentLanguageCode()]?.feature_description_scanning ?:
        baseLocale.feature_description_scanning

  public val feature_title_statistics: String
    get() = locales[getCurrentLanguageCode()]?.feature_title_statistics ?:
        baseLocale.feature_title_statistics

  public val feature_description_statistics: String
    get() = locales[getCurrentLanguageCode()]?.feature_description_statistics ?:
        baseLocale.feature_description_statistics

  public val feature_title_split: String
    get() = locales[getCurrentLanguageCode()]?.feature_title_split ?: baseLocale.feature_title_split

  public val feature_description_split: String
    get() = locales[getCurrentLanguageCode()]?.feature_description_split ?:
        baseLocale.feature_description_split

  public val by_parts: String
    get() = locales[getCurrentLanguageCode()]?.by_parts ?: baseLocale.by_parts

  public val by_parts_description: String
    get() = locales[getCurrentLanguageCode()]?.by_parts_description ?:
        baseLocale.by_parts_description

  public val by_products_description: String
    get() = locales[getCurrentLanguageCode()]?.by_products_description ?:
        baseLocale.by_products_description

  public val is_owed_you: IsOwedYou
    get() = locales[getCurrentLanguageCode()]?.is_owed_you ?: baseLocale.is_owed_you

  public val owes_you: OwesYou
    get() = locales[getCurrentLanguageCode()]?.owes_you ?: baseLocale.owes_you

  public val me: String
    get() = locales[getCurrentLanguageCode()]?.me ?: baseLocale.me

  public val split_from: SplitFrom
    get() = locales[getCurrentLanguageCode()]?.split_from ?: baseLocale.split_from

  public val debt_user_accepted: String
    get() = locales[getCurrentLanguageCode()]?.debt_user_accepted ?: baseLocale.debt_user_accepted

  public val debt_user_not_received: String
    get() = locales[getCurrentLanguageCode()]?.debt_user_not_received ?:
        baseLocale.debt_user_not_received

  public val debt_user_paid: String
    get() = locales[getCurrentLanguageCode()]?.debt_user_paid ?: baseLocale.debt_user_paid

  public val debt_user_not_paid: String
    get() = locales[getCurrentLanguageCode()]?.debt_user_not_paid ?: baseLocale.debt_user_not_paid

  public val ico_format: IcoFormat
    get() = locales[getCurrentLanguageCode()]?.ico_format ?: baseLocale.ico_format

  public val enter_amount: String
    get() = locales[getCurrentLanguageCode()]?.enter_amount ?: baseLocale.enter_amount

  public val manual_transaction: String
    get() = locales[getCurrentLanguageCode()]?.manual_transaction ?: baseLocale.manual_transaction

  public val all: String
    get() = locales[getCurrentLanguageCode()]?.all ?: baseLocale.all

  public val title_continue: String
    get() = locales[getCurrentLanguageCode()]?.title_continue ?: baseLocale.title_continue

  public val message_continue: String
    get() = locales[getCurrentLanguageCode()]?.message_continue ?: baseLocale.message_continue

  public val continue_: String
    get() = locales[getCurrentLanguageCode()]?.continue_ ?: baseLocale.continue_

  public val assign_items: String
    get() = locales[getCurrentLanguageCode()]?.assign_items ?: baseLocale.assign_items

  public val send_to: SendTo
    get() = locales[getCurrentLanguageCode()]?.send_to ?: baseLocale.send_to

  public val settlement_with: SettlementWith
    get() = locales[getCurrentLanguageCode()]?.settlement_with ?: baseLocale.settlement_with

  public val iban_for: IbanFor
    get() = locales[getCurrentLanguageCode()]?.iban_for ?: baseLocale.iban_for

  public val title_receipt_added: String
    get() = locales[getCurrentLanguageCode()]?.title_receipt_added ?: baseLocale.title_receipt_added

  public val yes: String
    get() = locales[getCurrentLanguageCode()]?.yes ?: baseLocale.yes

  public val no: String
    get() = locales[getCurrentLanguageCode()]?.no ?: baseLocale.no

  public val message_receipt_added: String
    get() = locales[getCurrentLanguageCode()]?.message_receipt_added ?:
        baseLocale.message_receipt_added

  public val category_auto_fuel: String
    get() = locales[getCurrentLanguageCode()]?.category_auto_fuel ?: baseLocale.category_auto_fuel

  public val category_auto_care: String
    get() = locales[getCurrentLanguageCode()]?.category_auto_care ?: baseLocale.category_auto_care

  public val category_baby_food: String
    get() = locales[getCurrentLanguageCode()]?.category_baby_food ?: baseLocale.category_baby_food

  public val category_baby_fake_food: String
    get() = locales[getCurrentLanguageCode()]?.category_baby_fake_food ?:
        baseLocale.category_baby_fake_food

  public val category_baby_supplies: String
    get() = locales[getCurrentLanguageCode()]?.category_baby_supplies ?:
        baseLocale.category_baby_supplies

  public val category_basic_canned_fish: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_canned_fish ?:
        baseLocale.category_basic_canned_fish

  public val category_basic_canned_fruits_vegetable: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_canned_fruits_vegetable ?:
        baseLocale.category_basic_canned_fruits_vegetable

  public val category_basic_morning: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_morning ?:
        baseLocale.category_basic_morning

  public val category_basic_flavoring: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_flavoring ?:
        baseLocale.category_basic_flavoring

  public val category_basic_instant: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_instant ?:
        baseLocale.category_basic_instant

  public val category_jams_marmelade_honey: String
    get() = locales[getCurrentLanguageCode()]?.category_jams_marmelade_honey ?:
        baseLocale.category_jams_marmelade_honey

  public val category_basic_oil_acid_grease: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_oil_acid_grease ?:
        baseLocale.category_basic_oil_acid_grease

  public val category_basic_pasta: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_pasta ?:
        baseLocale.category_basic_pasta

  public val category_basic_sauces: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_sauces ?:
        baseLocale.category_basic_sauces

  public val category_basic_soy_products: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_soy_products ?:
        baseLocale.category_basic_soy_products

  public val category_basic_spices: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_spices ?:
        baseLocale.category_basic_spices

  public val category_basic_baking: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_baking ?:
        baseLocale.category_basic_baking

  public val category_basic_spread: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_spread ?:
        baseLocale.category_basic_spread

  public val category_basic_sugar_flour_rice: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_sugar_flour_rice ?:
        baseLocale.category_basic_sugar_flour_rice

  public val category_basic_tea_coffee_soups: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_tea_coffee_soups ?:
        baseLocale.category_basic_tea_coffee_soups

  public val category_basic_wheat_legumes: String
    get() = locales[getCurrentLanguageCode()]?.category_basic_wheat_legumes ?:
        baseLocale.category_basic_wheat_legumes

  public val category_dairy_butter: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_butter ?:
        baseLocale.category_dairy_butter

  public val category_dairy_cheese: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_cheese ?:
        baseLocale.category_dairy_cheese

  public val category_dairy_cream: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_cream ?:
        baseLocale.category_dairy_cream

  public val category_dairy_eggs: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_eggs ?: baseLocale.category_dairy_eggs

  public val category_dairy_joghurts: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_joghurts ?:
        baseLocale.category_dairy_joghurts

  public val category_dairy_lactfree: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_lactfree ?:
        baseLocale.category_dairy_lactfree

  public val category_dairy_milk: String
    get() = locales[getCurrentLanguageCode()]?.category_dairy_milk ?: baseLocale.category_dairy_milk

  public val category_drinks_alcohol: String
    get() = locales[getCurrentLanguageCode()]?.category_drinks_alcohol ?:
        baseLocale.category_drinks_alcohol

  public val category_drinks_beer: String
    get() = locales[getCurrentLanguageCode()]?.category_drinks_beer ?:
        baseLocale.category_drinks_beer

  public val category_drinks_juice: String
    get() = locales[getCurrentLanguageCode()]?.category_drinks_juice ?:
        baseLocale.category_drinks_juice

  public val category_mineral_water: String
    get() = locales[getCurrentLanguageCode()]?.category_mineral_water ?:
        baseLocale.category_mineral_water

  public val category_drinks_non_alcoholic: String
    get() = locales[getCurrentLanguageCode()]?.category_drinks_non_alcoholic ?:
        baseLocale.category_drinks_non_alcoholic

  public val category_drinks_wine: String
    get() = locales[getCurrentLanguageCode()]?.category_drinks_wine ?:
        baseLocale.category_drinks_wine

  public val category_drugstore_beauty: String
    get() = locales[getCurrentLanguageCode()]?.category_drugstore_beauty ?:
        baseLocale.category_drugstore_beauty

  public val category_drugstore_dental: String
    get() = locales[getCurrentLanguageCode()]?.category_drugstore_dental ?:
        baseLocale.category_drugstore_dental

  public val category_drugstore_hygiene: String
    get() = locales[getCurrentLanguageCode()]?.category_drugstore_hygiene ?:
        baseLocale.category_drugstore_hygiene

  public val category_eating: String
    get() = locales[getCurrentLanguageCode()]?.category_eating ?: baseLocale.category_eating

  public val category_fashion_clothes: String
    get() = locales[getCurrentLanguageCode()]?.category_fashion_clothes ?:
        baseLocale.category_fashion_clothes

  public val category_fashion_kids: String
    get() = locales[getCurrentLanguageCode()]?.category_fashion_kids ?:
        baseLocale.category_fashion_kids

  public val category_freetime_books_magazines: String
    get() = locales[getCurrentLanguageCode()]?.category_freetime_books_magazines ?:
        baseLocale.category_freetime_books_magazines

  public val category_freetime_toys: String
    get() = locales[getCurrentLanguageCode()]?.category_freetime_toys ?:
        baseLocale.category_freetime_toys

  public val category_freetime_other: String
    get() = locales[getCurrentLanguageCode()]?.category_freetime_other ?:
        baseLocale.category_freetime_other

  public val category_frozen_fish: String
    get() = locales[getCurrentLanguageCode()]?.category_frozen_fish ?:
        baseLocale.category_frozen_fish

  public val category_frozen_fruit_vegetable: String
    get() = locales[getCurrentLanguageCode()]?.category_frozen_fruit_vegetable ?:
        baseLocale.category_frozen_fruit_vegetable

  public val category_frozen_instant: String
    get() = locales[getCurrentLanguageCode()]?.category_frozen_instant ?:
        baseLocale.category_frozen_instant

  public val category_frozen_meat: String
    get() = locales[getCurrentLanguageCode()]?.category_frozen_meat ?:
        baseLocale.category_frozen_meat

  public val category_frozen_sweet: String
    get() = locales[getCurrentLanguageCode()]?.category_frozen_sweet ?:
        baseLocale.category_frozen_sweet

  public val category_fruitsveg_dried_nuts: String
    get() = locales[getCurrentLanguageCode()]?.category_fruitsveg_dried_nuts ?:
        baseLocale.category_fruitsveg_dried_nuts

  public val category_fruitsveg_fruit: String
    get() = locales[getCurrentLanguageCode()]?.category_fruitsveg_fruit ?:
        baseLocale.category_fruitsveg_fruit

  public val category_fruitsveg_vegetable: String
    get() = locales[getCurrentLanguageCode()]?.category_fruitsveg_vegetable ?:
        baseLocale.category_fruitsveg_vegetable

  public val category_hazard: String
    get() = locales[getCurrentLanguageCode()]?.category_hazard ?: baseLocale.category_hazard

  public val category_health_lady: String
    get() = locales[getCurrentLanguageCode()]?.category_health_lady ?:
        baseLocale.category_health_lady

  public val category_health_medical_supplies: String
    get() = locales[getCurrentLanguageCode()]?.category_health_medical_supplies ?:
        baseLocale.category_health_medical_supplies

  public val category_health_vitamins: String
    get() = locales[getCurrentLanguageCode()]?.category_health_vitamins ?:
        baseLocale.category_health_vitamins

  public val category_health_sex: String
    get() = locales[getCurrentLanguageCode()]?.category_health_sex ?: baseLocale.category_health_sex

  public val category_home_accessories: String
    get() = locales[getCurrentLanguageCode()]?.category_home_accessories ?:
        baseLocale.category_home_accessories

  public val category_home_bags: String
    get() = locales[getCurrentLanguageCode()]?.category_home_bags ?: baseLocale.category_home_bags

  public val category_home_cleaning: String
    get() = locales[getCurrentLanguageCode()]?.category_home_cleaning ?:
        baseLocale.category_home_cleaning

  public val category_home_decorations: String
    get() = locales[getCurrentLanguageCode()]?.category_home_decorations ?:
        baseLocale.category_home_decorations

  public val category_home_laundry: String
    get() = locales[getCurrentLanguageCode()]?.category_home_laundry ?:
        baseLocale.category_home_laundry

  public val category_home_maintenence: String
    get() = locales[getCurrentLanguageCode()]?.category_home_maintenence ?:
        baseLocale.category_home_maintenence

  public val category_home_office: String
    get() = locales[getCurrentLanguageCode()]?.category_home_office ?:
        baseLocale.category_home_office

  public val category_home_plants_garden: String
    get() = locales[getCurrentLanguageCode()]?.category_home_plants_garden ?:
        baseLocale.category_home_plants_garden

  public val category_meat_deli: String
    get() = locales[getCurrentLanguageCode()]?.category_meat_deli ?: baseLocale.category_meat_deli

  public val category_meat_fish: String
    get() = locales[getCurrentLanguageCode()]?.category_meat_fish ?: baseLocale.category_meat_fish

  public val category_meat_meat: String
    get() = locales[getCurrentLanguageCode()]?.category_meat_meat ?: baseLocale.category_meat_meat

  public val category_meat_meat_products: String
    get() = locales[getCurrentLanguageCode()]?.category_meat_meat_products ?:
        baseLocale.category_meat_meat_products

  public val category_meat_smoked: String
    get() = locales[getCurrentLanguageCode()]?.category_meat_smoked ?:
        baseLocale.category_meat_smoked

  public val category_pastry_basic: String
    get() = locales[getCurrentLanguageCode()]?.category_pastry_basic ?:
        baseLocale.category_pastry_basic

  public val category_pastry_other: String
    get() = locales[getCurrentLanguageCode()]?.category_pastry_other ?:
        baseLocale.category_pastry_other

  public val category_pastry_salty: String
    get() = locales[getCurrentLanguageCode()]?.category_pastry_salty ?:
        baseLocale.category_pastry_salty

  public val category_pastry_sweet: String
    get() = locales[getCurrentLanguageCode()]?.category_pastry_sweet ?:
        baseLocale.category_pastry_sweet

  public val category_pets: String
    get() = locales[getCurrentLanguageCode()]?.category_pets ?: baseLocale.category_pets

  public val category_seasonal: String
    get() = locales[getCurrentLanguageCode()]?.category_seasonal ?: baseLocale.category_seasonal

  public val category_snacks_salty: String
    get() = locales[getCurrentLanguageCode()]?.category_snacks_salty ?:
        baseLocale.category_snacks_salty

  public val category_snacks_sweet: String
    get() = locales[getCurrentLanguageCode()]?.category_snacks_sweet ?:
        baseLocale.category_snacks_sweet

  public val category_freetime_visual: String
    get() = locales[getCurrentLanguageCode()]?.category_freetime_visual ?:
        baseLocale.category_freetime_visual

  public val tax_base_basic: String
    get() = locales[getCurrentLanguageCode()]?.tax_base_basic ?: baseLocale.tax_base_basic

  public val tax_base_reduced: String
    get() = locales[getCurrentLanguageCode()]?.tax_base_reduced ?: baseLocale.tax_base_reduced

  public val vat_amount_basic: String
    get() = locales[getCurrentLanguageCode()]?.vat_amount_basic ?: baseLocale.vat_amount_basic

  public val vat_amount_reduced: String
    get() = locales[getCurrentLanguageCode()]?.vat_amount_reduced ?: baseLocale.vat_amount_reduced

  public val vat_rate_basic: String
    get() = locales[getCurrentLanguageCode()]?.vat_rate_basic ?: baseLocale.vat_rate_basic

  public val vat_rate_reduced: String
    get() = locales[getCurrentLanguageCode()]?.vat_rate_reduced ?: baseLocale.vat_rate_reduced

  public val receipt_type: String
    get() = locales[getCurrentLanguageCode()]?.receipt_type ?: baseLocale.receipt_type

  public val invoice_number: String
    get() = locales[getCurrentLanguageCode()]?.invoice_number ?: baseLocale.invoice_number

  public val customer_id: String
    get() = locales[getCurrentLanguageCode()]?.customer_id ?: baseLocale.customer_id

  public val label_add_email_to_account: String
    get() = locales[getCurrentLanguageCode()]?.label_add_email_to_account ?:
        baseLocale.label_add_email_to_account

  public val message_settlement: MessageSettlement
    get() = locales[getCurrentLanguageCode()]?.message_settlement ?: baseLocale.message_settlement

  public val selected_no: SelectedNo
    get() = locales[getCurrentLanguageCode()]?.selected_no ?: baseLocale.selected_no

  public val who_paid_how_much: String
    get() = locales[getCurrentLanguageCode()]?.who_paid_how_much ?: baseLocale.who_paid_how_much

  public val choose_users: String
    get() = locales[getCurrentLanguageCode()]?.choose_users ?: baseLocale.choose_users

  public val show_week: ShowWeek
    get() = locales[getCurrentLanguageCode()]?.show_week ?: baseLocale.show_week

  public val show_month: ShowMonth
    get() = locales[getCurrentLanguageCode()]?.show_month ?: baseLocale.show_month

  public val budget: String
    get() = locales[getCurrentLanguageCode()]?.budget ?: baseLocale.budget

  public val is_all_settled: String
    get() = locales[getCurrentLanguageCode()]?.is_all_settled ?: baseLocale.is_all_settled

  public val you: String
    get() = locales[getCurrentLanguageCode()]?.you ?: baseLocale.you

  public val artificial: String
    get() = locales[getCurrentLanguageCode()]?.artificial ?: baseLocale.artificial

  public val total_price_: TotalPrice
    get() = locales[getCurrentLanguageCode()]?.total_price_ ?: baseLocale.total_price_

  public val settlement_created: String
    get() = locales[getCurrentLanguageCode()]?.settlement_created ?: baseLocale.settlement_created

  public val owes_to: OwesTo
    get() = locales[getCurrentLanguageCode()]?.owes_to ?: baseLocale.owes_to

  public val is_owed_from: IsOwedFrom
    get() = locales[getCurrentLanguageCode()]?.is_owed_from ?: baseLocale.is_owed_from

  public val my_price: String
    get() = locales[getCurrentLanguageCode()]?.my_price ?: baseLocale.my_price

  public val suggested: String
    get() = locales[getCurrentLanguageCode()]?.suggested ?: baseLocale.suggested

  public val unlock_someone: String
    get() = locales[getCurrentLanguageCode()]?.unlock_someone ?: baseLocale.unlock_someone

  public val unlock_someone_desc: String
    get() = locales[getCurrentLanguageCode()]?.unlock_someone_desc ?: baseLocale.unlock_someone_desc

  public val income_created: String
    get() = locales[getCurrentLanguageCode()]?.income_created ?: baseLocale.income_created

  public val income_updated: String
    get() = locales[getCurrentLanguageCode()]?.income_updated ?: baseLocale.income_updated

  public val income_deleted: String
    get() = locales[getCurrentLanguageCode()]?.income_deleted ?: baseLocale.income_deleted

  public val shared: String
    get() = locales[getCurrentLanguageCode()]?.shared ?: baseLocale.shared

  public val expense_deleted: String
    get() = locales[getCurrentLanguageCode()]?.expense_deleted ?: baseLocale.expense_deleted

  public val expense_updated: String
    get() = locales[getCurrentLanguageCode()]?.expense_updated ?: baseLocale.expense_updated

  public val expense_created: String
    get() = locales[getCurrentLanguageCode()]?.expense_created ?: baseLocale.expense_created

  public val message_choose_category: String
    get() = locales[getCurrentLanguageCode()]?.message_choose_category ?:
        baseLocale.message_choose_category

  public val error: String
    get() = locales[getCurrentLanguageCode()]?.error ?: baseLocale.error

  public val profile_updated: String
    get() = locales[getCurrentLanguageCode()]?.profile_updated ?: baseLocale.profile_updated

  public val category_other: String
    get() = locales[getCurrentLanguageCode()]?.category_other ?: baseLocale.category_other

  public val title_delete: String
    get() = locales[getCurrentLanguageCode()]?.title_delete ?: baseLocale.title_delete

  public val message_delete: String
    get() = locales[getCurrentLanguageCode()]?.message_delete ?: baseLocale.message_delete

  public val custom: String
    get() = locales[getCurrentLanguageCode()]?.custom ?: baseLocale.custom

  public val expensive_products: String
    get() = locales[getCurrentLanguageCode()]?.expensive_products ?: baseLocale.expensive_products

  public val expensive_products_description: String
    get() = locales[getCurrentLanguageCode()]?.expensive_products_description ?:
        baseLocale.expensive_products_description

  public val images: String
    get() = locales[getCurrentLanguageCode()]?.images ?: baseLocale.images

  public val january: String
    get() = locales[getCurrentLanguageCode()]?.january ?: baseLocale.january

  public val february: String
    get() = locales[getCurrentLanguageCode()]?.february ?: baseLocale.february

  public val march: String
    get() = locales[getCurrentLanguageCode()]?.march ?: baseLocale.march

  public val april: String
    get() = locales[getCurrentLanguageCode()]?.april ?: baseLocale.april

  public val may: String
    get() = locales[getCurrentLanguageCode()]?.may ?: baseLocale.may

  public val june: String
    get() = locales[getCurrentLanguageCode()]?.june ?: baseLocale.june

  public val july: String
    get() = locales[getCurrentLanguageCode()]?.july ?: baseLocale.july

  public val august: String
    get() = locales[getCurrentLanguageCode()]?.august ?: baseLocale.august

  public val september: String
    get() = locales[getCurrentLanguageCode()]?.september ?: baseLocale.september

  public val october: String
    get() = locales[getCurrentLanguageCode()]?.october ?: baseLocale.october

  public val november: String
    get() = locales[getCurrentLanguageCode()]?.november ?: baseLocale.november

  public val december: String
    get() = locales[getCurrentLanguageCode()]?.december ?: baseLocale.december

  public val notes_from_advisor: String
    get() = locales[getCurrentLanguageCode()]?.notes_from_advisor ?: baseLocale.notes_from_advisor

  public val documents_from_advisor: String
    get() = locales[getCurrentLanguageCode()]?.documents_from_advisor ?:
        baseLocale.documents_from_advisor

  public val dashboard_balance_string: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_balance_string ?:
        baseLocale.dashboard_balance_string

  public val dashboard_total_balance_string: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_total_balance_string ?:
        baseLocale.dashboard_total_balance_string

  public val dashboard_balance: DashboardBalance
    get() = locales[getCurrentLanguageCode()]?.dashboard_balance ?: baseLocale.dashboard_balance

  public val dashboard_income: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_income ?: baseLocale.dashboard_income

  public val dashboard_expected_income: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_expected_income ?:
        baseLocale.dashboard_expected_income

  public val dashboard_expense: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_expense ?: baseLocale.dashboard_expense

  public val dashboard_expected_expense: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_expected_expense ?:
        baseLocale.dashboard_expected_expense

  public val dashboard_previous_months: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_previous_months ?:
        baseLocale.dashboard_previous_months

  public val dashboard_expected_income_expense: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_expected_income_expense ?:
        baseLocale.dashboard_expected_income_expense

  public val dashboard_add_transaction: String
    get() = locales[getCurrentLanguageCode()]?.dashboard_add_transaction ?:
        baseLocale.dashboard_add_transaction

  public val documents: String
    get() = locales[getCurrentLanguageCode()]?.documents ?: baseLocale.documents

  public val document: String
    get() = locales[getCurrentLanguageCode()]?.document ?: baseLocale.document

  public val new_document: String
    get() = locales[getCurrentLanguageCode()]?.new_document ?: baseLocale.new_document

  public val all_documents: String
    get() = locales[getCurrentLanguageCode()]?.all_documents ?: baseLocale.all_documents

  public val attachments: String
    get() = locales[getCurrentLanguageCode()]?.attachments ?: baseLocale.attachments

  public val existing_attachments: String
    get() = locales[getCurrentLanguageCode()]?.existing_attachments ?:
        baseLocale.existing_attachments

  public val no_attachments: String
    get() = locales[getCurrentLanguageCode()]?.no_attachments ?: baseLocale.no_attachments

  public val new_attachments: String
    get() = locales[getCurrentLanguageCode()]?.new_attachments ?: baseLocale.new_attachments

  public val upload_attachment: String
    get() = locales[getCurrentLanguageCode()]?.upload_attachment ?: baseLocale.upload_attachment

  public val visible_to_advisor: String
    get() = locales[getCurrentLanguageCode()]?.visible_to_advisor ?: baseLocale.visible_to_advisor

  public val no_documents_from_advisor: String
    get() = locales[getCurrentLanguageCode()]?.no_documents_from_advisor ?:
        baseLocale.no_documents_from_advisor

  public val rename: String
    get() = locales[getCurrentLanguageCode()]?.rename ?: baseLocale.rename

  public val select_month: String
    get() = locales[getCurrentLanguageCode()]?.select_month ?: baseLocale.select_month

  public val select_week: String
    get() = locales[getCurrentLanguageCode()]?.select_week ?: baseLocale.select_week

  public val from: String
    get() = locales[getCurrentLanguageCode()]?.from ?: baseLocale.from

  public val to: String
    get() = locales[getCurrentLanguageCode()]?.to ?: baseLocale.to

  public val planning_expected_income: String
    get() = locales[getCurrentLanguageCode()]?.planning_expected_income ?:
        baseLocale.planning_expected_income

  public val planning_expected_income_button: String
    get() = locales[getCurrentLanguageCode()]?.planning_expected_income_button ?:
        baseLocale.planning_expected_income_button

  public val planning_expected_expenses: String
    get() = locales[getCurrentLanguageCode()]?.planning_expected_expenses ?:
        baseLocale.planning_expected_expenses

  public val planning_expected_expenses_button: String
    get() = locales[getCurrentLanguageCode()]?.planning_expected_expenses_button ?:
        baseLocale.planning_expected_expenses_button

  public val planning_sum: PlanningSum
    get() = locales[getCurrentLanguageCode()]?.planning_sum ?: baseLocale.planning_sum

  public val planning_income_legend: String
    get() = locales[getCurrentLanguageCode()]?.planning_income_legend ?:
        baseLocale.planning_income_legend

  public val planning_income_legend_switch: String
    get() = locales[getCurrentLanguageCode()]?.planning_income_legend_switch ?:
        baseLocale.planning_income_legend_switch

  public val planning_income: String
    get() = locales[getCurrentLanguageCode()]?.planning_income ?: baseLocale.planning_income

  public val planning_income_business: String
    get() = locales[getCurrentLanguageCode()]?.planning_income_business ?:
        baseLocale.planning_income_business

  public val planning_income_social: String
    get() = locales[getCurrentLanguageCode()]?.planning_income_social ?:
        baseLocale.planning_income_social

  public val planning_income_other: String
    get() = locales[getCurrentLanguageCode()]?.planning_income_other ?:
        baseLocale.planning_income_other

  public val planning_expense_rent: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_rent ?:
        baseLocale.planning_expense_rent

  public val planning_expense_electricity: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_electricity ?:
        baseLocale.planning_expense_electricity

  public val planning_expense_water: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_water ?:
        baseLocale.planning_expense_water

  public val planning_expense_gas: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_gas ?:
        baseLocale.planning_expense_gas

  public val planning_expense_heating: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_heating ?:
        baseLocale.planning_expense_heating

  public val planning_expense_otherLivingExpenses: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_otherLivingExpenses ?:
        baseLocale.planning_expense_otherLivingExpenses

  public val planning_expense_telephone: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_telephone ?:
        baseLocale.planning_expense_telephone

  public val planning_expense_tv: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_tv ?: baseLocale.planning_expense_tv

  public val planning_expense_internet: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_internet ?:
        baseLocale.planning_expense_internet

  public val planning_expense_medical: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_medical ?:
        baseLocale.planning_expense_medical

  public val planning_expense_food: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_food ?:
        baseLocale.planning_expense_food

  public val planning_expense_transportation: String
    get() = locales[getCurrentLanguageCode()]?.planning_expense_transportation ?:
        baseLocale.planning_expense_transportation

  public val no_upload_days: NoUploadDays
    get() = locales[getCurrentLanguageCode()]?.no_upload_days ?: baseLocale.no_upload_days
}

package com.erstedigital.socialbank.utils

import com.erstedigital.socialbank.ui.home.utils.CategoryInput

fun String?.parseCategoryInput(): CategoryInput? {
    if (this == null) {
        return null
    }
    val splitted = this.split("/")

    val first = splitted.firstOrNull() ?: ""
    var second = splitted.getOrNull(1)

    if (second == "null") {
        second = null
    }

    return CategoryInput(first, second)
}
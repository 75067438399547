package com.erstedigital.socialbank.data.datasources.user

import com.erstedigital.socialbank.data.entity.UserEntity
import com.erstedigital.socialbank.data.network.utils.NetworkResultState
import com.erstedigital.socialbank.domain.exception.NotLoggedInException
import com.erstedigital.socialbank.domain.utils.toErrorResult
import com.russhwolf.settings.ExperimentalSettingsApi
import com.russhwolf.settings.ObservableSettings
import com.russhwolf.settings.Settings
import com.russhwolf.settings.coroutines.SuspendSettings
import com.russhwolf.settings.serialization.decodeValueOrNull
import com.russhwolf.settings.serialization.encodeValue
import com.russhwolf.settings.set
import io.github.aakira.napier.Napier
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.json.Json

class UserLocalDataSource(
    private val settings: SuspendSettings
): UserDataSource.Local {


    private var currentUser: UserEntity? = null

    override suspend fun getUser(): UserEntity? {
        currentUser = retrieveSavedUser()
        return currentUser
    }

    @OptIn(ExperimentalSettingsApi::class)
    private suspend fun retrieveSavedUser(): UserEntity? {
        try {
            val stringUser = settings.getString(KEY_USER, "")
            return if (stringUser.isNotEmpty()) {
                Json.decodeFromString(UserEntity.serializer().nullable, stringUser)
            } else {
                null
            }

        } catch (e: Exception) {
            e.printStackTrace()
        }
        return null
    }

    @OptIn(ExperimentalSerializationApi::class, ExperimentalSettingsApi::class)
    private suspend fun saveUser(user: UserEntity?) {
        if (user == null) {
            settings.remove(KEY_USER)
            return
        }

        val encodedUser = Json.encodeToString(UserEntity.serializer().nullable, user)
        settings.putString(KEY_USER, encodedUser)
    }

    override suspend fun getUserResult(): NetworkResultState<UserEntity> {
        if (getUser() == null) {
            return NotLoggedInException().toErrorResult()
        }
        return NetworkResultState.Success(getUser()!!)
    }

    override suspend fun setUser(user: UserEntity?) {
        saveUser(user)
    }

    override suspend fun isLoggedIn(): Boolean {
        return getUser()?.token != null
    }

    companion object {
        const val PREFS_NAME = "PREFS_SOCIAL_BANK"
        const val KEY_USER = "KEY_APP_USER"
    }
}
package com.erstedigital.socialbank.domain.models

import kotlinx.serialization.Serializable

@Serializable
data class OrgUnitModel(
    val id: Long?,
    var name: String?,
    var streetName: String?,
    var buildingNumber: String?,
    var cashRegisterCode: String?,
    var country: String?,
    var municipality: String?,
    var postalCode: String?,
    var propertyRegistrationNumber: String?,
    var type: String?
)
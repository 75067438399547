package com.erstedigital.socialbank.domain.usecase.transactions

import com.erstedigital.socialbank.data.datasources.company.CompanyRemoteDataSource
import com.erstedigital.socialbank.data.network.models.common.Organization
import com.erstedigital.socialbank.data.network.models.request.CreateCompanyRequest
import com.erstedigital.socialbank.data.network.models.response.ErrorResponse
import com.erstedigital.socialbank.data.network.utils.ApiResponse

class CreateOrganizationUsecase(
    private val companyRemoteDataSource: CompanyRemoteDataSource
) {
    suspend fun createOrganization(createCompanyRequest: CreateCompanyRequest): ApiResponse<Organization, ErrorResponse> {
        return companyRemoteDataSource.create(createCompanyRequest)
    }
}